/* eslint no-mixed-operators: 0 */
/* eslint no-nested-ternary: 0 */

import React from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { Link } from 'react-router';
import { connect } from 'react-redux';

import get from 'lodash/get';

import IoChatboxes from 'react-icons/lib/io/chatboxes';

import Modal from '../../components/shared/Modal';
import Loader from '../../components/shared/Loader';
import CommentBox from '../../components/orders/CommentBox';
import FilterDrawer from '../../components/shared/FilterDrawer';
import DiffsList from '../../components/orders/diffs/DiffsList';

import { fetchReqn } from '../../actions/order/reqn';
import { fetchOrder } from '../../actions/order/orders';
import { editOrder } from '../../actions/order/editOrder';
import { fetchVendor } from '../../actions/vendor/fetchVendor';
import { fetchProject } from '../../actions/fetchProject';
import { fetchReqnDiff, resetReqnDiff } from '../../actions/order/reqnDiff';
import { createOrderReset } from '../../actions/order/createOrder';

import toUsd from '../../etc/toUsd';

require('../../sass/containers/Orders/RequisitionView.scss');

function getReqnCompany(companyAbbrev) {
  switch (companyAbbrev) {
    case 'ses':
      return 'Sevenson Environmental Services, Inc.';
    case 'sesPA':
      return 'SES of PA';
    case 'sis':
      return 'Sevenson Industrial Services, Inc.';
    default:
      return 'Sevenson Environmental Services, Inc.';
  }
}

function getPaymentTerms(termsObj) {
  if (!termsObj) return '';
  switch (termsObj.selectedType) {
    case 'subContractRetainer':
      return `${termsObj.retentionRate}% net 10 days after Contractor receives
    payment Vendor's work from Owner. Balance: 45 days after Owner's
    acceptance of work and payment of retention to Contractor.`;
    case 'netDays':
      return `Net ${termsObj.netDays} days.`;
    case 'discountAndNetDays':
      return `${termsObj.discountRate}% ${termsObj.discountDays} days,
    net ${termsObj.netDays} days.`;
    case 'other':
      return `${termsObj.other}`;
    default:
      return '';
  }
}

function getSchedule() {
  return 'As ordered by Contractor.';
}

function getFob(fobType) {
  switch (fobType) {
    case 'jobsite':
      return 'Jobsite';
    case 'vendorPlant':
      return 'Vendor Plant';
    case 'vendorYard':
      return 'Vendor Yard';
    default:
      return '';
  }
}

function getVia(viaType) {
  switch (viaType) {
    case 'vendor':
      return 'Vendor';
    case 'contractor':
      return 'Contractor';
    case 'commonCarrier':
      return 'Common Carrier';
    default:
      return '';
  }
}

const rendProjCon = con => {
  if (!con) return null;
  con.name = con.name || {};
  return (
    <span>
      <p>{[con.name.first, con.name.last].filter(Boolean).join(' ')}</p>
      <p>{con.title}</p>
      <p>{con.email}</p>
      <p>{con.phone}</p>
    </span>
  );
};

const rendVendRep = rep => {
  if (!rep) return null;
  rep.name = rep.name || {};
  return (
    <span>
      <p>{[rep.name.first, rep.name.last].filter(Boolean).join(' ')}</p>
      <p>{rep.email}</p>
      <p>{rep.phone}</p>
    </span>
  );
};

const isStale = obj => !obj.lastUpdated || obj.lastUpdated < Date.now() - 5e3;

const combineDiff = (idx, diff, data) =>
  Object.entries(diff).reduce((obj, [key, val]) => ({ ...obj, [key]: val[idx] }), { ...data });

// eslint-disable-next-line no-confusing-arrow
const pickDiff = (diff, data, combine) => (__, idx) =>
  combine ? combineDiff(idx, diff, data) : diff[idx];

const pickDiffs = (diff, data, combine) => new Array(2).fill('').map(pickDiff(diff, data, combine));

@connect(s => ({
  order: s.order,
  reqn: s.reqn,
  user: s.user,
  reqnDiff: s.reqnDiff,
  oldVendor: s.vendor,
  oldProject: s.project,
  createOrder: s.createOrder
}))
export default class RequisitionView extends React.Component {
  static need = [fetchOrder, fetchReqn];

  constructor(props) {
    super(props);

    this.state = { historyDrawer: false };

    this.approve = this.approve.bind(this);

    this.getActiveDiff = this.getActiveDiff.bind(this);
    this.renderDiff = this.renderDiff.bind(this);
    this.commentExist = this.commentExist.bind(this);

    this.toggleDrawer = this.toggleDrawer.bind(this);

    this.closeCommentBox = this.closeCommentBox.bind(this);
    this.openCommentBox = this.openCommentBox.bind(this);
  }

  componentWillMount() {
    const { reqn, dispatch, params } = this.props;
    if (isStale(reqn) || reqn._id !== params.reqnId) {
      dispatch(fetchReqn(params));
    }
  }

  componentWillReceiveProps(nextProps) {
    const { reqn, dispatch, oldVendor, oldProject } = this.props;

    const diff = this.getActiveDiff(nextProps);

    if (reqn.version !== nextProps.reqn.version) {
      const { vendorRef, projectRef } = diff;

      if (vendorRef && vendorRef[0]) dispatch(fetchVendor({ id: vendorRef[0] }));
      if (projectRef && projectRef[0]) dispatch(fetchProject({ id: projectRef[0] }));
    }

    if (
      oldVendor.data &&
      nextProps.oldVendor &&
      oldVendor.data._id !== nextProps.oldVendor.data._id
    ) {
      const newVend = reqn.data.vendorRef;
      diff.vendorRef = Object.entries(newVend).reduce((vend, [key, nVal]) => {
        const oVal = nextProps.oldVendor.data[key];
        return { ...vend, [key]: oVal ? [oVal, nVal] : [nVal] };
      }, {});
    }

    if (
      oldProject.data &&
      nextProps.oldProject.data &&
      oldProject.data._id !== nextProps.oldProject.data._id
    ) {
      const newProj = reqn.data.projectRef;
      diff.projectRef = Object.entries(newProj).reduce((proj, [key, nVal]) => {
        const oVal = nextProps.oldProject.data[key];
        return { ...proj, [key]: oVal ? [oVal, nVal] : [nVal] };
      }, {});
    }
  }

  componentWillUnmount() {
    const { dispatch, createOrder } = this.props;

    dispatch(resetReqnDiff());

    if (createOrder.submitted) {
      dispatch(createOrderReset());
    }
  }

  getActiveDiff(nextProps) {
    const { reqn, reqnDiff } = nextProps || this.props;
    const { historyDrawer } = this.state;

    const { version } = reqn;
    const thisHist = reqnDiff.data[version];

    return historyDrawer && Number.isFinite(version) && thisHist && thisHist.diff;
  }

  openCommentBox({ labelText, position }, e) {
    const comments = this.props.reqn.data.comments || [];

    const saveIdx = comments.findIndex(comment => comment.position === position);
    const { message } = comments[saveIdx];

    this.setState({ commentBox: { labelText, message, top: e.clientY, left: e.clientX } });
  }

  closeCommentBox() {
    this.setState({ commentBox: false });
  }

  commentExist(field, name) {
    const { comments } = this.props.reqn.data;

    if (!comments || comments.findIndex(c => c.position === field) === -1) return null;

    return (
      <IoChatboxes
        className="view-comment"
        onClick={e => this.openCommentBox({ labelText: name, position: field }, e)}
        style={{ display: this.state.historyDrawer ? 'none' : null }}
      />
    );
  }

  toggleDrawer() {
    const isOpen = this.state.historyDrawer;
    const { reqnDiff, dispatch, params } = this.props;

    if (!isOpen && isStale(reqnDiff)) {
      dispatch(fetchReqnDiff(params));
    }

    this.setState({ historyDrawer: !isOpen });
  }

  linkToEdit(page) {
    const { reqn, user } = this.props;
    const order = this.props.order.data;
    const project = order.projectRef;
    const reqnData = reqn.data;

    const currentUser = user.data._id;

    const { authOverrides } = this.props.user.data || {};
    const { orders: orderAOs } = authOverrides;
    const canEdit = authOverrides && orderAOs.edit;

    const isApprover =
      project &&
      project.approvers &&
      project.approvers.find(approver => approver.userRef === currentUser);
    const isAccessor =
      project &&
      project.accessors &&
      project.accessors.find(accessor => accessor.userRef === currentUser);

    if (
      reqnData._id === order.reqns[order.reqns.length - 1]._id &&
      canEdit &&
      (order.status === 'open' || order.status === 'needEdit') &&
      (isApprover || isAccessor) &&
      reqn.author &&
      reqn.author.userRef === currentUser
    ) {
      return (
        <Link
          to={`/orders/${reqnData.orderRef && reqnData.orderRef._id}/reqns/${
            reqnData._id
          }/edit?wizard=${page}`}
          className="simple-edit"
          target="_blank"
        >
          Edit
        </Link>
      );
    }

    return null;
  }

  linkToApprove() {
    const { reqn, user } = this.props;
    const order = this.props.order.data;
    const project = order.projectRef;
    const reqnData = reqn.data;

    const currentUser = user.data._id;

    const { authOverrides } = this.props.user.data || {};
    const { orders: orderAOs } = authOverrides;
    const canApprove = authOverrides && orderAOs.approve;

    const isApprover =
      project &&
      project.approvers &&
      project.approvers.find(approver => approver.userRef === currentUser);

    if (
      reqnData._id === order.reqns[order.reqns.length - 1]._id &&
      canApprove &&
      order.status === 'open' &&
      isApprover
    ) {
      return (
        <button
          className="standard-button secondary-button"
          onClick={() => this.setState({ approve: true })}
        >
          Approve
        </button>
      );
    }
    return null;
  }

  approve(e) {
    e && e.preventDefault();

    const { order, dispatch } = this.props;
    const submitObject = { ...order.data };

    submitObject.status = 'needSignature';

    dispatch(editOrder(submitObject, submitObject._id));
  }

  renderDiff(key, format = x => x, combineParent) {
    let thisDiff;

    const { reqn } = this.props;
    const reqnData = reqn.data;
    const activeDiff = this.getActiveDiff();

    const keyMatch = key.match(/(\w+)\.(\d+)\.(\w+)/);
    if (keyMatch) {
      const [parentKey, idx, childKey] = keyMatch.slice(1);

      if (!get(activeDiff, key)) {
        const added = get(activeDiff, [parentKey, idx]);
        const removed = get(activeDiff, [parentKey, `_${idx}`]);
        if (added && Array.isArray(added)) {
          thisDiff = [undefined, added[0][childKey]];
        } else if (removed && Array.isArray(removed)) {
          thisDiff = [removed[0][childKey], undefined];
        }
      }
    }

    thisDiff = thisDiff || (activeDiff && get(activeDiff, key));
    const thisData = reqnData && get(reqnData, key);

    const [diff0, diff1] = thisDiff ? pickDiffs(thisDiff, thisData, combineParent) : [];

    return (
      <span>
        {thisDiff ? (
          <span className="diff-text">
            <span className="diff-deleted">{format(diff0)}</span>
            <span className="diff-added">{format(diff1)}</span>
          </span>
        ) : (
          format(get(reqnData, key))
        )}
      </span>
    );
  }

  render() {
    const { reqn, reqnDiff, oldVendor, oldProject } = this.props;
    const order = this.props.order.data;
    const { commentBox } = this.state;
    const reqnData = reqn.data;

    const isFetching =
      reqn.isFetching || reqnDiff.isFetching || oldVendor.isFetching || oldProject.isFetching;

    if (!reqnData) return <Loader {...{ isFetching }} />;

    const items = reqnData.items && [...reqnData.items];

    if (!reqn || !order) {
      return (
        <div className="requisition-view-page">
          <h2>Sorry, this Order could not be found.</h2>
        </div>
      );
    }

    return (
      <div className="requisition-view-page">
        <Loader {...{ isFetching }} />
        {commentBox && (
          <CommentBox
            readOnly
            title={commentBox.labelText}
            style={{ top: commentBox.top + 40, left: commentBox.left - 200 }}
            close={this.closeCommentBox}
            message={commentBox.message}
          />
        )}
        <div className="pre-table-header">
          <div className="page-title" id="number">
            Viewing: Requisition
            {!reqnData.isChangeOrder ? (
              reqnData.number ? (
                <span> #{this.renderDiff('number')}</span>
              ) : (
                ' (Unnumbered)'
              )
            ) : reqnData.isInternal ? (
              <p>Internal {moment(reqnData.createdAt).format('M-D-YY')}</p>
            ) : (
              <span>
                #{this.renderDiff('originalPONumber')}, CO #{this.renderDiff('number')}
              </span>
            )}
            {this.commentExist('reqn.number', 'Requisition Number')}
          </div>
          <button
            className="standard-button primary-button justify-right"
            onClick={this.toggleDrawer}
          >
            {this.state.historyDrawer ? 'Hide' : 'Show'} History
          </button>
          {this.linkToApprove}
        </div>
        <div className={classNames('page-body', { 'history-open': this.state.historyDrawer })}>
          <div className="reqn-content">
            <div className="reqn-section">
              <p className="emph blue">
                This is a {reqnData.isInternal && 'Internal '}
                {order.isTD && 'T&D '}
                {reqnData.isChangeOrder ? 'Change Order' : 'Purchase Order'}.
              </p>
            </div>
            <div className="reqn-section" id="company">
              <p className="title emph">Operating Company {this.linkToEdit(0)}</p>
              <div className="body">{this.renderDiff('company', getReqnCompany)}</div>
            </div>
            <div className="reqn-section" id="date">
              <p className="title emph">
                Date {this.commentExist('reqn.date', 'Date')}
                {this.linkToEdit(0)}
              </p>
              <div className="body">
                {this.renderDiff('date', x => moment.utc(x).format('dddd, MMMM Do, YYYY'))}
              </div>
            </div>
            <div className="reqn-section" id="projectRef">
              <p className="title emph">
                Project Name {this.commentExist('mainContact.name', 'Project')}
                {this.linkToEdit(1)}
              </p>
              <p className="body">{this.renderDiff('projectRef.name')}</p>
            </div>
            <div className="reqn-section" id="projectRef">
              <p className="title emph">Main Contact Information {this.linkToEdit(1)}</p>
              <div className="body">{this.renderDiff('projectRef.mainContact', rendProjCon)}</div>
            </div>
            <div className="reqn-section" id="vendorRef">
              <p className="title emph">
                Vendor Information {this.commentExist('representative.name', 'Vendor')}
                {this.linkToEdit(2)}
              </p>
              <div className="body">
                <p>{this.renderDiff('vendorRef.name')}</p>
                {this.renderDiff('vendorRef.representative', rendVendRep)}
              </div>
            </div>
            {order.category === 'service' && (
              <div className="reqn-section" id="header">
                <p className="title emph">Header {this.linkToEdit(0)}</p>
                <div className="body">{this.renderDiff('header')}</div>
              </div>
            )}
            <div className="reqn-section" id="paymentTerms">
              <p className="title emph">
                Payment Terms {this.commentExist('reqn.paymentTerms.selectedType', 'Payment Terms')}
                {this.linkToEdit(3)}
              </p>
              <div className="body">{this.renderDiff('paymentTerms', getPaymentTerms, true)}</div>
            </div>
            <div className="reqn-section">
              <p className="title emph">
                Other Details
                {this.linkToEdit(4)}
              </p>
              <div className="body">
                <div className="row">
                  <div id="schedule">
                    <p className="emph">
                      Schedule {this.commentExist('reqn.schedule', 'Schedule')}
                    </p>
                    <p>
                      {this.renderDiff('schedule', x =>
                        x === 'other' ? this.renderDiff('scheduleOther') : getSchedule(x)
                      )}
                    </p>
                  </div>
                  <div id="fob">
                    <p className="emph">FOB {this.commentExist('reqn.fob', 'FOB')}</p>
                    <p>
                      {this.renderDiff('fob', x =>
                        x === 'other' ? this.renderDiff('fobOther') : getFob(x)
                      )}
                    </p>
                  </div>
                </div>
                <div id="via">
                  <p className="emph">
                    Party responsible for delivering materials to jobsite:
                    {this.commentExist('reqn.via', 'Via')}
                  </p>
                  <p>
                    {this.renderDiff('via', x =>
                      x === 'other' ? this.renderDiff('viaOther') : getVia(x)
                    )}
                  </p>
                </div>
              </div>
            </div>
            <div className="reqn-section" id="notes">
              <p className="title emph">
                Notes {this.commentExist('reqn.notes', 'Notes')} {this.linkToEdit(6)}
              </p>
              <p className="body">{this.renderDiff('notes')}</p>
            </div>
            <div className="reqn-section" id="items">
              <p className="title emph">
                Added Items {this.commentExist('reqn.items', 'Items')}
                {this.linkToEdit(5)}
              </p>
              <div className="body" id="costCode">
                <div>
                  <p className="emph">
                    Cost Code {this.commentExist('reqn.costCode', 'Cost Code')}
                  </p>
                  <p>{this.renderDiff('costCode', x => x || 'See individual items.')}</p>
                  {order.notToExceed && <h3 className="nte">NOT TO EXCEED</h3>}
                </div>
              </div>
              <table className="simple-table">
                <thead>
                  <tr>
                    <th>Quantity</th>
                    <th>Unit</th>
                    <th>Name</th>
                    <th>Price</th>
                    <th>Code</th>
                    <th>Total</th>
                    <th>Tax</th>
                  </tr>
                </thead>
                <tbody>
                  {items &&
                    items.length &&
                    items.map((item, idx) => (
                      <tr key={item._id || idx}>
                        <td>{this.renderDiff(`items.${idx}.quantity`)}</td>
                        <td>{this.renderDiff(`items.${idx}.unit`)}</td>
                        <td>{this.renderDiff(`items.${idx}.description`)}</td>
                        <td>{this.renderDiff(`items.${idx}.unitPrice`, toUsd)}</td>
                        <td>{this.renderDiff(`items.${idx}.costCode`)}</td>
                        <td>{this.renderDiff(`items.${idx}.total`, toUsd)}</td>
                        <td>{this.renderDiff(`items.${idx}.hasTax`, x => (x ? 'Yes' : 'No'))}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
              <table className="totals-group">
                <tbody>
                  <tr id="subtotal">
                    <td className="label-column">Subtotal</td>
                    <td className="total-column">{this.renderDiff('subtotal', toUsd)}</td>
                  </tr>
                  <tr id="salesTax">
                    <td className="label-column">Sales Tax (%)</td>
                    <td className="total-column">{this.renderDiff('salesTax')}%</td>
                  </tr>
                  <tr id="taxTotal">
                    <td className="label-column">Sales Tax Total</td>
                    <td className="total-column">{this.renderDiff('taxTotal', toUsd)}</td>
                  </tr>
                  <tr id="totalOffset">
                    <td className="label-column">Total Offset</td>
                    <td className="total-column">{this.renderDiff('totalOffset', toUsd)}</td>
                  </tr>
                  <tr id="total">
                    <td className="label-column">Total</td>
                    <td className="total-column">{this.renderDiff('total', toUsd)}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <FilterDrawer
            right
            noCloseOnBlur
            open={this.state.historyDrawer}
            closeDrawer={this.toggleDrawer}
            className="history-drawer"
          >
            <DiffsList diffs={reqnDiff.data} />
          </FilterDrawer>
        </div>
        {this.state.approve && (
          <Modal
            isOpen
            extraClass="delete-confirm-modal"
            ref={modal => {
              this.approve = modal;
            }}
            toCancel={() => {
              this.setState({ approve: undefined });
            }}
          >
            <div className="modal-text-top">
              <p className="modal-header">Approve?</p>
              <p className="modal-subheader">
                Mark this order as ready for signature? This may not be undone.
              </p>
            </div>
            <div className="modal-body">
              <div className="modal-buttons">
                <button className="modal-button modal-add" onClick={this.approve}>
                  confirm
                </button>
                <button
                  className="modal-button modal-cancel"
                  onClick={() => {
                    this.setState({ approve: undefined });
                  }}
                >
                  cancel
                </button>
              </div>
            </div>
          </Modal>
        )}
      </div>
    );
  }
}
