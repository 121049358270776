import axios from 'axios';
import * as types from '../constants/users';
import transAxoisErr from '../etc/axios/transformAxiosError';

export const fetchUsersRequest = () => ({ type: types.FETCH_USERS_REQUEST });

export const fetchUsersSuccess = ({ data, next, prev }) => ({
  data,
  next,
  prev,
  receivedAt: Date.now(),
  type: types.FETCH_USERS_SUCCESS
});

export const fetchUsersFailure = error => ({
  error,
  receivedAt: Date.now(),
  type: types.FETCH_USERS_FAILURE
});

export const fetchUsers = ({ next, ...params } = {}) => dispatch => {
  dispatch(fetchUsersRequest());
  return axios
    .get(next || '/user/all', { params })
    .then(
      res => dispatch(fetchUsersSuccess(res.data)),
      error => dispatch(fetchUsersFailure(transAxoisErr(error)))
    );
};

export const sdpRequest = () => ({
  type: types.SDP_USER_REQUEST
});

export const sdpSuccess = ({ data }) => ({
  data,
  receivedAt: Date.now(),
  type: types.SDP_USER_SUCCESS
});

export const sdpFailure = error => ({
  error,
  receivedAt: Date.now(),
  type: types.SDP_USER_FAILURE
});

export const sdp = id => dispatch => {
  dispatch(sdpRequest());
  return axios
    .put(`/user/${id}/set-default-password`)
    .then(res => dispatch(sdpSuccess(res.data)))
    .catch(error => dispatch(sdpFailure(transAxoisErr(error))));
};
