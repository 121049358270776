import React from 'react';
import { Link } from 'react-router';

const { Typeahead } = require('react-typeahead');

export default class OrderWizardProjectInfo extends React.Component {
  componentWillReceiveProps(nextProps) {
    if (this.props.typeaheadVal !== nextProps.typeaheadVal) {
      this.typeahead && this.typeahead.setEntryText(nextProps.typeaheadVal);
    }
  }

  render() {
    const {
      COTextInput,
      projects,
      handleProjectSelect,
      typeaheadVal,
      errors = [],
      edit,
      comments,
      user
    } = this.props;

    const { authLevel, authOverrides } = user.data;
    const canAddNewProjects = authLevel >= 2 || (authOverrides && authOverrides.projects.create);

    const typeaheadClasses = {
      input: `text-input-standard ${(errors.project || errors.projectAccess) && 'input-error'}`,
      results: 'typeahead-results',
      listItem: 'typeahead-list-item',
      listAnchor: 'typeahead-list-anchor',
      hover: 'typeahead-item-hover',
      typeahead: 'standard-typeahead',
      resultsTruncated: 'typeahead-results'
    };

    const typeaheadRef = this.typeahead;

    return (
      <div>
        <div className="top-bar">
          <Typeahead
            closeDropdownOnBlur
            id="projectRef"
            options={projects}
            maxVisible={6}
            filterOption="numberName"
            displayOption="numberName"
            customClasses={typeaheadClasses}
            onOptionSelected={handleProjectSelect}
            value={typeaheadVal}
            placeholder="Type Project name"
            ref={el => (this.typeahead = el)}
          />
          {canAddNewProjects && (
            <Link
              to={{
                pathname: '/projects/create',
                state: {
                  modal: true,
                  returnTo: '/orders/create',
                  extraClass: 'add-edit-modal add-project-modal',
                  defaultName: typeaheadRef && typeaheadRef.state && typeaheadRef.state.entryValue
                }
              }}
              className="standard-button outline-button"
            >
              Add New
            </Link>
          )}
        </div>
        <p className="form-section-title">Project Information</p>
        <div className="input-fields">
          <div className="modal-form-block">
            <COTextInput
              readOnly
              labelText="Main Respresentative"
              name="mainContact.name"
              edit={edit}
              commentExist={comments.findIndex(c => c.position === 'mainContact.name') > -1}
            />
            <COTextInput readOnly labelText="Title" name="mainContact.title" />
          </div>
          <div className="modal-form-block">
            <COTextInput readOnly type="tel" labelText="Phone" name="mainContact.phone" />
            <COTextInput readOnly type="email" labelText="Email" name="mainContact.email" />
          </div>
        </div>
      </div>
    );
  }
}
