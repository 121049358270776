import * as actions from '../../constants/order/reqn';
import * as diffActions from '../../constants/order/reqnDiff';

const initialState = {
  data: {},
  version: null,
  isFetching: false,
  lastUpdated: null,
  error: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_REQN_REQUEST:
    case actions.GET_REQN_VERSION_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: {}
      };
    case actions.FETCH_REQN_FAILURE:
    case actions.GET_REQN_VERSION_FAILURE:
      return {
        ...state,
        isFetching: false,
        lastUpdated: action.receivedAt,
        error: action.error
      };
    case actions.FETCH_REQN_SUCCESS:
    case actions.GET_REQN_VERSION_SUCCESS:
      return {
        ...state,
        data: action.payload,
        version: Number.isFinite(action.version) ? action.version : state.version,
        isFetching: false,
        lastUpdated: action.receivedAt,
        error: {}
      };
    case diffActions.FETCH_REQN_DIFF_SUCCESS:
      return {
        ...state,
        version: action.payload.slice(-1)[0].version
      };
    default:
      return state;
  }
};
