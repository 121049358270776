import axios from 'axios';
import * as types from '../../constants/order/orders';
import transAxoisErr from '../../etc/axios/transformAxiosError';

export const deleteOrderRequest = () => ({type: types.DELETE_ORDER_REQUEST});

export const deleteOrderSuccess = ({ data }) => ({
  data,
  receivedAt: Date.now(),
  type: types.DELETE_ORDER_SUCCESS
});

export const deleteOrderFailure = error => ({
  error,
  receivedAt: Date.now(),
  type: types.DELETE_ORDER_FAILURE
});

export const deleteOrder = id => (dispatch) => {
  dispatch(deleteOrderRequest());
  return axios
    .delete(`/order/${id}`)
    .then(res => dispatch(deleteOrderSuccess(res.data)))
    .catch(error => dispatch(deleteOrderFailure(transAxoisErr(error))));
};
