import * as actions from '../constants/weather';

const initialState = {
  data: {},
  isFetching: false,
  lastUpdated: null,
  error: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.WEATHER_REQUEST:
      return { ...state, isFetching: true, error: {} };
    case actions.WEATHER_FAILURE:
      return {
        ...state,
        isFetching: false,
        lastUpdated: action.receivedAt,
        error: action.error
      };
    case actions.WEATHER_SUCCESS:
      return {
        ...state,
        data: action.data,
        isFetching: false,
        lastUpdated: action.receivedAt,
        error: {}
      };
    default:
      return state;
  }
};
