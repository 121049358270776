import axios from 'axios';
import moment from 'moment';
import * as types from '../constants/weather';
import transAxoisErr from '../etc/axios/transformAxiosError';

export const weatherRequest = () => ({ type: types.WEATHER_REQUEST });

export const weatherSuccess = ({ data }) => ({
  data,
  receivedAt: Date.now(),
  type: types.WEATHER_SUCCESS
});

export const weatherFailure = error => ({
  error,
  receivedAt: Date.now(),
  type: types.WEATHER_FAILURE
});

// TODO: use prefetched lat/lng when possible;
export const fetchWeather = (projectId, date) => dispatch => {
  dispatch(weatherRequest());
  return axios
    .get(`/project/${projectId}/weather`, { params: { date: moment(date).format() } })
    .then(res => {
      dispatch(weatherSuccess(res.data));
    })
    .catch(error => dispatch(weatherFailure(transAxoisErr(error))));
};
