import thunk from 'redux-thunk';
import createRavenMiddleware from 'raven-for-redux';
import { createLogger } from 'redux-logger';
import { routerMiddleware } from 'react-router-redux';
import { createStore, applyMiddleware, compose } from 'redux';

import rootReducer from '../reducers';
import promiseMiddleware from '../api/promiseMiddleware';

/*
 * @param {Object} initial state to bootstrap our stores with for server-side rendering
 * @param {History Object} a history object. We use `createMemoryHistory` for server-side rendering,
 *                          while using browserHistory for client-side
 *                          rendering.
 */
export default function configureStore(initialState, history) {
  // Installs hooks that always keep react-router and redux
  // store in sync
  const middleware = [thunk, promiseMiddleware, routerMiddleware(history)];
  if (__DEVCLIENT__) {
    middleware.push(createLogger());
  } else {
    const raven = global.window && window.Raven;
    raven &&
      middleware.push(
        createRavenMiddleware(raven, {
          actionTransformer: () => {},
          stateTransformer: () => {}
        })
      );
  }

  const store = createStore(
    rootReducer,
    initialState,
    compose(
      applyMiddleware(...middleware),
      typeof window === 'object' && typeof window.devToolsExtension !== 'undefined'
        ? window.devToolsExtension({ trace: true })
        : f => f
    )
  );

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('reducers', () => {
      const nextReducer = require('../reducers'); // eslint-disable-line global-require
      store.replaceReducer(nextReducer);
    });
  }

  return store;
}
