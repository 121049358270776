import React from 'react';
import ErrorPage from '../components/shared/ErrorPage';

require('../sass/main.scss');

/*
 * React-router's <Router> component renders <Route>'s
 * and replaces `this.props.children` with the proper React Component.
 *
 * Please refer to `routes.jsx` for the route config.
 *
 * A better explanation of react-router is available here:
 * https://github.com/rackt/react-router/blob/latest/docs/Introduction.md
 */
export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      setTimeout(() => (document.body.className = ''), 500);
    }
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error });

    console.error(error);

    const raven = global.window && window.Raven;
    raven && raven.captureException(error, { extra: errorInfo });
  }

  render() {
    if (this.state.error) return <ErrorPage />;

    return <div className="container">{this.props.children}</div>;
  }
}
