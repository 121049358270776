import React from 'react';

import Modal from '../../components/shared/Modal';
import UploadFileModalBody from './UploadFileModalBody';

require('../../sass/components/orders/UploadFileModal.scss');

export default class UploadFileModal extends React.Component {
  render() {
    const { COTextInput, totalProgress, filesArray, toCancel, save, handleDrop } = this.props;

    return (
      <Modal isOpen toCancel={toCancel} extraClass="upload-file-modal">
        <div className="modal-text-top">
          <p className="modal-header">Upload Files</p>
        </div>
        <div className="modal-body">
          <UploadFileModalBody
            onDrop={handleDrop}
            totalProgress={totalProgress}
            filesArray={filesArray}
            coTextInput={COTextInput}
          />
          <div className="modal-buttons">
            <button className="modal-button modal-add" onClick={save}>
              Save
            </button>
            <button type="button" className="modal-button modal-cancel" onClick={toCancel}>
              Cancel
            </button>
          </div>
        </div>
      </Modal>
    );
  }
}
