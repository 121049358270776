import axios from 'axios';
import * as types from '../constants/zipProject';
import transAxoisErr from '../etc/axios/transformAxiosError';

export const makeProjectZipRequest = () => ({type: types.MAKE_PROJECT_ZIP_REQUEST});

export const makeProjectZipSuccess = ({ data }) => ({
  type: types.MAKE_PROJECT_ZIP_SUCCESS,
  payload: data,
  receivedAt: Date.now()
});

export const makeProjectZipFailure = error => ({
  type: types.MAKE_PROJECT_ZIP_FAILURE,
  error,
  receivedAt: Date.now()
});

export const makeProjectZip = id => (dispatch) => {
  dispatch(makeProjectZipRequest());
  return axios
    .post(`/project/${id}/zip-pdfs`)
    .then(res => dispatch(makeProjectZipSuccess(res.data)))
    .catch(error => dispatch(makeProjectZipFailure(transAxoisErr(error))));
};

export const makeProjectMediaZipRequest = () => ({type: types.MAKE_PROJECT_MEDIA_ZIP_REQUEST});

export const makeProjectMediaZipSuccess = ({ data }) => ({
  type: types.MAKE_PROJECT_MEDIA_ZIP_SUCCESS,
  payload: data,
  receivedAt: Date.now()
});

export const makeProjectMediaZipFailure = error => ({
  type: types.MAKE_PROJECT_MEDIA_ZIP_FAILURE,
  error,
  receivedAt: Date.now()
});

export const makeProjectMediaZip = id => (dispatch) => {
  dispatch(makeProjectMediaZipRequest());
  return axios
    .post(`/project/${id}/zip-media`)
    .then(res => dispatch(makeProjectMediaZipSuccess(res.data)))
    .catch(error => dispatch(makeProjectMediaZipFailure(transAxoisErr(error))));
};
