export default [
  'RENTAL RATES AND PAYMENT. Contractor will pay, as full compensation for use of the Equipment, rental at the rate(s) (hereinafter “Rates”) set forth on the front of this Purchase Order and in accordance with the following terms and conditions. Payments for rental accrued during the previous Rental Period will be made from invoices submitted by Vendor at the end of each month or at the end of the rental, whichever occurs first. Rental will accrue on each article of equipment for the actual length of the Rental Period thereof at the rental rate stated therefor. When the base rental rate is a monthly rate, and unless otherwise stated, the rental payable for a Rental Period of less than three weeks will be computed using the rates as follows: for a full week (4 days or more) or weeks a weekly rate equal to the monthly rate divided by 3; for a partial week of 3 days or fewer a daily rate equal to the monthly rate divided by 12.',
  "CONDITION OF EQUIPMENT. When delivered to the job site, the Equipment shall be in condition to render efficient, economical, and continuous service, and shall comply fully with all manufacturer’s requirements and recommendations and with all federal, state, and local regulations including the Occupational Safety and Health Act (OSHA) and any regulations issued thereunder. Contractor will make repairs or, at its option, reimburse Vendor for repairs due to damage caused by Contractor due to Contractor’s exceeding the Equipment's rated capacities or to any other fault of Contractor in the care, maintenance, use or operation of the Equipment. All other repairs attributable to equipment failure shall be Vendor's responsibility. Should Equipment become inoperative because of necessary repairs which are Vendor's responsibility, the Rental Period will discontinue upon Contractor’s notification of Vendor of such condition, and will resume only when the Equipment is made operative again. Contractor will notify and obtain Vendor's approval prior to making repairs for Vendor's account. Unless otherwise specified, Contractor will furnish all fuel, lubricants, operators, and mechanics necessary for operating, using, and servicing the Equipment. Equipment, when returned to Vendor, shall be in as good condition as when delivered to Contractor, usual and ordinary wear and tear excepted.",
  "RENTAL PERIOD. The period for which rental is payable for an item of Equipment shall begin on the date the Equipment is delivered to the job site (except, if Contractor gives a definite required date to Vendor prior to shipment, the Rental Period shall not start until such date, unless Contractor places the Equipment in service at an earlier date and so notifies Vendor in writing) and end on return to Vendor or delivery by Contractor to a carrier for shipment to the point of origin or such other point as Vendor may designate. If Vendor fails to notify Contractor of the destination prior to the time the Equipment is ready for return, no rental time shall accrue thereafter. If Equipment is not in required condition, as determined by Contractor, when it arrives at the job site, the Rental Period shall not begin until it has been placed in proper condition at Vendor's expense. If an inspection, made immediately prior to return, establishes that the Equipment is not in condition required of Contractor under Section 2 above, Contractor will do all things necessary to place the Equipment in such condition, and the Rental Period shall not terminate until the Equipment is in such condition. Unless otherwise stated in the order, Contractor makes no representation or guarantee of the length of the Rental Period or the amount of rental which will accrue for any Equipment, it being the intent that the Equipment may be rented for as long as Contractor requires. The rental of any Equipment which does not perform to the satisfaction of Contractor may be terminated upon notice by Contractor to Vendor.",
  'TRANSPORTATION / DELIVERY. If transportation is to be by common carrier at Contractor’s expense, transportation will be paid by Contractor F.O.B. original point of shipment and return transportation F.O.B. to original point of shipment or equivalent mileage. Transportation of Equipment shall be accomplished by the most economical means and there shall be paid the actual cost of such transportation. No transportation charges will be paid by Contractor for any Equipment which arrives at the job site in unsatisfactory condition and which cannot be placed in the required condition within a reasonable length of time as determined by Contractor. On delivery to the job site, and unless otherwise stated, Contractor will unload and set up the Equipment ready for use and, at the end of the Rental Period, Contractor will load the Equipment for return shipment. If Vendor is to deliver the Equipment to the job site and unless otherwise stated, Vendor shall unload and set up the Equipment ready for use and, at the end of the Rental Period, Vendor will promptly load and remove the Equipment from the job site. If Contractor is to pick-up the equipment at Vendor’s location and unless otherwise stated, Contractor will load, transport, unload and set up the Equipment and, at the end of the Rental Period, return the Equipment to Vendor’s location or other agreed location.',
  'LIABILITY. By accepting this Purchase Order, Vendor agrees that: (a) Vendor assumes and hereby relieves Contractor of any and all liability for any loss or damage to the Equipment occurring between the date of shipment or delivery, as the case may be, to Contractor and the date of return to Vendor, except to the extent such loss or damage arises out of Contractor’s negligence, willful misconduct or other fault in the care, maintenance, use or operation of the Equipment; and (b) the liability of Contractor shall be limited to the fair market value of the Equipment, taking into consideration its age and condition immediately prior to said loss or damage.',
  'RECAPTURE (In effect only if so provided on the front of this Purchase Order). (a) If this provision is applicable: (1) a recapture valuation, mutually agreeable to Contractor and Vendor, shall be established and stated in the order; and (2) Vendor certifies that there are no encumbrances of any nature, legal or equitable, held by any person against the Equipment rented hereunder, unless so stated in the order; (b) At any time during or at the end of the Rental Period of the Equipment, Vendor may purchase the Equipment by paying Vendor the difference between such valuation and total rental paid. Title to such Equipment shall then vest in Contractor and Vendor shall deliver to Contractor such instruments of title as Contractor may require, free of any and all liens and encumbrances.',
  "EQUIPMENT OPERATED AND MAINTAINED. This Section applies if Equipment is rented on the basis of operation and maintenance by Vendor. RENTAL RATES. The rental rates set forth in the order shall include operation and maintenance of the Equipment. Overtime rates for operator shall be in accordance with prevailing rates and practices in the area in which the Equipment will be operated. SAFETY. Vendor shall comply with all federal, state, and local regulations, and with all safety information and instructions as may be set forth in writing or otherwise provided by the manufacturer of the Equipment. In addition, Vendor shall: (a) comply with recognized safety practices for the type of work being performed including furnishing necessary safety equipment; (b) conduct and protect its operations to avoid exposing others to injury; and (c) comply with any site specific health and safety requirements. TAXES. Unless otherwise stated herein, Contractor will pay value-added tax (VAT), Goods and Services Tax (GST), use, transfer, and similar taxes and surcharges (regardless of how they are denominated) levied by a taxing authority against or upon the Services (collectively, the 'Taxes'). In the alternative, Contractor will provide Vendor with a certificate evidencing Contractor’s exemption from payment of or liability for such taxes.",
  "INDEMNIFICATION. To the fullest extent permitted by law, Vendor shall indemnify, defend and hold harmless Contractor from and against all claims, liabilities, damages, losses or expenses to the extent arising out of any negligence, willful misconduct, breach of contract or violation of law by Vendor, its employees, agents, subcontractors, or assigns in connection with this Purchase Order, or when entering, while on, or upon leaving Contractor’s property or project site. The claims, liabilities, damages, losses or expenses covered hereunder include, but are not limited to, settlements, judgments, court costs, attorneys' fees and other litigation expenses, fines, and penalties arising out of actual or alleged (a) injury to or death of any person, including employees of Vendor or Contractor, or (b) loss of or damage to property, including property of Vendor or Contractor, or (c) damage to the environment. Vendor’s indemnity obligations hereunder shall not be limited by any limitation on the amount or type of damages, compensation, or benefits payable under workers compensation acts, disability benefit acts, or other employee benefit acts.",
  "INSURANCE. At Vendor’s expense, Vendor shall carry and maintain in force at all times relevant hereto the following insurance, on policy forms and with insurance companies acceptable to Contractor, at the indicated minimum coverage. Commercial General Liability (Occurrence Form), including Blanket Contractual Liability, Product/Completed Operations, Broad Form Property Damage and Personal Injury, in a combined single limit for Bodily Injury and Property Damage - $1,000,000 per occurrence. Automobile Liability Insurance with combined single limit for Bodily Injury and Property Damage - $1,000,000 per occurrence. Contractor, the Contractor’s customer(s), the job site’s owner(s) and/or responsible parties, the engineer, and their respective officers, agents, employees, subsidiaries and affiliates (“Additional Insureds”) shall be named as additional insureds (“Additional Insureds”) for the above insurances. Workers Compensation and Employers Liability Insurance as required by the state in which Vendor provides operation and/or maintenance of the Equipment. To the extent permitted by law, all policies shall provide that insurer's right of subrogation against the Additional Insureds shall be waived. Vendor shall provide a certificate of insurance evidencing the coverage required above has been delivered to Contractor. Such certificate shall provide that the insurer will give Contractor thirty (30) days advance notice of any cancellation or non-renewal of coverage. Neither Vendor’s failure to comply with any or all of the insurance requirements, nor the failure to secure endorsements on policies, shall be construed to limit or relieve Vendor from any obligation.",
  'VENDOR’S EMPLOYEES. Vendor shall be responsible for all persons, whether employees or independent contractors, engaged by Vendor to operate or maintain the Equipment or to perform any other services Vendor is obligated to perform.'
];
