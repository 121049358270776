import axios from 'axios';
import * as types from '../../constants/vendor/vendors';
import transAxoisErr from '../../etc/axios/transformAxiosError';

export const fetchVendorRequest = () => ({
  type: types.FETCH_VENDOR_REQUEST,
  data: {}
});

export const fetchVendorSuccess = ({ data }) => ({
  type: types.FETCH_VENDOR_SUCCESS,
  payload: data,
  receivedAt: Date.now()
});

export const fetchVendorFailure = error => ({
  type: types.FETCH_VENDOR_FAILURE,
  error,
  receivedAt: Date.now()
});

export const fetchVendor = ({ id }) => (dispatch) => {
  dispatch(fetchVendorRequest());
  return axios
    .get(`/vendor/${id}`)
    .then(res => dispatch(fetchVendorSuccess(res.data)))
    .catch(error => dispatch(fetchVendorFailure(transAxoisErr(error))));
};
