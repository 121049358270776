/* eslint-disable no-underscore-dangle */
import React from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Typeahead } from 'react-typeahead';
import { bindActionCreators } from 'redux';
import { Link, browserHistory } from 'react-router';

import pick from 'lodash/pick';
import pickBy from 'lodash/pickBy';

import IoChevronDown from 'react-icons/lib/io/chevron-down';
import IoCloseCircled from 'react-icons/lib/io/close-circled';
import IoIosDownload from 'react-icons/lib/io/ios-download';
import IoAlertCircled from 'react-icons/lib/io/alert-circled';
import IoIosPlay from 'react-icons/lib/io/ios-play';

import Loader from '../../components/shared/Loader';
import FilterDrawer from '../../components/shared/FilterDrawer';
import Dropdown from '../../components/shared/Dropdown';
import DateFilter from '../../components/shared/DateFilter';
import Modal from '../../components/shared/Modal';
import Alert from '../../components/shared/Alert';
import Pagination from '../../components/shared/Pagination';

import { fetchReports, makeZipAll } from '../../actions/report/reports';
import { createReportReset } from '../../actions/report/createReport';
import { unlockReport } from '../../actions/report/editReport';
import { deleteReport } from '../../actions/report/deleteReport';
import { fetchProjects } from '../../actions/projects';
import { recordFilterDrawer } from '../../actions/setFilterDrawer';

import '../../sass/containers/Reports/Index.scss';
import '../../sass/containers/Users.scss';

import sevensonLogoText from '../../images/sevenson-logo-text.png';

// eslint-disable-next-line global-require
const URL = global.URL || require('url').URL;

import trash from '../../images/buttonIcons/trash.svg';
import unlock from '../../images/buttonIcons/unlock.svg';

const getExpiry = () => Date.now() - 1000;

const typeaheadClasses = {
  input: 'typeahead-input',
  results: 'typeahead-results',
  listItem: 'typeahead-list-item',
  listAnchor: 'typeahead-list-anchor',
  hover: 'typeahead-item-hover',
  typeahead: 'typeahead-typeahead',
  resultsTruncated: 'typeahead-results'
};

class Reports extends React.Component {
  static need = [fetchReports, fetchProjects.bind(null, { limit: -1 })];
  static searchFields = ['sort', 'textSearch', 'projectRef', 'dateAbove', 'dateBelow', 'page'];

  constructor(props) {
    super(props);

    this.state = {};

    this.updateSearch = this.updateSearch.bind(this);
    this.page = this.page.bind(this);
    this.changeSort = this.changeSort.bind(this);
    this.searchSubmit = this.searchSubmit.bind(this);
    this.projectFilterSubmit = this.projectFilterSubmit.bind(this);
    this.clearProjectFilter = this.clearProjectFilter.bind(this);
    this.clearDateFilter = this.clearDateFilter.bind(this);
    this.clearAllFilters = this.clearAllFilters.bind(this);
    this.filterByDateSubmit = this.filterByDateSubmit.bind(this);
    this.unlockConfirm = this.unlockConfirm.bind(this);
    this.cancelUnlockModal = this.cancelUnlockModal.bind(this);
    this.unlockReportSubmit = this.unlockReportSubmit.bind(this);
    this.deleteConfirm = this.deleteConfirm.bind(this);
    this.cancelDeleteModal = this.cancelDeleteModal.bind(this);
    this.deleteReportSubmit = this.deleteReportSubmit.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
    this.makeZipAllClickHandler = this.makeZipAllClickHandler.bind(this);
    this.cancelZipModal = this.cancelZipModal.bind(this);
    this.zipConfirm = this.zipConfirm.bind(this);
  }

  componentWillMount() {
    const { location, reports } = this.props;
    const { isFetching, lastUpdated } = reports;

    if (!isFetching && lastUpdated < getExpiry()) {
      this.props.actions.fetchReports((location && location.query) || {});
      this.props.actions.fetchProjects({ limit: -1 });
    }
  }

  componentDidMount() {
    this.props.actions.recordFilterDrawer('reports');
  }

  componentWillReceiveProps(nextProps) {
    const { query } = nextProps.location;
    const { isFetching, lastUpdated } = nextProps.reports;
    const { actions } = this.props;

    if (!isFetching && !lastUpdated) {
      actions.fetchReports(query);
    } else if (this.props.createReport.submitted) {
      actions.createReportReset();
      actions.fetchReports(query);
    } else if (this.props.location && this.props.location.query) {
      const oldQuery = pick(this.props.location.query, Reports.searchFields);
      const newQuery = pick(query, Reports.searchFields);

      const changed = Object.entries(newQuery)
        .map(([key, val]) => oldQuery[key] !== val)
        .filter(Boolean).length;

      const keysRemoved = Object.keys(oldQuery).length > Object.keys(newQuery).length;

      if (!changed && !keysRemoved) return;
      actions.fetchReports(newQuery);
    }
  }

  updateSearch(props) {
    const { location } = this.props;

    if (!Object.keys(props).includes('page')) {
      props.page = undefined;
    }

    const oldQuery = location.query;
    const query = pickBy(Object.assign(oldQuery, props), Boolean);
    browserHistory.push({ ...location, query });
  }

  page(direction) {
    return () => {
      const { query } = this.props.location;
      const page = parseInt(query.page, 10) || 1;
      if (direction === 'prev' && page > 1) {
        this.updateSearch({ page: page - 1 });
      } else {
        this.updateSearch({ page: page + 1 });
      }
    };
  }

  changeSort(_field) {
    return () => {
      let field = _field;
      if (this.props.location.query.sort === field) {
        field = field.startsWith('-') ? field.slice(1) : `-${field}`;
      }
      this.updateSearch({ sort: field });
    };
  }

  searchSubmit(e) {
    e.preventDefault();
    this.updateSearch({ textSearch: this.searchInput.value });
  }

  projectFilterSubmit({ _id, name }) {
    this.updateSearch({ projectRef: _id, projName: name });
  }

  filterByDateSubmit(dateAbove, dateBelow, value) {
    this.updateSearch({ dateAbove, dateBelow, dateFilter: value });
  }

  clearSearch() {
    this.updateSearch({ textSearch: undefined });
  }

  clearProjectFilter() {
    this.typeahead._onEscape();
    this.typeahead.setEntryText('');
    this.updateSearch({ projectRef: undefined, projName: undefined });
  }

  clearDateFilter() {
    this.reportDateFilter.resetDatepicker();
    this.updateSearch({ dateAbove: undefined, dateBelow: undefined, dateFilter: undefined });
  }

  clearAllFilters() {
    this.reportDateFilter.resetDatepicker();
    this.typeahead._onEscape();
    this.typeahead.setEntryText('');
    this.updateSearch({
      dateAbove: undefined,
      dateBelow: undefined,
      dateFilter: undefined,
      projectRef: undefined,
      projName: undefined
    });
  }

  unlockReportSubmit = id => () => {
    this.props.actions.unlockReport(id);
    this.setState({ unlockModal: undefined });
  };

  unlockConfirm = id => () => {
    this.setState({ unlockModal: id });
  };

  cancelUnlockModal = () => {
    this.setState({ unlockModal: undefined });
  };

  deleteReportSubmit = id => () => {
    this.props.actions.deleteReport(id);
    this.setState({ deleteModal: undefined });
  };

  deleteConfirm = id => () => {
    this.setState({ deleteModal: id });
  };

  cancelDeleteModal = () => {
    this.setState({ deleteModal: undefined });
  };

  makeZipAllClickHandler() {
    this.props.actions.makeZipAll();
    this.setState({ zipModal: 'submitted' });
  }

  cancelZipModal = () => {
    this.setState({ zipModal: undefined });
  };

  zipConfirm() {
    this.setState({ zipModal: true });
  }

  render() {
    const { reports, actions, setFilterDrawer } = this.props;

    const { query, pathname, search } = this.props.location;
    const { authLevel, authOverrides } = this.props.user.data;
    const canAddNewReports = authLevel >= 1 || (authOverrides && authOverrides.reports.create);

    const returnTo = pathname + search;

    let noResults;
    if (!reports.data || (reports.data && reports.data.length === 0)) {
      noResults = 'There are no results to display.';
    }

    const projects = this.props.projects.data;
    projects.forEach(p => {
      p.numberName = `${p.number} – ${p.name}`;
    });

    const projectFilterOn = query.projectRef;
    const dateFilterOn = query.dateAbove || query.dateBelow;
    const searchFilterOn = query.textSearch;
    const areThereFiltersOn = dateFilterOn || projectFilterOn || searchFilterOn;

    const zipRequestSubmitted = this.state.zipModal === 'submitted';

    let pageCnt;
    let thisPage;

    const { next, prev } = reports;

    if (next || prev) {
      try {
        const pagingSearch = new URL(next || prev).searchParams;

        pageCnt = parseInt(pagingSearch.get('pages'), 10) || 1;
        const queryPage = parseInt(pagingSearch.get('page'), 10);

        thisPage = parseInt(query.page, 10) || next ? queryPage : queryPage + 2;
      } catch (e) {
        console.error(e);
      }
    }

    let alert;
    if (reports.error && reports.error.message) {
      alert = (
        <Alert delay={5000} type="danger">
          <i>
            <IoAlertCircled />
          </i>
          &nbsp;
          {reports.error.message}
        </Alert>
      );
    }

    let reportAuth;
    if (authOverrides) {
      reportAuth = authOverrides.reports;
    }

    const allowedToEditReports = authLevel >= 1 || (authOverrides && reportAuth.edit);
    const allowedToCloneReports = authLevel >= 1 || (authOverrides && reportAuth.clone);
    const allowedToDeleteReports = authLevel >= 2 || (authOverrides && reportAuth.delete);

    const cloneButtonClasses = classNames({
      'standard-button': true,
      'clone-button': true,
      disabled: !allowedToCloneReports
    });

    const deleteButtonClasses = classNames({
      'standard-button': true,
      'delete-button': true,
      disabled: !allowedToDeleteReports
    });

    const filterOn = setFilterDrawer && setFilterDrawer.drawer === 'reports';

    return (
      <div className={classNames('main-list-page grid', { 'filter-open': filterOn })}>
        <FilterDrawer open={filterOn} closeDrawer={actions.recordFilterDrawer}>
          <div className="filter-section">
            <button className="clear-filters-button standard-button" onClick={this.clearAllFilters}>
              Clear All Filters
            </button>
          </div>
          <div className="filter-section">
            <p className="filter-name">Project Name/Number</p>
            <Typeahead
              closeDropdownOnBlur
              options={projects}
              maxVisible={6}
              filterOption="numberName"
              displayOption="numberName"
              customClasses={typeaheadClasses}
              onOptionSelected={this.projectFilterSubmit}
              ref={input => (this.typeahead = input)}
              placeholder="Filter by Project"
            />
            {projectFilterOn && (
              <div className="filter-notification-type" onClick={this.clearProjectFilter}>
                {query.projName}
                <span className="filter-clear-icon">
                  <IoCloseCircled />
                </span>
              </div>
            )}
          </div>
          <div className="filter-section">
            <p className="filter-name">Date</p>
            <DateFilter
              filterFunc={this.filterByDateSubmit}
              ref={datefilter => {
                this.reportDateFilter = datefilter;
              }}
            />
            {dateFilterOn && (
              <div className="filter-notification-type" onClick={this.clearDateFilter}>
                {query.dateFilter}
                <span className="filter-clear-icon">
                  <IoCloseCircled />
                </span>
              </div>
            )}
          </div>
          <div className="filter-section">
            <Dropdown
              className="extra-options-dropdown filter-dropdown"
              outsideText={
                <button className="standard-button primary-button">
                  More Options&nbsp;
                  <IoChevronDown />
                </button>
              }
            >
              <div>
                <ul>
                  <Link className="view-activity-link" to="/reports/all/activity">
                    <li>View All Activity</li>
                  </Link>
                  <li onClick={this.zipConfirm} className="create-pdf-button">
                    <div className="pdf-generator">
                      Create PDFs of All Reports
                      <i>
                        <IoIosDownload />
                      </i>
                    </div>
                  </li>
                </ul>
              </div>
            </Dropdown>
          </div>
        </FilterDrawer>
        <div className="non-drawer-content">
          {alert}
          <Loader isFetching={reports.isFetching} />
          {this.state.unlockModal && (
            <Modal
              isOpen
              extraClass="unlock-confirm-modal"
              ref={modal => {
                this.unlockModal = modal;
              }}
              toCancel={() => {
                this.setState({ unlockModal: undefined });
              }}
            >
              <div className="modal-text-top">
                <p className="modal-header">Are you sure?</p>
                <p className="modal-subheader">
                  Do you want to unlock this report, potentially kicking another user out of
                  editing?
                </p>
                <p className="modal-subheader">
                  <strong>
                    Unlock could take up to five minutes, please refresh the report view at that
                    time.
                  </strong>
                </p>
              </div>
              <div className="modal-body">
                <div className="modal-buttons">
                  <button
                    className="modal-button modal-delete"
                    onClick={this.unlockReportSubmit(this.state.unlockModal)}
                  >
                    confirm
                  </button>
                  <button className="modal-button modal-cancel" onClick={this.cancelUnlockModal}>
                    cancel
                  </button>
                </div>
              </div>
            </Modal>
          )}
          {this.state.deleteModal && (
            <Modal
              isOpen
              extraClass="delete-confirm-modal"
              ref={modal => {
                this.deleteModal = modal;
              }}
              toCancel={() => {
                this.setState({ deleteModal: undefined });
              }}
            >
              <div className="modal-text-top">
                <p className="modal-header">Are you sure?</p>
                <p className="modal-subheader">
                  Delete this report? This report and all of its data will no longer be accessible
                  to users.
                </p>
              </div>
              <div className="modal-body">
                <div className="modal-buttons">
                  <button
                    className="modal-button modal-delete"
                    onClick={this.deleteReportSubmit(this.state.deleteModal)}
                  >
                    confirm
                  </button>
                  <button className="modal-button modal-cancel" onClick={this.cancelDeleteModal}>
                    cancel
                  </button>
                </div>
              </div>
            </Modal>
          )}
          {this.state.zipModal && (
            <Modal
              isOpen
              extraClass="zip-confirm-modal"
              toCancel={() => {
                this.setState({ zipModal: undefined });
              }}
            >
              <div className="modal-text-top">
                <p className="modal-header">
                  {zipRequestSubmitted ? 'Request submitted' : 'Are you sure?'}
                </p>
                <p className="modal-subheader">
                  {zipRequestSubmitted
                    ? ' Your request has been submitted. When the PDFs are ready, you will receive an email with a link to download. Please be patient. This may take up to 24 hours. Do not click the button again during that time.'
                    : 'Clicking confirm will generate a zip file of all PDFs currently in the database. This may significantly diminish website performance for the next several hours. This action may not be canceled once started.'}
                </p>
              </div>
              <div className="modal-body">
                {zipRequestSubmitted ? (
                  <div className="modal-buttos">
                    <button className="modal-button" onClick={this.cancelZipModal}>
                      OK
                    </button>
                  </div>
                ) : (
                  <div className="modal-buttons">
                    <button
                      className="modal-button primary-button"
                      onClick={this.makeZipAllClickHandler}
                    >
                      confirm
                    </button>
                    <button className="modal-button modal-cancel" onClick={this.cancelZipModal}>
                      cancel
                    </button>
                  </div>
                )}
              </div>
            </Modal>
          )}
          <div className="pre-table-header">
            <div className="report-top-section">
              <div className="top-section-left">
                <p className="page-title">Daily Reports</p>
              </div>
              <div className="top-section-right">
                <div className="search-filter-things">
                  <form className="search-and-button">
                    <input
                      id="report-search"
                      className="search-bar"
                      type="text"
                      ref={el => {
                        this.searchInput = el;
                      }}
                      placeholder="Search reports..."
                    />
                    <input type="submit" value="Search" onClick={this.searchSubmit} />
                  </form>
                  {areThereFiltersOn && (
                    <div className="filter-notification-text">
                      <span>Current Filters:</span>
                      {projectFilterOn && (
                        <div className="filter-notification-type" onClick={this.clearProjectFilter}>
                          Project
                          <span className="filter-clear-icon">
                            <IoCloseCircled />
                          </span>
                        </div>
                      )}
                      {dateFilterOn && (
                        <div className="filter-notification-type" onClick={this.clearDateFilter}>
                          Date
                          <span className="filter-clear-icon">
                            <IoCloseCircled />
                          </span>
                        </div>
                      )}
                      {searchFilterOn && (
                        <div className="filter-notification-type" onClick={this.clearSearch}>
                          Search for: “{query.textSearch}”
                          <span className="filter-clear-icon">
                            <IoCloseCircled />
                          </span>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="add-and-sorts">
            {canAddNewReports && (
              <Link
                to={{
                  pathname: '/reports/create',
                  state: {
                    returnTo,
                    modal: true,
                    extraClass: 'add-edit-modal add-report-modal wide-modal'
                  }
                }}
                className="standard-button main-add-button primary-button"
              >
                Add New
              </Link>
            )}
            <ul className="sorts">
              <li onClick={this.changeSort('-number')}>
                Number
                <IoIosPlay className={`sort-down ${query.sort === '-number'}`} />
                <IoIosPlay className={`sort-up ${query.sort === 'number'}`} />
              </li>
              <li onClick={this.changeSort('-createdAt')}>
                Date
                <IoIosPlay className={`sort-down ${query.sort === '-createdAt'}`} />
                <IoIosPlay className={`sort-up ${query.sort === 'createdAt'}`} />
              </li>
              <li onClick={this.changeSort('-isPublished')}>
                Status
                <IoIosPlay className={`sort-down ${query.sort === '-isPublished'}`} />
                <IoIosPlay className={`sort-up ${query.sort === 'isPublished'}`} />
              </li>
            </ul>
          </div>
          <div className="list-cards-container">
            {// TODO might be good to extract below to a component
            reports.data.map(report => {
              const allowedToViewAllReports =
                !report.isPublished &&
                (authLevel >= 1 || (authOverrides && reportAuth.viewUnpublished));

              const isLocked = !!report.isLockedBy;
              const lockAuthor = (isLocked && report.isLockedBy.author) || {};
              const { name, userRef } = lockAuthor;

              const isLockedBySelf = isLocked && userRef === this.props.user.data._id;
              const isLockedByName = isLocked
                ? [name.first, name.last].filter(Boolean).join(' ')
                : '';

              const { address } = report.projectRef;

              const viewButtonClasses = classNames({
                'standard-button': true,
                'view-button': true,
                'primary-button': true,
                disabled: !report.isPublished && !allowedToViewAllReports
              });

              const editButtonClasses = classNames({
                'standard-button': true,
                'edit-button': true,
                disabled: !allowedToEditReports,
                locked: report.isLockedBy && !isLockedBySelf
              });

              return (
                <div className="list-card reports-table" key={report._id}>
                  <div className="card-body">
                    <div className="group project">
                      <p className="body-field">Project Name</p>
                      <p className="emph">
                        #{report.projectRef.number} — {report.projectRef.name}
                      </p>
                      {report.isLockedBy && !isLockedBySelf && (
                        <span className="edit-lock-text">{`${isLockedByName} editing!`}</span>
                      )}
                    </div>
                    <div className="group number">
                      <p className="body-field">#</p>
                      <p>{report.number}</p>
                    </div>
                    <div className="group date">
                      <p className="body-field">Date</p>
                      <p>{moment(report.createdAt, 'YYYY-MM-DD').format('MM-DD-YYYY')}</p>
                    </div>
                    <div className="group status">
                      <p className="body-field">Status</p>
                      <p>{report.isPublished ? 'Published' : 'Draft'}</p>
                    </div>
                    <div className="group location">
                      <p className="body-field">Location</p>
                      <p>
                        {address.city}
                        {address.city && address.state && ', '}
                        {address.state}
                      </p>
                    </div>
                    <div className="group actions">
                      <p className="body-field">Actions</p>
                      <div className="button-contain">
                        <Link
                          title="View"
                          className={viewButtonClasses}
                          to={`/reports/${report._id}`}
                        >
                          View
                        </Link>
                        {!(report.isLockedBy && !isLockedBySelf) ? (
                          <Link
                            title="Edit"
                            className={editButtonClasses}
                            to={{
                              pathname: `/reports/${report._id}/edit`,
                              state: {
                                returnTo,
                                modal: true,
                                extraClass: 'add-report-modal wide-modal'
                              }
                            }}
                          >
                            Edit
                          </Link>
                        ) : (
                          <button
                            className={deleteButtonClasses}
                            disabled={!allowedToDeleteReports}
                            onClick={this.unlockConfirm(report._id)}
                          >
                            <img src={unlock} alt="unlock padlock" />
                          </button>
                        )}
                        <Link
                          title="Clone"
                          className={cloneButtonClasses}
                          to={{
                            pathname: `/reports/${report._id}/clone`,
                            state: {
                              returnTo,
                              modal: true,
                              extraClass: 'add-report-modal wide-modal'
                            }
                          }}
                        >
                          Clone
                        </Link>
                        {allowedToDeleteReports && (
                          <button
                            title="Delete"
                            className={deleteButtonClasses}
                            disabled={!allowedToDeleteReports}
                            onClick={this.deleteConfirm(report._id)}
                          >
                            <img src={trash} alt="trash can" />
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          {noResults && (
            <div className="no-results">
              <span>{noResults}</span>
            </div>
          )}
          <Pagination
            pages={pageCnt}
            activePage={thisPage}
            prev={!!reports.prev}
            next={!!reports.next}
            updateSearch={this.updateSearch}
          />
          <img src={sevensonLogoText} alt="sevenson logo" className="table-bottom-sevenson-logo" />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  reports: state.reports,
  projects: state.projects,
  createReport: state.createReport,
  user: state.user,
  setFilterDrawer: state.setFilterDrawer
});

const mapDispatchToProps = dispatch => {
  const actions = {
    fetchReports,
    unlockReport,
    createReportReset,
    deleteReport,
    fetchProjects,
    makeZipAll,
    recordFilterDrawer
  };
  return { actions: bindActionCreators(actions, dispatch) };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Reports);
