import React from 'react';

export default class OrderWizardUploadFiles extends React.Component {
  render() {
    const { children } = this.props;

    return (
      <div className="wizard-upload-page upload-drawer">
          <p className="text-input-standard-label">Upload attachments, quotes & proposals.</p>
          <p className="text-input-standard-label">At least one file is required.</p>
          {children}
      </div>
    );
  }
}
