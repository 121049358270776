import * as actions from '../constants/settings';

const initialState = {
  data: {},
  isFetching: false,
  lastUpdated: null,
  error: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_SETTINGS_REQUEST:
    case actions.UPDATE_SETTINGS_REQUEST:
      return { ...state, error: {}, isFetching: true };
    case actions.FETCH_SETTINGS_FAILURE:
    case actions.UPDATE_SETTINGS_FAILURE:
      return {
        ...state,
        error: action.error,
        isFetching: false,
        lastUpdated: action.receivedAt
      };
    case actions.FETCH_SETTINGS_SUCCESS:
    case actions.UPDATE_SETTINGS_SUCCESS:
      return {
        ...state,
        error: {},
        data: action.data,
        isFetching: false,
        submitted: action.receivedAt,
        lastUpdated: action.receivedAt
      };
    case actions.SETTINGS_RESET:
      return {
        ...state,
        submitted: false
      };
    default:
      return state;
  }
};
