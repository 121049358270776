export const CREATE_REPORT_REQUEST = 'CREATE_REPORT_REQUEST';
export const CREATE_REPORT_SUCCESS = 'CREATE_REPORT_SUCCESS';
export const CREATE_REPORT_FAILURE = 'CREATE_REPORT_FAILURE';
export const CREATE_REPORT_RESET = 'CREATE_REPORT_RESET';

export const EDIT_REPORT_REQUEST = 'EDIT_REPORT_REQUEST';
export const EDIT_REPORT_SUCCESS = 'EDIT_REPORT_SUCCESS';
export const EDIT_REPORT_FAILURE = 'EDIT_REPORT_FAILURE';

export const LOCK_SUCCESS = 'LOCK_SUCCESS';
