import axios from 'axios';
import * as types from '../constants/users';
import transAxoisErr from '../etc/axios/transformAxiosError';

export const fetchUserRequest = () => ({
  type: types.FETCH_USER_REQUEST,
  data: {}
});

export const fetchUserSuccess = ({ data }) => ({
  type: types.FETCH_USER_SUCCESS,
  payload: data,
  receivedAt: Date.now()
});

export const fetchUserFailure = error => ({
  type: types.FETCH_USER_FAILURE,
  error,
  receivedAt: Date.now()
});

export const fetchUser = ({ id }) => (dispatch) => {
  dispatch(fetchUserRequest());
  return axios
    .get(`/user/${id}`)
    .then(res => dispatch(fetchUserSuccess(res.data)))
    .catch(error => dispatch(fetchUserFailure(transAxoisErr(error))));
};
