/* eslint jsx-a11y/no-static-element-interactions: 0 */
import React from 'react';
import IoArrowDownB from 'react-icons/lib/io/arrow-down-b';
import IoArrowUpB from 'react-icons/lib/io/arrow-up-b';

const classNames = require('classnames');

require('../../../sass/components/shared/table.scss');

export default class TableHeader extends React.Component {
  render() {
    const { columnHeaders, headerClass, sortFn, sortValues } = this.props;

    const tableHeader = columnHeaders.map((header, idx) => {
      const classedHeaderText = header[0]
        .toLowerCase()
        .replace(/\s+/g, '-')
        .replace('/', '-')
        .replace('#', 'number');
      const headerClasses = classNames({ [headerClass]: true, [classedHeaderText]: true });

      return (
        // eslint-disable-next-line react/no-array-index-key
        <th key={idx} className={headerClasses} onClick={sortFn && sortFn(sortValues[idx])}>
          {header[0]}
          <span className={header[1]}>
            <IoArrowUpB />
            <IoArrowDownB />
          </span>
        </th>
      );
    });

    return (
      <thead className={headerClass}>
        <tr>{tableHeader}</tr>
      </thead>
    );
  }
}
