/* eslint-disable jsx-a11y/href-no-hash */
/* eslint-disable react/jsx-indent */
/* eslint jsx-a11y/no-static-element-interactions: 0 */

import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link, browserHistory } from 'react-router';

import pick from 'lodash/pick';
import pickBy from 'lodash/pickBy';

import IoCloseCircled from 'react-icons/lib/io/close-circled';
import IoAlertCircled from 'react-icons/lib/io/alert-circled';
import IoIosPlay from 'react-icons/lib/io/ios-play';

import { fetchVendors } from '../../actions/vendor/vendors';
import { deleteVendor } from '../../actions/vendor/deleteVendor';
import { createVendorReset } from '../../actions/vendor/createVendor';

import Loader from '../../components/shared/Loader';
import Modal from '../../components/shared/Modal';
import Alert from '../../components/shared/Alert';
import Pagination from '../../components/shared/Pagination';

import '../../sass/containers/Reports/Index.scss';
import '../../sass/containers/Vendors.scss';

import sevensonLogoText from '../../images/sevenson-logo-text.png';
import trash from '../../images/buttonIcons/trash.svg';

// eslint-disable-next-line global-require
const URL = global.URL || require('url').URL;

const getExpiry = () => Date.now() - 1000;

const searchFields = ['page', 'name', 'number', 'sort'];

class Vendors extends React.Component {
  static need = [fetchVendors];

  constructor(props) {
    super(props);

    this.state = {};

    this.updateSearch = this.updateSearch.bind(this);
    this.changeSort = this.changeSort.bind(this);
    this.deleteConfirm = this.deleteConfirm.bind(this);
    this.cancelDeleteModal = this.cancelDeleteModal.bind(this);
    this.deleteVendorSubmit = this.deleteVendorSubmit.bind(this);
    this.searchSubmit = this.searchSubmit.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
  }

  componentWillMount() {
    const { isFetching, lastUpdated } = this.props.vendors;
    if (!isFetching && lastUpdated < getExpiry()) {
      this.props.actions.fetchVendors();
    }
  }

  componentWillReceiveProps(nextProps) {
    const { actions } = this.props;
    const { location, vendors } = nextProps;
    const { isFetching, lastUpdated } = vendors;
    const { query } = location;

    if (!isFetching && !lastUpdated) {
      this.props.actions.fetchVendors(query);
    } else if (this.props.createVendor.submitted) {
      actions.createVendorReset();
      actions.fetchVendors(query);
    } else if (this.props.location && this.props.location.query) {
      const oldQuery = pick(this.props.location.query, searchFields);
      const newQuery = pick(query, searchFields);

      const changed = Object.entries(newQuery)
        .map(([key, val]) => oldQuery[key] !== val)
        .filter(Boolean).length;

      const keysRemoved = Object.keys(oldQuery).length > Object.keys(newQuery).length;

      if (!changed && !keysRemoved) return;
      actions.fetchVendors(newQuery);
    }
  }

  updateSearch(props) {
    const { location } = this.props;

    if (!Object.keys(props).includes('page')) {
      props.page = undefined;
    }

    const oldQuery = pick(location.query, searchFields);
    const newQuery = pick(props, searchFields);

    const query = pickBy(Object.assign(oldQuery, newQuery), Boolean);
    browserHistory.push({ ...location, query });
  }

  changeSort(_field) {
    return () => {
      let field = _field;
      if (this.props.location.query.sort === field) {
        field = `-${field}`;
      }
      this.updateSearch({ sort: field });
    };
  }

  searchSubmit(e) {
    e.preventDefault();
    this.updateSearch({ name: this.searchInput.value });
  }

  clearSearch() {
    this.updateSearch({ name: undefined });
  }

  deleteVendorSubmit = id => () => {
    this.props.actions.deleteVendor(id);
    this.setState({ deleteVendorModal: undefined });
  };

  deleteConfirm = id => () => {
    this.setState({ deleteVendorModal: id });
  };

  cancelDeleteModal = () => {
    this.setState({ deleteVendorModal: undefined });
  };

  render() {
    const { vendors, location } = this.props;
    const { authOverrides } = this.props.user.data;

    let vendorAuth;
    if (authOverrides) {
      vendorAuth = authOverrides.vendors;
    }
    const canAddNewVendors = authOverrides && vendorAuth.create;
    const allowedToEditVendors = authOverrides && vendorAuth.edit;
    const allowedToDeleteVendors = authOverrides && vendorAuth.delete;

    let noResults;
    if (vendors.data.length === 0) {
      noResults = 'There are no results to display.';
    }

    let pageCnt;
    let thisPage;

    const { next, prev } = vendors;
    const { query } = location || {};

    if (next || prev) {
      try {
        const pagingSearch = new URL(next || prev).searchParams;

        pageCnt = parseInt(pagingSearch.get('pages'), 10) || 1;
        const queryPage = parseInt(pagingSearch.get('page'), 10);

        thisPage = parseInt(query.page, 10) || (next ? queryPage : queryPage + 2);
      } catch (e) {
        console.error(e);
      }
    }

    const searchFilterOn = query.name;

    let alert;
    if (vendors.error && vendors.error.message) {
      alert = (
        <Alert delay={5000} type="danger">
          <i>
            <IoAlertCircled />
          </i>
          &nbsp;
          {vendors.error.message}
        </Alert>
      );
    }

    const editButtonClasses = classNames({
      'standard-button': true,
      'primary-button': true,
      disabled: !allowedToEditVendors
    });

    const deleteButtonClasses = classNames({
      'delete-button': true,
      'standard-button': true,
      disabled: !allowedToDeleteVendors
    });

    return (
      <div className="main-list-page vendors">
        {alert}
        <Loader isFetching={vendors.isFetching} />
        {this.state.deleteVendorModal && (
          <Modal
            isOpen
            extraClass="delete-confirm-modal"
            ref={modal => {
              this.deleteModal = modal;
            }}
            toCancel={() => {
              this.setState({ deleteVendorModal: undefined });
            }}
          >
            <div className="modal-text-top">
              <p className="modal-header">Are you sure?</p>
              <p className="modal-subheader">
                Delete this vendor? Deleting this vendor will remove the vendor from the database,
                making it unusable for future orders. All existing associated orders will not be
                affected.
              </p>
            </div>
            <div className="modal-body">
              <div className="modal-buttons">
                <button
                  className="modal-button modal-delete"
                  onClick={this.deleteVendorSubmit(this.state.deleteVendorModal)}
                >
                  confirm
                </button>
                <button className="modal-button modal-cancel" onClick={this.cancelDeleteModal}>
                  cancel
                </button>
              </div>
            </div>
          </Modal>
        )}
        <div className="pre-table-header">
          <div className="report-top-section">
            <div className="top-section-left">
              <p className="page-title">Vendors</p>
            </div>
            <div className="top-section-right">
              <div className="search-filter-things">
                <form className="search-and-button">
                  <input
                    id="vendor-search"
                    className="search-bar"
                    type="text"
                    ref={el => {
                      this.searchInput = el;
                    }}
                    placeholder="Search by vendor name…"
                  />
                  <input type="submit" value="Search" onClick={this.searchSubmit} />
                </form>
                {searchFilterOn && (
                  <div className="filter-notification-text">
                    <span>Current Filters:</span>
                    <div className="filter-notification-type" onClick={this.clearSearch}>
                      Vendor Name
                      <span className="filter-clear-icon">
                        <IoCloseCircled />
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="add-and-sorts">
          {canAddNewVendors && (
            <Link
              to={{
                pathname: '/vendors/create',
                state: {
                  modal: true,
                  returnTo: this.props.location.pathname,
                  extraClass: 'add-edit-modal add-vendor-modal'
                }
              }}
              className="standard-button main-add-button primary-button"
            >
              Add New
            </Link>
          )}
          <ul className="sorts">
            <li onClick={this.changeSort('name')}>
              Name
              <IoIosPlay className={`sort-down ${query.sort === 'name'}`} />
              <IoIosPlay className={`sort-up ${query.sort === '-name'}`} />
            </li>
            <li onClick={this.changeSort('number')}>
              Number
              <IoIosPlay className={`sort-down ${query.sort === 'number'}`} />
              <IoIosPlay className={`sort-up ${query.sort === '-number'}`} />
            </li>
          </ul>
        </div>
        <div className="list-cards-container">
          {vendors.data.filter(Boolean).map(vendor => {
            vendor.address = vendor.address || {};
            vendor.representative = vendor.representative || {};
            vendor.representative.name = vendor.representative.name || {};

            const { address, representative } = vendor;

            return (
              <div className="list-card reports-table" key={vendor._id}>
                <div className="card-body">
                  <div className="group name">
                    <p className="body-field">Vendor Name</p>
                    <p className="emph">{vendor.name}</p>
                  </div>
                  <div className="group number">
                    <p className="body-field">Number</p>
                    <p>#{vendor.number}</p>
                  </div>
                  <div className="group address">
                    <p className="body-field">Address</p>
                    <p>{address.street}</p>
                    <p>
                      {address.city}
                      {address.state && address.city && ', '}
                      {address.state} {address.zip}
                    </p>
                  </div>
                  <div className="group rep">
                    <p className="body-field">Representative</p>
                    {representative ? (
                      <div>
                        <p className="emph">
                          {[representative.name.first, representative.name.last].join(' ')}
                        </p>
                        <p>{representative.email}</p>
                        <p>{representative.phone}</p>
                      </div>
                    ) : (
                      'Unknown'
                    )}
                  </div>
                  <div className="group actions">
                    <p className="body-field">Actions</p>
                    <div className="button-contain">
                      <Link
                        title="Edit"
                        className={editButtonClasses}
                        to={{
                          pathname: `/vendors/${vendor._id}/edit`,
                          state: {
                            modal: true,
                            returnTo: this.props.location.pathname,
                            extraClass: 'add-report-modal'
                          }
                        }}
                      >
                        Edit
                      </Link>
                      {allowedToDeleteVendors && (
                        <button
                          title="Delete"
                          disabled={!allowedToDeleteVendors}
                          className={deleteButtonClasses}
                          onClick={this.deleteConfirm(vendor._id)}
                        >
                          <img src={trash} alt="trash can" />
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
          {noResults && (
            <div className="no-results">
              <span>{noResults}</span>
            </div>
          )}
        </div>
        <Pagination
          pages={pageCnt}
          activePage={thisPage}
          prev={!!vendors.prev}
          next={!!vendors.next}
          updateSearch={this.updateSearch}
        />
        <img src={sevensonLogoText} alt="sevenson logo" className="table-bottom-sevenson-logo" />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  vendors: state.vendors,
  createVendor: state.createVendor,
  user: state.user
});

const mapDispatchToProps = dispatch => {
  const actions = { fetchVendors, deleteVendor, createVendorReset };
  return { actions: bindActionCreators(actions, dispatch) };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Vendors);
