import axios from 'axios';
import * as types from '../constants/projects';
import transAxoisErr from '../etc/axios/transformAxiosError';

export const deleteProjectRequest = () => ({type: types.DELETE_PROJECT_REQUEST});

export const deleteProjectSuccess = ({ data }) => ({
  data,
  receivedAt: Date.now(),
  type: types.DELETE_PROJECT_SUCCESS
});

export const deleteProjectFailure = error => ({
  error,
  receivedAt: Date.now(),
  type: types.DELETE_PROJECT_FAILURE
});

export const deleteProject = id => (dispatch) => {
  dispatch(deleteProjectRequest());
  return axios
    .delete(`/project/${id}`)
    .then(res => dispatch(deleteProjectSuccess(res.data)))
    .catch(error => dispatch(deleteProjectFailure(transAxoisErr(error))));
};
