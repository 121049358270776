import axios from 'axios';
import * as types from '../../constants/order/reqnDraft';
import transAxiosErr from '../../etc/axios/transformAxiosError';

export const fetchReqnDraftRequest = () => ({
  type: types.GET_REQN_DRAFT_REQUEST,
  data: {}
});

export const fetchReqnDraftSuccess = ({ data }) => ({
  type: types.GET_REQN_DRAFT_SUCCESS,
  payload: data,
  receivedAt: Date.now()
});

export const fetchReqnDraftFailure = error => ({
  type: types.GET_REQN_DRAFT_FAILURE,
  error,
  receivedAt: Date.now()
});

export const fetchReqnDraft = reqnId => dispatch => {
  dispatch(fetchReqnDraftRequest());
  return axios
    .get(`/reqn/draft`, { params: { id: reqnId } })
    .then(res => dispatch(fetchReqnDraftSuccess(res.data)))
    .catch(error => dispatch(fetchReqnDraftFailure(transAxiosErr(error))));
};

export const postReqnDraftRequest = () => ({
  type: types.POST_REQN_DRAFT_REQUEST
});

export const postReqnDraftSuccess = ({ data }) => ({
  type: types.POST_REQN_DRAFT_SUCCESS,
  payload: data,
  receivedAt: Date.now()
});

export const postReqnDraftFailure = error => ({
  type: types.POST_REQN_DRAFT_FAILURE,
  payload: error,
  receivedAt: Date.now()
});

export const postReqnDraft = (reqnId, data) => dispatch => {
  dispatch(postReqnDraftRequest());
  return axios
    .post(`/reqn/draft`, data, { params: { id: reqnId } })
    .then(res => dispatch(postReqnDraftSuccess(res.data)))
    .catch(error => dispatch(postReqnDraftFailure(transAxiosErr(error))));
};
