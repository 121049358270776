import React from 'react';

import IoDocumentText from 'react-icons/lib/io/document-text';

export default class Attachments extends React.Component {
  render() {
    const allAttachments = this.props.attachmentArray.map(file => (
      <a
        href={file.url}
        key={file._id || file.url}
        download="file.name"
        className="attachment-block"
      >
        <p className="file-name">{file.name}</p>
        <i className="document-icon">
          <IoDocumentText />
        </i>
      </a>
    ));
    return <div className="rv-attachments">{allAttachments}</div>;
  }
}
