import axios from 'axios';
import * as types from '../../constants/vendor/vendors';
import transAxoisErr from '../../etc/axios/transformAxiosError';

export const deleteVendorRequest = () => ({type: types.DELETE_VENDOR_REQUEST});

export const deleteVendorSuccess = ({ data }) => ({
  data,
  receivedAt: Date.now(),
  type: types.DELETE_VENDOR_SUCCESS
});

export const deleteVendorFailure = error => ({
  error,
  receivedAt: Date.now(),
  type: types.DELETE_VENDOR_FAILURE
});

export const deleteVendor = id => (dispatch) => {
  dispatch(deleteVendorRequest());
  return axios
    .delete(`/vendor/${id}`)
    .then(res => dispatch(deleteVendorSuccess(res.data)))
    .catch(error => dispatch(deleteVendorFailure(transAxoisErr(error))));
};
