/* eslint-disable global-require */
import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { getClassNameForFilename } from 'font-awesome-filetypes';

import ProgressBar from '../shared/ProgressBar';
import uploadIcon from '../../images/upload.png';

const icons = {
  'fa-file-image-o': require('react-icons/lib/fa/file-image-o'),
  'fa-file-pdf-o': require('react-icons/lib/fa/file-pdf-o'),
  'fa-file-word-o': require('react-icons/lib/fa/file-word-o'),
  'fa-file-powerpoint-o': require('react-icons/lib/fa/file-powerpoint-o'),
  'fa-file-excel-o': require('react-icons/lib/fa/file-excel-o'),
  'fa-file-audio-o': require('react-icons/lib/fa/file-audio-o'),
  'fa-file-video-o': require('react-icons/lib/fa/file-movie-o'),
  'fa-file-zip-o': require('react-icons/lib/fa/file-archive-o'),
  'fa-file-code-o': require('react-icons/lib/fa/file-code-o'),
  'fa-file-text-o': require('react-icons/lib/fa/file-text-o'),
  'fa-file-o': require('react-icons/lib/fa/file-o')
};

const makeThumbnail = (COTextInput, disable) => ({ url }, idx) => (
  <tr key={idx}>
    <td style={{ verticalAlign: 'middle' }}>
      <a href={url} rel="noopener noreferrer" target="_blank" tabIndex="-1">
        {icons[getClassNameForFilename(url)]({ size: 50, color: '#4a90e2' })}
      </a>
    </td>
    <td className="create-media-title">
      <COTextInput disabled={disable} labelText="File Title" name={`filesArray[${idx}].name`} />
    </td>
  </tr>
);

const fileSingle = (filesArray, COTextInput) => {
  if (!filesArray || !filesArray.length) return null;
  return (
    <div key="fileViewer" className="repeater-container">
      <table className="repeater-table">
        <thead className="repeater-header">
          <tr>
            <th className="repeater-column-header image-header">File Link</th>
            <th className="repeater-column-header">Title</th>
          </tr>
        </thead>
        <tbody className="repeater-body">{filesArray.map(makeThumbnail(COTextInput, true))}</tbody>
      </table>
    </div>
  );
};

const fileRepeater = (filesArray, COTextInput) => {
  if (!filesArray || !filesArray.length) return null;
  return (
    <div className="repeater-container">
      <table className="repeater-table">
        <thead className="repeater-header">
          <tr>
            <th className="repeater-column-header image-header">File Link</th>
            <th className="repeater-column-header">Title</th>
          </tr>
        </thead>
        <tbody className="repeater-body">{filesArray.map(makeThumbnail(COTextInput))}</tbody>
      </table>
    </div>
  );
};

export default class UploadFileModalBody extends React.Component {
  static propTypes = {
    coTextInput: PropTypes.func.isRequired,
    onDrop: PropTypes.func.isRequired,
    filesArray: PropTypes.arrayOf(PropTypes.object).isRequired,
    totalProgress: PropTypes.number,
    fileRepeater: PropTypes.bool,
    accept: PropTypes.string,
    multiple: PropTypes.bool
  };

  static defaultProps = {
    totalProgress: null,
    fileRepeater: true,
    fileSingle: false,
    accept: null,
    multiple: true
  };

  render() {
    const { totalProgress, onDrop, coTextInput, filesArray, multiple } = this.props;

    return [
      <Dropzone
        key="dropzone"
        accept={this.props.accept}
        multiple={multiple}
        onDrop={onDrop}
      >
        {({ getRootProps, getInputProps }) => (
          <section  className="dropzone-body add-media-file-input">
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              {!!totalProgress && totalProgress > 0 && (
                <ProgressBar hidePercentage percentage={totalProgress} />
              )}
              {(multiple || !filesArray.length) && (
                <div className="drag-drop-body">
                  <div className="drag-drop">
                    <img src={uploadIcon} alt="upload here" />
                    <p>Drag and drop</p>
                  </div>
                  <p>or</p>
                  <button className="standard-button secondary-button">Browse</button>
                </div>
              )}
              {multiple && this.props.fileRepeater && fileRepeater(filesArray, coTextInput)}
            </div>
          </section>
        )}
      </Dropzone>,
      !multiple && fileSingle(filesArray, coTextInput)
    ];
  }
}
