import React from 'react';
import ReactCSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import Spinner from 'react-spinkit';

require('../../sass/components/shared/loader.scss');

export default class Loader extends React.Component {
  render() {
    if (!this.props.isFetching) return null;

    const items = [
      <div key="opacity-screen" className="opacity-screen" />,
      <Spinner className="spinner" key="spinner" name="cube-grid" fadeIn="none" />
    ];

    return (
      <ReactCSSTransitionGroup
        transitionName="spinner-transition"
        transitionEnterTimeout={10}
        transitionLeaveTimeout={500}
      >
        {items}
      </ReactCSSTransitionGroup>
    );
  }
}
