import React from 'react';
import { connect } from 'react-redux';
import { Typeahead } from 'react-typeahead';

import IoAlertCircled from 'react-icons/lib/io/alert-circled';
import IoHappyOutline from 'react-icons/lib/io/happy-outline';

import get from 'lodash/get';
import set from 'lodash/set';

import Repeater from '../components/shared/forms/Repeater';
import Tabs from '../components/shared/Tabs';
import Alert from '../components/shared/Alert';
import combineName from '../etc/combineName';

import { fetchUsers } from '../actions/users';
import { fetchSettings, updateSettings, settingsReset } from '../actions/settings';

const reduceKeys = keys => keys.reduce((obj, key) => set(obj, key, ''), {});

require('../sass/containers/Settings.scss');

const minError = 'All admin sections must contain at least one user.';

@connect(({ user, users, settings }) => ({ user, users, settings }))
export default class Settings extends React.Component {
  static need = [fetchSettings, fetchUsers.bind(null, { limit: -1 })];

  constructor(props) {
    super(props);

    // eslint-disable-next-line no-underscore-dangle
    const pSetting = props.settings.data || { hq: [], signing: [], purchasing: [], td: [], vendor: [] };
    if (!pSetting.vendor) {
      pSetting.vendor = []
    }
    const settings = Object.entries(pSetting).reduce((sets, [key, val]) => {
      if (/signing|purchasing|vendor|td/.test(key)) {
        sets[key] = val && val.length ? val : [{ name: { first: '', last: '' }, userRef: '' }];
      } else if (key === 'hq') {
        sets[key] = val || { name: { first: '', last: '' }, userRef: '' };
      } else {
        sets[key] = val;
      }
      return sets;
    }, {});

    this.state = { settings, errorState: [], submittedSuccess: null };

    this.getValueFromId = this.getValueFromId.bind(this);
    this.getPath = this.getPath.bind(this);
    this.replacePath = this.replacePath.bind(this);
    this.addRow = this.addRow.bind(this);
    this.delRow = this.delRow.bind(this);

    this.handleInputChange = this.handleInputChange.bind(this);
    this.typeaheadSelect = this.typeaheadSelect.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.renderPrevNext = this.renderPrevNext.bind(this);
    this.renderFormSubmit = this.renderFormSubmit.bind(this);
  }

  componentWillMount() {
    const { users, settings, dispatch } = this.props;

    if (!settings.data || settings.lastUpdated < Date.now() - 5e3) {
      dispatch(fetchSettings());
    }

    if (!users.data.length || users.lastUpdated < Date.now() - 5e3) {
      dispatch(fetchUsers({ limit: -1 }));
    }
  }

  componentWillReceiveProps(nextProps) {
    const { settings, dispatch } = this.props;

    if (
      settings &&
      settings.data &&
      nextProps.settings &&
      nextProps.settings.data &&
      settings.data !== nextProps.settings.data
    ) {
      const fetchedSettings = nextProps.settings.data;

      if (!fetchedSettings.vendor) {
        fetchedSettings.vendor = [];
      }
      this.setState({ settings: fetchedSettings });
    }
    if (nextProps.settings.submitted) {
      this.setState({ submittedSuccess: 'Settings changes saved.' });
      dispatch(settingsReset());
    }
  }

  getValueFromId(id) {
    return get(this.state.settings, id);
  }

  getPath(path) {
    return get(this.state.settings, path);
  }

  replacePath(path, content) {
    this.setState({ settings: set({ ...this.state.settings }, path, content) });
  }

  addRow(path, keys) {
    return cb => {
      const newItem = typeof keys === 'string' ? keys : reduceKeys(keys);
      const settings = { ...this.state.settings };
      settings[path].push(newItem);
      this.setState({ settings }, cb);
    };
  }

  delRow(path, idx) {
    return () => {
      const settings = { ...this.state.settings };
      const parent = get(settings, path);
      parent.splice(idx, 1);

      const nextState = { settings };

      this.setState(nextState);
    };
  }

  handleInputChange(key) {
    return event => {
      this.setState({ settings: set({ ...this.state.settings }, key, event.target.value) });
    };
  }

  typeaheadSelect(
    {
      name: { first = '', last = '' },
      _id = ''
    },
    path
  ) {
    const content = { name: { first, last }, userRef: _id };

    this.setState({ settings: set({ ...this.state.settings }, path, content) });
  }

  handleSubmit(e) {
    e.preventDefault();

    const { settings, errorState: eState } = this.state;
    const { hq, purchasing, signing } = settings;
    const hqUser = !!hq.userRef;
    const pUsers = purchasing.filter(user => user.userRef);
    const sUsers = signing.filter(user => user.userRef);

    if (!hqUser || !pUsers || !pUsers.length || !sUsers || !sUsers.length) {
      this.setState({ errorState: [...eState, minError] });
    } else {
      this.props.dispatch(updateSettings(this.state.settings));
      this.setState({ submittedSuccess: null });
    }
  }

  renderPrevNext() {
    return (
      <div className="prev-next-block">
        <p
          className="prev-tab"
          onClick={() => {
            this.tabs.setTab(this.tabs.state.tabIdx - 1);
          }}
        >
          Previous
        </p>
        <p
          className="next-tab"
          onClick={() => {
            this.tabs.setTab(this.tabs.state.tabIdx + 1);
          }}
        >
          Next
        </p>
      </div>
    );
  }

  renderFormSubmit() {
    return (
      <div className="form-submit-buttons">
        <input
          className="form-button primary-button"
          type="submit"
          onClick={this.handleSubmit}
          value="Save"
        />
      </div>
    );
  }

  render() {
    const { settings, errorState } = this.state;
    const {
      settings: settingsData,
      users: { data: users }
    } = this.props;

    const defaultRepeaterProps = {
      data: settings,
      addRow: this.addRow,
      delRow: this.delRow,
      onChange: this.handleInputChange,
      valueGetter: this.getValueFromId,
      getPath: this.getPath,
      replacePath: this.replacePath,
      disableDrag: true
    };

    let alert;
    if (settingsData && settingsData.error && settingsData.error.message) {
      alert = (
        <Alert delay={5000} type="danger">
          <i>
            <IoAlertCircled />
          </i>
          &nbsp;
          {settingsData.error.message}
        </Alert>
      );
    }

    if (this.state.submittedSuccess) {
      alert = (
        <Alert key="save-success" delay={5000} type="success">
          <i>
            <IoHappyOutline />
          </i>
          &nbsp;
          {this.state.submittedSuccess}
        </Alert>
      );
    }

    let formWarning;
    if (errorState.length) {
      formWarning = errorState.map((error, idx) => (
        // eslint-disable-next-line react/no-array-index-key
        <Alert key={idx} delay={5000} type="danger">
          <i>
            <IoAlertCircled />
          </i>
          &nbsp;
          {error}
        </Alert>
      ));
    }

    return (
      <div className="settings create-edit-modal">
        {alert}
        {formWarning}
        <p className="modal-header">Global System Settings</p>
        <Tabs ref={el => (this.tabs = el)}>
          <div idx={0} label="HQ">
            <p className="form-section-title">HQ</p>
            <p className="form-section-info">
              This user will receive an email <b>every time</b> an order is created or changed.
            </p>
            <p className="form-section-info">
              This user&apos;s approval <b>will be required</b> on every purchase order and change
              order.
            </p>
            <Typeahead
              closeDropdownOnBlur
              id="hq"
              key="hq"
              options={users}
              maxVisible={6}
              filterOption="searchName"
              placeholder="Start typing a name to autofill contact information."
              customClasses={{
                input: 'text-input-standard',
                results: 'typeahead-results',
                listItem: 'typeahead-list-item',
                listAnchor: 'typeahead-list-anchor',
                hover: 'typeahead-item-hover',
                typeahead: 'standard-typeahead',
                resultsTruncated: 'typeahead-results'
              }}
              displayOption={({ name }) => combineName(name)}
              onOptionSelected={e => this.typeaheadSelect(e, 'hq')}
              value={(() => {
                const { name } = this.getValueFromId('hq') || {};
                return combineName(name);
              })()}
            />
            <p
              className="next-tab"
              onClick={() => {
                this.tabs.setTab(this.tabs.state.tabIdx + 1);
              }}
            >
              Next
            </p>
            {this.renderFormSubmit()}
          </div>
          <div idx={1} label="Purchasing">
            <p className="form-section-title">Purchasing</p>
            <p className="form-section-info">
              These users will automatically receive an email every time an order is created or
              marked as complete.
            </p>
            <Repeater
              {...{ ...this.props, ...defaultRepeaterProps }}
              noTitle
              single
              buttonRight
              circleRemove
              name="purchasing"
              path="purchasing"
              className="items-repeats"
              tableID="purchasing-repeater"
              fields={[
                {
                  id: 'purchasing',
                  title: 'Purchasing',
                  inputType: 'typeahead',
                  typeaheadKey: 'purchasing',
                  typeaheadData: users,
                  typeaheadFilter: 'searchName',
                  typeaheadDisplay: ({ name }) => combineName(name),
                  typeaheadOnSelect: this.typeaheadSelect,
                  typeaheadValuePath: 'name',
                  typeaheadValueFn: name => combineName(name),
                  required: true
                }
              ]}
            />
            {this.renderPrevNext()}
            {this.renderFormSubmit()}
          </div>
          <div idx={2} label="Signing">
            <p className="form-section-title">Signing</p>
            <p className="form-section-info">
              These users will automatically receive an email every time an order is marked as
              approved and ready for signature.
            </p>
            <Repeater
              {...{ ...this.props, ...defaultRepeaterProps }}
              noTitle
              single
              buttonRight
              circleRemove
              name="signing"
              path="signing"
              className="items-repeats"
              tableID="signing-repeater"
              fields={[
                {
                  id: 'signing',
                  title: 'Signing',
                  inputType: 'typeahead',
                  typeaheadKey: 'signing',
                  typeaheadData: users,
                  typeaheadFilter: 'searchName',
                  typeaheadDisplay: ({ name }) => combineName(name),
                  typeaheadOnSelect: this.typeaheadSelect,
                  typeaheadValuePath: 'name',
                  typeaheadValueFn: name => combineName(name),
                  required: true
                }
              ]}
            />
            {this.renderPrevNext()}
            {this.renderFormSubmit()}
          </div>
          <div idx={3} label="T&D">
            <p className="form-section-title">Trucking & Disposal</p>
            <p className="form-section-info">
              These users will automatically be added to the list of approvers whenever an order is
              marked as a T&D order.
            </p>
            <Repeater
              {...{ ...this.props, ...defaultRepeaterProps }}
              noTitle
              single
              buttonRight
              circleRemove
              name="td"
              path="td"
              className="items-repeats"
              tableID="td-repeater"
              fields={[
                {
                  id: 'td',
                  title: 'T&D',
                  inputType: 'typeahead',
                  typeaheadKey: 'td',
                  typeaheadData: users,
                  typeaheadFilter: 'searchName',
                  typeaheadDisplay: ({ name }) => combineName(name),
                  typeaheadOnSelect: this.typeaheadSelect,
                  typeaheadValuePath: 'name',
                  typeaheadValueFn: name => combineName(name),
                  required: true
                }
              ]}
            />
            {this.renderPrevNext()}
            {this.renderFormSubmit()}
          </div>
          <div idx={4} label="Vendor Admin">
            <p className="form-section-title">Vendor Admin</p>
            <p className="form-section-info">
              These users will automatically receive emails when a vendor is created without a vendor number.
            </p>
            <Repeater
              {...{ ...this.props, ...defaultRepeaterProps }}
              noTitle
              single
              buttonRight
              circleRemove
              name="vendor"
              path="vendor"
              className="items-repeats"
              tableID="vendor-repeater"
              fields={[
                {
                  id: 'vendor',
                  title: 'Vendor Admin',
                  inputType: 'typeahead',
                  typeaheadKey: 'vendor',
                  typeaheadData: users,
                  typeaheadFilter: 'searchName',
                  typeaheadDisplay: ({ name }) => combineName(name),
                  typeaheadOnSelect: this.typeaheadSelect,
                  typeaheadValuePath: 'name',
                  typeaheadValueFn: name => combineName(name),
                  required: true
                }
              ]}
            />
            <div className="prev-next-block">
              <p
                className="prev-tab"
                onClick={() => {
                  this.tabs.setTab(this.tabs.state.tabIdx - 1);
                }}
              >
                Previous
              </p>
            </div>
            {this.renderFormSubmit()}
          </div>
        </Tabs>
      </div>
    );
  }
}
