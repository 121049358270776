import React from 'react';
import PropTypes from 'prop-types';

require('../../../sass/components/shared/forms/TextInput.scss');

const classNames = require('classnames');

export default class TextInput extends React.Component {
  static propTypes = {
    commentExist: PropTypes.bool,
    edit: PropTypes.bool,
    inputId: PropTypes.string,
    inputPlaceholder: PropTypes.string,
    inputType: PropTypes.string,
    inputValue: PropTypes.string,
    labelText: PropTypes.string,
    multiLine: PropTypes.bool,
    name: PropTypes.string,
    onChange: PropTypes.func,
    openComment: PropTypes.func,
    setOnChange: PropTypes.bool,
    value: PropTypes.string,
    valueGetter: PropTypes.func
  };

  static defaultProps = {
    commentExist: false,
    edit: false,
    inputId: '',
    inputPlaceholder: '',
    inputType: 'text',
    inputValue: '',
    labelText: '',
    multiLine: false,
    name: '',
    setOnChange: false,
    value: '',
    onChange: () => {},
    openComment: () => {},
    valueGetter: null
  };

  constructor(props) {
    super(props);

    this.state = {};

    this.resizeTextArea = this.resizeTextArea.bind(this);
  }

  resizeTextArea(inputId) {
    const input = this[inputId];
    const cols = input.cols * 1.8;
    const value = input.value;
    let nextRows = 0;

    value.split('\n').forEach((l, idx) => {
      nextRows += Math.ceil(l.length / cols);
      if (idx < value.length - 1) nextRows += 1;
    });
    input.rows = nextRows;

    if (!this.state[inputId]) {
      this.setState({ [inputId]: true });
    }
  }

  render() {
    const {
      name,
      inputId,
      inputType,
      inputValue,
      valueGetter,
      multiLine,
      inputPlaceholder,
      labelText,
      setOnChange,
      onChange,
      value,
      edit,
      openComment,
      commentExist,
      ...restProps
    } = this.props;

    const textInputClasses = classNames({
      'text-input-block': true,
      'multi-line-input-block': multiLine
    });

    const valueAttr = value || (inputValue || valueGetter ? valueGetter(name || inputId) : '');
    const valVal = inputType === 'checkbox' ? 'checked' : 'value';

    const valObj = { [valVal]: valueAttr };

    if (multiLine && this[inputId] && !this.state[inputId]) {
      //resize on load
      this.resizeTextArea(inputId);
    }

    const inputElement = multiLine ? (
      <textarea
        id={inputId}
        ref={el => (this[inputId] = el)}
        name={name}
        className="text-input-standard text-input-multiline"
        placeholder={inputPlaceholder}
        onChange={setOnChange ? onChange : onChange(inputId)}
        onKeyDown={() => this.resizeTextArea(inputId)}
        {...{ ...restProps, ...valObj }}
      />
    ) : (
      <input
        id={inputId}
        name={name}
        className="text-input-standard"
        type={inputType}
        placeholder={inputPlaceholder}
        onChange={setOnChange ? onChange : onChange(inputId)}
        {...{ ...restProps, ...valObj }}
      />
    );

    const commentClass = classNames('add-comment', { exists: commentExist });

    return (
      <div className={textInputClasses}>
        {labelText && (
          <div className="label-wrapper">
            {edit && (
              <div
                className={commentClass}
                onClick={e => openComment({ labelText, position: name }, e)}
              />
            )}
            <label htmlFor={inputId} className="text-input-standard-label">
              {labelText}
            </label>
          </div>
        )}
        {!labelText && edit && (
          <div
            className={commentClass}
            onClick={e => openComment({ labelText, position: name }, e)}
          />
        )}
        {inputElement}
      </div>
    );
  }
}
