import axios from 'axios';
import * as types from '../../constants/order/orders';
import transAxoisErr from '../../etc/axios/transformAxiosError';

export const fetchOrdersRequest = () => ({ type: types.FETCH_ORDERS_REQUEST });

export const fetchOrdersSuccess = ({ data, next, prev }) => ({
  data,
  next,
  prev,
  receivedAt: Date.now(),
  type: types.FETCH_ORDERS_SUCCESS
});

export const fetchOrdersFailure = error => ({
  error,
  receivedAt: Date.now(),
  type: types.FETCH_ORDERS_FAILURE
});

export const fetchOrders = ({ next, ...params } = {}) => dispatch => {
  dispatch(fetchOrdersRequest());
  return axios
    .get(next || '/order/all', { params })
    .then(res => dispatch(fetchOrdersSuccess(res.data)))
    .catch(error => dispatch(fetchOrdersFailure(transAxoisErr(error))));
};

export const fetchOrderRequest = () => ({ type: types.FETCH_ORDER_REQUEST });

export const fetchOrderSuccess = ({ data, next, prev }) => ({
  data,
  next,
  prev,
  receivedAt: Date.now(),
  type: types.FETCH_ORDER_SUCCESS
});

export const fetchOrderFailure = error => ({
  error,
  receivedAt: Date.now(),
  type: types.FETCH_ORDER_FAILURE
});

export const fetchOrder = ({ id }) => dispatch => {
  dispatch(fetchOrderRequest());
  return axios
    .get(`/order/${id}`)
    .then(
      res => dispatch(fetchOrderSuccess(res.data)),
      error => dispatch(fetchOrderFailure(transAxoisErr(error)))
    );
};
