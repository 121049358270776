export default [
  'The materials, services and/or equipment to be supplied and/or performed by Subcontractor are hereinafter referred to as “Subcontractor’s Work”. Subcontractor expressly assumes toward Contractor all obligations and responsibilities applicable to the furnishing and/or performance of Subcontractor’s Work which Contractor assumes toward Owner in the Principal Contract. Subcontractor agrees that, in addition to any other rights and remedies afforded Contractor by this agreement or law, Contractor shall have the same rights and remedies against Subcontractor with respect to Subcontractor’s Work which Owner has against Contractor under the Principal Contract, all with the same force and effect as if set forth herein in full. The Principal Contract includes the entire agreement between Owner and Contractor including all contract provisions required by law, order or regulation, all with the same force and effect as if set forth herein in full.',
  'TIME OF PERFORMANCE IS OF THE ESSENCE. Subcontractor agrees that Subcontractor’s Work shall be commenced promptly, prosecuted with strict diligence, and fully completed within the time limits herein fixed for delivery and completion or, if no time limits are herein fixed, as directed by Contractor.',
  'Contractor reserves the right to order additions, omissions or modifications to Subcontractor’s Work, subject to an adjustment of price. No addition, omission or modification to Subcontractor’s Work shall be authorized, nor shall any claim on account thereof be made or valid, except upon Contractor’s prior written order.',
  'Subcontractor hereby assumes entire responsibility for the health and safety of its employees and all other persons who may be exposed to harm incidental to Subcontractor’s Work. At all times, Subcontractor shall provide and maintain a safe place to work; shall be satisfied that all equipment, tools, facilities, ladders, scaffolds and other like items, regardless of ownership, are fit and safe for use; shall provide all necessary personal safety equipment and be responsible to insure that same is used at all times when required; and shall comply with all laws, rules, regulations, instructions, standards and requirements concerning safety. Subcontractor shall erect and maintain all reasonable and necessary safeguards for protection of workers, and shall post signs warning against hazards incidental to Subcontractor’s Work. In fulfilling its obligations hereunder, Subcontractor shall not rely on Contractor as having taken or to take any particular action or precaution.',
  'Subcontractor assumes entire responsibility for any and all damage or injury of any kind or nature whatever, including death, illness and disease, to persons, including Subcontractor’s employees, and to property, including adjoining and off-site property and including loss of use or services, caused by, resulting from, or occurring in connection with Subcontractor’s Work or any Subcontractor act or omission. Subcontractor shall defend and, to the fullest extent permitted by law, indemnify and save harmless, Contractor, Owner, Engineer, and their respective officers, agents and employees, from and against any and all claims, losses, damages, costs, liability, and expenses, including legal fees and expenses, directly or indirectly caused by, resulting from, or occurring in connection with Subcontractor’s Work or any Subcontractor act or omission. Subcontractor’s indemnity obligation shall not be restricted by any limitation on the amount or type of damages, compensation or benefits payable by or for Subcontractor under workers compensation, disability, or other employee benefit acts.',
  'Subcontractor shall maintain such insurance as required by the Principal Contract, which shall include the following minimum coverages, all with carriers satisfactory to Contractor: Workers Compensation Insurance as required by the jurisdiction wherein the work is performed; Commercial General Liability Insurance including the following: Contractual Liability Insurance, Completed Operations and Product Liability, Broad Form Property Damage, Premises/Operations, and Explosion, Collapse and Underground Property Damage; and Automobile Liability Insurance including coverage for non-owned and hired vehicles. Such insurance shall provide limits of not less than $1,000,000 combined bodily injury and property damage. Subcontractor shall furnish a certificate in form and substance as Contractor shall require certifying that such insurance is in force, that the liability policies name as additional insureds, and all policies waive subrogation against, Contractor, Owner, Engineer and their respective officers, agents and employees, and all other persons or entities required to be so named by the Principal Contract and that such insurance may not be cancelled or allowed to expire except upon 30 days’ prior written notice to Contractor. Coverage provided additional insureds shall be primary coverage with respect to any insurance or self-insurance maintained by an additional insured which shall be excess of, and non-contributory to, Subcontractor’s insurance.',
  'Each month Subcontractor shall submit to Contractor a written requisition for payment for Subcontractor’s Work furnished or performed the previous month. Payment shall be made in the manner, at the time, and to the extent specified herein, but in no event shall payment exceed the value of Subcontractor’s Work furnished or performed through the end of the previous month less all previous payments, less all charges for material and services furnished by Contractor to Subcontractor, less all amounts reserved for deficiencies in the materials or work or for third-party claims related to Subcontractor’s Work for which Contractor may be liable, and less any other sums Contractor shall be entitled to retain under this agreement or by law. Any payment due hereunder shall be made solely from current funds received by Contractor from Owner, which such receipt shall be a condition precedent of Contractor’s obligation to pay Subcontractor. Contractor shall not be liable to Subcontractor for interest except any monies past due more than 30 days due to no fault or neglect of Subcontractor will thereafter bear interest at the prime rate. Subcontractor shall not assign, transfer, convey, or sublet this Purchase Order or any money due hereunder, without Contractor’s prior written consent.',
  'Subcontractor agrees to accept payment in full for Subcontractor’s Work at the unit prices and/or lump sums specified herein, regardless of whether the final quantities are more or less than the estimated quantities stated herein or in the Principal Contract. Subcontractor shall have and make no claim for damages or for anticipated or lost profits because of a difference between the quantities of the various items of work actually done or materials actually delivered, and the stated estimated quantities of such items or because of the entire omission of any item or items of work.',
  'Subcontractor shall be solely responsible for obtaining approval of all samples, tests, shop drawings and other submittals as may be required by the Engineer. Contractor’s and/or Engineer’s review, approval or failure to object to Subcontractor’s submissions or tests shall not relieve Subcontractor from full responsibility for performance and completion of the Subcontractor’s Work in compliance with the Principal Contract.',
  'Contractor shall have the right at any time upon written notice to Subcontractor to terminate and cancel this contract, without fault of Subcontractor, for any cause or for its convenience, and to require Subcontractor to cease work as Contractor directs. In such event, Contractor shall pay Subcontractor for such of Subcontractor’s Work actually furnished or performed at the contract price of such work; Subcontractor shall not be entitled to or receive anticipated profits on work unperformed or on materials, equipment or other items not furnished or used. Termination by Contractor shall not relieve Subcontractor of any responsibility or liability for any default or breach of this agreement by Subcontractor.',
  'Should at any time Subcontractor become financially incapable, or should Subcontractor fail in any respect, to prosecute Subcontractor’s Work with promptness and diligence, or should Subcontractor otherwise default, Contractor may declare Subcontractor in default and may, upon three (3) days’ written notice to Subcontractor, mailed or delivered to Subcontractor’s above address, terminate Subcontractor’s performance and take possession of Subcontractor’s materials, and employ any persons to finish Subcontractor’s Work. In the event of such termination, Subcontractor shall not be entitled to receive further payment hereunder until Subcontractor’s Work is complete, at which time, if the unpaid balance of the amount to be paid exceeds the costs incurred in finishing said work, such excess shall be paid to Subcontractor; but if such costs exceed the unpaid balance, then Subcontractor shall pay such balance to Contractor. Such costs also include all losses, damages, costs and expenses, including legal fees and expense, incurred by Contractor resulting from Subcontractor’s default. Termination hereunder shall not relieve Subcontractor of any responsibility or liability for any default or breach of this agreement by Subcontractor. A termination hereunder, if found to have been beyond the right reserved herein to Contractor, shall be treated as a termination under Paragraph 10.',
  'In addition to all other guarantees, and not in limitation thereof, Subcontractor guarantees that Subcontractor’s Work shall be performed in a good and workmanlike manner, that all materials and other items furnished hereunder shall be good and merchantable, and that said work, materials and other items shall be in strict accord with the Principal Contract, shall be free from defects, and shall be fit for their intended purpose. Subcontractor agrees to repair or replace, as Owner or Contractor shall require, any and all work, materials or other items furnished hereunder which Owner or Contractor judge to be faulty, defective, or not in accordance with Principal Contract. Subcontractor agrees that the receipt, acknowledgment, retention, use or payment for work, materials or other items furnished hereunder shall not be construed to be an acceptance of faulty, defective or non-conforming items.',
  'Contractor may at any time suspend execution of Subcontractor’s Work or work under the Principal Contract, in whole or part, for a reasonable period, or periods, of time, if Contractor determines to do so. Should Subcontractor be delayed in prosecution or completion of Subcontractor’s Work, or should the sequence or timing of Subcontractor’s Work be changed or disrupted by such suspension, or by force majeure, or by changes in the Subcontractor’s Work or other project work, or by unforeseen physical conditions at the site, or by the act, fault or neglect or the Owner, Engineer, Contractor, subcontractors or suppliers, or any other cause whatsoever, without any fault or neglect on Subcontractor’s part, then, provided Subcontractor has complied with applicable notice requirements of the Principal Contract, the time for completion of the Subcontractor’s Work shall be extended for a period equivalent to the time lost thereby; but Contractor shall not be liable or responsible or answerable in any way for any additional costs, loss of profit or any other damages caused thereby. Subcontractor agrees to make no claim for delay or interruption of Subcontractor’s Work except for an extension of time.',
  'Subcontractor agrees to comply with all laws, rules, regulations, standards and ordinances of the federal, state and local authorities applicable to Subcontractor’s Work. Subcontractor shall be familiar with and shall adhere to Contractor’s “Subcontractor/Subcontractor Code of Ethics” found at www.sevenson.com/policies.',
  'Any dispute arising hereunder shall be subject to any applicable dispute resolution procedures in the Principal Contract. Subcontractor agrees that venue in any action by Subcontractor against Contractor and/or Contractor’s surety shall be Erie County, N.Y. This contract shall be governed and construed under the laws of the state, territory or province in which the project is located without regard to rules concerning conflicts of law. Contractor and Vendor agree that neither shall be liable to the other for any special, indirect, punitive or exemplary damages and hereby mutually release each other from any such liability.',
  'Subcontractor shall assure harmonious labor relations at the project site and prevent strikes or other labor disputes. Subcontractor shall fully abide by all applicable labor agreements and jurisdictional decisions presently in force or subsequently executed with or by Contractor.',
  'This agreement is the sole and entire agreement between the parties and supersedes all prior communications, proposals, offers and agreements, whether written or oral. Any reference to Subcontractor’s quotation, bid or proposal does not imply acceptance of any term or condition contained therein. In the event of conflict between this agreement and any term in a Subcontractor-supplied form, this agreement shall govern. Subcontractor agrees that Contractor has made no representations or promises of any kind with respect to Subcontractor’s Work or this agreement except as may be expressly set forth herein. No waiver or change by Contractor of any term hereof shall be effective unless in writing subscribed by an officer of Contractor. '
];
