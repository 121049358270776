/* eslint jsx-a11y/no-static-element-interactions: 0 */
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link, browserHistory } from 'react-router';
import IoAlertCircled from 'react-icons/lib/io/alert-circled';

import get from 'lodash/get';
import set from 'lodash/set';

import Repeater from '../../components/shared/forms/Repeater';
import Alert from '../../components/shared/Alert';
import Tabs from '../../components/shared/Tabs';
import { createOrderSubmit, createOrderReset } from '../../actions/order/createOrder';
import { editOrder } from '../../actions/order/editOrder';
import { fetchOrder } from '../../actions/order/orders';
import { fetchProjects } from '../../actions/projects';
import { fetchVendors } from '../../actions/vendor/vendors';

require('../../sass/containers/Reports/CreateReport.scss');
require('../../sass/containers/CreateProject.scss');
require('../../sass/containers/Orders/EditOrder.scss');

const { Typeahead } = require('react-typeahead');

const typeaheadClasses = {
  input: 'text-input-standard',
  results: 'typeahead-results',
  listItem: 'typeahead-list-item',
  listAnchor: 'typeahead-list-anchor',
  hover: 'typeahead-item-hover',
  typeahead: 'standard-typeahead',
  resultsTruncated: 'typeahead-results'
};

class EditOrder extends React.Component {
  static need = [fetchProjects.bind(null, { limit: -1 }), fetchVendors.bind(null, { limit: -1 })];
  constructor(props) {
    super(props);

    this.state = {
      order: {
        number: '',
        status: '',
        category: '',
        files: [],
        emailRecipients: [''],
        vendorRef: '',
        projectRef: ''
      },
      project: '',
      vendor: ''
    };

    this.getValueFromId = this.getValueFromId.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.addRow = this.addRow.bind(this);
    this.delRow = this.delRow.bind(this);
    this.replacePath = this.replacePath.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleTypeaheadSelect = this.handleTypeaheadSelect.bind(this);
  }

  componentWillMount() {
    this.props.actions.fetchOrder(this.props.params);
    if (!this.props.projects || !this.props.projects.data || !this.props.projects.data.length) {
      this.props.actions.fetchProjects({ limit: -1 });
    }
    if (!this.props.vendors || !this.props.vendors.data || !this.props.vendors.data.length) {
      this.props.actions.fetchVendors({ limit: -1 });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.order.data !== nextProps.order.data) {
      const nextState = { ...this.state };
      const fetchedOrder = nextProps.order.data;

      nextState.order = fetchedOrder;

      const projectRef = fetchedOrder.projectRef || {};
      const vendorRef = fetchedOrder.vendorRef || {};

      nextState.project = projectRef.name;
      nextState.order.projectRef = projectRef._id;

      nextState.vendor = vendorRef.name;
      nextState.order.vendorRef = vendorRef._id;

      this.setState(nextState);
    } else if (nextProps.createOrder.submitted) {
      if (window.confirm('Saved successfully, go back?')) {
        browserHistory.push(this.props.location.state.returnTo);
      }
    }
  }

  componentWillUnmount() {
    if (this.props.createOrder.submitted) {
      this.props.actions.createOrderReset();
    }
  }

  getValueFromId(id) {
    return get(this.state.order, id) || '';
  }

  addRow(path) {
    return () => {
      const emailRecipients = [...this.state.order.emailRecipients];
      emailRecipients.push('');
      this.setState({ order: set({ ...this.state.order }, path, emailRecipients) });
    };
  }

  delRow(path, idx) {
    return () => {
      // eslint-disable-next-line no-alert
      if (!window.confirm('Are you sure you want to delete this row?')) return;
      const emailRecipients = [...this.state.order.emailRecipients];
      emailRecipients.splice(idx, 1);
      this.setState({ order: set({ ...this.state.order }, path, emailRecipients) });
    };
  }

  replacePath(path, content) {
    this.setState({ order: set({ ...this.state.order }, 'emailRecipients', content) });
  }

  handleSubmit(e) {
    e.preventDefault();

    this.props.actions.editOrder(this.state.order, this.props.params.id);
  }

  handleInputChange(key) {
    return event => {
      this.setState({ order: set({ ...this.state.order }, key, event.target.value) });
    };
  }

  handleTypeaheadSelect({ _id, name }) {
    return type => {
      const nextState = { ...this.state };
      nextState.order[`${type}Ref`] = _id;
      nextState[type] = name;
      this.setState(nextState);
    };
  }

  render() {
    const { order } = this.state;
    const { createOrder } = this.props;
    const projects = this.props.projects.data;
    const vendors = this.props.vendors.data;

    let returnTo = false;
    const locationObject = this.props.location.state;
    if (locationObject && locationObject.returnTo) {
      // eslint-disable-next-line prefer-destructuring
      returnTo = locationObject.returnTo;
    }

    let alert;
    if (createOrder.error && createOrder.error.message) {
      alert = (
        <Alert delay={5000} type="danger">
          <i>
            <IoAlertCircled />
          </i>
          &nbsp;
          {createOrder.error.message}
        </Alert>
      );
    }

    return (
      <div className="create-edit-modal edit-order">
        {alert}
        <p className="modal-header">{`Order #${order.number}`}</p>
        <Tabs ref={el => (this.tabs = el)}>
          <div idx={0} label="Order Info">
            <form className="modal-form" id="create-order-form">
              <p className="form-section-title">Order Information</p>
              <div className="input-fields">
                <div className="modal-form-block">
                  {/* TODO: if we want the number changeable will also need to auto update first Reqn number */}
                  {/* <TextInput labelText="Order Number" inputId="number" {...defaultInputProps} /> */}
                  <div className="text-input-block select-block">
                    <p className="text-input-standard-label">Category</p>
                    <select
                      id="category"
                      form="create-order-form"
                      className="standard-select category"
                      value={this.getValueFromId('category')}
                      onChange={this.handleInputChange('category')}
                    >
                      <option value="standard">Standard</option>
                      <option value="subcontract">Subcontractor</option>
                      <option value="rental">Rental</option>{' '}
                      <option value="service">Service</option>
                    </select>
                  </div>
                </div>
              </div>
              <p className="next-tab" onClick={() => this.tabs.setTab(this.tabs.state.tabIdx + 1)}>
                Next
              </p>
              <div className="form-submit-buttons">
                <input
                  className="form-button primary-button"
                  type="submit"
                  onClick={this.handleSubmit}
                  value="Save"
                />
                {returnTo && (
                  <Link to={returnTo} className="div-link-button form-button cancel-button">
                    Cancel
                  </Link>
                )}
              </div>
            </form>
          </div>
          <div idx={1} label="Project/Vendor">
            <form className="modal-form" id="create-order-form">
              <p className="form-section-title">Change Project or Vendor</p>
              <div className="modal-form-block">
                <div className="typeahead-label-block">
                  <label htmlFor="projectRef" className="text-input-standard-label">
                    Project
                  </label>
                  <Typeahead
                    closeDropdownOnBlur
                    id="projectRef"
                    options={projects}
                    maxVisible={6}
                    filterOption="name"
                    displayOption="name"
                    customClasses={typeaheadClasses}
                    onOptionSelected={e => this.handleTypeaheadSelect(e)('project')}
                    value={this.state.project}
                    placeholder="Type Project name"
                  />
                </div>
                <div className="typeahead-label-block">
                  <label htmlFor="projectRef" className="text-input-standard-label">
                    Vendor
                  </label>
                  <Typeahead
                    closeDropdownOnBlur
                    id="vendorRef"
                    options={vendors}
                    maxVisible={6}
                    filterOption="name"
                    displayOption="name"
                    customClasses={typeaheadClasses}
                    onOptionSelected={e => this.handleTypeaheadSelect(e)('vendor')}
                    value={this.state.vendor}
                    placeholder="Type Vendor name"
                  />
                </div>
              </div>
              <div className="modal-form-block" />

              <div className="prev-next-block">
                <p
                  className="prev-tab"
                  onClick={() => {
                    this.tabs.setTab(this.tabs.state.tabIdx - 1);
                  }}
                >
                  Previous
                </p>
                <p
                  className="next-tab"
                  onClick={() => {
                    this.tabs.setTab(this.tabs.state.tabIdx + 1);
                  }}
                >
                  Next
                </p>
              </div>
              <div className="form-submit-buttons">
                <input
                  className="form-button primary-button"
                  type="submit"
                  onClick={this.handleSubmit}
                  value="Save"
                />
                {returnTo && (
                  <Link to={returnTo} className="div-link-button form-button cancel-button">
                    Cancel
                  </Link>
                )}
              </div>
            </form>
          </div>
          <div idx={2} label="Emails">
            <form className="modal-form" id="create-order-form">
              <p className="form-section-title">Changes to this Order will notify:</p>
              <div className="modal-form-block">
                <Repeater
                  single
                  noTitle
                  buttonRight
                  circleRemove
                  addRow={this.addRow}
                  delRow={this.delRow}
                  getPath={this.getValueFromId}
                  className="create-order-repeater"
                  path="emailRecipients"
                  namePath="emailRecipients"
                  replacePath={this.replacePath}
                  onChange={this.handleInputChange}
                  valueGetter={this.getValueFromId}
                  data={order}
                  tableID="email-repeater"
                  fields={[
                    {
                      title: 'Email Address',
                      inputType: 'email',
                      required: true
                    }
                  ]}
                />
              </div>
              <p
                className="prev-tab"
                onClick={() => {
                  this.tabs.setTab(this.tabs.state.tabIdx - 1);
                }}
              >
                Previous
              </p>
              <div className="form-submit-buttons">
                <input
                  className="form-button primary-button"
                  type="submit"
                  onClick={this.handleSubmit}
                  value="Save"
                />
                {returnTo && (
                  <Link to={returnTo} className="div-link-button form-button cancel-button">
                    Cancel
                  </Link>
                )}
              </div>
            </form>
          </div>
        </Tabs>
      </div>
    );
  }
}

const mapStateToProps = ({ createOrder, user, order, projects, vendors }) => ({
  createOrder,
  user,
  order,
  projects,
  vendors
});

const mapDispatchToProps = dispatch => {
  const actions = {
    createOrderSubmit,
    createOrderReset,
    fetchOrder,
    editOrder,
    fetchProjects,
    fetchVendors
  };
  return { actions: bindActionCreators(actions, dispatch) };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditOrder);
