import axios from 'axios';
import * as types from '../constants/user';
import transAxoisErr from '../etc/axios/transformAxiosError';

export const loginRequest = () => ({type: types.LOGIN_REQUEST});

export const logoutRequest = () => ({type: types.LOGOUT_REQUEST});

export const loginSuccess = ({ data }) => ({
  type: types.LOGIN_SUCCESS,
  payload: data,
  receivedAt: Date.now()
});

export const logoutSuccess = ({ data }) => ({
  type: types.LOGOUT_SUCCESS,
  payload: data,
  receivedAt: Date.now()
});

export const loginFailure = error => ({
  type: types.LOGIN_FAILURE,
  error,
  receivedAt: Date.now()
});

export const logoutFailure = error => ({
  type: types.LOGOUT_FAILURE,
  error,
  receivedAt: Date.now()
});

export const submitLogin = body => dispatch => {
  dispatch(loginRequest());
  body.username = body.username && body.username.toLowerCase();
  return axios
    .post('/public/user/login', body)
    .then(res => dispatch(loginSuccess(res.data)))
    .catch(error => dispatch(loginFailure(transAxoisErr(error))));
};

export const logout = () => dispatch => {
  dispatch(logoutRequest());
  return axios
    .post('/public/user/logout')
    .then(res => dispatch(logoutSuccess(res.data)))
    .catch(error => dispatch(logoutFailure(transAxoisErr(error))));
};
