/* eslint jsx-a11y/no-static-element-interactions: 0 */
import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';

import IoArrowUp from 'react-icons/lib/io/arrow-up-b';
import FaClose from 'react-icons/lib/fa/close';

import Modal from '../../components/shared/Modal';
import FilterDrawer from '../../components/shared/FilterDrawer';

import setFileViewerFile from '../../actions/fileViewer';

const classNames = require('classnames');

require('../../sass/components/shared/Wizard.scss');

function makeWizardSteps(containers, stepIdx, setStep, errorPages = [], onNext) {
  return containers.map((container, idx) => {
    const labelClasses = classNames({
      'wizard-step': true,
      'active-step': idx === stepIdx
    });
    const boxClasses = classNames({
      box: true,
      error: errorPages && errorPages.includes(idx)
    });

    return (
      <div
        className={labelClasses}
        // eslint-disable-next-line react/no-array-index-key
        key={idx}
        onClick={() => {
          setStep(idx);
          onNext && onNext();
        }}
      >
        <div className="box-and-dots">
          <span className="dots" />
          <span className={boxClasses} />
          <span className="dots" />
        </div>
        <div className="step-name">
          <p>{container && container.props && container.props.label}</p>
        </div>
      </div>
    );
  });
}

@connect(
  ({ fileViewer }) => ({ fileViewer }),
  null,
  null,
  { forwardRef: true }
)
export default class Wizard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stepIdx: 0,
      wizardDrawer: false,
      showErrorList: false
    };

    this.setStep = this.setStep.bind(this);
    this.trackCheckbox = this.trackCheckbox.bind(this);
    this.toggleDrawer = this.toggleDrawer.bind(this);
    this.closeFileViewer = this.closeFileViewer.bind(this);
    this.makeStepperButtons = this.makeStepperButtons.bind(this);
  }

  componentWillMount() {
    const { location } = this.props;

    if (location && location.query && location.query.wizard) {
      this.setState({ stepIdx: location.query.wizard });
    }
  }

  componentWillUnmount() {
    this.closeFileViewer();
  }

  setStep(stepIdx) {
    const nextState = { ...this.state };
    nextState.stepIdx = stepIdx;
    if (this.props.errors && this.props.errors.length && !this.state.showErrorList) {
      nextState.showErrorList = true;
    }
    this.setState(nextState);
    this.props.trackWizard(stepIdx);
  }

  trackCheckbox() {
    return this.checkbox && this.checkbox.checked;
  }

  toggleDrawer() {
    const current = this.state.wizardDrawer;
    this.setState({ wizardDrawer: !current });
    this.props.trackWizardDrawer(!current);
  }

  closeFileViewer() {
    this.props.dispatch(setFileViewerFile());
  }

  makeStepperButtons() {
    const { stepIdx, checkbox: sCheckbox } = this.state;
    const containers = this.props.children;
    const activeContainers = containers.filter(c => c.props && c.props.label) || [];
    const {
      onNext,
      handleFinish,
      checkbox,
      finishLabel,
      checkboxFinish,
      disabled,
      disabledCB
    } = this.props;

    const isChecked = sCheckbox;

    return (
      <div className="button-container">
        <button
          className="standard-button secondary-button cancel"
          onClick={() => {
            this.setState({ cancel: true });
          }}
        >
          Cancel
        </button>
        <div className="checkbox-and-button">
          {checkbox && !disabledCB && (
            <div className="checkbox-group">
              <label htmlFor="option-checkbox">{checkbox}</label>
              <input
                type="checkbox"
                id="option-checkbox"
                ref={el => (this.checkbox = el)}
                onChange={() => this.setState({ checkbox: this.checkbox.checked })}
              />
            </div>
          )}
          <button
            disabled={(!isChecked && disabled) || (isChecked && disabledCB)}
            onClick={() => {
              /**
               * Draft if checkbox is for 'Submit' & is not checked.
               *
               * If checkbox is for something other than submit then we are
               * past the draft-able stage.
               *
               */
              handleFinish(checkbox === 'Submit' && !this.state.checkbox);
              this.setState({ showErrorList: true });
            }}
            className="standard-button primary-button super-button"
          >
            {this.state.checkbox ? checkboxFinish : finishLabel}
          </button>
          {stepIdx < activeContainers.length - 1 && (
            <button
              className="standard-button primary-button super-button"
              onClick={() => {
                this.setStep(stepIdx + 1);
                onNext && onNext();
              }}
            >
              Next
            </button>
          )}
        </div>
      </div>
    );
  }

  render() {
    const containers = this.props.children;
    const activeContainers = containers.filter(c => c.props && c.props.label) || [];
    const { stepIdx, showErrorList } = this.state;

    const {
      onNext,
      allPageTitle,
      extraSubtitle,
      drawer,
      cancel,
      errors,
      errorPages,
      fileViewer: { file }
    } = this.props;

    const drawerOpen = this.state.wizardDrawer;
    const fViewOpen = !!file.url;

    const contentClasses = classNames('wizard-content-with-drawer', { 'drawer-open': drawerOpen });
    const pageContentClasses = classNames('wizard-page-content', { 'file-viewer-open': fViewOpen });

    return (
      <div className="wizard-container">
        <div className="header">
          <div className="page-title">
            <p>
              {activeContainers[stepIdx] &&
                activeContainers[stepIdx].props &&
                activeContainers[stepIdx].props.label}
            </p>
            <p className="sub-title">{allPageTitle}</p>
            {extraSubtitle && <p className="sub-title">{extraSubtitle}</p>}
          </div>
          <button
            className="standard-button primary-button justify-right"
            onClick={() => {
              return axios
                .post(
                  '/reqn/pdf-preview',
                  { ...this.props.reqn, order: this.props.order },
                  { responseType: 'arraybuffer' }
                )
                .then(resp => {
                  const file = new Blob([resp.data], { type: resp.headers['content-type'] });
                  const fileURL = URL.createObjectURL(file);
                  window.open(fileURL, 'pdfPreview');
                  // const link = document.createElement('a');
                  // link.href = fileURL;
                  // link.target = '_blank';
                  // link.download = 'order_preview.pdf';
                })
                .catch(e => window.alert(e));
            }}
          >
            View PDF Preview
          </button>
          {drawer && drawer.content && (
            <button
              className="standard-button primary-button justify-right"
              onClick={this.toggleDrawer}
            >
              {drawer.button}
            </button>
          )}
        </div>
        <div className={contentClasses}>
          <div className="wizard-body">
            <div className="wizard-stepper-and-button">
              <div className="wizard-stepper">
                {makeWizardSteps(activeContainers, stepIdx, this.setStep, errorPages, onNext)}
              </div>
              {showErrorList && errors && errors.length ? (
                <div className={classNames('wizard-errors', { 'is-co': this.props.isChangeOrder })}>
                  <IoArrowUp className="error-triangle" />
                  <h3>Errors or incomplete sections</h3>
                  {/* eslint-disable-next-line react/no-array-index-key */}
                  <ul>{errors && errors.map((error, idx) => <li key={idx}>{error}</li>)}</ul>
                </div>
              ) : null}
            </div>
            <div
              className={pageContentClasses}
              onFocus={() => this.setState({ showErrorList: false })}
            >
              {activeContainers[stepIdx] &&
                activeContainers[stepIdx].props &&
                activeContainers[stepIdx].props.children}
              {file.url && (
                <div className="file-viewer">
                  <div className="file-title">
                    <h3>{file.name}</h3>
                    <button
                      title="Close File Viewer"
                      onClick={this.closeFileViewer}
                      className="file-close-btn"
                    >
                      Close
                    </button>
                  </div>
                  <iframe
                    title="File Viewer"
                    className="file-frame"
                    src={`https://docs.google.com/gview?url=${file.url}&embedded=true`}
                  />
                </div>
              )}
            </div>
            {this.makeStepperButtons()}
          </div>
          {drawer && drawer.content && (
            <FilterDrawer
              right
              noCloseOnBlur
              open={drawerOpen}
              closeDrawer={this.toggleDrawer}
              className={drawer.className}
              onFocus={() => this.setState({ showErrorList: false })}
            >
              {drawer.content}
            </FilterDrawer>
          )}
        </div>
        {this.state.cancel && (
          <Modal
            isOpen
            extraClass="delete-confirm-modal"
            ref={modal => {
              this.cancel = modal;
            }}
            toCancel={() => {
              this.setState({ cancel: undefined });
            }}
          >
            <div className="modal-text-top">
              <p className="modal-header">Are you sure?</p>
              <p className="modal-subheader">Discard your changes? You cannot recover them.</p>
            </div>
            <div className="modal-body">
              <div className="modal-buttons">
                <button className="modal-button modal-delete" onClick={cancel}>
                  confirm
                </button>
                <button
                  className="modal-button modal-cancel"
                  onClick={() => {
                    this.setState({ cancel: undefined });
                  }}
                >
                  cancel
                </button>
              </div>
            </div>
          </Modal>
        )}
      </div>
    );
  }
}
