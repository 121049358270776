export default [
  'OFFER AND ACCEPTANCE. This order is an offer to purchase and is expressly limited to the terms contained in this order. Any terms and conditions contained in quotations or similar forms of Vendor or that may be proposed in any acknowledgment or acceptance that are in addition to or different from these terms are hereby rejected and shall not become part of the purchase agreement. If prior to signing and accepting this order Vendor ships any goods in response to this order, Contractor may at its sole election, either reject the tendered goods or treat such action as constituting acceptance and assent to the terms contained in this order.',
  'PRICE. If no prices are stipulated herein, the price of the goods furnished hereunder shall not exceed those last previously quoted or charged to Contractor for goods of like kind or quality.',
  'SHIPMENT AND DELIVERY. Time is of the essence with respect to any delivery or performance dates provided for in this order. Except as otherwise indicated on the face hereof, all goods are to be shipped F.O.B. destination. Vendor shall suitably pack, crate and mark all goods to prevent damage and conform to requirements of carriers without additional charge. Delivery shall be made in quantities and at times specified herein or in supplementary written instructions to Vendor from Contractor, and Contractor expressly reserves the right to change delivery schedules. Notwithstanding any shipping, F.O.B. or other terms, Contractor shall have the right to return all freight damaged goods to Vendor at Vendor’s expense and receive full credit therefor, unless such damage is due to Contractor’s negligence. If any goods delivered do not conform to all terms of this order, Contractor may reject such goods or the entire lot received. If this order requires or authorizes deliveries of goods in separate lots, Contractor, if it rejects or cancels one or more separate lots as aforesaid, may also, at its sole election, cancel any undelivered lots and purchase elsewhere and charge Vendor with any loss incurred as a result thereof.',
  'WARRANTIES. Vendor warrants that all goods provided by it: (i) shall be of good quality and workmanship and free from defects, latent or patent; (ii) shall conform to all specifications, drawings, or descriptions furnished, specified or adopted by Contractor; (iii) shall be merchantable and suitable and sufficient for their intended purposes; and (iv) shall be free of any claim of any third party. The warranties set forth in this paragraph apply whether or not Vendor is a merchant with respect to such goods. Contractor’s inspection, acceptance or retention of or its payment for goods shall not constitute a waiver of any of Vendor’s warranties. Contractor’s approval of any sample or acceptance of any goods or services shall not relieve Vendor from its responsibility to deliver goods in conformity with all terms of this order.',
  'PATENTS. Vendor warrants that neither the goods furnished hereunder nor the sale or use thereof will infringe any existing U.S. Letters Patent. Vendor shall defend, indemnify and hold Contractor harmless against any and all liability, loss and expense incurred in connection with any suit or proceeding brought against Contractor that is based on a claim that the goods furnished hereunder, when used in the manner and for the purpose for which said goods are manufactured and sold, constitute an infringement of any existing U.S. Letters Patent, providing the Contractor give Vendor prompt written notice thereof.',
  'INDEMNITY. Vendor shall defend, indemnify and hold Contractor harmless from any and all liability, loss or expense, or claims therefor, arising out of death or personal injury or damage to property or business, by whomsoever suffered, connected with or related to goods purchased under this order, including the delivery and unloading thereof, and including but not limited to the failure of the goods to comply with any warranty made by Vendor or with any federal, state or local law, rule or regulation applicable to the manufacture or furnishing of the goods, provided that this indemnity shall not apply to the extent such liability, loss or expense, or claims therefor, result solely from the negligence of Contractor.',
  'CANCELLATION AND REMEDIES. Contractor may cancel this order in whole or part if: (1) the goods furnished do not conform to Vendor’s warranties; (ii) Vendor fails to make deliveries as required by this order; (iii) Vendor breaches any other term of this order; or (iv) any representation by Vendor has been false. In the event of any such cancellation, Contractor, in addition to other rights it may have under applicable law or other terms of this order, shall have the right (i) to refuse to accept further delivery of goods; (ii) to return to Vendor at Vendor’s expense any goods already delivered and to recover all payments made therefor and for expenses incident thereto; (iii) to recover any advance payments to Vendor for undelivered, unperformed or returned goods; and (iv) to purchase elsewhere and charge Vendor with any additional costs resulting therefrom.',
  'CHANGES. Contractor reserves the right at any time prior to shipment to make changes as to methods of shipment or packing and place or time of delivery. If any such change causes an increase or decrease in the cost of or the time required for performance of this order, an equitable adjustment shall be made in the contract price or deliver schedule, or both. Any claim for adjustment shall be deemed waived unless asserted in writing within ten (10) days from receipt by Vendor of the change.',
  'CANCELLATION. Contractor may terminate this order in whole or in part at any time by written notice to Vendor, in which event, except as otherwise directed by said notice, Vendor shall forthwith discontinue all work and the placing of orders, and shall cancel all commitments pertaining to this order. In full settlement of all claims arising out of such termination, Contractor will, within a reasonable time after such termination, pay to Vendor (i) the prices specified in this order for all goods completed an services performed and; (ii) the costs reasonably incurred with respect to the uncompleted portion of this order, including the amount of any reasonable expenditures made and obligations incurred in canceling any commitments applicable to this order. Vendor’s right to payment under this paragraph shall be conditioned upon its delivery to Contractor or Contractor’s designee of the goods, materials, inventories and other things for which compensation is provided hereunder, and in no event shall Vendor be entitled to an amount in excess of the order price.',
  'COMPLIANCE WITH LAW. Vendor certifies that in the performance hereof Vendor will comply with all applicable law, statutes, rules, regulations orders of the United States government or of any state or political subdivision thereof, and same shall be deemed incorporated herein by reference as if stated in full. Vendor certifies that all goods and services to be supplied hereunder were produced in compliance with all applicable requirements of Fair Labor Standards Act of 1938, as amended, and of regulations and orders issued pursuant thereto.',
  'GOVERNMENT CONTRACTS. If this order is for material or work in respect of a government contract or subcontract, all contract provisions applicable hereto and required by law, order, regulation or Contractor’s government contract or subcontract are hereby incorporated herein by reference as fully as if set forth herein in full.',
  'All dies, tools, jugs, fixtures, gauges, patterns and machine tools paid for by Contractor are to become the property of Contractor and are to be delivered upon demand or on termination of this order.',
  'Except as otherwise specifically provided in this order, Vendor as between Contractor and Vendor shall be responsible for any sales, use, excise or other tax which may be imposed upon any of the goods or services to be provided hereunder or their sale, use or delivery.',
  'No delay or omission by Contractor in exercising any right or remedy hereunder shall be a waiver thereof or of any other right or remedy. No single or partial waiver by Contractor thereof shall preclude any other or further exercise thereof or the exercise of any other right or remedy. All rights and remedies of Contractor hereunder are cumulative.',
  'Vendor shall not assign any right or delegate any duty arising under this order, and any such attempted assignment or delegation shall be void.',
  'The terms of this order cannot be modified or amended without the written consent of Contractor.',
  'The terms of this order shall be construed under and governed by the laws of New York State. The venue for any suit pertaining to this order shall be Erie County, New York.'
];
