import * as actions from '../../constants/order/reqnDiff';

const initialState = {
  data: [],
  active: {},
  isFetching: false,
  lastUpdated: null,
  error: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_REQN_DIFF_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: {}
      };
    case actions.FETCH_REQN_DIFF_FAILURE:
      return {
        ...state,
        isFetching: false,
        lastUpdated: action.receivedAt,
        error: action.error
      };
    case actions.FETCH_REQN_DIFF_SUCCESS:
      return {
        ...state,
        isFetching: false,
        lastUpdated: action.receivedAt,
        data: action.payload
      };
    case actions.SET_ACTIVE_REQN_DIFF_KEY:
      return {
        ...state,
        active: action.active
      };
    case actions.RESET_REQN_DIFF:
      return initialState;
    default:
      return state;
  }
};
