import axios from 'axios';
import * as types from '../../constants/report/createReport';
import transAxoisErr from '../../etc/axios/transformAxiosError';

export const editReportRequest = isInterval => ({
  isInterval,
  type: types.EDIT_REPORT_REQUEST
});

export const editReportSuccess = ({ data }) => ({
  data,
  receivedAt: Date.now(),
  type: types.EDIT_REPORT_SUCCESS
});

export const editReportFailure = error => ({
  error,
  receivedAt: Date.now(),
  type: types.EDIT_REPORT_FAILURE
});

export const lockSuccess = ({ data }) => ({
  data,
  receivedAt: Date.now(),
  type: types.LOCK_SUCCESS
});

export const editReport = (data, id, isInterval) => dispatch => {
  dispatch(editReportRequest(isInterval));
  const params = isInterval ? { lock: true } : {};
  axios
    .put(`/report/${id}`, data, { params })
    .then(res => {
      return isInterval ? dispatch(lockSuccess(res.data)) : dispatch(editReportSuccess(res.data));
    })
    .catch(error => dispatch(editReportFailure(transAxoisErr(error))));
};

export const lockReport = id => dispatch => {
  dispatch(editReportRequest());
  return axios
    .put(`/report/${id}/lock`)
    .then(res => dispatch(lockSuccess(res.data)))
    .catch(error => dispatch(editReportFailure(transAxoisErr(error))));
};

export const unlockReport = id => dispatch => {
  dispatch(editReportRequest());
  return axios
    .delete(`/report/${id}/lock`)
    .then(res => dispatch(lockSuccess(res.data)))
    .catch(error => dispatch(editReportFailure(transAxoisErr(error))));
};
