import axios from 'axios';
import * as types from '../constants/createUser';
import transAxoisErr from '../etc/axios/transformAxiosError';

export const editUserRequest = () => ({type: types.EDIT_USER_REQUEST});

export const editUserSuccess = ({ data }) => ({
  data,
  receivedAt: Date.now(),
  type: types.EDIT_USER_SUCCESS,
});

export const editUserFailure = error => ({
  error,
  receivedAt: Date.now(),
  type: types.EDIT_USER_FAILURE
});

export const editUser = (data, id) => (dispatch) => {
  dispatch(editUserRequest());
  return axios
    .put(`/user/${id}`, data)
    .then(res => dispatch(editUserSuccess(res.data)))
    .catch(error => dispatch(editUserFailure(transAxoisErr(error))));
};
