import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import IoAlertCircled from 'react-icons/lib/io/alert-circled';
import IoHappyOutline from 'react-icons/lib/io/happy-outline';

import get from 'lodash/get';
import set from 'lodash/set';

import TextInput from '../components/shared/forms/TextInput';
import Alert from '../components/shared/Alert';

import { editUser } from '../actions/editUser';
import { createUserReset } from '../actions/createUser';

require('../sass/containers/Account.scss');

class Account extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {
        name: {
          first: '',
          last: ''
        },
        email: '',
        username: '',
        password: '',
        passwordConfirmation: ''
      }
    };

    this.getValueFromId = this.getValueFromId.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.validatePassword = this.validatePassword.bind(this);
  }

  componentWillMount() {
    const fetchedUser = this.props.user.data;
    this.setState({ user: { ...this.state.user, ...fetchedUser, password: '' } });
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.user.data !== nextProps.user.data) {
      const fetchedUser = nextProps.user.data;
      this.setState({ user: { password: '', passwordConfirmation: '', ...fetchedUser } });
    }
    if (nextProps.createUser.submitted) {
      this.setState({ submittedSuccess: 'Account changes saved.' });
      this.props.actions.createUserReset();
    }
  }

  getValueFromId(id) {
    return get(this.state.user, id);
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState({ passwordError: null, submittedSuccess: null });
    if (!this.validatePassword()) {
      this.setState({ passwordError: 'Passwords must be at least 8 characters and match.' });
    } else {
      this.props.actions.editUser(this.state.user, 'me');
    }
  }

  handleInputChange(key, value = 'value') {
    return event => {
      const val = event.target[value];
      const nextState = { user: set({ ...this.state.user }, key, event._id || val) };

      this.setState(nextState);
    };
  }

  validatePassword() {
    const { user } = this.state;
    return user.password.length > 7 && user.passwordConfirmation === user.password;
  }

  render() {
    const defaultInputProps = {
      valueGetter: this.getValueFromId,
      onChange: this.handleInputChange
    };
    const { createUser } = this.props;
    let alert;

    if (this.state.passwordError) {
      alert = (
        <Alert key="password-error" delay={5000} type="danger">
          <i>
            <IoAlertCircled />
          </i>
          &nbsp;
          {this.state.passwordError}
        </Alert>
      );
    }

    if (createUser.error && createUser.error.message) {
      alert = (
        <Alert key="password-error" delay={5000} type="danger">
          <i>
            <IoAlertCircled />
          </i>
          &nbsp;
          {createUser.error.message}
        </Alert>
      );
    }

    if (this.state.submittedSuccess) {
      alert = (
        <Alert key="save-success" delay={5000} type="success">
          <i>
            <IoHappyOutline />
          </i>
          &nbsp;
          {this.state.submittedSuccess}
        </Alert>
      );
    }

    return (
      <div className="account-settings-container">
        {alert}
        <div className="pre-table-header">
          <p className="page-title">Account Settings</p>
        </div>
        <form className="modal-form" id="create-user-form" onSubmit={this.handleSubmit}>
          <div className="input-fields">
            <div className="modal-form-block">
              <TextInput
                required
                labelText="First Name"
                inputId="name.first"
                {...defaultInputProps}
              />
              <TextInput
                required
                labelText="Last Name"
                inputId="name.last"
                {...defaultInputProps}
              />
            </div>
            <div className="modal-form-block">
              <TextInput required labelText="Email" inputId="email" {...defaultInputProps} />
              <TextInput required labelText="Username" inputId="username" {...defaultInputProps} />
            </div>
            <div className="modal-form-block">
              <TextInput labelText="New Password" inputId="password" {...defaultInputProps} />
              <TextInput
                labelText="Password Confirmation"
                inputId="passwordConfirmation"
                {...defaultInputProps}
              />
            </div>
            <input className="form-button primary-button" type="submit" value="Save" />
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = ({ createUser, user }) => ({ createUser, user });

const mapDispatchToProps = dispatch => {
  const actions = { editUser, createUserReset };
  return { actions: bindActionCreators(actions, dispatch) };
};

export default connect(mapStateToProps, mapDispatchToProps)(Account);
