import axios from 'axios';
import * as types from '../constants/createMedia';
import transAxoisErr from '../etc/axios/transformAxiosError';

export const createMediaRequest = () => ({type: types.CREATE_MEDIA_REQUEST});

export const createMediaSuccess = ({ data }) => ({
  data,
  receivedAt: Date.now(),
  type: types.CREATE_MEDIA_SUCCESS,
});

export const createMediaFailure = error => ({
  error,
  receivedAt: Date.now(),
  type: types.CREATE_MEDIA_FAILURE
});

export const createMediaAction = data => (dispatch) => {
  dispatch(createMediaRequest());
  return axios
    .post('/media', data)
    .then(res => dispatch(createMediaSuccess(res.data)))
    .catch(error => dispatch(createMediaFailure(transAxoisErr(error))));
};

export const clear = () => ({ type: types.CLEAR_CREATE_MEDIA });
