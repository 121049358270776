import React from 'react';
import classNames from 'classnames';

import { ResponsivePie } from '@nivo/pie';
import { BoxLegendSvg } from '@nivo/legends';
import { getColorsGenerator } from '@nivo/core/lib/lib/colors';

import Repeater from '../shared/forms/Repeater';

import toUsd from '../../etc/toUsd';

const makeItemField = (id, title, inputType, opts) =>
  Object.assign(
    {
      id,
      title,
      inputType,
      setOnChange: true
    },
    opts
  );

const inputProps = {
  type: 'number',
  step: '0.01'
};

const getColor = getColorsGenerator('d320c', 'id');

export default class OrderWizardItems extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      useOld: false
    };

    this.toggleItemsShow = this.toggleItemsShow.bind(this);
  }

  toggleItemsShow() {
    const current = this.state.useOld;
    this.setState({ useOld: !current });
  }

  renderCostCodeGraph() {
    const data = Object.entries(this.props.reqn.codeTotals).map(([code, value]) => ({
      value,
      id: code,
      label: code
    }));

    const legendData = data.map(d => ({
      fill: getColor(d),
      label: `${d.id}: ${toUsd(d.value)}`
    }));

    const margin = {
      left: 40,
      right: 40,
      top: 20,
      bottom: 20
    };

    const legendStyle = {
      anchor: 'left',
      direction: 'column',
      itemWidth: 100,
      itemHeight: 20,
      symbolSize: 10,
      symbolShape: 'circle'
    };

    const legendWidth = 120;
    const legendHeight = data.length * 20;

    return (
      <div className="pie-grid">
        <div className="pie-grid-section">
          <svg width={legendWidth} height={legendHeight}>
            <BoxLegendSvg
              containerWidth={legendWidth}
              containerHeight={legendHeight}
              data={legendData}
              {...legendStyle}
            />
          </svg>
        </div>
        <div className="pie-grid-section">
          <ResponsivePie
            animate
            data={data}
            margin={margin}
            padAngle={2}
            innerRadius={0.3}
            cornerRadius={5}
            colors="d320c"
            colorBy="id"
            borderWidth={1}
            borderColor="inherit:darker(0.6)"
            tooltipFormat={toUsd}
            radialLabelsSkipAngle={10}
            radialLabelsTextXOffset={6}
            radialLabelsTextColor="#333333"
            radialLabelsLinkOffset={0}
            radialLabelsLinkDiagonalLength={16}
            radialLabelsLinkHorizontalLength={24}
            radialLabelsLinkStrokeWidth={1}
            radialLabelsLinkColor="inherit"
            sliceLabel={d => toUsd(d.value)}
            slicesLabelsSkipAngle={33}
            slicesLabelsTextColor="inherit:darker(2)"
            motionStiffness={90}
            motionDamping={15}
          />
        </div>
      </div>
    );
  }

  render() {
    const {
      COTextInput,
      order,
      reqn,
      edit,
      openComment,
      comments,
      isChangeOrder,
      errors
    } = this.props;

    const defaultRepeaterProps = { data: reqn };

    const commentExist = field => comments.findIndex(c => c.position === field) > -1;
    const commentClass = field => classNames('add-comment', { exists: commentExist(field) });

    const makeCommentIcon = (label, position) => {
      return (
        <div
          className={commentClass('reqn.schedule')}
          onClick={e => openComment({ labelText: label, position }, e)}
        />
      );
    };

    const reqnIdx = reqn._id && order.reqns && order.reqns.findIndex(r => r._id === reqn._id);

    const { contractTotal = 0 } = order;
    let contractTotalLessReqn = contractTotal;

    if (reqnIdx > -1) {
      contractTotalLessReqn = order.reqns.reduce(
        (agg, { total }, idx) => (idx === reqnIdx ? agg : agg + total),
        0
      );
    }

    const { costCode, codeTotals } = reqn;

    const hasReqnCode = !!costCode;
    const hasCodeTotals = codeTotals && Object.keys(codeTotals).length > 0;
    const totalsGridClass = classNames('totals-grid', { 'hide-code-totals': !hasCodeTotals });

    return (
      <div className="items-page">
        {edit && (
          <div
            className={commentClass(reqn.items)}
            onClick={e => openComment({ labelText: 'Items Comments', position: 'reqn.items' }, e)}
          />
        )}

        <div className="items-top">
          <div className="cost-code-box top">
            {!hasCodeTotals && [
              <h6 className="cost-code-box-title top">Cost Code</h6>,
              <div key={1} className="form-section-subtitle">
                If this order does not use line item cost codes, please enter here.
              </div>,
              <COTextInput key={2} name="reqn.costCode" showError={errors && errors.costCode} />,
              <div className="form-section-subtitle">Otherwise, please enter cost codes below.</div>
            ]}
          </div>
          {isChangeOrder ? (
            <h3>NOT TO EXCEED</h3>
          ) : (
            <label className="not-to-exceed" htmlFor="notToExceed">
              <COTextInput
                id="notToExceed"
                disabled={isChangeOrder}
                type="checkbox"
                name="order.notToExceed"
                showError={errors?.notToExceed}
              />
              Not To Exceed
            </label>
          )}
          {isChangeOrder && (
            <label className="use-old" htmlFor="useOld">
              <COTextInput
                type="checkbox"
                id="useOld"
                name="useOld"
                disabled={!isChangeOrder}
                onClick={this.toggleItemsShow}
                useOld={this.state.useOld}
              />
              Copy PO Items?
            </label>
          )}
        </div>

        <Repeater
          {...{ ...this.props, ...defaultRepeaterProps }}
          noTitle
          buttonRight
          circleRemove
          path="items"
          className="create-order-repeater items-repeater"
          namePath="reqn.items"
          tableID="items-repeater"
          fields={[
            makeItemField('description', 'Description', null, { multiline: true, rows: 2 }),
            makeItemField('quantity', 'Qty', 'number', { step: 0.1, required: true }),
            makeItemField('unitPrice', 'Price', 'number', { step: 0.01, placeholder: '0.00' }),
            makeItemField('unit', 'Unit'),
            makeItemField('costCode', 'Cost Code', null, {
              readOnly: hasReqnCode,
              tabIndex: hasReqnCode ? -1 : 0
            }),
            makeItemField('total', 'Total', null, {
              tabIndex: -1,
              readOnly: true,
              placeholder: '0.00'
            }),
            makeItemField('hasTax', 'Taxable', 'checkbox')
          ]}
        />
        <div className={totalsGridClass}>
          {hasCodeTotals && (
            <div className="cost-code-box">
              <h6 className="cost-code-box-title">Cost Code Totals</h6>
              {this.renderCostCodeGraph()}
            </div>
          )}
          <table className="totals-group">
            <tbody>
              <tr>
                <td className="label-column">Subtotal</td>
                <td className="total-column">{toUsd(reqn.subtotal)}</td>
              </tr>
              <tr>
                <td className="label-column">Sales Tax (%)</td>
                <td className="total-column">
                  <COTextInput name="reqn.salesTax" {...inputProps} max={100} placeholder="0.00" />
                </td>
              </tr>
              <tr>
                <td className="label-column">Tax Total</td>
                <td className="total-column">{toUsd(reqn.taxTotal)}</td>
              </tr>
              <tr>
                <td className="label-column">Total Offset</td>
                <td className="total-column">
                  <COTextInput name="reqn.totalOffset" placeholder="0.00" {...inputProps} />
                </td>
              </tr>
            </tbody>
            {isChangeOrder ? (
              <tbody>
                <tr>
                  <td className="label-column">Net Change</td>
                  <td className="total-column">
                    {toUsd(contractTotalLessReqn + parseFloat(reqn.total) - contractTotal)}
                  </td>
                </tr>
                <tr>
                  <td className="label-column">Previous Total Contract Price</td>
                  <td className="total-column">{toUsd(contractTotal)}</td>
                </tr>
                <tr>
                  <td className="label-column">New Total Contract Price</td>
                  <td className="total-column">
                    {toUsd(contractTotalLessReqn + parseFloat(reqn.total))}
                  </td>
                </tr>
              </tbody>
            ) : (
              <tbody>
                <tr>
                  <td className="label-column">Total</td>
                  <td className="total-column">{toUsd(reqn.total)}</td>
                </tr>
              </tbody>
            )}
          </table>
        </div>
      </div>
    );
  }
}
