/* eslint jsx-a11y/no-static-element-interactions: 0 */
import React from 'react';
import { connect } from 'react-redux';
import { browserHistory, Link } from 'react-router';

import IoImages from 'react-icons/lib/io/images';
import IoHammer from 'react-icons/lib/io/hammer';
import IoClipboard from 'react-icons/lib/io/clipboard';
import IoPricetags from 'react-icons/lib/io/pricetags';
import IoIosFiling from 'react-icons/lib/io/ios-filing';
import IoPersonStalker from 'react-icons/lib/io/person-stalker';
import IoIosColorFilterOutline from 'react-icons/lib/io/ios-color-filter-outline';

import LeftArrow from 'react-icons/lib/io/android-arrow-dropleft-circle';
import RightArrow from 'react-icons/lib/io/android-arrow-dropright-circle';

import Gears from 'react-icons/lib/io/ios-gear';

import { recordFilterDrawer } from '../../actions/setFilterDrawer';

import NavLink from './NavLink';
import UserInfo from './UserInfo';
import collapseNav from '../../actions/collapseNav';

require('../../sass/components/shared/MainNav.scss');
const classNames = require('classnames');

@connect(({ setFilterDrawer, collapseNav }) => ({ setFilterDrawer, collapseNav }))
export default class MainNav extends React.Component {
  constructor(props) {
    super(props);

    this.state = { navVisible: false };

    this.hamburgerToggle = this.hamburgerToggle.bind(this);
    this.toggleCollapse = this.toggleCollapse.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
  }

  componentDidMount() {
    // TODO: these should maybe use onBlur & onFocus from JSX/render?

    this.menu.addEventListener(
      'blur',
      () => {
        this.timer = window.setTimeout(this.closeMenu, 0);
      },
      true
    );
    this.menu.addEventListener(
      'focus',
      () => {
        clearTimeout(this.timer);
      },
      true
    );
    browserHistory.listen(() => {
      this.closeMenu();
    });
  }

  hamburgerToggle() {
    if (window.innerWidth < 730) {
      this.setState({ navVisible: !this.state.navVisible });
      this.menu.focus();
    }
  }

  toggleCollapse() {
    this.props.dispatch(collapseNav());
  }

  closeMenu() {
    if (this.state.navVisible) {
      this.setState({ navVisible: false });
    }
  }

  render() {
    const { dispatch, userData, setFilterDrawer, location } = this.props;
    const { name, authOverrides } = userData;

    const authLevel = userData.authLevel === null ? -1 : userData.authLevel;

    const reportFilterArrow = classNames({
      'filter-arrow': true,
      open: setFilterDrawer && setFilterDrawer.drawer === 'reports'
    });

    const orderFilterArrow = classNames({
      'filter-arrow': true,
      open: setFilterDrawer && setFilterDrawer.drawer === 'orders'
    });

    const toggleReport = setFilterDrawer.drawer === 'reports' ? undefined : 'reports';
    const toggleOrder = setFilterDrawer.drawer === 'orders' ? undefined : 'orders';

    return (
      <nav className="main-nav-container" ref={el => (this.menu = el)} tabIndex="-1">
        <UserInfo name={name} />
        <div onClick={this.hamburgerToggle} className="hamburger">
          {/* Note: these are presentational divs. */}
          <div />
          <div />
          <div />
        </div>
        <ul className={this.state.navVisible ? 'visible' : 'hidden'}>
          {(authLevel >= 0 || authOverrides.reports.view) && (
            <li>
              <NavLink to="/reports">
                <i>
                  <IoClipboard />
                </i>
                <p>Reports</p>
              </NavLink>
              {location.pathname === '/reports' && (
                <div
                  className={reportFilterArrow}
                  onClick={() => dispatch(recordFilterDrawer(toggleReport))}
                >
                  <IoIosColorFilterOutline size={17} />
                </div>
              )}
            </li>
          )}
          {(authLevel >= 0 || authOverrides.media.view) && (
            <li>
              <NavLink to="/media">
                <i>
                  <IoImages />
                </i>
                <p>Media</p>
              </NavLink>
            </li>
          )}
          {(authLevel >= 2 || authOverrides.projects.view) && (
            <li>
              <NavLink to="/projects">
                <i>
                  <IoHammer />
                </i>
                <p>Projects</p>
              </NavLink>
            </li>
          )}
          {(authLevel >= 2 || authOverrides.users.view) && (
            <li>
              <NavLink to="/users">
                <i>
                  <IoPersonStalker />
                </i>
                <p>Users</p>
              </NavLink>
            </li>
          )}
          {(authLevel === 100 || authOverrides.vendors.view) && (
            <li>
              <NavLink to="/vendors">
                <i>
                  <IoPricetags />
                </i>
                <p>Vendors</p>
              </NavLink>
            </li>
          )}
          {(authLevel === 100 || authOverrides.orders.view) && (
            <li>
              <NavLink to={`/orders?user=${userData._id}`}>
                <i>
                  <IoIosFiling />
                </i>
                <p>Reqs</p>
              </NavLink>
              {location.pathname === '/orders' && (
                <div
                  className={orderFilterArrow}
                  onClick={() => dispatch(recordFilterDrawer(toggleOrder))}
                >
                  <IoIosColorFilterOutline size={20} />
                </div>
              )}
            </li>
          )}
          <li className="nav-spacer" />
          <li className="settings-item">
            <NavLink onClick={this.toggleCollapse}>
              <i>{this.props.collapseNav ? <RightArrow /> : <LeftArrow />}</i>
              <p>Collapse</p>
            </NavLink>
          </li>
          {(authOverrides.admin.editSystem || authOverrides.admin.super) && (
            <li className="settings-item">
              <NavLink to="/settings">
                <i>
                  <Gears />
                </i>
                <p>Settings</p>
              </NavLink>
            </li>
          )}
        </ul>
      </nav>
    );
  }
}
