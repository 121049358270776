export const FETCH_REPORTS_REQUEST = 'FETCH_REPORTS_REQUEST';
export const FETCH_REPORTS_SUCCESS = 'FETCH_REPORTS_SUCCESS';
export const FETCH_REPORTS_FAILURE = 'FETCH_REPORTS_FAILURE';

export const DELETE_REPORT_REQUEST = 'DELETE_REPORT_REQUEST';
export const DELETE_REPORT_SUCCESS = 'DELETE_REPORT_SUCCESS';
export const DELETE_REPORT_FAILURE = 'DELETE_REPORT_FAILURE';

export const MAKE_ZIP_ALL_REQUEST = 'MAKE_ZIP_ALL_REQUEST';
export const MAKE_ZIP_ALL_SUCCESS = 'MAKE_ZIP_ALL_SUCCESS';
export const MAKE_ZIP_ALL_FAILURE = 'MAKE_ZIP_ALL_FAILURE';
