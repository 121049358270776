import * as actions from '../constants/createMedia';

const initialState = {
  data: {},
  isFetching: false,
  lastUpdated: null,
  error: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.CREATE_MEDIA_REQUEST:
    case actions.EDIT_MEDIA_REQUEST:
      return { ...state, isFetching: true, error: {} };
    case actions.CREATE_MEDIA_FAILURE:
    case actions.EDIT_MEDIA_FAILURE:
      return {...state, isFetching: false, lastUpdated: action.receivedAt, error: action.error};
    case actions.CREATE_MEDIA_SUCCESS:
    case actions.EDIT_MEDIA_SUCCESS:
      return {
        ...state,
        data: action.data,
        isFetching: false,
        lastUpdated: action.receivedAt,
        submitted: true,
        error: {}
      };
    case actions.CLEAR_CREATE_MEDIA:
      return initialState;
    default:
      return state;
  }
};
