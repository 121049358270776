import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router';

import IoIosGear from 'react-icons/lib/io/ios-gear';
import IoLocked from 'react-icons/lib/io/ios-locked';

import Dropdown from './Dropdown';
import { logout } from '../../actions/user';

import sevensonLogoWhite from '../../images/sevenson-logo-white.png';

import '../../sass/components/shared/UserInfo.scss';

class UserInfo extends React.Component {
  constructor(props) {
    super(props);
    this.logout = props.actions.logout.bind(this);
  }

  render() {
    const { name } = this.props;

    return (
      <div className="user-info-container">
        <Dropdown
          className="account-dropdown"
          outsideText={
            <span>
              <img
                src={sevensonLogoWhite}
                alt="sevenson-logo-white"
                className="sevenson-logo-white"
              />
              <div className="username">
                <p className="first-name">{name.first}</p>
                <p className="last-name">{name.last}</p>
              </div>
            </span>
          }
        >
          <p>
            <Link to="/account" className="account-link">
              <i>
                <IoIosGear />
              </i>
              <span>Account</span>
            </Link>
          </p>
          <p>
            <button className="logout-button" onClick={this.logout}>
              <i>
                <IoLocked />
              </i>
              <span>Log out</span>
            </button>
          </p>
        </Dropdown>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  const actions = { logout };
  return { actions: bindActionCreators(actions, dispatch) };
};

export default connect(
  false,
  mapDispatchToProps
)(UserInfo);
