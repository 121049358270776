import axios from 'axios';
import pick from 'lodash/pick';
import * as types from '../../constants/report/reports';
import transAxoisErr from '../../etc/axios/transformAxiosError';

import Reports from '../../containers/Report/Reports';

export const fetchReportsRequest = () => ({ type: types.FETCH_REPORTS_REQUEST });

export const fetchReportsSuccess = ({ data, next, prev }) => ({
  data,
  next,
  prev,
  receivedAt: Date.now(),
  type: types.FETCH_REPORTS_SUCCESS
});

export const fetchReportsFailure = error => ({
  error,
  receivedAt: Date.now(),
  type: types.FETCH_REPORTS_FAILURE
});

export const fetchReports = opts => dispatch => {
  dispatch(fetchReportsRequest());
  const params = pick(opts, Reports.searchFields);
  return axios
    .get(opts.next || '/report/all', { params })
    .then(res => dispatch(fetchReportsSuccess(res.data)))
    .catch(error => dispatch(fetchReportsFailure(transAxoisErr(error))));
};

export const makeZipAllRequest = () => ({ type: types.MAKE_ZIP_ALL_REQUEST });

export const makeZipAllSuccess = ({ data }) => ({
  type: types.MAKE_ZIP_ALL_SUCCESS,
  payload: data,
  receivedAt: Date.now()
});

export const makeZipAllFailure = error => ({
  type: types.MAKE_ZIP_ALL_FAILURE,
  error,
  receivedAt: Date.now()
});

export const makeZipAll = dispatch => {
  dispatch(makeZipAllRequest());
  return axios
    .post('/report/all/zip-pdfs')
    .then(res => dispatch(makeZipAllSuccess(res.data)))
    .catch(error => dispatch(makeZipAllFailure(transAxoisErr(error))));
};
