import React from 'react';

const checkComment = c => c.position === 'reqn.number' || c.position === 'reqn.originalPONumber';
const commentExists = comments => comments.findIndex(checkComment) > -1;

export default class OrderWizardBasicInfo extends React.Component {
  render() {
    const {
      COTextInput,
      CORadioGroup,
      CORadioInput,
      isChangeOrder,
      errors = [],
      edit,
      comments,
      canNumber,
      internal,
      isTD
    } = this.props;

    return (
      <div className="ow-basic-info">
        <div className="input-fields">
          <COTextInput
            edit={edit}
            showError={errors.reqnNum}
            labelText={`${isChangeOrder ? 'Original ' : ''}PO Number`}
            name={isChangeOrder ? 'reqn.originalPONumber' : 'reqn.number'}
            disabled={isChangeOrder || !canNumber}
            commentExist={commentExists(comments)}
          />
          <div className="row">
            {isChangeOrder && (
              <COTextInput
                type="number"
                labelText="Change Order Number"
                name="reqn.number"
                disabled={internal}
              />
            )}
            {isChangeOrder && (
              <COTextInput
                type="checkbox"
                id="reqn.isInternal"
                name="reqn.isInternal"
                labelText="Internal?"
                checked={internal}
              />
            )}
          </div>
          <COTextInput
            edit={edit}
            type="date"
            labelText="Date"
            name="reqn.date"
            commentExist={comments.findIndex(c => c.position === 'reqn.date') > -1}
          />
          <div className="row opts">
            <div>
              <p className="text-input-standard-label">Company</p>
              <CORadioGroup name="reqn.company" other={false}>
                <CORadioInput value="ses">Sevenson Environmental Services, Inc.</CORadioInput>
                <CORadioInput value="sesPA">Sevenson Environmental Services, Inc. of PA</CORadioInput>
                <CORadioInput value="sis">Sevenson Industrial Services, Inc.</CORadioInput>
              </CORadioGroup>
            </div>
            <div className="category-selector">
              <p className="text-input-standard-label">Category</p>
              <CORadioGroup
                editable
                otherMultiline
                name="order.category"
                other="other"
                otherName="reqn.header"
                label="Edit the default notes. These will appear at the beginning of the finished document."
              >
                <CORadioInput value="standard">
                  Purchase of Materials / Equipment / Parts / Etc.
                </CORadioInput>
                <CORadioInput value="subcontract">Subcontractor</CORadioInput>
                <CORadioInput value="rental">Rental</CORadioInput>
                <CORadioInput value="service">Service</CORadioInput>
                <CORadioInput value="other">Other</CORadioInput>
              </CORadioGroup>
            </div>
            <COTextInput
              type="checkbox"
              id="order.isTD"
              name="order.isTD"
              labelText="T&D?"
              checked={isTD}
              disabled={isChangeOrder}
            />
          </div>
        </div>
      </div>
    );
  }
}
