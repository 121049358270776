import axios from 'axios';
import * as types from '../constants/settings';
import transAxoisErr from '../etc/axios/transformAxiosError';

const updateSettingsRequest = () => ({ type: types.UPDATE_SETTINGS_REQUEST });

export const settingsReset = () => ({ type: types.SETTINGS_RESET });

const updateSettingsSuccess = ({ data }) => ({
  data,
  receivedAt: Date.now(),
  type: types.UPDATE_SETTINGS_SUCCESS
});

const updateSettingsFailure = error => ({
  error,
  receivedAt: Date.now(),
  type: types.UPDATE_SETTINGS_FAILURE
});

export const updateSettings = data => dispatch => {
  dispatch(updateSettingsRequest());

  const filteredData = Object.entries(data).reduce((sets, [key, val]) => {
    if (/signing|purchasing|td|vendor/.test(key)) {
      sets[key] = val.filter(x => !!x.userRef);
    } else if (!/__v|createdAt|updatedAt/.test(key)) {
      sets[key] = val;
    }
    return sets;
  }, {});

  return axios
    .put('/settings', filteredData)
    .then(
      res => dispatch(updateSettingsSuccess(res.data)),
      error => dispatch(updateSettingsFailure(transAxoisErr(error)))
    );
};

const fetchSettingsRequest = () => ({ type: types.FETCH_SETTINGS_REQUEST });

const fetchSettingsSuccess = ({ data }) => ({
  data,
  receivedAt: Date.now(),
  type: types.FETCH_SETTINGS_SUCCESS
});

const fetchSettingsFailure = error => ({
  error,
  receivedAt: Date.now(),
  type: types.FETCH_SETTINGS_FAILURE
});

export const fetchSettings = () => dispatch => {
  dispatch(fetchSettingsRequest());

  return axios
    .get('/settings')
    .then(
      res => dispatch(fetchSettingsSuccess(res.data)),
      error => dispatch(fetchSettingsFailure(transAxoisErr(error)))
    );
};
