import axios from 'axios';
import * as types from '../constants/createUser';
import transAxoisErr from '../etc/axios/transformAxiosError';

export const createUserRequest = () => ({type: types.CREATE_USER_REQUEST});

export const createUserReset = () => ({type: types.CREATE_USER_RESET});

export const createUserSuccess = ({ data }) => ({
  data,
  receivedAt: Date.now(),
  type: types.CREATE_USER_SUCCESS,
});

export const createUserFailure = error => ({
  error,
  receivedAt: Date.now(),
  type: types.CREATE_USER_FAILURE
});

export const createUserAction = data => (dispatch) => {
  dispatch(createUserRequest());
  return axios
    .post('/user', data)
    .then(res => dispatch(createUserSuccess(res.data)))
    .catch(error => dispatch(createUserFailure(transAxoisErr(error))));
};
