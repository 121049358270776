import axios from 'axios';
import * as types from '../constants/createProject';
import transAxoisErr from '../etc/axios/transformAxiosError';

export const createProjectRequest = () => ({type: types.CREATE_PROJECT_REQUEST});

export const createProjectReset = () => ({type: types.CREATE_PROJECT_RESET});

export const createProjectSuccess = ({ data }) => ({
  data,
  receivedAt: Date.now(),
  type: types.CREATE_PROJECT_SUCCESS,
});

export const createProjectFailure = error => ({
  error,
  receivedAt: Date.now(),
  type: types.CREATE_PROJECT_FAILURE
});

export const createProject = data => (dispatch) => {
  dispatch(createProjectRequest());
  return axios
    .post('/project', data)
    .then(res => dispatch(createProjectSuccess(res.data)))
    .catch(error => dispatch(createProjectFailure(transAxoisErr(error))));
};
