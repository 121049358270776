import axios from 'axios';
import * as types from '../constants/projects';
import transAxoisErr from '../etc/axios/transformAxiosError';

export const fetchProjectsRequest = () => ({ type: types.FETCH_PROJECTS_REQUEST });

export const fetchProjectsSuccess = ({ data, next, prev }) => ({
  data,
  next,
  prev,
  receivedAt: Date.now(),
  type: types.FETCH_PROJECTS_SUCCESS
});

export const fetchProjectsFailure = error => ({
  error,
  receivedAt: Date.now(),
  type: types.FETCH_PROJECTS_FAILURE
});

export const fetchProjects = ({ next, ...params } = {}) => dispatch => {
  dispatch(fetchProjectsRequest());
  return axios
    .get(next || '/project/all', { params })
    .then(res => dispatch(fetchProjectsSuccess(res.data)))
    .catch(error => dispatch(fetchProjectsFailure(transAxoisErr(error))));
};
