import React from 'react';
import PropTypes from 'prop-types';

const get = require('lodash/get');
const isNaN = require('lodash/isNaN');

const moment = require('moment');
const classNames = require('classnames');

export default class TableRows extends React.Component {
  static defaultProps = {
    bodyClass: '',
    colEndpoints: [],
    columnAssignments: [],
    dataArray: [],
    notDateSettings: []
  };

  static propTypes = {
    bodyClass: PropTypes.string,
    colEndpoints: PropTypes.arrayOf(PropTypes.string),
    columnAssignments: PropTypes.arrayOf(PropTypes.array),
    dataArray: PropTypes.arrayOf(PropTypes.object),
    notDateSettings:PropTypes.arrayOf(PropTypes.bool)
  };

  render() {
    const { dataArray, colEndpoints, columnAssignments, notDateSettings } = this.props;

    const makeClass = c =>
      c[0]
        .toLowerCase()
        .replace(/\s+/g, '-')
        .replace('/', '-')
        .replace('#', 'number');

    const classedColumnArray = columnAssignments.map(makeClass);

    const rows = dataArray.map(row => {
      const cells = colEndpoints.map((colEndpoint, idx) => {
        let cellData;
        const rawData = get(row, colEndpoint);

        const colClasses = classNames({ [classedColumnArray[idx] + '-cell']: true });

        if (!notDateSettings?.[idx] && moment(rawData, moment.ISO_8601, true).isValid()) {
          const date = new Date(rawData);

          if (isNaN(date.getMonth()) || isNaN(date.getDate()) || isNaN(date.getFullYear())) {
            cellData = rawData;
          } else {
            cellData = date.getMonth() + 1 + '/' + date.getDate() + '/' + date.getFullYear();
          }
        } else {
          cellData = rawData;
        }

        return (
          // eslint-disable-next-line react/no-array-index-key
          <td className={colClasses} key={idx}>
            {cellData}
          </td>
        );
      });
      return <tr key={row._id}>{cells}</tr>;
    });

    return <tbody className={this.props.bodyClass}>{rows}</tbody>;
  }
}
