import axios from 'axios';
import * as types from '../../constants/order/createOrder';
import transAxoisErr from '../../etc/axios/transformAxiosError';

export const editOrderRequest = isInterval => ({
  isInterval,
  type: types.EDIT_ORDER_REQUEST
});

export const editOrderSuccess = ({ data }) => ({
  data,
  receivedAt: Date.now(),
  type: types.EDIT_ORDER_SUCCESS
});

export const editOrderFailure = error => ({
  error,
  receivedAt: Date.now(),
  type: types.EDIT_ORDER_FAILURE
});

export const editOrder = (data, id) => (dispatch) => {
  dispatch(editOrderRequest());
  return axios
    .put(`/order/${id}`, data)
    .then(res => dispatch(editOrderSuccess(res.data)))
    .catch(error => dispatch(editOrderFailure(transAxoisErr(error))));
};

