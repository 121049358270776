/* eslint no-nested-ternary: 0 */
/* eslint-disable react/jsx-indent */
/* eslint react/jsx-no-target-blank: 0 */
import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import ReactS3Uploader from 'react-s3-uploader';
import { Typeahead } from 'react-typeahead';

import debounce from 'lodash/debounce';

import IoIosTrash from 'react-icons/lib/io/ios-trash';
import TiTimes from 'react-icons/lib/ti/times';

import TextInput from './TextInput';
import ProgressBar from '../../../components/shared/ProgressBar';

require('../../../sass/components/shared/forms/Repeater.scss');

const findTable = el => {
  if (!el) throw Error('Repeater container not found.');
  if (/repeater-container/.test(el.className)) {
    return el;
  }
  return findTable(el.parentNode);
};

const typeaheadClasses = {
  input: 'text-input-standard',
  results: 'typeahead-results',
  listItem: 'typeahead-list-item',
  listAnchor: 'typeahead-list-anchor',
  hover: 'typeahead-item-hover',
  typeahead: 'standard-typeahead',
  resultsTruncated: 'typeahead-results'
};

const insertPlaceholder = debounce((parent, sibling, fn) => {
  parent.insertBefore(fn(), sibling);
}, 100);

export default class Repeater extends React.Component {
  static propTypes = {
    single: PropTypes.bool,
    noTitle: PropTypes.bool,
    circleRemove: PropTypes.bool,
    photoUploader: PropTypes.bool,

    data: PropTypes.objectOf(PropTypes.any),
    path: PropTypes.string,
    title: PropTypes.string,
    tableID: PropTypes.string.isRequired,
    namePath: PropTypes.string,
    mediaRemovals: PropTypes.number,

    fields: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        title: PropTypes.string,
        multiline: PropTypes.bool,
        inputType: PropTypes.string,
        signingUrl: PropTypes.string,
        uploaderFns: PropTypes.objectOf(PropTypes.func)
      })
    ),

    getPath: PropTypes.func,
    delImage: PropTypes.func,
    replacePath: PropTypes.func,

    addRow: PropTypes.func.isRequired,
    delRow: PropTypes.func.isRequired,

    onChange: PropTypes.func.isRequired,
    valueGetter: PropTypes.func.isRequired,

    uploaderProgresses: PropTypes.objectOf(PropTypes.array)
  };

  static defaultProps = {
    single: false,
    noTitle: false,
    circleRemove: false,
    photoUploader: false,

    data: {},
    path: '',
    title: '',
    namePath: '',
    mediaRemovals: 0,

    fields: [],
    getPath: () => {},
    delImage: () => {},
    replacePath: () => {},

    uploaderProgresses: {}
  };

  constructor(props) {
    super(props);

    this.state = { draggable: true };

    this.lastClientY = null;

    this.enableDrag = this.enableDrag.bind(this);
    this.disableDrag = this.disableDrag.bind(this);

    this.makePlaceholder = this.makePlaceholder.bind(this);
    this.startDrag = this.startDrag.bind(this);
    this.dragOver = this.dragOver.bind(this);
    this.endDrag = this.endDrag.bind(this);

    this.addRowExtra = this.addRowExtra.bind(this);
  }

  addRowExtra(path, single, fields) {
    const self = this;
    const addFn = this.props.addRow(path, single ? '' : fields.map(f => f.id));
    return () => {
      addFn(() => {
        const input =
          self.lastRow && self.lastRow.querySelector('.text-input-standard');
        input && input.focus();
      });
    };
  }

  enableDrag() {
    if (this.dragTimeout) clearTimeout(this.dragTimeout);
    if (this.state.draggable === false) this.setState({ draggable: true });
  }

  disableDrag() {
    this.dragTimeout = setTimeout(
      () => this.setState({ draggable: false }),
      200
    );
  }

  makePlaceholder() {
    if (!this.placeholder) {
      const tr = document.createElement('tr');
      tr.className = 'placeholder';
      const td = document.createElement('td');
      td.colSpan = this.placeholderWidth + 2;
      td.appendChild(document.createTextNode('Drop here'));
      tr.appendChild(td);
      this.placeholder = tr;
    }
    return this.placeholder;
  }

  startDrag(fieldsLength) {
    this.savedID = '';
    return e => {
      this.placeholderWidth = fieldsLength;
      this.dragged = e.currentTarget;
      this.savedID = findTable(e.currentTarget).id;

      window.requestAnimationFrame(() => (this.dragged.style.display = 'none'));

      e.dataTransfer.effectAllowed = 'move';
      e.dataTransfer.setData('text/html', e.currentTarget);
    };
  }

  dragOver(dataLength) {
    return ({ clientY, target, tagName }) => {
      if (clientY - 5 <= this.lastClientY && this.lastClientY <= clientY + 5)
        return;

      this.lastClientY = clientY;

      if (tagName === 'tr') {
        this.over = target;
      } else if (tagName !== 'tr' && target.closest('tr')) {
        this.over = target.closest('tr');
      }

      if (
        (this.over && this.over.className === 'placeholder') ||
        (this.over && this.over.className === 'repeater-header-row')
      ) {
        if (
          this.over.nextSibling &&
          this.over.nextSibling.className === 'repeater-header-row'
        ) {
          const header = this.over.closest('thead');
          this.over = header.nextSibling;
          this.nodePlacement = 'after';
        }

        if (this.over.className === 'repeater-header-row') {
          return;
        }
        this.over = this.over.nextSibling || this.over.previousSibling;
      }

      if (this.over && findTable(this.over).id === this.savedID) {
        const to = Number(this.over.dataset.id);
        if (to === dataLength - 1) {
          const { bottom, height } = this.over.getBoundingClientRect();
          const currentTableBottom = (bottom + height) / 2;

          if (clientY + height >= currentTableBottom) {
            this.nodePlacement = 'after';
          } else {
            this.nodePlacement = '';
          }
        }

        const parent = this.over.parentNode;
        const sibling =
          this.nodePlacement === 'after' ? this.over.nextSibling : this.over;

        insertPlaceholder(parent, sibling, this.makePlaceholder);
      }
    };
  }

  endDrag(path) {
    return () => {
      if (this.over && this.over.dataset && this.over.dataset.id) {
        const currentArray = this.props.getPath(path);

        const from = Number(this.dragged.dataset.id);
        let to = Number(this.over.dataset.id);

        if (this.nodePlacement === 'after') {
          to += 1;
        } else if (from < to) {
          to -= 1;
        }

        currentArray.splice(to, 0, currentArray.splice(from, 1)[0]);
        this.props.replacePath(path, currentArray);
        this.nodePlacement = '';
      }

      delete this.over;
      if (this.placeholder) this.placeholder.remove();
      window.requestAnimationFrame(
        () => (this.dragged.style.display = 'table-row')
      );
    };
  }

  renderInput(field, tdIdx, trIdx) {
    const {
      id,
      inputType,
      uploaderFns,
      multiline,
      typeaheadKey,
      typeaheadData,
      typeaheadFilter,
      typeaheadDisplay,
      typeaheadOnSelect,
      typeaheadValuePath,
      typeaheadValueFn,
      required,
      ...restParams
    } = field;
    const {
      data,
      path,
      namePath,
      title,
      single,
      photoUploader,
      delImage,
      onChange,
      valueGetter,
      uploaderProgresses
    } = this.props;

    const baseIdSingle = `${path}.${trIdx}`;
    const baseIdSingleName = namePath && `${namePath}.${trIdx}`;

    const baseId = `${baseIdSingle}.${id}`;
    const baseIdName = baseIdSingleName && `${baseIdSingleName}.${id}`;

    let photoUrl;
    let photoTitle;
    let attachmentUrl;
    let attachmentName;

    if (data.media && data.media[trIdx] && data.media[trIdx].url) {
      const photo = data.media[trIdx];
      photoUrl = photo.publicUrl || photo.url;
      photoTitle = photo.title;
    }

    if (data.attachments?.[trIdx]?.url) {
      const attachment = data.attachments[trIdx];
      attachmentUrl = attachment.publicUrl || attachment.url;
      const slicePoint = attachmentUrl.indexOf('_') + 1;
      const sliceEndPoint = attachmentUrl.indexOf('?');
      attachmentName = attachmentUrl.slice(slicePoint, sliceEndPoint);
    }

    switch (inputType) {
      case 's3Uploader':
        if (photoUploader && photoUrl) {
          return (
            <div className="photo-uploaded-block">
              <a href={photoUrl} target="_blank" className="repeater-image">
                <img
                  src={photoUrl}
                  alt={photoTitle}
                  className="repeater-preview-img"
                />
              </a>
              <button
                className="form-button small-button delete-button"
                type="button"
                onClick={delImage(path, trIdx)}
              >
                <IoIosTrash />
              </button>
            </div>
          );
        } else if (!photoUploader && attachmentUrl) {
          return (
            <a href={attachmentUrl} target="_blank">
              {attachmentName}
            </a>
          );
        }

        return (
          <div className="repeater-choose-file">
            <ReactS3Uploader
              id={baseId}
              preprocess={uploaderFns.preprocess}
              onError={uploaderFns.onError}
              onFinish={uploaderFns.onFinish(baseId)}
              onProgress={uploaderFns.onProgress(trIdx, title)}
              uploadRequestHeaders={{ 'x-amz-acl': 'private' }}
              {...restParams}
            />
            <ProgressBar
              percentage={
                uploaderProgresses[title] && uploaderProgresses[title][trIdx]
                  ? uploaderProgresses[title][trIdx].percentage
                  : 0
              }
            />
          </div>
        );
      case 'checkbox':
        return (
          <div className="repeater-checkbox-container">
            <TextInput
              data-id={trIdx}
              name={single ? baseIdSingleName : baseIdName}
              inputId={single ? baseIdSingle : baseId}
              inputType={inputType}
              onChange={onChange}
              valueGetter={valueGetter}
              onMouseEnter={this.disableDrag}
              onMouseLeave={this.enableDrag}
              {...restParams}
            />
          </div>
        );
      case 'typeahead':
        return (
          <Typeahead
            key={typeaheadKey}
            closeDropdownOnBlur
            id={path}
            options={typeaheadData}
            maxVisible={6}
            filterOption={typeaheadFilter}
            displayOption={typeaheadDisplay}
            customClasses={typeaheadClasses}
            onOptionSelected={e =>
              typeaheadOnSelect(e, single ? baseIdSingle : baseId)
            }
            ref={input => (this.typeahead = input)}
            placeholder="Start typing a name to autofill contact information."
            value={typeaheadValueFn(
              valueGetter(single ? baseIdSingle : baseId)[typeaheadValuePath]
            )}
            // onMouseEnter={this.disableDrag}
            // onMouseLeave={this.enableDrag}
          />
        );
      default:
        return (
          <TextInput
            required={required}
            multiLine={multiline}
            data-id={trIdx}
            name={single ? baseIdSingleName : baseIdName}
            inputId={single ? baseIdSingle : baseId}
            inputType={inputType}
            onChange={onChange}
            valueGetter={valueGetter}
            onMouseEnter={this.disableDrag}
            onMouseLeave={this.enableDrag}
            {...restParams}
          />
        );
    }
  }

  render() {
    const {
      single,
      title,
      noTitle,
      circleRemove,
      className,
      tableID, // must be provided or rows may be dragged to other tables
      fields,
      data,
      path,
      namePath,
      delRow,
      mediaRemovals,
      disableDrag
    } = this.props;

    return (
      <div className={classNames('repeater-container', className)} id={tableID}>
        {!noTitle && <p className="repeater-title">{title}</p>}
        <table
          className="repeater-table"
          onDragOver={data[path] && this.dragOver(data[path].length)}
        >
          <thead className="repeater-header">
            <tr className="repeater-header-row">
              <th />
              {fields.map((field, thIdx) => (
                // eslint-disable-next-line react/no-array-index-key
                <th className="repeater-column-header" key={thIdx}>
                  {field.title}
                </th>
              ))}
              <th />
            </tr>
          </thead>
          <tbody className="repeater-body">
            <tr className="repeater-table-top" data-id={-1} />
            {data?.[path].map((_, trIdx) => {
              return (
                <tr
                  // eslint-disable-next-line react/no-array-index-key
                  key={trIdx}
                  data-id={trIdx}
                  ref={el => (this.lastRow = el)}
                  className={`repeater-row ${!disableDrag && 'grabbable'}`}
                  draggable={!disableDrag && this.state.draggable}
                  onDragEnd={this.endDrag(namePath || path)}
                  onDragStart={this.startDrag(fields.length)}
                >
                  <td className="repeater-row-counter" data-id={trIdx}>
                    <span className="grab-dots" />
                    <span className="counter-number">{trIdx + 1}</span>
                  </td>
                  {fields.map((field, tdIdx) => {
                    const key = mediaRemovals
                      ? `${tdIdx}:${mediaRemovals}`
                      : tdIdx;

                    return (
                      <td
                        data-id={trIdx}
                        className={classNames('repeater-uploader', {
                          readOnly: field.readOnly
                        })}
                        key={key}
                      >
                        {this.renderInput(field, tdIdx, trIdx)}
                      </td>
                    );
                  })}
                  <td className="delete-button-cell" data-id={trIdx}>
                    {circleRemove ? (
                      <button
                        tabIndex={-1}
                        type="button"
                        className="form-button delete-button circle-delete"
                        onClick={delRow(path, trIdx)}
                      >
                        <TiTimes size={20} />
                      </button>
                    ) : (
                      <button
                        tabIndex={-1}
                        type="button"
                        className="form-button small-button delete-button"
                        onClick={delRow(path, trIdx)}
                      >
                        {' '}
                        Remove{' '}
                      </button>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="repeater-table-bottom" data-id={99999} />

        <button
          className="form-button small-button primary-button repeater-add-row"
          type="button"
          onClick={this.addRowExtra(path, single, fields)}
        >
          Add Row
        </button>
      </div>
    );
  }
}
