import axios from 'axios';
import * as types from '../../constants/order/reqn';
import transAxoisErr from '../../etc/axios/transformAxiosError';

export const fetchReqnRequest = () => ({
  type: types.FETCH_REQN_REQUEST,
  data: {}
});

export const fetchReqnSuccess = ({ data }, version) => ({
  type: types.FETCH_REQN_SUCCESS,
  version,
  payload: data,
  receivedAt: Date.now()
});

export const fetchReqnFailure = error => ({
  type: types.FETCH_REQN_FAILURE,
  error,
  receivedAt: Date.now()
});

export const fetchReqn = ({ reqnId, version }) => dispatch => {
  dispatch(fetchReqnRequest());
  return axios
    .get(`/reqn/${reqnId}`)
    .then(res => dispatch(fetchReqnSuccess(res.data, version)))
    .catch(error => dispatch(fetchReqnFailure(transAxoisErr(error))));
};

export const getReqnVersionRequest = () => ({type: types.GET_REQN_VERSION_REQUEST});

export const getReqnVersionSuccess = ({ data }, version) => ({
  version,
  payload: data,
  receivedAt: Date.now(),
  type: types.GET_REQN_VERSION_SUCCESS
});

export const getReqnVersionFailure = error => ({
  error,
  receivedAt: Date.now(),
  type: types.GET_REQN_VERSION_FAILURE
});

export const getReqnVersion = ({ reqnId, version }) => dispatch => {
  dispatch(getReqnVersionRequest());
  return axios
    .get(`/reqn/${reqnId}/version/${version}`)
    .then(res => dispatch(getReqnVersionSuccess(res.data, version)))
    .catch(error => dispatch(getReqnVersionFailure(transAxoisErr(error))));
};
