export const FETCH_REPORT_REQUEST = 'FETCH_REPORT_REQUEST';
export const FETCH_REPORT_SUCCESS = 'FETCH_REPORT_SUCCESS';
export const FETCH_REPORT_FAILURE = 'FETCH_REPORT_FAILURE';

export const MAKE_PDF_REQUEST = 'MAKE_PDF_REQUEST';
export const MAKE_PDF_SUCCESS = 'MAKE_PDF_SUCCESS';
export const MAKE_PDF_FAILURE = 'MAKE_PDF_FAILURE';

export const RESEND_EMAILS_REQUEST = 'RESEND_EMAILS_REQUEST';
export const RESEND_EMAILS_SUCCESS = 'RESEND_EMAILS_SUCCESS';
export const RESEND_EMAILS_FAILURE = 'RESEND_EMAILS_FAILURE';
