import axios from 'axios';
import * as types from '../constants/media';
import transAxoisErr from '../etc/axios/transformAxiosError';

export const fetchSingleMediaRequest = () => ({
  type: types.FETCH_SINGLE_MEDIA_REQUEST,
  data: {}
});

export const fetchSingleMediaSuccess = ({ data }) => ({
  type: types.FETCH_SINGLE_MEDIA_SUCCESS,
  payload: data,
  receivedAt: Date.now()
});

export const fetchSingleMediaFailure = error => ({
  type: types.FETCH_SINGLE_MEDIA_FAILURE,
  error,
  receivedAt: Date.now()
});

export const fetchSingleMedia = ({ id }) => (dispatch) => {
  dispatch(fetchSingleMediaRequest());
  return axios
    .get(`/media/${id}`)
    .then(res => dispatch(fetchSingleMediaSuccess(res.data)))
    .catch(error => dispatch(fetchSingleMediaFailure(transAxoisErr(error))));
};
