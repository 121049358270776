import * as actions from '../constants/resetPassword';

const initialState = {
  isSendingRequest: false,
  error: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.RESET_PASSWORD_REQUEST:
      return {
        ...state,
        isSendingRequest: true,
        error: {}
      };
    case actions.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        isSendingRequest: false,
        error: {},
        submitted: true
      };
    case actions.RESET_PASSWORD_FAILURE:
      return {
        ...state,
        isSendingRequest: false,
        error: action.error
      };
    default:
      return state;
  }
};
