/* eslint jsx-a11y/no-static-element-interactions: 0 */
import React from 'react';
import { browserHistory } from 'react-router';
import IoArrowDownB from 'react-icons/lib/io/arrow-down-b';

require('../../sass/components/etc/dropdown.scss');
const classNames = require('classnames');

export default class Dropdown extends React.Component {
  constructor(props) {
    super(props);

    this.state = { collapsed: true, mounting: true };

    this.handleBlur = this.handleBlur.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.closeDropdown = this.closeDropdown.bind(this);
    this.dropdownToggle = this.dropdownToggle.bind(this);
  }

  componentDidMount() {
    setTimeout(() => this.setState({ mounting: false }), 500);

    browserHistory.listen(() => {
      this.closeDropdown();
    });
  }

  handleBlur() {
    this.timer = window.setTimeout(this.closeDropdown, 100);
  }

  handleFocus() {
    clearTimeout(this.timer);
  }

  closeDropdown() {
    if (this.state.collapsed === false) {
      this.setState({ collapsed: true });
    }
  }

  dropdownToggle() {
    this.setState({ collapsed: !this.state.collapsed });
    if (!this.state.collapsed) {
      this.dropdown.focus();
    }
  }

  render() {
    const arrowClasses = classNames({
      'dropdown-arrow': true,
      down: this.state.collapsed,
      up: !this.state.collapsed
    });

    const windowClasses = classNames({
      'dropdown-window': true,
      closed: this.state.collapsed,
      open: !this.state.collapsed,
      mounting: this.state.mounting
    });

    return (
      <div
        tabIndex="-1"
        ref={el => (this.dropdown = el)}
        className={`dropdown ${this.props.className}`}
        onBlur={this.handleBlur}
        onFocus={this.handleFocus}
      >
        <div className="dropdown-outside" onClick={this.dropdownToggle}>
          {this.props.outsideText}
          <i className={arrowClasses}>
            <IoArrowDownB />
          </i>
        </div>
        <div className={windowClasses}>{this.props.children}</div>
      </div>
    );
  }
}
