import axios from 'axios';
import * as types from '../../constants/report/activity';
import transAxoisErr from '../../etc/axios/transformAxiosError';

export const fetchActivityRequest = () => ({type: types.FETCH_ACTIVITY_REQUEST});

export const fetchActivitySuccess = ({ data, next, prev }) => ({
  data,
  next,
  prev,
  receivedAt: Date.now(),
  type: types.FETCH_ACTIVITY_SUCCESS,
});

export const fetchActivityFailure = error => ({
  error,
  receivedAt: Date.now(),
  type: types.FETCH_ACTIVITY_FAILURE
});

export const fetchActivity = ({ next, sort, report } = {}) => (dispatch) => {
  dispatch(fetchActivityRequest());
  return axios
    .get(next || '/activity/all', { params: { sort, report } })
    .then(res => dispatch(fetchActivitySuccess(res.data)))
    .catch(error => dispatch(fetchActivityFailure(transAxoisErr(error))));
};
