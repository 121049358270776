import React from 'react';
import { Link } from 'react-router';

require('../../sass/components/etc/navlink.scss');

export default class NavLink extends React.Component {
  render() {
    return <Link {...this.props} activeClassName="active">{this.props.children}</Link>;
  }
}
