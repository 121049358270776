import axios from 'axios';
import * as types from '../../constants/report/reports';
import transAxoisErr from '../../etc/axios/transformAxiosError';

export const deleteReportRequest = () => ({type: types.DELETE_REPORT_REQUEST});

export const deleteReportSuccess = ({ data }) => ({
  data,
  receivedAt: Date.now(),
  type: types.DELETE_REPORT_SUCCESS,
});

export const deleteReportFailure = error => ({
  error,
  receivedAt: Date.now(),
  type: types.DELETE_REPORT_FAILURE
});

export const deleteReport = id => (dispatch) => {
  dispatch(deleteReportRequest());
  return axios
    .delete(`/report/${id}`)
    .then(res => dispatch(deleteReportSuccess(res.data)))
    .catch(error => dispatch(deleteReportFailure(transAxoisErr(error))));
};
