import * as actions from '../../constants/report/reportView';

const initialState = {
  data: null,
  isFetching: false,
  isFetchingPDF: false,
  isResendingEmails: false,
  lastUpdated: null,
  error: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_REPORT_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: {}
      };
    case actions.FETCH_REPORT_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isFetching: false,
        lastUpdated: action.receivedAt,
        error: {}
      };
    case actions.FETCH_REPORT_FAILURE:
      return {
        ...state,
        isFetching: false,
        lastUpdated: action.receivedAt,
        error: action.error
      };
    case actions.RESEND_EMAILS_REQUEST:
      return {
        ...state,
        isResendingEmails: true,
        error: {}
      };
    case actions.RESEND_EMAILS_SUCCESS:
      return {
        ...state,
        report: action.payload,
        isResendingEmails: false,
        data: action.payload,
        lastUpdated: action.receivedAt,
        error: {}
      };
    case actions.RESEND_EMAILS_FAILURE:
      return {
        ...state,
        isResendingEmails: false,
        lastUpdated: action.receivedAt,
        error: action.error
      };
    case actions.MAKE_PDF_REQUEST:
      return {
        ...state,
        isFetchingPDF: true,
        error: {}
      };
    case actions.MAKE_PDF_SUCCESS:
      return {
        ...state,
        report: action.payload,
        isFetchingPDF: false,
        data: action.payload,
        lastUpdated: action.receivedAt,
        error: {}
      };
    case actions.MAKE_PDF_FAILURE:
      return {
        ...state,
        isFetchingPDF: false,
        lastUpdated: action.receivedAt,
        error: action.error
      };
    default:
      return state;
  }
};
