/* eslint react/no-did-mount-set-state: 0 */
import React from 'react';
import { connect } from 'react-redux';
import Modal from '../components/shared/Modal';
import MainNav from '../components/shared/MainNav';

require('../sass/containers/Dash.scss');
const classNames = require('classnames');

import sevensonLogoText from '../images/sevenson-logo-text-white.png';

/*
 * Note: This is kept as a container-level component,
 *  i.e. We should keep this as the container that does the data-fetching
 *  and dispatching of actions if you decide to have any sub-components.
 */
@connect(({ user, collapseNav }) => ({ user, collapseNav }))
export default class Dash extends React.Component {
  componentWillReceiveProps(nextProps) {
    // if we changed routes...
    if (
      nextProps.location.key !== this.props.location.key &&
      nextProps.location.state &&
      nextProps.location.state.modal
    ) {
      // save the old children (just like animation)
      this.previousChildren = this.props.children;
    }
  }

  render() {
    const { location, user, collapseNav } = this.props;

    const isModal = location.state && location.state.modal && this.previousChildren;

    const dashClasses = classNames('dashboard', { collapseNav });

    return (
      <div className={dashClasses}>
        <MainNav userData={user.data} location={location} />
        <div className={isModal ? 'page-container modal-open' : 'page-container'}>
          {isModal ? this.previousChildren : this.props.children}

          {isModal && (
            <Modal isOpen returnTo={location.state.returnTo} extraClass={location.state.extraClass}>
              {this.props.children}
            </Modal>
          )}
        </div>
        <div className="footer">
          <img src={sevensonLogoText} alt="sevenson environmental services, inc, logo" className="footer-sevenson-logo" />
        </div>
      </div>
    );
  }
}
