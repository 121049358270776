import React from 'react';

require('../sass/containers/UnAuth.scss');

const UnAuth = () => (
  <div className="un-auth-container">
    <div className="un-auth-message-box">
      <p>You do not have permission to access this page.</p>
      <p>An administrator can change your authentication level if you require access.</p>
    </div>
  </div>
);

export default UnAuth;
