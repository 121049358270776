/* eslint jsx-a11y/no-static-element-interactions: 0 */
import React from 'react';

require('../../sass/components/shared/tabs.scss');
const classNames = require('classnames');

function makeTabContainers(containers, tabIdx, setTab) {
  return containers.map((container, idx) => {
    const { className, onClick, label } = container.props;

    const extraClass = className;
    const labelClasses = classNames({
      'tabs-label': true,
      'active-label': idx === tabIdx,
      [extraClass]: extraClass
    });
    return (
      // eslint-disable-next-line react/no-array-index-key
      <div
        className={labelClasses}
        key={idx}
        onClick={() => {
          onClick && onClick();
          setTab(idx);
        }}
      >
        {label}
      </div>
    );
  });
}

export default class Tabs extends React.Component {
  constructor(props) {
    super(props);
    this.state = { tabIdx: 0 };

    this.setTab = this.setTab.bind(this);
  }

  setTab(tabIdx) {
    this.setState({ tabIdx });
  }

  render() {
    const containers = this.props.children;
    const { tabIdx } = this.state;

    return (
      <div className="tabs-container">
        <div className="labels-row">{makeTabContainers(containers, tabIdx, this.setTab)}</div>
        <div className="tab-content">{containers[tabIdx].props.children}</div>
      </div>
    );
  }
}
