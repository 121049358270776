export const API_URL = (() => {
  switch (process.env.APP_ENV) {
    case 'staging':
      return 'https://sevenson-test-3568caaad51a.herokuapp.com/api';
    case 'production':
      return 'https://sevensonapps.com/api';
    case 'aardonyx':
      return 'https://sevenson.aardonyx.io/api';
    case 'sreq':
      return 'https://sreq.aardonyx.io/api';
    default:
      return 'http://127.0.0.1:5093/api';
  }
})();

export const APP_NAME = 'Sevenson Environmental Services, Inc.: Daily Report';
