import axios from 'axios';
import * as types from '../../constants/report/createReport';
import transAxoisErr from '../../etc/axios/transformAxiosError';

export const createReportRequest = () => ({type: types.CREATE_REPORT_REQUEST});

export const createReportReset = () => ({type: types.CREATE_REPORT_RESET});

export const createReportSuccess = ({ data }) => ({
  data,
  receivedAt: Date.now(),
  type: types.CREATE_REPORT_SUCCESS,
});

export const createReportFailure = error => ({
  error,
  receivedAt: Date.now(),
  type: types.CREATE_REPORT_FAILURE
});

export const createReport = data => (dispatch) => {
  dispatch(createReportRequest());
  return axios
    .post('/report', data)
    .then(res => dispatch(createReportSuccess(res.data)))
    .catch(error => dispatch(createReportFailure(transAxoisErr(error))));
};
