/* eslint-disable jsx-a11y/href-no-hash */
/* eslint-disable react/jsx-indent */
/* eslint jsx-a11y/no-static-element-interactions: 0 */

import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link, browserHistory } from 'react-router';

import pick from 'lodash/pick';
import pickBy from 'lodash/pickBy';

import IoCloseCircled from 'react-icons/lib/io/close-circled';
import IoIosDownload from 'react-icons/lib/io/ios-download';
import IoAlertCircled from 'react-icons/lib/io/alert-circled';
import IoIosPlay from 'react-icons/lib/io/ios-play';

import { fetchProjects } from '../actions/projects';
import { cloneProject } from '../actions/cloneProject';
import { deleteProject } from '../actions/deleteProject';
import { createProjectReset } from '../actions/createProject';
import { makeProjectZip, makeProjectMediaZip } from '../actions/zipProjects';

import Dropdown from '../components/shared/Dropdown';
import Loader from '../components/shared/Loader';
import Modal from '../components/shared/Modal';
import Alert from '../components/shared/Alert';
import Pagination from '../components/shared/Pagination';

import '../sass/containers/Reports/Index.scss';
import '../sass/containers/Projects.scss';

import sevensonLogoText from '../images/sevenson-logo-text.png';
import trash from '../images/buttonIcons/trash.svg';
import more from '../images/buttonIcons/more.svg';

// eslint-disable-next-line global-require
const URL = global.URL || require('url').URL;

const getExpiry = () => Date.now() - 1000;

const searchFields = ['sort', 'page', 'name', 'textSearch', 'number', 'location'];

class Projects extends React.Component {
  static need = [fetchProjects];

  constructor(props) {
    super(props);

    this.state = {};

    this.changeSort = this.changeSort.bind(this);
    this.updateSearch = this.updateSearch.bind(this);
    this.cloneConfirm = this.cloneConfirm.bind(this);
    this.deleteConfirm = this.deleteConfirm.bind(this);
    this.cancelDeleteModal = this.cancelDeleteModal.bind(this);
    this.deleteProjectSubmit = this.deleteProjectSubmit.bind(this);
    this.searchSubmit = this.searchSubmit.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
    this.makeZipAllClickHandler = this.makeZipAllClickHandler.bind(this);
    this.cancelZipModal = this.cancelZipModal.bind(this);
    this.zipConfirm = this.zipConfirm.bind(this);
  }

  componentWillMount() {
    const { isFetching, lastUpdated } = this.props.projects;
    if (!isFetching && lastUpdated < getExpiry()) {
      this.props.actions.fetchProjects();
    }
  }

  componentWillReceiveProps(nextProps) {
    const { actions } = this.props;
    const { location, projects } = nextProps;
    const { isFetching, lastUpdated } = projects;
    const { query } = location;

    if (!isFetching && !lastUpdated) {
      this.props.actions.fetchProjects(query);
    } else if (this.props.createProject.submitted) {
      actions.createProjectReset();
      actions.fetchProjects(query);
    } else if (this.props.location && this.props.location.query) {
      const oldQuery = pick(this.props.location.query, searchFields);
      const newQuery = pick(query, searchFields);

      const changed = Object.entries(newQuery)
        .map(([key, val]) => oldQuery[key] !== val)
        .filter(Boolean).length;

      const keysRemoved = Object.keys(oldQuery).length > Object.keys(newQuery).length;

      if (!changed && !keysRemoved) return;
      actions.fetchProjects(newQuery);
    }
  }

  updateSearch(props) {
    const { location } = this.props;

    if (!Object.keys(props).includes('page')) {
      props.page = undefined;
    }

    const oldQuery = pick(location.query, searchFields);
    const newQuery = pick(props, searchFields);

    const query = pickBy(Object.assign(oldQuery, newQuery), Boolean);
    browserHistory.push({ ...location, query });
  }

  changeSort(_field) {
    return () => {
      let field = _field;
      if (this.props.location.query.sort === field) {
        field = `-${field}`;
      }
      this.updateSearch({ sort: field });
    };
  }

  searchSubmit(e) {
    e.preventDefault();
    this.updateSearch({ textSearch: this.searchInput.value });
  }

  clearSearch() {
    this.updateSearch({ textSearch: undefined });
  }

  cloneProjectSubmit = id => () => {
    this.props.actions.cloneProject(id, this.cloneInput.value);
    this.setState({ cloneProjModal: undefined });
  };

  cloneConfirm = id => () => {
    this.setState({ cloneProjModal: id });
  };

  cancelCloneModal = () => {
    this.setState({ cloneProjModal: undefined });
  };

  deleteProjectSubmit = id => () => {
    this.props.actions.deleteProject(id);
    this.setState({ deleteProjModal: undefined });
  };

  deleteConfirm = id => () => {
    this.setState({ deleteProjModal: id });
  };

  cancelDeleteModal = () => {
    this.setState({ deleteProjModal: undefined });
  };

  makeZipAllClickHandler() {
    if (this.state.isMediaZip) {
      this.props.actions.makeProjectMediaZip(this.state.zipModal);
      this.setState({ zipModal: 'submitted', isMediaZip: undefined });
    } else {
      this.props.actions.makeProjectZip(this.state.zipModal);
      this.setState({ zipModal: 'submitted' });
    }
  }

  cancelZipModal = () => {
    this.setState({ zipModal: undefined });
  };

  zipConfirm = (id, media) => () => {
    this.setState({ zipModal: id, isMediaZip: media });
  };

  render() {
    const { projects, location } = this.props;
    const { authLevel, authOverrides } = this.props.user.data;
    const canAddNewProjects = authLevel >= 2 || (authOverrides && authOverrides.projects.create);
    const zipRequestSubmitted = this.state.zipModal === 'submitted';

    let noResults;
    if (projects.data.length === 0) {
      noResults = 'There are no results to display.';
    }

    let pageCnt;
    let thisPage;

    const { next, prev } = projects;
    const { query } = location || {};

    if (next || prev) {
      try {
        const pagingSearch = new URL(next || prev).searchParams;

        pageCnt = parseInt(pagingSearch.get('pages'), 10) || 1;
        const queryPage = parseInt(pagingSearch.get('page'), 10);

        thisPage = parseInt(query.page, 10) || (next ? queryPage : queryPage + 2);
      } catch (e) {
        console.error(e);
      }
    }

    const searchFilterOn = !!query.name;

    let alert;
    if (projects.error && projects.error.message) {
      alert = (
        <Alert delay={5000} type="danger">
          <i>
            <IoAlertCircled />
          </i>
          &nbsp;
          {projects.error.message}
        </Alert>
      );
    }

    let projectAuth;
    if (authOverrides) {
      projectAuth = authOverrides.projects;
    }

    const allowedToEditProjects = authLevel >= 2 || (authOverrides && projectAuth.edit);
    const allowedToDeleteProjects = authLevel >= 2 || (authOverrides && projectAuth.delete);

    const editButtonClasses = classNames({
      'standard-button': true,
      'primary-button': true,
      disabled: !allowedToEditProjects
    });

    const cloneButtonClasses = classNames({
      'standard-button': true,
      'clone-button': true,
      disabled: !allowedToEditProjects
    });

    const deleteButtonClasses = classNames({
      'delete-button': true,
      'standard-button': true,
      disabled: !allowedToDeleteProjects
    });

    return (
      <div className="main-list-page projects-container">
        {alert}
        <Loader isFetching={projects.isFetching} />
        {this.state.cloneProjModal && (
          <Modal
            isOpen
            extraClass="clone-confirm-modal"
            ref={modal => {
              this.cloneModal = modal;
            }}
            toCancel={() => {
              this.setState({ cloneProjModal: undefined });
            }}
          >
            <p className="modal-header clone-header">Are you sure?</p>
            <div className="modal-body">
              <p className="clone-modal-text">Clone this project?</p>
              <br />
              <label htmlFor="clone-input">
                New Project Name:
                <input id="clone-input" type="text" required ref={el => (this.cloneInput = el)} />
              </label>
              <br />
              <br />
              <button className="modal-button modal-cancel" onClick={this.cancelCloneModal}>
                cancel
              </button>
              <button
                className="modal-button modal-save"
                onClick={this.cloneProjectSubmit(this.state.cloneProjModal)}
              >
                clone
              </button>
            </div>
          </Modal>
        )}
        {this.state.deleteProjModal && (
          <Modal
            isOpen
            extraClass="delete-confirm-modal"
            ref={modal => {
              this.deleteModal = modal;
            }}
            toCancel={() => {
              this.setState({ deleteProjModal: undefined });
            }}
          >
            <div className="modal-text-top">
              <p className="modal-header">Are you sure?</p>
              <p className="modal-subheader">
                Delete this project? Deleting this project will also delete all associated reports
                and media.
              </p>
            </div>
            <div className="modal-body">
              <div className="modal-buttons">
                <button
                  className="modal-button modal-delete"
                  onClick={this.deleteProjectSubmit(this.state.deleteProjModal)}
                >
                  confirm
                </button>
                <button className="modal-button modal-cancel" onClick={this.cancelDeleteModal}>
                  cancel
                </button>
              </div>
            </div>
          </Modal>
        )}
        {this.state.zipModal && (
          <Modal
            isOpen
            extraClass="zip-request-modal"
            toCancel={() => {
              this.setState({ zipModal: undefined });
            }}
          >
            <div className="modal-text-top">
              <p className="modal-header">
                {' '}
                {zipRequestSubmitted ? 'Request submitted' : 'Are you sure?'}
              </p>
              <p className="modal-subheader">
                {zipRequestSubmitted
                  ? 'Your request has been submitted. When the PDFs are ready, you will receive an email with a link to download. Please be patient. This may take up to 12 hours. Do not click the button again during that time.'
                  : 'Clicking confirm will generate a zip file of all PDFs associated with this project. This may significantly diminish website performance for the next several hours. This action may not be canceled once started.'}
              </p>
            </div>
            <div className="modal-body">
              {zipRequestSubmitted ? (
                <div className="modal-buttons">
                  <button className="modal-button" onClick={this.cancelZipModal}>
                    OK
                  </button>
                </div>
              ) : (
                <div className="modal-buttons">
                  <button className="modal-button modal-save" onClick={this.makeZipAllClickHandler}>
                    confirm
                  </button>
                  <button className="modal-button modal-cancel" onClick={this.cancelZipModal}>
                    cancel
                  </button>
                </div>
              )}
            </div>
          </Modal>
        )}
        <div className="pre-table-header">
          <div className="report-top-section">
            <div className="top-section-left">
              <p className="page-title">Projects</p>
            </div>
            <div className="top-section-right">
              <div className="search-filter-things">
                <form className="search-and-button">
                  <input
                    id="project-search"
                    className="search-bar"
                    type="text"
                    ref={el => {
                      this.searchInput = el;
                    }}
                    placeholder="Search by project name or number…"
                  />
                  <input type="submit" value="Search" onClick={this.searchSubmit} />
                </form>
                {searchFilterOn && (
                  <div className="filter-notification-text">
                    <span>Current Filters:</span>
                    <div className="filter-notification-type" onClick={this.clearSearch}>
                      Project Name
                      <span className="filter-clear-icon">
                        <IoCloseCircled />
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="add-and-sorts">
          {canAddNewProjects && (
            <Link
              to={{
                pathname: '/projects/create',
                state: {
                  modal: true,
                  returnTo: this.props.location.pathname,
                  extraClass: 'add-edit-modal add-project-modal'
                }
              }}
              className="standard-button main-add-button primary-button"
            >
              Add New
            </Link>
          )}
          <ul className="sorts">
            <li onClick={this.changeSort('name')}>
              Name
              <IoIosPlay className={`sort-down ${query.sort === 'name'}`} />
              <IoIosPlay className={`sort-up ${query.sort === '-name'}`} />
            </li>
            <li onClick={this.changeSort('number')}>
              Number
              <IoIosPlay className={`sort-down ${query.sort === 'number'}`} />
              <IoIosPlay className={`sort-up ${query.sort === '-number'}`} />
            </li>
            <li onClick={this.changeSort('address.state address.city')}>
              Location
              <IoIosPlay className={`sort-down ${query.sort === 'address.state address.city'}`} />
              <IoIosPlay className={`sort-up ${query.sort === '-address.state address.city'}`} />
            </li>
          </ul>
        </div>
        <div className="list-cards-container">
          {projects.data.map(project => {
            return (
              <div className="list-card reports-table" key={project._id}>
                <div className="card-body">
                  <div className="group project-name">
                    <p className="body-field">Project</p>
                    <p className="emph">{project.name}</p>
                  </div>
                  <div className="group number">
                    <p className="body-field">Number</p>
                    <p>{project.number}</p>
                  </div>
                  <div className="group location">
                    <p className="body-field">Location</p>
                    <p>
                      {project.address.city}
                      {project.address.state && project.address.city && ', '}
                      {project.address.state}
                    </p>
                  </div>

                  <div className="group actions">
                    <p className="body-field">Actions</p>
                    <div className="button-contain">
                      <Link
                        title="Edit"
                        className={editButtonClasses}
                        to={{
                          pathname: `/projects/${project._id}/edit`,
                          state: {
                            modal: true,
                            returnTo: this.props.location.pathname,
                            extraClass: 'add-report-modal'
                          }
                        }}
                      >
                        Edit
                      </Link>
                      <button
                        className={cloneButtonClasses}
                        onClick={this.cloneConfirm(project._id)}
                      >
                        Clone
                      </button>
                      {allowedToDeleteProjects && (
                        <button
                          title="Delete"
                          disabled={!allowedToDeleteProjects}
                          className={deleteButtonClasses}
                          onClick={this.deleteConfirm(project._id)}
                        >
                          <img src={trash} alt="trash can" />
                        </button>
                      )}

                      <Dropdown
                        className="extra-options-dropdown filter-dropdown"
                        outsideText={
                          <button title="More Options" className="standard-button">
                            <img src={more} alt="vertical ellipsis" />
                          </button>
                        }
                      >
                        <ul className="project-dropdown">
                          <li>
                            {/* TODO: Hide this for non-authed users */}
                            <Link
                              className="view-media-button"
                              to={`/orders?projectRef=${project._id}`}
                            >
                              View Orders
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="view-media-button"
                              to={`/media?projectRef=${project._id}`}
                            >
                              View Media
                            </Link>
                          </li>
                          <li
                            onClick={this.zipConfirm(project._id, false)}
                            className="pdf-generator"
                          >
                            Download PDFs
                            <IoIosDownload />
                          </li>
                          <li
                            onClick={this.zipConfirm(project._id, true)}
                            className="pdf-generator"
                          >
                            Download Media
                            <IoIosDownload />
                          </li>
                        </ul>
                      </Dropdown>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
          {noResults && (
            <div className="no-results">
              <span>{noResults}</span>
            </div>
          )}
        </div>
        <Pagination
          pages={pageCnt}
          activePage={thisPage}
          prev={!!projects.prev}
          next={!!projects.next}
          updateSearch={this.updateSearch}
        />
        <img src={sevensonLogoText} alt="sevenson logo" className="table-bottom-sevenson-logo" />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  projects: state.projects,
  createProject: state.createProject,
  user: state.user
});

const mapDispatchToProps = dispatch => {
  const actions = {
    fetchProjects,
    cloneProject,
    deleteProject,
    createProjectReset,
    makeProjectZip,
    makeProjectMediaZip
  };
  return { actions: bindActionCreators(actions, dispatch) };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Projects);
