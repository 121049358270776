export const chkIsApprover = (uid, project) =>
  !project ||
  !!(project && project.approvers && project.approvers.find(approver => approver.userRef === uid));

export const chkIsAccessor = (uid, project) =>
  !project ||
  !!(project && project.accessors && project.accessors.find(accessor => accessor.userRef === uid));

/**
 * ACCESS RIGHTS
 * draft: author || super => (draft | open)
 * open: hq || approvers || super => (needEdit | pendingFinalApproval)
 * needEdit: author || approvers || super => (open)
 * pendingFinalApproval: author || approvers || super => (needEdit | needSignature)
 * needSignature: signers [no edit, only sign] => (complete)
 * complete: none
 */

const chkEditAllowed = (a, status) => {
  switch (status) {
    case 'draft':
      return (a.canCreate && a.isAuthor) || a.isSuper;
    case 'open':
      return (a.canApprove && (a.isHQ || a.isApprover)) || a.isSuper;
    case 'needEdit':
      return (a.canEdit && (a.isAuthor || a.isApprover)) || a.isSuper;
    case 'pendingFinalApproval':
      return (a.canApprove && (a.isAuthor || a.isApprover)) || a.isSuper;
    default:
      return false;
  }
};

const chkCoAllowed = (a, data) =>
  data.reqns && data.reqns.length && a.canAddNewOrders && data.status !== 'draft';

export const getAuthVals = (user, data = {}) => {
  const { authOverrides, authLevel } = user || {};
  const { orders: orderAOs } = authOverrides || {};

  const authv = {
    isHQ: user.isHQ,
    isSuper: authLevel === 100,
    isAuthor: data.author && user._id === data.author.userRef,

    canEdit: orderAOs.edit,
    canSign: orderAOs.sign,
    canCreate: orderAOs.create,
    canApprove: orderAOs.approve,
    canAddNewOrders: orderAOs.create,

    isApprover: chkIsApprover(user._id, data.projectRef),
    isAccessor: chkIsAccessor(user._id, data.projectRef)
  };

  authv.editAllowed = chkEditAllowed(authv, data.status);
  authv.coAllowed = chkCoAllowed(authv, data);

  return authv;
};
