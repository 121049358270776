import * as actions from '../../constants/report/createReport';

const initialState = {
  data: {},
  isFetching: false,
  lastUpdated: null,
  error: {},
  submitted: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.CREATE_REPORT_REQUEST:
    case actions.EDIT_REPORT_REQUEST:
      return { ...state, isFetching: !action.isInterval, error: {} };
    case actions.CREATE_REPORT_FAILURE:
    case actions.EDIT_REPORT_FAILURE:
      return {...state, isFetching: false, lastUpdated: action.receivedAt, error: action.error};
    case actions.CREATE_REPORT_SUCCESS:
    case actions.EDIT_REPORT_SUCCESS:
      return {
        ...state,
        data: action.data,
        isFetching: false,
        lastUpdated: action.receivedAt,
        error: {},
        submitted: true
      };
    case actions.LOCK_SUCCESS:
      return {
        ...state,
        isFetching: false,
        lastUpdated: action.receivedAt,
        error: {}
      };
    case actions.CREATE_REPORT_RESET:
      return initialState;
    default:
      return state;
  }
};
