export const FETCH_MEDIA_REQUEST = 'FETCH_MEDIA_REQUEST';
export const FETCH_MEDIA_SUCCESS = 'FETCH_MEDIA_SUCCESS';
export const FETCH_MEDIA_FAILURE = 'FETCH_MEDIA_FAILURE';

export const FETCH_SINGLE_MEDIA_REQUEST = 'FETCH_SINGLE_MEDIA_REQUEST';
export const FETCH_SINGLE_MEDIA_SUCCESS = 'FETCH_SINGLE_MEDIA_SUCCESS';
export const FETCH_SINGLE_MEDIA_FAILURE = 'FETCH_SINGLE_MEDIA_FAILURE';

export const DELETE_MEDIA_REQUEST = 'DELETE_MEDIA_REQUEST';
export const DELETE_MEDIA_SUCCESS = 'DELETE_MEDIA_SUCCESS';
export const DELETE_MEDIA_FAILURE = 'DELETE_MEDIA_FAILURE';
