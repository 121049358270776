import axios from 'axios';
import * as types from '../../constants/order/reqnDiff';
import transAxoisErr from '../../etc/axios/transformAxiosError';

export const fetchReqnDiffRequest = () => ({
  type: types.FETCH_REQN_DIFF_REQUEST,
  data: {}
});

export const fetchReqnDiffSuccess = ({ data }) => ({
  type: types.FETCH_REQN_DIFF_SUCCESS,
  payload: data,
  receivedAt: Date.now()
});

export const fetchReqnDiffFailure = error => ({
  type: types.FETCH_REQN_DIFF_FAILURE,
  error,
  receivedAt: Date.now()
});

export const fetchReqnDiff = ({ reqnId }) => dispatch => {
  dispatch(fetchReqnDiffRequest());
  return axios
    .get(`/reqn/${reqnId}/history`)
    .then(res => dispatch(fetchReqnDiffSuccess(res.data)))
    .catch(error => dispatch(fetchReqnDiffFailure(transAxoisErr(error))));
};

export const setActiveKey = (idx, field) => ({
  active: { idx, field },
  type: types.SET_ACTIVE_REQN_DIFF_KEY
});

export const resetReqnDiff = () => ({type: types.RESET_REQN_DIFF});
