/* eslint jsx-a11y/no-static-element-interactions: 0 */
import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

// Component for filtering a list by dates.
// Props:
// 1. filterFunc should define which criteria to filter

// Reset dateFilter is most intended to be used by the containing component

export default class DateFilter extends React.Component {
  static propTypes = { filterFunc: PropTypes.func.isRequired };

  constructor(props) {
    super(props);
    this.state = { showDatepicker: false, startDate: '', endDate: '' };
    this.filterByDateSubmit = this.filterByDateSubmit.bind(this);
    this.handleDateChangeStart = this.handleDateChangeStart.bind(this);
    this.handleDateChangeEnd = this.handleDateChangeEnd.bind(this);
    this.resetDatepicker = this.resetDatepicker.bind(this);
  }

  filterByDateSubmit(e) {
    e.preventDefault();

    const { options, selectedIndex } = e.target;
    const { value } = this.dateInput;

    if (value === 'custom') {
      this.setState({ showDatepicker: true });
      return;
    }

    const [dateAbove, dateBelow] = value.split(',');

    this.props.filterFunc(dateAbove, dateBelow || Date.now(), options[selectedIndex].label);
  }

  handleDateChangeStart(e) {
    const date = e.target.value;

    this.setState({ startDate: date });

    if (!date) {
      return this.props.filterFunc(null, this.state.endDate, 'Custom');
    }

    if (this.state.endDate) {
      this.props.filterFunc(
        moment(date).format('x'),
        moment(this.state.endDate).format('x'),
        'Custom'
      );
    }
  }

  handleDateChangeEnd(e) {
    const date = e.target.value;

    this.setState({ endDate: date });

    if (date && !new Date(date)) return;

    if (!date) {
      return this.props.filterFunc(this.state.startDate, null, 'Custom');
    }

    if (this.state.startDate) {
      this.props.filterFunc(
        moment(this.state.startDate).format('x'),
        moment(date).format('x'),
        'Custom'
      );
    }
  }

  resetDatepicker() {
    this.setState({ showDatepicker: false, startDate: '', endDate: '' });
    this.dateInput.value = '';
  }

  render() {
    return (
      <form className="date-filter-form">
        {this.state.showDatepicker && (
          <div>
            <label htmlFor="startDate">Start Date</label>
            <input
              id="startDate"
              type="date"
              value={this.state.startDate}
              onChange={this.handleDateChangeStart}
            />
            <label htmlFor="endDate">End Date</label>
            <input
              id="endDate"
              type="date"
              value={this.state.endDate}
              onChange={this.handleDateChangeEnd}
            />
          </div>
        )}
        <select
          ref={el => (this.dateInput = el)}
          name="date-select"
          onChange={this.filterByDateSubmit}
        >
          {/* TODO: make functions to make below cleaner */}
          <option value="">Select</option>
          <option label="Custom Date Range" value="custom">
            Custom Date Range
          </option>
          <option
            label="Today"
            value={moment()
              .startOf('day')
              .valueOf()}
          >
            Today
          </option>
          <option
            label="Yesterday"
            value={moment()
              .startOf('day')
              .subtract(1, 'days')
              .valueOf()}
          >
            Yesterday
          </option>
          <option
            label="Last 7 Days"
            value={moment()
              .startOf('day')
              .subtract(7, 'days')
              .valueOf()}
          >
            Last 7 Days
          </option>
          <option
            label="Last 14 Days"
            value={moment()
              .startOf('day')
              .subtract(14, 'days')
              .valueOf()}
          >
            Last 14 Days
          </option>
          <option
            label="Last 30 Days"
            value={moment()
              .startOf('day')
              .subtract(30, 'days')
              .valueOf()}
          >
            Last 30 Days
          </option>
          <option
            label="This Month"
            value={moment()
              .startOf('month')
              .valueOf()}
          >
            This Month
          </option>
          <option
            label="Last Month"
            value={[
              moment()
                .startOf('month')
                .subtract(1, 'months')
                .valueOf(),
              moment()
                .startOf('month')
                .subtract(1, 'day')
                .valueOf()
            ]}
          >
            Last Month
          </option>
          <option
            label="Last 3 Months"
            value={moment()
              .startOf('month')
              .subtract(3, 'months')
              .valueOf()}
          >
            Last 3 Months
          </option>
          <option
            label="Last 6 Months"
            value={moment()
              .startOf('month')
              .subtract(6, 'months')
              .valueOf()}
          >
            Last 6 Months
          </option>
          <option
            label="Last 12 Months"
            value={moment()
              .startOf('month')
              .subtract(12, 'months')
              .valueOf()}
          >
            Last 12 Months
          </option>
          <option
            label="This Year"
            value={moment()
              .startOf('year')
              .valueOf()}
          >
            This Year
          </option>
          <option
            label="Last Year"
            value={[
              moment()
                .startOf('year')
                .subtract(1, 'years')
                .valueOf(),
              moment()
                .startOf('year')
                .subtract(1, 'day')
                .valueOf()
            ]}
          >
            Last Year
          </option>
        </select>
      </form>
    );
  }
}
