import axios from 'axios';
import * as types from '../constants/projects';
import transAxoisErr from '../etc/axios/transformAxiosError';

export const cloneProjectRequest = () => ({
  type: types.CLONE_PROJECT_REQUEST
});

export const cloneProjectSuccess = ({ data }) => ({
  data,
  receivedAt: Date.now(),
  type: types.CLONE_PROJECT_SUCCESS
});

export const cloneProjectFailure = error => ({
  error,
  receivedAt: Date.now(),
  type: types.CLONE_PROJECT_FAILURE
});

export const cloneProject = (id, number) => dispatch => {
  dispatch(cloneProjectRequest());
  return axios
    .post(`/project/${id}/clone/${number}`)
    .then(res => dispatch(cloneProjectSuccess(res.data)))
    .catch(error => dispatch(cloneProjectFailure(transAxoisErr(error))));
};
