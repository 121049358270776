import React from 'react';
import classNames from 'classnames';

import isUndefined from 'lodash/isUndefined';

import Modal from '../shared/Modal';
import Repeater from '../shared/forms/Repeater';
import UploadFileModalBody from './UploadFileModalBody';

const makeItemField = (id, title, inputType, opts) =>
  Object.assign(
    {
      id,
      title,
      inputType,
      setOnChange: true
    },
    opts
  );

export default class OrderWizardOtherDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = { tcModalOpen: false, tcUpload: !!props.reqn.tcsPdf };

    this.renderModal = this.renderModal.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.toggleUpload = this.toggleUpload.bind(this);
  }

  toggleModal(val) {
    this.setState({ tcModalOpen: isUndefined(val) || val.target ? !this.state.tcModalOpen : val });
  }

  toggleUpload(val) {
    const tcUpload = isUndefined(val) || val.target ? !this.state.tcUpload : val;
    if (!tcUpload) this.props.unsetTcsPdf();
    this.setState({ tcUpload });
  }

  renderUploader() {
    const { reqn, uploaderProps } = this.props;
    const { handleDrop, totalProgress, filesArray, COTextInput } = uploaderProps;

    return reqn.tcsPdf ? (
      <div className="tcs-pdf-view">
        <a href={reqn.tcsPdf} target="_blank">
          View PDF
        </a>
        <button className="modal-button modal-delete" onClick={this.props.unsetTcsPdf}>
          Delete
        </button>
      </div>
    ) : (
      <UploadFileModalBody
        fileSingle
        multiple={false}
        accept="application/pdf"
        coTextInput={COTextInput}
        onDrop={handleDrop}
        filesArray={filesArray}
        totalProgress={totalProgress}
      />
    );
  }

  renderModal() {
    const { tcUpload } = this.state;
    const { reqn } = this.props;

    const defaultRepeaterProps = { data: reqn };

    return (
      <Modal toCancel={this.toggleModal}>
        <div className="modal-text-top">
          <p className="modal-header">Edit T & C</p>
          <p className="modal-subheader">
            Edit the text of Terms & Conditions or upload a PDF.
            <button className="modal-button modal-add" onClick={this.toggleUpload}>
              {tcUpload ? 'Input Text' : 'Upload a PDF'}
            </button>
          </p>
        </div>
        <div className="modal-body">
          {tcUpload ? (
            this.renderUploader()
          ) : (
            <Repeater
              {...{ ...this.props, ...defaultRepeaterProps }}
              single
              noTitle
              buttonRight
              circleRemove
              name="tcs"
              path="tcs"
              namePath="reqn.tcs"
              tableID="tcs-repeater"
              className="create-order-repeater"
              fields={[makeItemField('tcs', 'Line Items', null, { multiline: true, rows: 5 })]}
            />
          )}
        </div>
        <div className="modal-footer">
          <div className="modal-buttons center">
            <button
              className="modal-button modal-button-light"
              onClick={() => this.toggleModal(false)}
            >
              Close
            </button>
          </div>
        </div>
      </Modal>
    );
  }

  render() {
    const { CORadioGroup, CORadioInput, errors = [], edit, openComment, comments } = this.props;

    const commentExist = field => comments.findIndex(c => c.position === field) > -1;
    const commentClass = field => classNames('add-comment', { exists: commentExist(field) });

    const makeCommentIcon = (label, position) => {
      return (
        <span
          className={commentClass('reqn.schedule')}
          onClick={e => openComment({ labelText: label, position }, e)}
        />
      );
    };

    return (
      <div className="other-details-page">
        <div className="page-left">
          <p className="form-section-title">
            {edit && makeCommentIcon('Schedule', 'reqn.schedule')}
            Schedule
          </p>
          <CORadioGroup
            editable
            name="reqn.schedule"
            otherName="reqn.scheduleOther"
            showError={errors.otherDetails}
          >
            <CORadioInput value="asOrderedByContractor">As ordered by contractor</CORadioInput>
            <CORadioInput value="other">Other</CORadioInput>
          </CORadioGroup>
          <p className="form-section-title">
            {edit && makeCommentIcon('Via', 'reqn.via')}
            Party responsible for delivering the materials or equipment to the jobsite.
          </p>
          <CORadioGroup
            editable
            name="reqn.via"
            otherName="reqn.viaOther"
            showError={errors.otherDetails}
          >
            <CORadioInput value="vendor">Vendor</CORadioInput>
            <CORadioInput value="contractor">Contractor</CORadioInput>
            <CORadioInput value="commonCarrier">Common Carrier</CORadioInput>
            <CORadioInput value="other">Other</CORadioInput>
          </CORadioGroup>
        </div>
        <div className="page-right">
          <p className="form-section-title">
            {edit && makeCommentIcon('F.O.B.', 'reqn.fob')}
            F.O.B. (Free On Board)
          </p>
          <CORadioGroup
            editable
            name="reqn.fob"
            otherName="reqn.fobOther"
            showError={errors.otherDetails}
          >
            <CORadioInput value="jobsite">Job Site</CORadioInput>
            <CORadioInput value="vendorPlant">Vendor Plant</CORadioInput>
            <CORadioInput value="vendorYard">Vendor Yard</CORadioInput>
            <CORadioInput value="other">Other</CORadioInput>
          </CORadioGroup>
          <p className="form-section-title">Terms & Conditions</p>
          <button className="standard-button primary-button" onClick={this.toggleModal}>
            Edit T & C
          </button>
        </div>
        {this.state.tcModalOpen && this.renderModal()}
      </div>
    );
  }
}
