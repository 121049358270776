/* eslint react/no-string-refs: 0 */
/* eslint jsx-a11y/no-static-element-interactions: 0 */
import React from 'react';
import { connect } from 'react-redux';
import { Link, browserHistory } from 'react-router';

import IoClipboard from 'react-icons/lib/io/clipboard';
import IoIosFiling from 'react-icons/lib/io/ios-filing';
import IoAlertCircled from 'react-icons/lib/io/alert-circled';

import Alert from '../components/shared/Alert';
import Modal from '../components/shared/Modal';
import Loader from '../components/shared/Loader';
import TextInput from '../components/shared/forms/TextInput';

import { submitLogin } from '../actions/user';
import { resetPassword } from '../actions/resetPassword';

import extractFormRefs from '../etc/extractFormRefs';

import sevensonLogo from '../images/sevenson-logo-text-white.png';

import '../sass/containers/Login.scss';

const renderSelect = () => (
  <div className="login-form select">
    <Link className="login-select" to="reports">
      <IoClipboard />
      <span>Reports</span>
    </Link>
    <Link className="login-select" to="orders">
      <IoIosFiling />
      <span>Reqs</span>
    </Link>
  </div>
);

@connect(state => ({ user: state.user, passwordReset: state.passwordReset }))
export default class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      resetEmail: '',
      selectRedir: false
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.openPasswordModal = this.openPasswordModal.bind(this);
    this.getValuePasswordModal = this.getValuePasswordModal.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.sendPasswordRequest = this.sendPasswordRequest.bind(this);
    this.closePasswordModal = this.closePasswordModal.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.passwordReset.submitted) {
      this.setState({ passwordModal: undefined, resetEmail: '' });
    }

    if (nextProps.user.data) {
      const {
        authOverrides: { reports, orders }
      } = nextProps.user.data;

      if (reports.view && orders.view) {
        this.setState({ selectRedir: true });
        return;
      }

      browserHistory.replace(!orders.view ? 'reports' : 'orders');
    }
  }

  getValuePasswordModal() {
    return this.state.resetEmail;
  }

  openPasswordModal() {
    this.setState({ passwordModal: true });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.props.dispatch(submitLogin(extractFormRefs(this.refs)));
  }

  handleInputChange(key, value = 'value') {
    return event => {
      const nextState = { resetEmail: event.target[value] };
      this.setState(nextState);
    };
  }

  sendPasswordRequest(e) {
    e.preventDefault();
    this.props.dispatch(resetPassword(this.state.resetEmail));
  }

  closePasswordModal = () => {
    this.setState({ passwordModal: undefined, resetEmail: '' });
  };

  renderForm() {
    return (
      <form className="login-form">
        <div className="login-form-field">
          <p className="input-title">User Name</p>
          <input
            id="loginUsername"
            ref="username"
            type="text"
            className="first-field"
            placeholder="Username"
          />
        </div>
        <div className="login-form-field">
          <p className="input-title">Password</p>
          <input
            id="loginPassword"
            ref="password"
            type="password"
            className="last-field"
            placeholder="Password"
          />
        </div>
        <div className="login-form-button">
          <input
            type="submit"
            value="Log In"
            className="login-button"
            onClick={this.handleSubmit}
          />
          <div className="forgot-password-link">
            <p onClick={this.openPasswordModal}>Forgot password?</p>
          </div>
        </div>
      </form>
    );
  }

  render() {
    const { selectRedir } = this.state;
    const { user, passwordReset } = this.props;

    let alert;

    if (user.error && user.error.message) {
      alert = (
        <Alert delay={5000} ref="error" type="caution">
          <i>
            <IoAlertCircled />
          </i>
          {user.error.message}
        </Alert>
      );
    }

    if (passwordReset.error && passwordReset.error.message) {
      alert = (
        <Alert delay={5000} ref="error" type="danger">
          <i>
            <IoAlertCircled />
          </i>
          {passwordReset.error.message}
        </Alert>
      );
    }

    if (passwordReset.submitted) {
      alert = (
        <Alert delay={2000} ref="error" type="caution">
          <i>
            <IoAlertCircled />
          </i>
          An email has been sent with your new password.
        </Alert>
      );
      setTimeout(() => window.location.reload(false), 2000);
    }

    return (
      <div className="login-page login-background-image">
        <div className="login-gradient">
          {alert}
          {this.state.passwordModal && (
            <Modal
              isOpen
              extraClass="password-reset-modal"
              toCancel={this.closePasswordModal}
            >
              <div className="modal-text-top">
                <p className="modal-header">Forgot Password?</p>
                <p className="modal-subheader">
                  Let us help you with that. Enter your account email here. A
                  password reset will be emailed to you shortly.
                </p>
              </div>
              <div className="modal-body">
                <form
                  id="password-request-form"
                  className="modal-form"
                  onSubmit={this.sendPasswordRequest}
                >
                  <TextInput
                    type="email"
                    inputId="resetEmail"
                    labelText="User Email"
                    valueGetter={this.getValuePasswordModal}
                    onChange={this.handleInputChange}
                  />
                  <div className="modal-buttons">
                    <button type="submit" className="modal-button modal-add">
                      Send Request
                    </button>
                    <button
                      type="button"
                      className="modal-button modal-cancel"
                      onClick={this.closePasswordModal}
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </Modal>
          )}
          <img
            src={sevensonLogo}
            alt="sevenson-logo"
            className="sevenson-logo"
          />
          <div className="login-container">
            <div className="login-text-top">
              <h1 className="login-header">
                {selectRedir ? 'Select System' : 'Welcome'}
              </h1>
              {selectRedir ? (
                <h2 className="login-subheader">
                  Please select your subsystem.
                </h2>
              ) : (
                <h2 className="login-subheader">
                  Log in to access the official
                  <br />
                  Sevenson Environmental Services, Inc. Management System.
                </h2>
              )}
            </div>
            {selectRedir ? renderSelect() : this.renderForm()}
          </div>
          <Loader
            isFetching={user.isFetching || passwordReset.isSendingRequest}
          />
        </div>
      </div>
    );
  }
}
