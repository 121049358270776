/* eslint "jsx-a11y/no-static-element-interactions": 0 */
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router';
import moment from 'moment';
import IoChevronLeft from 'react-icons/lib/io/chevron-left';
import IoChevronRight from 'react-icons/lib/io/chevron-right';
import IoIosPlay from 'react-icons/lib/io/ios-play';

import { fetchActivity } from '../actions/report/reportActivity';
import { fetchReport } from '../actions/report/reportView';

import Loader from '../components/shared/Loader';

require('../sass/containers/Reports/Index.scss');
require('../sass/containers/Reports/ActivityLog.scss');

import sevensonLogoText from '../images/sevenson-logo-text.png';

class ActivityLog extends React.Component {
  constructor(props) {
    super(props);

    this.state = { sort: '', singleReport: false };

    this.page = this.page.bind(this);
    this.changeSort = this.changeSort.bind(this);
  }

  componentWillMount() {
    const { isFetching } = this.props.activity;
    const currentLocation = this.props.route.path;

    if (currentLocation === 'reports/:report/activity' && !isFetching) {
      this.setState({ singleReport: true, report: this.props.params.report }, () => {
        this.props.actions.fetchActivity(this.state);
        this.props.actions.fetchReport([this.props.params]);
      });
    } else {
      this.props.actions.fetchActivity();
    }
  }

  componentWillReceiveProps(nextProps) {
    const { isFetching, lastUpdated } = nextProps.activity;
    if (!isFetching && !lastUpdated) {
      if (this.state.singleReport) {
        this.setState({ report: this.props.params.report }, () => {
          this.props.actions.fetchActivity(this.state);
        });
      } else {
        this.props.actions.fetchActivity();
      }
    }
  }

  page(direction) {
    return () => {
      this.props.actions.fetchActivity({ next: this.props.activity[direction] });
    };
  }

  changeSort(_field) {
    return () => {
      let field = _field;
      if (this.state.sort === field) {
        field = `-${field}`;
      }
      // TODO: this seems semi-bad because of unnecessary render.
      this.setState({ sort: field }, () => {
        this.props.actions.fetchActivity(this.state);
      });
    };
  }

  render() {
    const { activity } = this.props;
    let report;
    if (this.state.singleReport) {
      report = this.props.report.data;
    }

    let noResults;
    if (activity.data.length === 0) {
      noResults = 'There are no results to display.';
    }

    let pageNumber = 1;

    const pageRegex = /\?.*page=(\d+)/;
    if (activity.next) {
      [pageNumber] = activity.next.match(pageRegex).slice(1);
    } else if (activity.prev) {
      pageNumber = parseInt(activity.prev.match(pageRegex)[1], 10) + 2;
    }

    return (
      <div className="activity-log-container">
        <Loader isFetching={activity.isFetching} />
        <div className="pre-table-header">
          <div className="report-top-section">
            <div className="top-section-left">
              <p className="page-title">
                Activity Log
                {this.state.singleReport &&
                  report && (
                    <p>
                      <Link to={`/reports/${this.state.report}`} className="report-return-link">
                        for {report.projectRef.name} Report# {report.number}
                      </Link>
                    </p>
                  )}
              </p>
            </div>
          </div>
        </div>
        <div className="add-and-sorts">
          <ul className="sorts">
            <li onClick={this.changeSort('project.number')}>
              Project
              <IoIosPlay className={`sort-down ${this.state.sort === 'project.number'}`} />
              <IoIosPlay className={`sort-up ${this.state.sort === '-project.number'}`} />
            </li>
            <li onClick={this.changeSort('createdAt')}>
              Date
              <IoIosPlay className={`sort-down ${this.state.sort === 'createdAt'}`} />
              <IoIosPlay className={`sort-up ${this.state.sort === '-createdAt'}`} />
            </li>
            <li onClick={this.changeSort('report.number')}>
              Report
              <IoIosPlay className={`sort-down ${this.state.sort === 'report.number'}`} />
              <IoIosPlay className={`sort-up ${this.state.sort === '-report.number'}`} />
            </li>
            <li onClick={this.changeSort('action')}>
              Action
              <IoIosPlay className={`sort-down ${this.state.sort === 'action'}`} />
              <IoIosPlay className={`sort-up ${this.state.sort === '-action'}`} />
            </li>
          </ul>
        </div>
        <div className="list-cards-container">
          {activity.data.length ? (
            activity.data.map((action, idx) => {
              const name = action.author ? action.author.name : '';
              return (
                <div className="list-card activity-card reports-table" key={action._id || idx}>
                  <div className="card-body">
                    <div className="group date">
                      <p className="body-field">Date</p>
                      <p>{moment(action.createdAt).format('MMMM Do YYYY [at] h:mm:ss a')}</p>
                    </div>
                    <div className="group name">
                      <p className="body-field">Name</p>
                      <p>{[name.first, name.last].join(' ')}</p>
                    </div>
                    <div className="group action">
                      <p className="body-field">Action</p>
                      <p className="emph">
                        {action.action === 'edit' ? (
                          <span className="activity-edit-text">edited</span>
                        ) : (
                          <span className={`activity-${action.action}-text`}>{action.action}d</span>
                        )}
                      </p>
                    </div>
                    <div className="group project">
                      <p className="body-field">Project</p>
                      <p>
                        Project&nbsp;
                        <span className="emph">{action.project && action.project.number}</span>
                      </p>
                    </div>
                    <div className="group reportNum">
                      <p className="body-field">Report</p>
                      <p>
                        Report&nbsp;
                        <span className="emph">{action.report && action.report.number}</span>
                      </p>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <p>No results found.</p>
          )}
        </div>
        {noResults && (
          <div className="no-results">
            <span>{noResults}</span>
          </div>
        )}
        <div className="prev-next-bottom">
          {activity.prev && (
            <button className="bottom-prev" onClick={this.page('prev')}>
              <IoChevronLeft />
            </button>
          )}
          {pageNumber && (
            <span className="page-number">
              <span>Page </span>
              {pageNumber}
            </span>
          )}
          <span className="page-number">
            <span>Page </span>
            {pageNumber}
          </span>
          {activity.next && (
            <button className="bottom-next" onClick={this.page('next')}>
              <IoChevronRight />
            </button>
          )}
        </div>
        <img src={sevensonLogoText} alt="sevenson environmental services, inc, logo" className="table-bottom-sevenson-logo" />
      </div>
    );
  }
}

const mapStateToProps = state => ({ activity: state.activity, report: state.report });

const mapDispatchToProps = dispatch => {
  const actions = { fetchActivity, fetchReport };
  return { actions: bindActionCreators(actions, dispatch) };
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivityLog);
