/* eslint-disable no-param-reassign */
/* eslint "jsx-a11y/no-static-element-interactions": 0 */
/* eslint "no-underscore-dangle": 0 */
import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router';

import Spinner from 'react-spinkit';
import IoIosDownload from 'react-icons/lib/io/ios-download';
import IoAlertCircled from 'react-icons/lib/io/alert-circled';

import {
  fetchReport,
  makePdf,
  resendEmails
} from '../../actions/report/reportView';
import CaptionedMedia from '../../components/shared/CaptionedMedia';
import Attachments from '../../components/shared/Attachments';
import Dropdown from '../../components/shared/Dropdown';
import Table from '../../components/shared/table/Table';
import Loader from '../../components/shared/Loader';
import Alert from '../../components/shared/Alert';

import sevensonLogoText from '../../images/sevenson-logo-text.png';
import blueExcavator from '../../images/excavator-blue-fade.jpg';
import lineExcavator from '../../images/excavator-line-filter.jpg';

import '../../sass/containers/Reports/Report.scss';

const getTotalJobHoursToDate = (report, hoursToday) => {
  const { _id, number, projectRef: project } = report;
  const hours = project.hours.filter(
    h => h.report !== _id && h.reportNumber < number
  );
  return hours.reduce(
    (acc, { total }) => acc + total,
    hoursToday + project.hoursMod
  );
};

/*
 * Note: This is kept as a container-level component,
 *  i.e. We should keep this as the container that does the data-fetching
 *  and dispatching of actions if you decide to have any sub-components.
 */

class Report extends React.Component {
  // static need = [fetchReport];

  constructor(props) {
    super(props);
    this.makePdfClickHandler = this.makePdfClickHandler.bind(this);
    this.resendEmailHandler = this.resendEmailHandler.bind(this);
  }

  componentWillMount() {
    this.props.actions.fetchReport(this.props.params);
  }

  makePdfClickHandler() {
    this.props.actions.makePdf(this.props.report.data._id);
  }

  resendEmailHandler() {
    this.props.actions.resendEmails(this.props.report.data._id);
  }

  render() {
    const { data: report } = this.props.report;

    if (!report) {
      return <div />;
    }

    const allowedToResend = this.props.user?.data?.authLevel >= 2;

    const { media } = report;

    report.contractorEquipment.forEach(e => {
      e.mobDate = e.mobDate && moment.utc(e.mobDate).format('MM/DD/YYYY');
      e.demobDate = e.demobDate && moment.utc(e.demobDate).format('MM/DD/YYYY');
    });

    report.rentalEquipment.forEach(e => {
      e.mobDate = e.mobDate && moment.utc(e.mobDate).format('MM/DD/YYYY');
    });

    const cEquipCols = [
      [['Equipment Name', 'no-arrow'], 'name'],
      [['Make/Model', 'no-arrow'], 'makeAndModel'],
      [['MOB Date', 'no-arrow'], 'mobDate'],
      [['DEMOB Date', 'no-arrow'], 'demobDate'],
      [['Quantity', 'no-arrow'], 'quantity'],
      [['Equip #', 'no-arrow'], 'equipmentNumber', null, true]
    ];
    const rEquipCols = [
      [['Equipment Name', 'no-arrow'], 'name'],
      [['Make/Model', 'no-arrow'], 'makeAndModel'],
      [['Description', 'no-arrow'], 'description'],
      [['MOB Date', 'no-arrow'], 'mobDate'],
      [['Quantity', 'no-arrow'], 'quantity'],
      [['Equip #', 'no-arrow'], 'equipmentNumber', null, true]
    ];
    const sLaborCols = [
      [['# of Personnel', 'no-arrow'], 'personnel.count]'],
      [['Type of Personnel', 'no-arrow'], 'personnel.type'],
      [['Hours Worked', 'no-arrow'], 'hours']
    ];
    const scLaborCols = [
      [['Name', 'no-arrow'], 'name'],
      [['Position', 'no-arrow'], 'position'],
      [['Hours Worked', 'no-arrow'], 'hours']
    ];

    let sevensonHours = 0;
    let subcontractorHours = 0;
    const yearToday = new Date().getFullYear();
    const reportDate = moment(report.createdAt, 'YYYY-MM-DD')._d;
    const weekDays = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday'
    ];

    const sevensonHoursToday = () => {
      report.sevensonLabor.forEach(({ hours, personnel }) => {
        const count = report.oldRef ? 1 : personnel.count;
        sevensonHours += hours * count;
      });
      return sevensonHours;
    };

    const contractorHoursToday = () => {
      report.subcontractorLabor.forEach(laborType => {
        subcontractorHours += laborType.hours;
      });
      return subcontractorHours;
    };

    /* TODO: make sure to account for if length or content is 0 or absent */

    function makeId(length = 5) {
      let text = '';
      const possible =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < length; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
      }

      return text;
    }

    const pdfUrl = `/api/report/${report._id}/get-pdf-url`;

    /* TODO: see if can get pdf name to change and force download*/
    // const pdfName = this.props.report.report.projectRef.name + ' ' + this.props.report.report.number + '.pdf';

    let alert;
    if (report.error && report.error.message) {
      alert = (
        <Alert delay={5000} type="danger">
          <IoAlertCircled />
          &nbsp;
          {report.error.message}
        </Alert>
      );
    }

    return (
      <div className="rv-body">
        {alert}
        <Loader isFetching={this.props.report.isFetching} />
        <img
          src={blueExcavator}
          className="blue-excavator"
          alt="sevenson-excavator-background"
        />
        <div className="rv-header clearfix">
          <div className="rv-header-left">
            <img
              src={sevensonLogoText}
              alt="sevenson-logo"
              className="rv-sevenson-logo"
            />
            <span className="hide-print">
              <p className="text-project-name">{report.projectRef.name}</p>
              <p className="text-location">
                <span>{report.projectRef.address.city}</span>,&nbsp;
                <span>{report.projectRef.address.state}</span>
              </p>
            </span>
          </div>
          <div className="rv-header-right">
            <Dropdown
              className="extra-options-dropdown filter-dropdown hide-print"
              outsideText={
                <button className="standard-button secondary-button">
                  More Options
                </button>
              }
            >
              <div>
                <ul>
                  <Link
                    className="view-activity-link"
                    to={`/reports/${report._id}/activity`}
                  >
                    <li>View Activity</li>
                  </Link>
                  {!pdfUrl && (
                    <li
                      onClick={this.makePdfClickHandler}
                      className="create-pdf-button"
                    >
                      Create PDF &nbsp;
                      {this.props.report.isFetchingPDF && (
                        <Spinner name="circle" />
                      )}
                    </li>
                  )}
                  {pdfUrl && (
                    <li>
                      <a
                        href={pdfUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="download-pdf-button"
                      >
                        Download PDF <IoIosDownload />
                      </a>
                    </li>
                  )}
                  {allowedToResend && report.isPublished && (
                    <li
                      onClick={this.resendEmailHandler}
                      className="resend-button"
                    >
                      Resend Emails
                      {this.props.report.isResendingEmails && (
                        <span> Sending…</span>
                      )}
                    </li>
                  )}
                </ul>
              </div>
            </Dropdown>
            <span className="hide-screen">
              <p className="text-project-name">{report.projectRef.name}</p>
              <p className="text-location">
                <span>{report.projectRef.address.city}</span>,&nbsp;
                <span>{report.projectRef.address.state}</span>
              </p>
            </span>
          </div>
        </div>
        <div className="rv-top-info">
          <div className="row">
            <div className="column">
              <p className="top-info-title">Date</p>
              <p className="top-info-text">
                {`${
                  reportDate.getMonth() + 1
                }/${reportDate.getDate()}/${reportDate.getFullYear()}`}
              </p>
            </div>
            <div className="column">
              <p className="top-info-title">Week Day</p>
              <p className="top-info-text">{weekDays[reportDate.getDay()]}</p>
            </div>
            <div className="column">
              <p className="top-info-title">Report No.</p>
              <p className="top-info-text">{report.number}</p>
            </div>
            <div className="column">
              <p className="top-info-title">Project No.</p>
              <p className="top-info-text">{report.projectRef.number}</p>
            </div>
          </div>
          <div className="row">
            <div className="column">
              <p className="top-info-title">Temp High</p>
              <p className="top-info-text">{report.weather.tempHigh}</p>
            </div>
            <div className="column">
              <p className="top-info-title">Temp Low</p>
              <p className="top-info-text">{report.weather.tempLow}</p>
            </div>
            <div className="column">
              <p className="top-info-title">Precip.</p>
              <p className="top-info-text">{report.weather.precipitation}</p>
            </div>
            <div className="column">
              <p className="top-info-title">Weather</p>
              <p className="top-info-text">{report.weather.conditions}</p>
            </div>
          </div>
        </div>
        <CaptionedMedia mediaArray={media} />
        <div className="text-before-tables">
          {report.workPerformedBySevenson && (
            <div className="rv-info-block rv-first-section">
              <p className="rv-section-title">
                Work Performed by Sevenson Environmental Services, Inc.
              </p>
              <p className="rv-section-body">
                {report.workPerformedBySevenson}
              </p>
            </div>
          )}
          {report.workPerformedBySubcontractors && (
            <div className="rv-info-block">
              <p className="rv-section-title">
                Work Performed by Subcontractors
              </p>
              <p className="rv-section-body">
                {report.workPerformedBySubcontractors}
              </p>
            </div>
          )}
          {report.plannedActivities && (
            <div className="rv-info-block">
              <p className="rv-section-title">Planned Activities</p>
              <p className="rv-section-body">{report.plannedActivities}</p>
            </div>
          )}
          {report.safetyRemarks && (
            <div className="rv-info-block">
              <p className="rv-section-title">Safety Remarks</p>
              <p className="rv-section-body">{report.safetyRemarks}</p>
            </div>
          )}
          {report.remarksOrVerbalDirectives && (
            <div className="rv-info-block">
              <p className="rv-section-title">Remarks/Verbal Directives</p>
              <p className="rv-section-body">
                {report.remarksOrVerbalDirectives}
              </p>
            </div>
          )}
          {report.equipmentOrMaterialsDeliveredToSite && (
            <div className="rv-info-block">
              <p className="rv-section-title">Equipment/Materials Delivered</p>
              <p className="rv-section-body">
                {report.equipmentOrMaterialsDeliveredToSite}
              </p>
            </div>
          )}
          {report.miscellaneousInfo && (
            <div className="rv-info-block">
              <p className="rv-section-title">Miscellaneous Information</p>
              <p className="rv-section-body">{report.miscellaneousInfo}</p>
            </div>
          )}
          <img
            src={lineExcavator}
            className="line-excavator"
            alt="background outline of excavator"
          />
        </div>
        <div className="rv-summary-tables">
          <div className="rv-info-block table">
            <p className="rv-section-title">Contractor Equipment</p>
            {report.contractorEquipment ? (
              <Table
                tableData={report.contractorEquipment}
                tableCols={cEquipCols}
                tableClass="c-equipment-table"
              />
            ) : (
              <p>None reported.</p>
            )}
          </div>
          <div className="rv-info-block table">
            <p className="rv-section-title">Rental Equipment</p>
            {report.rentalEquipment ? (
              <Table
                tableData={report.rentalEquipment}
                tableCols={rEquipCols}
                tableClass="r-equipment-table"
              />
            ) : (
              <p>None reported.</p>
            )}
          </div>
          <div className="rv-info-block table">
            <p className="rv-section-title">
              Sevenson Environmental Services, Inc. Labor
            </p>
            {report.sevensonLabor ? (
              <Table
                tableData={report.sevensonLabor}
                tableCols={sLaborCols}
                tableClass="s-labor-table"
              />
            ) : (
              <p>None reported.</p>
            )}
          </div>
          <div className="rv-info-block table">
            <p className="rv-section-title">Subcontractor Labor</p>
            {report.subcontractorLabor ? (
              <Table
                tableData={report.subcontractorLabor}
                tableCols={scLaborCols}
                tableClass="sc-labor-table"
              />
            ) : (
              <p>None reported.</p>
            )}
          </div>
        </div>
        <div className="rv-number-totals">
          <div className="totals-block">
            <p className="totals-title">
              Today‘s Sevenson Environmental Services, Inc. Labor:
            </p>
            <p className="totals-number">
              <span>{sevensonHoursToday()}</span>
              <span className="hours-text"> hours</span>
            </p>
          </div>
          <div className="totals-block">
            <p className="totals-title">Today‘s Subcontractor Labor:</p>
            <p className="totals-number">
              <span>{contractorHoursToday()}</span>
              <span className="hours-text"> hours</span>
            </p>
          </div>
          <div className="totals-block">
            <p className="totals-title">Today‘s Total Labor Hours:</p>
            <p className="totals-number">
              <span>{sevensonHours + subcontractorHours}</span>
              <span className="hours-text"> hours</span>
            </p>
          </div>
          <div className="totals-block">
            <p className="totals-title">Project‘s Total Hours:</p>
            <p className="totals-number">
              <span>
                {getTotalJobHoursToDate(
                  report,
                  sevensonHours + subcontractorHours
                )}
              </span>
              <span className="hours-text"> hours</span>
            </p>
          </div>
        </div>
        <Attachments attachmentArray={report.attachments} />
        <div className="rv-ending-text">
          <p className="rv-completed-by">Report completed by</p>
          <p className="rv-author">
            {report.author.name.first} {report.author.name.last}
          </p>
          <p className="rv-copyright">
            Copyright<span>{' ' + yearToday}</span>, Sevenson Environmental
            Services, Inc.
          </p>
          <p className="rv.copyright">All Rights Reserved.</p>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  report: state.report,
  user: state.user
});

const mapDispatchToProps = dispatch => {
  const actions = { fetchReport, makePdf, resendEmails };
  return { actions: bindActionCreators(actions, dispatch) };
};

export default connect(mapStateToProps, mapDispatchToProps)(Report);
