import axios from 'axios';
import * as types from '../constants/projects';
import transAxoisErr from '../etc/axios/transformAxiosError';

export const fetchProjectRequest = () => ({
  type: types.FETCH_PROJECT_REQUEST,
  data: {}
});

export const fetchProjectSuccess = ({ data }) => ({
  type: types.FETCH_PROJECT_SUCCESS,
  payload: data,
  receivedAt: Date.now()
});

export const fetchProjectFailure = error => ({
  type: types.FETCH_PROJECT_FAILURE,
  error,
  receivedAt: Date.now()
});

export const fetchProject = ({ id }) => dispatch => {
  dispatch(fetchProjectRequest());
  return axios
    .get(`/project/${id}`)
    .then(
      res => dispatch(fetchProjectSuccess(res.data)),
      error => dispatch(fetchProjectFailure(transAxoisErr(error)))
    );
};
