import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

import IoChevronLeft from 'react-icons/lib/io/chevron-left';
import IoChevronRight from 'react-icons/lib/io/chevron-right';

import '../../sass/components/shared/Pagination.scss';

export default class Pagination extends React.Component {
  static propTypes = {
    pages: PropTypes.number,
    activePage: PropTypes.number,

    prev: PropTypes.bool,
    next: PropTypes.bool,

    updateSearch: PropTypes.func.isRequired
  };

  static defaultProps = {
    pages: 1,
    activePage: 1,

    prev: false,
    next: false
  };

  constructor(props) {
    super(props);

    this.makePages = this.makePages.bind(this);
    this.page = this.page.bind(this);
  }

  makePages() {
    const { pages, activePage } = this.props;

    const length = Math.min(pages, 10);

    // eslint-disable-next-line no-mixed-operators
    const minPageDiff = Math.max(length - (pages - activePage) - 1, 5);
    const minPage = activePage > 6 ? activePage - minPageDiff : 1;
    const getBtnClass = page => classNames('button-paging', { active: page === activePage });

    return Array.from({ length }, (val, idx) => idx + minPage).map(page => {
      return (
        <button key={page} className={getBtnClass(page)} onClick={this.page(null, page)}>
          {page}
        </button>
      );
    });
  }

  page(direction, page) {
    return () => {
      const { activePage, updateSearch } = this.props;

      if (page) {
        updateSearch({ page });
        return;
      }

      if (direction === 'prev' && activePage > 1) {
        updateSearch({ page: activePage - 1 });
      } else {
        updateSearch({ page: activePage + 1 });
      }
    };
  }

  render() {
    const { prev, next, pages } = this.props;

    if (pages < 2) return null;

    return (
      <div className="paging-block-wrapper">
        <div className="paging-block">
          {prev && (
            <button className="button-paging prev" onClick={this.page('prev')}>
              <IoChevronLeft />
            </button>
          )}
          {this.makePages()}
          {next && (
            <button className="button-paging next" onClick={this.page('next')}>
              <IoChevronRight />
            </button>
          )}
        </div>
      </div>
    );
  }
}
