/* eslint "jsx-a11y/no-static-element-interactions": 0 */
// 'returnTo' - If the modal is used from an independent route <Link> use prop 'returnTo'
// 'toCancel' - should be a function that references container state for modal closing.
// 'isOpen' - include as a prop
// 'extraClass' - self-explanatory

// EXTRA CLASSES you may use
// 'modal-box-shadow' applies an appropriate classy box shadow behind your modal content
// 'modal-header' creates a header at the top of your modal content, may be used with
// 'modal-body', and also in combindation with 'delete-header'
// 'modal-button' in combination with 'modal-cancel', 'modal-delete', 'modal-save'

import React from 'react';
import { browserHistory } from 'react-router';

import IosCloseEmpty from 'react-icons/lib/io/ios-close-empty';

require('../../sass/components/shared/modal.scss');

const classNames = require('classnames');

export default class Modal extends React.Component {
  constructor(props) {
    super(props);

    this.close = this.close.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown);
  }

  close() {
    const { returnTo, toCancel } = this.props;

    if (returnTo) {
      browserHistory.push(returnTo);
    } else if (toCancel) {
      toCancel();
    }
  }

  handleKeyDown({ key }) {
    if (key === 'Escape' || key === 'Esc') {
      this.close();
    }
  }

  render() {
    const modalClasses = classNames({
      'modal-container': true,
      [this.props.extraClass]: true,
      hide: !this.props.isOpen
    });

    return (
      <div className={modalClasses} onClick={this.close}>
        <div className="modal-content" onClick={e => e.stopPropagation()}>
          <div className="modal-close">
            <div onClick={this.close}>
              <IosCloseEmpty />
            </div>
          </div>
          {this.props.children}
        </div>
      </div>
    );
  }
}
