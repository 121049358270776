import * as actions from '../constants/users';

const initialState = {
  data: [],
  isFetching: false,
  lastUpdated: null,
  error: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.SDP_USER_REQUEST:
    case actions.FETCH_USERS_REQUEST:
    case actions.DELETE_USER_REQUEST:
      return { ...state, isFetching: true, error: {} };
    case actions.SDP_USER_FAILURE:
    case actions.FETCH_USERS_FAILURE:
    case actions.DELETE_USER_FAILURE:
      return {
        ...state,
        isFetching: false,
        lastUpdated: action.receivedAt,
        error: action.error
      };
    case actions.FETCH_USERS_SUCCESS:
      return {
        ...state,
        next: action.next,
        prev: action.prev,
        data: action.data,
        isFetching: false,
        lastUpdated: action.receivedAt,
        error: {}
      };
    case actions.DELETE_USER_SUCCESS:
      return {
        ...state,
        isFetching: false,
        lastUpdated: null,
        error: {}
      };
    case actions.SDP_USER_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: {}
      };
    default:
      return state;
  }
};
