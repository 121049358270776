import axios from 'axios';
import * as types from '../constants/media';
import transAxoisErr from '../etc/axios/transformAxiosError';

export const deleteMediaRequest = () => ({type: types.DELETE_MEDIA_REQUEST});

export const deleteMediaSuccess = ({ data }) => ({
  data,
  receivedAt: Date.now(),
  type: types.DELETE_MEDIA_SUCCESS
});

export const deleteMediaFailure = error => ({
  error,
  receivedAt: Date.now(),
  type: types.DELETE_MEDIA_FAILURE
});

export const deleteMedia = id => (dispatch) => {
  dispatch(deleteMediaRequest());
  return axios
    .delete(`/media/${id}`)
    .then(res => dispatch(deleteMediaSuccess(res.data)))
    .catch(error => dispatch(deleteMediaFailure(transAxoisErr(error))));
};
