import React from 'react';
import TableHeader from './Header';
import TableRows from './Rows';

const classNames = require('classnames');

export default class Table extends React.Component {
  static defaultProps = { tableClass: 'table' };

  render() {
    const {tableCols, tableData, tableClass, sortFn} = this.props;

    const headerArray = tableCols.map(dataSet => dataSet[0]);
    const colEndpointsArray = tableCols.map(dataSet => dataSet[1]);
    const sortValues = tableCols.map(dataSet => dataSet[2] || dataSet[1]);
    const notDateSettings = tableCols.map(dataSet => dataSet[3])

    const headerClass = classNames({[tableClass + '-header']: true});

    const bodyClass = classNames({[tableClass + '-body']: true});

    return (
      <table className={tableClass}>
        <TableHeader
          columnHeaders={headerArray}
          headerClass={headerClass}
          sortFn={sortFn}
          sortValues={sortValues}
        />
        <TableRows
          dataArray={tableData}
          colEndpoints={colEndpointsArray}
          bodyClass={bodyClass}
          columnAssignments={headerArray}
          notDateSettings={notDateSettings}
        />
      </table>
    );
  }
}
