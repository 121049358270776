import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import startCase from 'lodash/startCase';
import { connect } from 'react-redux';
import { setActiveKey } from '../../../actions/order/reqnDiff';
import { getReqnVersion, fetchReqn } from '../../../actions/order/reqn';

const classNames = require('classnames');

const fieldSwitch = field => {
  switch (field) {
    case 'fob':
      return 'FOB';
    case 'vendorRef':
      return 'Vendor';
    case 'projectRef':
      return 'Project';
    default:
      return startCase(field);
  }
};

const sortOrder = [
  'number',
  'company',
  'date',
  'projectRef',
  'vendorRef',
  'header',
  'paymentTerms',
  'schedule',
  'fob',
  'via',
  'notes',
  'items',
  'costCode',
  'subtotal',
  'salesTax',
  'taxTotal',
  'totalOffset',
  'total'
];

@connect(s => ({ active: s.reqnDiff.active, reqn: s.reqn }))
export default class Diff extends React.Component {
  static propTypes = {
    idx: PropTypes.number.isRequired,
    current: PropTypes.bool,

    diffObj: PropTypes.shape({
      user: PropTypes.shape({ name: PropTypes.objectOf(PropTypes.string) }),
      diff: PropTypes.objectOf(PropTypes.any),
      version: PropTypes.number,
      createdAt: PropTypes.string
    }).isRequired
  };

  static defaultProps = { current: false };

  constructor(props) {
    super(props);

    this.isOpen = this.isOpen.bind(this);
    this.setActive = this.setActive.bind(this);
    this.getFieldClass = this.getFieldClass.bind(this);
    this.handleVersionSelect = this.handleVersionSelect.bind(this);
  }

  setActive(field) {
    return () => {
      const { dispatch, idx } = this.props;
      dispatch(setActiveKey(idx, field));
    };
  }

  getFieldClass(field) {
    const { idx, active } = this.props;
    const diffSelected = active.idx === idx && active.field === field;

    return classNames({ 'diffs-block': true, 'diff-selected': diffSelected });
  }

  isOpen() {
    const { diffObj, reqn } = this.props;
    const { version } = diffObj;
    return reqn.version === version;
  }

  handleVersionSelect() {
    const { current, diffObj, reqn, dispatch } = this.props;

    const reqnId = reqn.data._id;
    const { version } = diffObj;

    if (reqn.version === version) return;

    if (current) {
      dispatch(fetchReqn({ reqnId, version }));
    } else {
      dispatch(getReqnVersion({ reqnId, version }));
    }
  }

  render() {
    const { diffObj } = this.props;
    const { createdAt, user, diff } = diffObj;

    const name = (user && user.name) || false;
    const nameStr = name
      ? [name.first, name.middle, name.last].filter(Boolean).join(' ')
      : 'Unknown Author';

    const sortedDiffs = sortOrder
      .map(item => {
        if (Object.keys(diff).find(d => d === item)) {
          return item;
        }
        return false;
      })
      .filter(Boolean);

    return (
      <details
        className="diff"
        key={createdAt}
        onClick={this.handleVersionSelect}
        open={this.isOpen()}
      >
        <summary className="diffs-block">
          <h3 className="diff-date">{moment(createdAt).calendar()}</h3>
          <p className="diff-author">{nameStr}</p>
        </summary>
        <ul className="diff-list">
          {sortedDiffs.map(field => (
            <a href={`#${field}`}>
              <li key={field} onClick={this.setActive(field)} className={this.getFieldClass(field)}>
                {fieldSwitch(field)}
              </li>
            </a>
          ))}
        </ul>
      </details>
    );
  }
}
