import axios from 'axios';
import * as types from '../../constants/vendor/vendors';
import transAxoisErr from '../../etc/axios/transformAxiosError';

export const fetchVendorsRequest = () => ({type: types.FETCH_VENDORS_REQUEST});

export const fetchVendorsSuccess = ({ data, next, prev }) => ({
  data,
  next,
  prev,
  receivedAt: Date.now(),
  type: types.FETCH_VENDORS_SUCCESS,
});

export const fetchVendorsFailure = error => ({
  error,
  receivedAt: Date.now(),
  type: types.FETCH_VENDORS_FAILURE
});

export const fetchVendors = ({next, sort, name, limit} = {}) => (dispatch) => {
  dispatch(fetchVendorsRequest());
  return axios
    .get(next || '/vendor/all', { params: { sort, name, limit } })
    .then(res => dispatch(fetchVendorsSuccess(res.data)))
    .catch(error => dispatch(fetchVendorsFailure(transAxoisErr(error))));
};
