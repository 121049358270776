/* eslint jsx-a11y/no-static-element-interactions: 0 */
import React from 'react';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';

require('../../sass/components/etc/FilterDrawer.scss');
const classNames = require('classnames');

export default class FilterDrawer extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    closeDrawer: PropTypes.func,
    closeOnMount: PropTypes.bool,
    noCloseOnBlur: PropTypes.bool,
    open: PropTypes.bool,
    right: PropTypes.bool
  };

  static defaultProps = {
    className: '',
    closeDrawer: () => {},
    closeOnMount: false,
    noCloseOnBlur: false,
    open: false,
    right: false
  };

  constructor(props) {
    super(props);
    this.state = {};
    this.closeDrawer = this.closeDrawer.bind(this);
  }

  componentDidMount() {
    if (this.props.closeOnMount) {
      browserHistory.listen(() => {
        this.closeDrawer();
      });
    }
  }

  closeDrawer() {
    if (this.props.open) this.props.closeDrawer(undefined);
  }

  render() {
    const { open, right, className } = this.props;

    const drawerClasses = classNames('filter-drawer', className, { open, right });

    return (
      <div
        tabIndex="-1"
        className={drawerClasses}
        onFocus={this.handleFocus}
        ref={el => (this.drawer = el)}
      >
        <div className="drawer-content">{this.props.children}</div>
      </div>
    );
  }
}
