import React from 'react';
import ReactCSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';

require('../../sass/components/etc/alert.scss');

export default class Alert extends React.Component {
  constructor(props) {
    super(props);
    this.state = { visible: true };
  }

  componentDidMount() {
    this.setTimer();
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  setTimer() {
    // clear any existing timer
    if (this.timer !== null) {
      clearTimeout(this.timer);
    }

    // hide after 'delay' milliseconds
    setTimeout(() => {
      this.setState({ visible: false });
      this.timer = null;
    }, this.props.delay);
  }

  render() {
    let items;
    if (this.props.children && this.state.visible) {
      items = (
        <div key="alert" className="alert-container">
          {this.props.children}
        </div>
      );
    }

    return (
      <ReactCSSTransitionGroup
        transitionName="alert-transition"
        transitionAppear
        transitionAppearTimeout={5000}
        // If we ever need two alerts at the same time, change transitionEnter to true and add corresponding transition styles
        transitionEnter={false}
        transitionLeaveTimeout={500}
        className={this.props.type}
      >
        {items}
      </ReactCSSTransitionGroup>
    );
  }
}
