import RECORD_FILTER_DRAWER from '../constants/setFilterDrawer';

const initialState = { drawer: '' };

export default (state = initialState, data) => {
  switch (data.type) {
    case RECORD_FILTER_DRAWER:
      return { drawer: data.drawer };
    default:
      return state;
  }
};
