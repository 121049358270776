import React from 'react';
import moment from 'moment';
import fileType from 'file-type/browser';
import { Link, browserHistory } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import IoAlertCircled from 'react-icons/lib/io/alert-circled';

import get from 'lodash/get';
import set from 'lodash/set';
import throttle from 'lodash/throttle';

import Repeater from '../../components/shared/forms/Repeater';
import TextInput from '../../components/shared/forms/TextInput';
import Loader from '../../components/shared/Loader';
import Alert from '../../components/shared/Alert';

import { editReport, lockReport } from '../../actions/report/editReport';
import { fetchProjects } from '../../actions/projects';
import { fetchWeather } from '../../actions/weather';
import { fetchReport } from '../../actions/report/reportView';
import {
  createReportReset,
  createReport as createReportSubmit
} from '../../actions/report/createReport';

require('../../sass/containers/Reports/CreateReport.scss');

const { Typeahead } = require('react-typeahead');

function reportNumber(nextState) {
  if (nextState.report.number) return nextState;
  const { hours } = nextState.project;
  if (hours && hours.length) {
    nextState.report.number = Math.max(...hours.map(h => h.reportNumber)) + 1;
  } else {
    nextState.report.number = 1;
  }
  return nextState;
}

const tzOffsetMs = new Date().getTimezoneOffset() * 60 * 1000;
const formatMobDate = d =>
  d && moment(moment(d, 'YYYY-MM-DD') + tzOffsetMs).format('YYYY-MM-DD');

const makeContEqp =
  report =>
  ({ demobDate, mobDate }, idx) => ({
    ...report.contractorEquipment[idx],
    mobDate: formatMobDate(mobDate),
    demobDate: formatMobDate(demobDate)
  });

const makeRentEqp =
  report =>
  ({ mobDate }, idx) => ({
    ...report.rentalEquipment[idx],
    mobDate: mobDate && formatMobDate(mobDate)
  });

class CreateReport extends React.Component {
  static need = [fetchProjects.bind(null, { limit: -1 })];

  constructor(props) {
    super(props);

    // TODO: include userRef
    this.state = {
      report: {
        weather: {
          tempHigh: '',
          tempLow: '',
          conditions: '',
          precipitation: '',
          override: false
        },
        number: '',
        projectRef: '',
        createdAt: moment().format('YYYY-MM-DD'),
        contractorEquipment: [],
        rentalEquipment: [],
        sevensonLabor: [{ hours: 0, personnel: { count: 0, type: '' } }],
        subcontractorLabor: [],
        emailRecipients: [],
        attachments: [],
        media: new Array(4)
          .fill(undefined)
          .map(() => ({ url: '', title: '', description: '' })),
        author: { userRef: props.user.data._id, name: props.user.data.name },
        isPublished: false,
        totalJobHours: ''
      },
      errorState: [],
      mediaRemovals: 0,
      typeaheadResultsHidden: false,
      uploadProgress: {
        Attachments: [],
        Photos: []
      }
    };

    this.getValueFromId = this.getValueFromId.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.markAsUsed = this.markAsUsed.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.getPath = this.getPath.bind(this);
    this.unlockReport = this.unlockReport.bind(this);
    this.replacePath = this.replacePath.bind(this);
    this.addRow = this.addRow.bind(this);
    this.delRow = this.delRow.bind(this);
    this.delImage = this.delImage.bind(this);

    this.preprocess = this.preprocess.bind(this);
    this.onUploadError = this.onUploadError.bind(this);
    this.onUploadFinish = this.onUploadFinish.bind(this);
    this.onUploadProgress = this.onUploadProgress.bind(this);
    this.handleTabKeys = this.handleTabKeys.bind(this);

    this.getWeather = this.getWeather.bind(this);

    this.refreshLock = throttle(this.handleSubmit.bind(this, null, true), 60e3);
  }

  componentWillMount() {
    const { isFetching, lastUpdated } = this.props.projects;
    const currentLocation = this.props.route.path;

    if (currentLocation === 'reports/:id/edit') {
      this.setState({ editForm: true });
      this.props.actions.fetchReport(this.props.params);
    } else if (currentLocation === 'reports/:id/clone') {
      this.setState({ cloneForm: true });
      this.props.actions.fetchReport(this.props.params);
    }
    if (!isFetching && !lastUpdated) {
      this.props.actions.fetchProjects({ limit: -1 });
    }
    if (this.props.createReport.submitted) {
      this.props.actions.createReportReset();
    }
  }

  componentDidMount() {
    if (this.props.route.path === 'reports/:id/edit') {
      window.addEventListener('beforeunload', this.unlockReport);
    }
  }

  componentWillReceiveProps(nextProps) {
    const { isFetching, data } = nextProps.weather;
    if (!isFetching && data.conditions) {
      this.setState({ report: { ...this.state.report, weather: data } });
    }

    if (this.props.report.data !== nextProps.report.data) {
      const fetchedReport = nextProps.report.data;
      const projectName = fetchedReport.projectRef.name;

      const media = fetchedReport.media
        .filter(m => !!m.mediaRef)
        .map(({ mediaRef: { url, title, _id } = {}, description }) => ({
          url,
          title,
          description,
          mediaRef: _id
        }));

      const attachments = fetchedReport.attachments.map(
        ({ url = '', name = '' }) => ({
          url,
          name
        })
      );

      const contractorEquipment = fetchedReport.contractorEquipment.map(
        makeContEqp(fetchedReport)
      );
      const rentalEquipment = fetchedReport.rentalEquipment.map(
        makeRentEqp(fetchedReport)
      );

      if (this.state.cloneForm && !nextProps.createReport.submitted) {
        const newReportObj = Object.assign({}, fetchedReport, {
          media,
          attachments,
          contractorEquipment,
          rentalEquipment,
          createdAt: moment(Date.now()).format('YYYY-MM-DD'),
          hasEmailed: false,
          isPublished: false,
          projectRef: fetchedReport.projectRef._id,
          weather: {
            tempHigh: '',
            tempLow: '',
            conditions: '',
            precipitation: ''
          },
          author: {
            userRef: this.props.user.data._id,
            name: this.props.user.data.name
          }
        });

        delete newReportObj._id;
        delete newReportObj.oldRef;
        delete newReportObj.number;

        const nextState = {
          ...this.state,
          report: newReportObj,
          project: fetchedReport.projectRef,
          typeaheadResultsHidden: true
        };
        reportNumber(nextState);
        this.updateTotalHours(nextState);
        nextState.justLoaded = true;
        this.setState(nextState);
        this.projTypeahead.setEntryText(projectName);
      } else if (this.state.editForm && !nextProps.createReport.submitted) {
        const newReportObj = Object.assign({}, fetchedReport, {
          media,
          attachments,
          contractorEquipment,
          rentalEquipment,
          projectRef: fetchedReport.projectRef._id,
          createdAt: moment(fetchedReport.createdAt, 'YYYY-MM-DD').format(
            'YYYY-MM-DD'
          )
        });

        const project = fetchedReport.projectRef;
        const nextState = {
          report: newReportObj,
          project,
          typeaheadResultsHidden: true
        };

        this.updateTotalHours(nextState);
        nextState.justLoaded = true;

        this.setState(nextState);
        this.projTypeahead.setEntryText(projectName);
      }
    }
    if (nextProps.createReport.submitted) {
      const locationState = this.props.location.state;
      const redirect = locationState ? locationState.returnTo : '/reports';
      browserHistory.push(redirect);
    }
  }

  componentWillUnmount() {
    this.refreshLock.cancel();

    if (this.state.editForm) {
      this.unlockReport();
    }

    if (this.props.route.path === 'reports/:id/edit') {
      window.removeEventListener('beforeunload', this.unlockReport);
    }
  }

  // eslint-disable-next-line react/sort-comp,class-methods-use-this
  preprocess(file, next) {
    if (file.type) return next(file);

    const fileReader = new FileReader();

    fileReader.onload = event => {
      const arrayBuffer = event.target.result;
      const { ext, mime } = fileType.fromBuffer(arrayBuffer);

      return next(
        new File([arrayBuffer], `${file.name}.${ext}`, {
          type: mime,
          lastModified: file.lastModified || Date.now()
        })
      );
    };

    return fileReader.readAsArrayBuffer(file);
  }

  onUploadError(error) {
    console.log({ uploadError: error });
    this.setState({ error });
  }

  onUploadFinish(key) {
    return res => {
      const match = res.signedUrl.match(/(.*)\?/);
      const url = match && match[1];
      const newReport = { ...this.state.report };

      let publicKey = key.split('.');
      publicKey.splice(-1, 1, 'publicUrl');
      publicKey = publicKey.join('.');

      set(newReport, key, url);
      set(newReport, publicKey, res.publicUrl);

      if (url) {
        this.setState({ report: set({ ...this.state.report }, key, url) });
      } else {
        console.log(
          'Something has happened in onUploadFinish that should not have.'
        );
      }
    };
  }

  onUploadProgress(index, title) {
    return (percentage, status) => {
      if ((index && percentage && status) !== undefined) {
        const uploadProgress = { ...this.state.uploadProgress };
        uploadProgress[title][index] = { percentage, status };
        this.setState({ uploadProgress });
      }
    };
  }

  getValueFromId(id) {
    const value = get(this.state.report, id);
    if (typeof value === 'undefined') return '';
    return value;
  }

  getWeather(e) {
    e.preventDefault();
    const { projectRef, createdAt } = this.state.report;

    if (createdAt && projectRef) {
      this.props.actions.fetchWeather(projectRef._id || projectRef, createdAt);
    }
  }

  getPath(path) {
    return get(this.state.report, path);
  }

  unlockReport(event) {
    if (event) {
      event.preventDefault();
    }
    this.props.actions.editReport(
      { isLockedBy: null, unlockRequest: false },
      this.props.params.id
    );
  }

  addRow(path, keys) {
    return () => {
      const newItem =
        typeof keys === 'string'
          ? keys
          : keys.reduce(
              (obj, key) => set(obj, key, key === 'mir' ? false : ''),
              {}
            );

      const report = {
        ...this.state.report,
        [path]: [...this.state.report[path], newItem]
      };
      this.setState({ report });
    };
  }

  replacePath(path, content) {
    this.setState({ report: set({ ...this.state.report }, path, content) });
  }

  handleTabKeys(e) {
    if (e.keyCode === 9) {
      this.inputAfterTypeahead.focus();
    }
  }

  delImage(path, idx) {
    return () => {
      const nextState = { ...this.state };
      const thisMedia = nextState.report[path][idx];
      const { mediaRemovals, uploadProgress } = this.state;

      if (!thisMedia) return;

      thisMedia.mediaRef = undefined;
      thisMedia.url = '';
      nextState.mediaRemovals = mediaRemovals + 1;

      if (uploadProgress && Array.isArray(uploadProgress)) {
        nextState.uploadProgress = [...uploadProgress];
        nextState.uploadProgress.splice(idx, 1);
      }

      this.setState(nextState);
    };
  }

  delRow(path, idx) {
    return () => {
      const report = { ...this.state.report };
      const parent = get(report, path);
      parent.splice(idx, 1);

      const nextState = { report };
      if (path === 'media') {
        const { mediaRemovals, uploadProgress } = this.state;
        nextState.mediaRemovals = mediaRemovals + 1;

        if (uploadProgress && Array.isArray(uploadProgress)) {
          nextState.uploadProgress = [...uploadProgress];
          nextState.uploadProgress.splice(idx, 1);
        }
      }

      this.setState(nextState);
    };
  }

  // eslint-disable-next-line class-methods-use-this
  updateTotalHours(nextState) {
    const { sevensonLabor, subcontractorLabor, oldRef } = nextState.report;

    nextState.report.totalJobHours = [
      ...subcontractorLabor.map(({ hours }) => parseFloat(hours || 0)),
      ...sevensonLabor.map(({ hours, personnel }) => {
        const count = oldRef ? 1 : personnel.count;
        return parseFloat(hours || 0) * parseInt(count || 0, 10);
      })
    ].reduce((a, b) => a + b);

    return nextState;
  }

  markAsUsed() {
    this.setState({ justLoaded: false });
  }

  handleInputChange(key, value = 'value') {
    return e => {
      let nextState;

      if (e?.target?.type === 'checkbox') {
        nextState = {
          report: set({ ...this.state.report }, key, !!e.target.checked)
        };
      } else {
        nextState = {
          report: set({ ...this.state.report }, key, e._id || e.target[value])
        };
      }

      nextState.justLoaded = false;

      if (key === 'projectRef') {
        nextState.project = this.props.projects.data.find(p => p._id === e._id);
        reportNumber(nextState);
        this.updateTotalHours(nextState);
      } else if (/sevensonLabor|subcontractorLabor/.test(key)) {
        this.updateTotalHours(nextState);
      }

      if (this.state.editForm) {
        if (!this.state.hasLocked) {
          nextState.hasLocked = true;
          this.props.actions.lockReport(this.props.params.id);
        } else {
          this.refreshLock();
        }
      }

      this.setState(nextState);
    };
  }

  handleSubmit(e, isInterval) {
    if (e) e.preventDefault();

    const { report, errorState: eState } = this.state;
    const { projectRef, media, sevensonLabor } = report;

    if (!projectRef.length) {
      if (isInterval) return;
      this.setState({ errorState: [...eState, 'Error: Project not set.'] });
    } else if (media.length < 4 && report.isPublished) {
      if (isInterval) return;
      this.setState({
        errorState: [
          ...eState,
          'Need at least four images to publish a report.'
        ]
      });
    } else if (sevensonLabor < 1) {
      if (isInterval) return;
      this.setState({
        errorState: [...eState, 'Need at least one Sevenson Labor report.']
      });
    } else if (this.state.editForm) {
      this.props.actions.editReport(
        this.state.report,
        this.props.params.id,
        isInterval
      );
    } else {
      this.props.actions.createReportSubmit(this.state.report);
    }
  }

  render() {
    const {
      createReport,
      location,
      weather,
      projects: { data: projects }
    } = this.props;
    const fetchReportStatus = this.props.report;
    const { authLevel, authOverrides } = this.props.user.data;
    const { report, errorState, editForm } = this.state;
    const { isPublished } = report;

    let returnTo = false;
    if (location.state && location.state.returnTo) {
      // eslint-disable-next-line prefer-destructuring
      returnTo = location.state.returnTo;
    }

    let alert;
    if (createReport.error && createReport.error.message) {
      alert = (
        <Alert delay={5000} type="danger">
          <i>
            <IoAlertCircled />
          </i>
          &nbsp;
          {createReport.error.message}
        </Alert>
      );
      window.setTimeout(browserHistory.push, 5e3, returnTo || '/reports');
    }

    if (weather.error && weather.error.message) {
      alert = (
        <Alert delay={5000} type="danger">
          <i>
            <IoAlertCircled />
          </i>
          &nbsp;
          {weather.error.message}
        </Alert>
      );
    }

    if (fetchReportStatus.error && fetchReportStatus.error.message) {
      alert = (
        <Alert delay={5000} type="danger">
          <i>
            <IoAlertCircled />
          </i>
          &nbsp;
          {fetchReportStatus.error.message}
        </Alert>
      );
    }

    let formWarning;
    if (errorState.length) {
      formWarning = errorState.map((error, idx) => (
        // eslint-disable-next-line react/no-array-index-key
        <Alert key={idx} delay={5000} type="danger">
          <i>
            <IoAlertCircled />
          </i>
          &nbsp;
          {error}
        </Alert>
      ));
    }

    const defaultInputProps = {
      valueGetter: this.getValueFromId,
      onChange: this.handleInputChange
    };

    const defaultRepeaterProps = {
      data: report,
      addRow: this.addRow,
      delRow: this.delRow,
      delImage: this.delImage,
      onChange: this.handleInputChange,
      valueGetter: this.getValueFromId,
      getPath: this.getPath,
      replacePath: this.replacePath
    };

    const typeaheadClasses = {
      input: 'text-input-standard',
      results: this.state.justLoaded ? 'no-display' : 'add-report-results',
      listItem: 'add-report-list-item',
      listAnchor: 'add-report-list-anchor',
      hover: 'add-report-hover',
      typeahead: 'add-report-typeahead',
      resultsTruncated: this.state.justLoaded
        ? 'no-display'
        : 'add-report-results'
    };

    const getWeatherAllowed = report.createdAt && report.projectRef;

    projects.forEach(project => {
      project.numberName = `${project.number} — ${project.name}`;
    });

    return (
      <div className="create-edit-modal">
        <Loader isFetching={createReport.isFetching} />
        {alert}
        {formWarning}
        <p className="modal-header">
          {!editForm ? 'Create Report' : 'Edit Report'}
        </p>
        <div className="modal-body">
          <form
            id="create-report-form"
            className="modal-form"
            onSubmit={this.handleSubmit}
          >
            <div className="modal-form-block">
              <TextInput
                labelText="Report Date"
                inputId="createdAt"
                inputType="date"
                {...defaultInputProps}
              />
              {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
              <div className="text-input-block" tabIndex="0">
                <p className="text-input-standard-label">Project</p>
                <Typeahead
                  required
                  closeDropdownOnBlur
                  id="projectRef"
                  ref={typeahead => (this.projTypeahead = typeahead)}
                  options={projects}
                  maxVisible={6}
                  placeholder="Enter Project Name"
                  filterOption="numberName"
                  displayOption="numberName"
                  customClasses={typeaheadClasses}
                  onOptionSelected={this.handleInputChange('projectRef')}
                  onChange={this.markAsUsed}
                />
              </div>
              <button
                className="form-button weather-button"
                onClick={this.getWeather}
                disabled={!getWeatherAllowed}
              >
                Get Weather
              </button>
              <label
                htmlFor="weather.override"
                className="text-input-standard-label checkbox-label"
              >
                Weather Override? &nbsp;
              </label>
              <input
                id="weather.override"
                type="checkbox"
                checked={this.getValueFromId('weather.override')}
                onChange={this.handleInputChange('weather.override', 'checked')}
              />
              <div className="modal-form-block">
                <TextInput
                  ref={input => {
                    this.inputAfterTypeahead = input;
                  }}
                  placeholder="[Please select a project]"
                  labelText="Report Number"
                  inputId="number"
                  inputType="number"
                  {...defaultInputProps}
                />
              </div>
              <div className="modal-form-block">
                <TextInput
                  disabled={!report.weather.override}
                  placeholder="[Select project & click Get Weather]"
                  labelText="Temperature High"
                  inputId="weather.tempHigh"
                  inputType="number"
                  {...defaultInputProps}
                />
                <TextInput
                  disabled={!report.weather.override}
                  placeholder="[Select project & click Get Weather]"
                  labelText="Temperature Low"
                  inputId="weather.tempLow"
                  inputType="number"
                  {...defaultInputProps}
                />
                <TextInput
                  disabled={!report.weather.override}
                  placeholder="[Select project & click Get Weather]"
                  labelText="Precipitation"
                  inputId="weather.precipitation"
                  inputType="number"
                  {...defaultInputProps}
                />
                <TextInput
                  disabled={!report.weather.override}
                  placeholder="[Select project & click Get Weather]"
                  labelText="Weather Conditions"
                  inputId="weather.conditions"
                  inputType="text"
                  {...defaultInputProps}
                />
              </div>
              <p className="alert-text">
                ***If you have having trouble uploading images, please ensure
                that you do not have an adblocker turned on before requesting
                support.***
              </p>
              <Repeater
                {...defaultRepeaterProps}
                photoUploader
                path="media"
                tableID="media-repeater"
                title="Photos"
                mediaRemovals={this.state.mediaRemovals}
                uploaderProgresses={this.state.uploadProgress}
                fields={[
                  {
                    id: 'url',
                    title: 'Photo',
                    inputType: 's3Uploader',
                    signingUrl: '/api/s3/media/sign',
                    uploaderFns: {
                      onError: this.onUploadError,
                      onFinish: this.onUploadFinish,
                      onProgress: this.onUploadProgress
                    },
                    required: isPublished
                  },
                  { id: 'title', title: 'Title', required: isPublished },
                  {
                    id: 'description',
                    title: 'Description',
                    multiline: true,
                    required: isPublished
                  }
                ]}
              />
              <TextInput
                required
                multiLine
                labelText="Work Performed by Sevenson, Inc."
                inputId="workPerformedBySevenson"
                {...defaultInputProps}
              />
              <TextInput
                required
                multiLine
                labelText="Work Performed by Subcontractors"
                inputId="workPerformedBySubcontractors"
                {...defaultInputProps}
              />
              <TextInput
                required
                multiLine
                labelText="Safety Remarks"
                inputId="safetyRemarks"
                {...defaultInputProps}
              />
              <TextInput
                required
                multiLine
                labelText="Planned Activities"
                inputId="plannedActivities"
                {...defaultInputProps}
              />
              <TextInput
                required
                multiLine
                labelText="Remarks/Verbal Directives"
                inputId="remarksOrVerbalDirectives"
                {...defaultInputProps}
              />
              <TextInput
                required
                multiLine
                labelText="Equipment/Materials Delivered to Site"
                inputId="equipmentOrMaterialsDeliveredToSite"
                {...defaultInputProps}
              />
              <Repeater
                {...defaultRepeaterProps}
                path="contractorEquipment"
                tableID="CE-list"
                title="Contractor Equipment List"
                fields={[
                  {
                    id: 'name',
                    title: 'Equipment Name'
                  },
                  {
                    id: 'makeAndModel',
                    title: 'Make Model'
                  },
                  {
                    id: 'mobDate',
                    title: 'Mob Date',
                    inputType: 'date'
                  },
                  {
                    id: 'demobDate',
                    title: 'Demob Date',
                    inputType: 'date'
                  },
                  {
                    id: 'quantity',
                    title: 'Quantity',
                    inputType: 'number'
                  },
                  {
                    id: 'equipmentNumber',
                    title: 'Equipment Number'
                  },
                  {
                    id: 'mir',
                    title: 'MIR',
                    inputType: 'checkbox'
                  }
                ]}
              />
              <Repeater
                {...defaultRepeaterProps}
                path="rentalEquipment"
                tableID="RE-list"
                title="Rental Equipment List"
                fields={[
                  {
                    id: 'name',
                    title: 'Rental Equipment'
                  },
                  {
                    id: 'makeAndModel',
                    title: 'Make Model'
                  },
                  {
                    id: 'description',
                    title: 'Description'
                  },
                  {
                    id: 'mobDate',
                    title: 'Mob Date',
                    inputType: 'date'
                  },
                  {
                    id: 'quantity',
                    title: 'Quantity',
                    inputType: 'number'
                  },
                  {
                    id: 'equipmentNumber',
                    title: 'Equipment Number'
                  },
                  {
                    id: 'hours',
                    title: 'Hours',
                    inputType: 'number'
                  },
                  {
                    id: 'mir',
                    title: 'MIR',
                    inputType: 'checkbox'
                  }
                ]}
              />
              <TextInput
                required
                multiLine
                labelText="Miscellaneous Information"
                inputId="miscellaneousInfo"
                {...defaultInputProps}
              />
              {this.props.report.data && this.props.report.data.isOld ? (
                <p className="alert-text">
                  ***You are on an OLD REPORT. Thus, no math will be performed
                  for you. For example, if you enter 3 Engineers at 8 Hours
                  Worked, this will calculate as 8 hours in the report total.***
                </p>
              ) : (
                <p className="alert-text">
                  ***You are on a NEW REPORT. Thus, the hours will multiply
                  automatically. For example, if you enter 3 Engineers at 8
                  Hours Worked, this will calculate as 3*8 = 24 hours in the
                  report total.***
                </p>
              )}
              <Repeater
                {...defaultRepeaterProps}
                path="sevensonLabor"
                title="Labor – Sevenson, Inc."
                tableID="SES-labor"
                fields={[
                  {
                    id: 'personnel.count',
                    title: 'Number of Personnel',
                    inputType: 'number',
                    required: true
                  },
                  {
                    id: 'personnel.type',
                    title: 'Type of Personnel',
                    required: true
                  },
                  {
                    id: 'hours',
                    title: 'Hours Worked',
                    inputType: 'number',
                    required: true
                  }
                ]}
              />
              <Repeater
                {...defaultRepeaterProps}
                path="subcontractorLabor"
                title="Labor – Subcontractor"
                tableID="sub-labor"
                fields={[
                  {
                    id: 'name',
                    title: 'Sub Contractor Name',
                    required: true
                  },
                  {
                    id: 'position',
                    title: 'Position',
                    required: true
                  },
                  {
                    id: 'hours',
                    title: 'Hours Worked',
                    inputType: 'number',
                    required: true
                  }
                ]}
              />
              {/* TODO: Job Hours should autofill. */}
              <TextInput
                disabled
                labelText="Total Report Hours"
                placeholder="[Enter Sevenson, Inc./subcontractor hours above]"
                inputId="totalJobHours"
                type="number"
                {...defaultInputProps}
              />
              <Repeater
                {...defaultRepeaterProps}
                path="attachments"
                title="Attachments"
                tableID="attachment-list"
                uploaderProgresses={this.state.uploadProgress}
                fields={[
                  {
                    id: 'url',
                    title: 'Attachment',
                    inputType: 's3Uploader',
                    signingUrl: '/api/s3/attachments/sign',
                    uploaderFns: {
                      preprocess: this.preprocess,
                      onError: this.onUploadError,
                      onFinish: this.onUploadFinish,
                      onProgress: this.onUploadProgress
                    },
                    required: true
                  },
                  { id: 'name', title: 'Name', required: true }
                ]}
              />
              <TextInput
                disabled
                inputId="author.name.first"
                labelText="First Name"
                {...defaultInputProps}
              />
              <TextInput
                disabled
                inputId="author.name.last"
                labelText="Last Name"
                {...defaultInputProps}
              />
              <Repeater
                {...defaultRepeaterProps}
                single
                tableID="email-list"
                path="emailRecipients"
                title="People to Email Report To"
                fields={[
                  { title: 'Email Address', inputType: 'email', required: true }
                ]}
              />
              <div className="text-input-block">
                <label
                  htmlFor="isPublished"
                  className="text-input-standard-label checkbox-label"
                >
                  Publish Report? &nbsp;
                </label>
                {/* eslint-disable-next-line no-nested-ternary */}
                {editForm &&
                this.props.report.data &&
                this.props.report.data.isPublished ? (
                  <div>
                    <input id="isPublished" type="checkbox" checked readOnly />
                    <p className="published-info">
                      Report is already published. Saving will update the
                      published report.
                    </p>
                  </div>
                ) : authLevel >= 1 ||
                  (authOverrides && authOverrides.reports.publish) ? (
                  <input
                    id="isPublished"
                    type="checkbox"
                    checked={this.getValueFromId('isPublished')}
                    onChange={this.handleInputChange('isPublished', 'checked')}
                  />
                ) : (
                  <div>
                    <input
                      id="isPublished"
                      type="checkbox"
                      checked={false}
                      disabled
                    />
                    <p className="published-info">
                      You do not have permission to publish reports. An
                      administrator can assign you this permission.
                    </p>
                  </div>
                )}
              </div>
              <div className="form-submit-buttons">
                {returnTo && (
                  <Link to={returnTo}>
                    <div className="standard-button secondary-button">
                      Cancel
                    </div>
                  </Link>
                )}
                <input
                  className={`standard-button ${
                    isPublished ? 'primary-button' : 'secondary-button'
                  }`}
                  type="submit"
                  value={isPublished ? 'Publish' : 'Save Draft'}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({
  createReport,
  user,
  projects,
  weather,
  report
}) => ({
  createReport,
  user,
  projects,
  weather,
  report
});

const mapDispatchToProps = dispatch => {
  const actions = {
    createReportSubmit,
    createReportReset,
    editReport,
    lockReport,
    fetchProjects,
    fetchWeather,
    fetchReport
  };
  return { actions: bindActionCreators(actions, dispatch) };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateReport);
