import React from 'react';
import Diff from './Diff';


function diffsExtended(diffs) {
  return (
    <span>
      <h2 className="diffs-block diffs-history">History</h2>
      {diffs
        .map((diffObj, idx) => {
          return <Diff key={diffObj._id} {...{ diffObj, idx }} />;
        })
        .reverse()}
    </span>
  );
}

function diffsMain(diffs) {
  const lastIdx = diffs.length - 1;

  const current = diffs.slice(-1)[0];
  const remaining = diffs.slice(0, -1);

  return (
    <span>
      <Diff current idx={lastIdx} diffObj={current} />
      {remaining.length > 0 && diffsExtended(remaining)}
    </span>
  );
}

export default ({ diffs }) => {
  const diffObjs = diffs.filter(({ createdAt, diff, version }) => {
    return createdAt && diff && Number.isFinite(version);
  });

  return (
    <div className="diffs-list">
      <h2 className="diffs-block diffs-current">Current Version</h2>
      {diffObjs.length > 0 ? diffsMain(diffObjs) : 'This is the original version.'}
    </div>
  );
};
