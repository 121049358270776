import * as actions from '../constants/user';
import combineName from '../etc/combineName';

const initialState = {
  data: null,
  isFetching: false,
  lastUpdated: null,
  error: {}
};

const setUserCtx = user => {
  const ctx = user ? { _id: user._id, email: user.email, username: user.username } : undefined;
  ctx && (ctx.name = combineName(user.name));

  window.Raven && window.Raven.setUserContext(ctx);
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.LOGIN_REQUEST:
    case actions.LOGOUT_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: {}
      };
    case actions.LOGIN_FAILURE:
    case actions.LOGOUT_FAILURE:
      return {
        ...state,
        isFetching: false,
        lastUpdated: action.receivedAt,
        error: action.error
      };
    case actions.LOGIN_SUCCESS:
    case actions.LOGOUT_SUCCESS:
      setUserCtx(action.payload);

      return {
        ...state,
        data: action.payload,
        isFetching: false,
        lastUpdated: action.receivedAt,
        error: {}
      };
    default:
      return state;
  }
};
