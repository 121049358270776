import React from 'react';

export default class OrderWizardNotes extends React.Component {
  render() {
    const { COTextInput, edit, comments } = this.props;

    return (
      <div>
        <COTextInput
          multiLine
          edit={edit}
          labelText="Additional Notes (optional)"
          name="reqn.notes"
          commentExist={comments.findIndex(c => c.position === 'reqn.notes') > -1}
        />
      </div>
    );
  }
}
