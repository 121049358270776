import React from 'react';
import classNames from 'classnames';

const paymentOptions = [
  {
    key: 'Net Days',
    value: 'netDays'
  },
  {
    key: 'Discount and Net Days',
    value: 'discountAndNetDays'
  },
  {
    key: 'Subcontract Retainer',
    value: 'subContractRetainer'
  },
  {
    key: 'Other',
    value: 'other'
  }
];

const inputProps = { type: 'number', className: 'payment-rate-input text-input-standard' };

export default class OrderWizardPaymentTerms extends React.Component {
  render() {
    const {
      COTextInput,
      handleInputChange,
      getValue,
      reqn,
      edit,
      openComment,
      comments
    } = this.props;

    const commentExist = field => comments.findIndex(c => c.position === field) > -1;
    const commentClass = field =>
      classNames('add-comment', {exists: commentExist(field)});

    return (
      <div>
        <select
          id="payment-term-select"
          form="create-user-form"
          className="standard-select"
          name="reqn.paymentTerms.selectedType"
          value={getValue('reqn.paymentTerms.selectedType')}
          onChange={handleInputChange}
        >
          {paymentOptions.map(payType => (
            <option value={payType.value} key={payType.value}>
              {payType.key}
            </option>
          ))}
        </select>
        {edit && (
          <div
            className={commentClass('reqn.paymentTerms.selectedType')}
            onClick={e =>
              openComment(
                { labelText: 'Payment Terms', position: 'reqn.paymentTerms.selectedType' },
                e
              )
            }
          />
        )}
        <div className="payment-terms-page">
          {reqn.paymentTerms.selectedType === 'subContractRetainer' && (
            <div>
              <COTextInput name="reqn.paymentTerms.retentionRate" {...inputProps} />
              <span>
                &#37; net 10 days after Contractor receives payment for Vendor&#8217;s work from
                Owner. Balance&#58; 45 days after Owner&#8217;s acceptance of work and payment of
                retention to Contractor.
              </span>
            </div>
          )}
          {reqn.paymentTerms.selectedType === 'netDays' && (
            <div>
              <span>Net&nbsp;</span>
              <COTextInput name="reqn.paymentTerms.netDays" {...inputProps} />
              <span>&nbsp;Days</span>
            </div>
          )}
          {reqn.paymentTerms.selectedType === 'discountAndNetDays' && (
            <div>
              <COTextInput name="reqn.paymentTerms.discountRate" {...inputProps} />%&nbsp;
              <COTextInput name="reqn.paymentTerms.discountDays" {...inputProps} />
              <span>&nbsp;Days, Net&nbsp;</span>
              <COTextInput name="reqn.paymentTerms.netDays" {...inputProps} />
              <span>&nbsp;Days</span>
            </div>
          )}
          {reqn.paymentTerms.selectedType === 'other' && (
            <div>
              <COTextInput {...inputProps} multiLine name="reqn.paymentTerms.other" type="text" />
            </div>
          )}
        </div>
      </div>
    );
  }
}
