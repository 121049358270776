import RECORD_FILTER_DRAWER from '../constants/setFilterDrawer';

export const setDrawer = drawer => ({
  drawer,
  type: RECORD_FILTER_DRAWER
});

export const recordFilterDrawer = drawer => dispatch => {
  dispatch(setDrawer(drawer));
};
