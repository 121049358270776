import React from 'react';
import ReactS3Uploader from 'react-s3-uploader';
import { Typeahead } from 'react-typeahead';
import { Link, browserHistory } from 'react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import get from 'lodash/get';
import set from 'lodash/set';

import TextInput from '../../components/shared/forms/TextInput';
import ProgressBar from '../../components/shared/ProgressBar';

import { fetchMedia } from '../../actions/media';
import { fetchProjects } from '../../actions/projects';
import { createMediaAction, clear } from '../../actions/createMedia';
import { fetchSingleMedia } from '../../actions/fetchMedia';
import { editMedia } from '../../actions/editMedia';

require('../../sass/containers/Reports/CreateReport.scss');
require('../../sass/containers/Media/CreateMedia.scss');
require('../../sass/components/shared/forms/Repeater.scss');

class CreateMedia extends React.Component {
  constructor(props) {
    super(props);

    this.state = { media: [], files: {}, error: {}, totalProgress: null };

    this.onUploadProgress = this.onUploadProgress.bind(this);
    this.onUploadFinish = this.onUploadFinish.bind(this);
    this.onUploadError = this.onUploadError.bind(this);

    this.getValueFromId = this.getValueFromId.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  componentWillMount() {
    if (this.props.route.path === 'media/:id/edit') {
      this.setState({ editForm: true });
      this.props.actions.fetchSingleMedia(this.props.params);
    }
    this.props.actions.fetchProjects({ limit: -1 });
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.fetchedMedia &&
      this.props.fetchedMedia.data !== nextProps.fetchedMedia.data
    ) {
      const fetchedMedia = nextProps.fetchedMedia.data;
      this.setState({ media: [fetchedMedia] });
    }
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.createMedia.submitted) {
      this.props.actions.fetchMedia();
      browserHistory.push('/media');
      return false;
    }
    return true;
  }

  componentWillUnmount() {
    this.props.actions.clear();
  }

  onUploadError(error, { name }) {
    this.setState({ error: { ...error, fileName: name } });
  }

  onUploadFinish(res, { name }) {
    const { projectRef } = this.state;
    const {
      user: { data: user }
    } = this.props;

    const match = res.signedUrl.match(/(.*)\?/);
    const url = match && match[1];

    const publicUrl = res.publicUrl;

    if (url) {
      const nextState = { ...this.state };
      nextState.files[name].url = url;
      nextState.files[name].publicUrl = publicUrl;
      nextState.media.push({
        url,
        publicUrl,
        projectRef,
        title: '',
        author: { userRef: user._id, name: user.name }
      });
      this.setState(nextState);
    } else {
      console.error(
        'Something has happened in onUploadFinish that should not have.'
      );
    }
  }

  onUploadProgress(progress, status, { name }) {
    const files = { ...this.state.files };

    files[name] = files[name] || {};
    files[name].status = status;
    files[name].progress = progress;

    const filesArray = Object.values(files);
    const totalProgress =
      filesArray.reduce((acc, f) => acc + f.progress, 0) / filesArray.length;

    this.setState({ files, totalProgress });
  }

  getValueFromId(id) {
    return get(this.state, id);
  }

  handleSubmit(e) {
    e.preventDefault();
    const { editForm, media } = this.state;
    if (editForm && media[0]) {
      this.props.actions.editMedia(
        { title: media[0].title },
        this.props.params.id
      );
    } else {
      // TODO: handle error when no Project Ref.
      if (!this.state.media.length) {
        return;
      }
      this.props.actions.createMediaAction(this.state.media);
    }
  }

  handleInputChange(key) {
    return e => {
      let nextState;
      if (key === 'projectRef') {
        const projectRef = e._id;
        nextState = { ...this.state, projectRef };
        nextState.media = nextState.media.map(medium => ({
          ...medium,
          projectRef
        }));
      } else {
        nextState = set({ ...this.state }, key, e.target.value);
      }
      this.setState(nextState);
    };
  }

  render() {
    const {
      location,
      projects: { data: projects }
    } = this.props;
    const { media, editForm } = this.state;

    let returnTo = false;
    if (location.state && location.state.returnTo) {
      // eslint-disable-next-line prefer-destructuring
      returnTo = location.state.returnTo;
    }

    const { totalProgress } = this.state;

    const typeaheadClasses = {
      input: 'text-input-standard',
      results: 'typeahead-results',
      listItem: 'typeahead-list-item',
      listAnchor: 'typeahead-list-anchor',
      hover: 'typeahead-item-hover',
      typeahead: 'standard-typeahead',
      resultsTruncated: 'typeahead-results'
    };

    return (
      <div className="create-edit-modal">
        <p className="modal-header">
          {!editForm ? 'Upload Media' : 'Edit Media'}
        </p>
        {!!totalProgress && totalProgress > 0 && (
          <ProgressBar hidePercentage percentage={totalProgress} />
        )}
        <div className="modal-body">
          <form
            onSubmit={this.handleSubmit}
            className="modal-form media-form"
            id="create-media-form"
          >
            <div className="input-fields">
              <div className="modal-form-block">
                {!media.length && (
                  <div style={{ width: '100%' }}>
                    <ReactS3Uploader
                      multiple
                      accept="image/*"
                      onError={this.onUploadError}
                      onFinish={this.onUploadFinish}
                      onProgress={this.onUploadProgress}
                      signingUrl="/api/s3/media/sign"
                      uploadRequestHeaders={{ 'x-amz-acl': 'private' }}
                      className="add-media-file-input"
                    />
                  </div>
                )}
                {!editForm && (
                  <Typeahead
                    required
                    id="projectRef"
                    options={projects}
                    maxVisible={6}
                    placeholder="Enter Project Name"
                    filterOption="name"
                    displayOption="name"
                    customClasses={typeaheadClasses}
                    onOptionSelected={this.handleInputChange('projectRef')}
                    closeDropdownOnBlur
                  />
                )}
                {media.length ? (
                  <div className="repeater-container">
                    <table className="repeater-table">
                      <thead className="repeater-header">
                        <tr>
                          <th className="repeater-column-header image-header">
                            Image
                          </th>
                          <th className="repeater-column-header">Title</th>
                        </tr>
                      </thead>
                      <tbody className="repeater-body">
                        {media.map(({ _id, url, publicUrl }, idx) => (
                          <tr key={_id || idx}>
                            <td>
                              <a
                                href={publicUrl || url}
                                rel="noopener noreferrer"
                                target="_blank"
                                tabIndex="-1"
                              >
                                {/* eslint-disable-next-line jsx-a11y/alt-text */}
                                <img
                                  src={publicUrl || url}
                                  role="presentation"
                                  className="repeater-preview-img create-media-upload"
                                />
                              </a>
                            </td>
                            <td className="create-media-title">
                              <TextInput
                                required
                                inputId={`media[${idx}].title`}
                                onChange={this.handleInputChange}
                                valueGetter={this.getValueFromId}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="form-submit-buttons">
              {returnTo && (
                <Link to={returnTo}>
                  <div className="standard-button secondary-button">Cancel</div>
                </Link>
              )}
              <input
                className="standard-button primary-button"
                type="submit"
                value="Save"
              />
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ user, createMedia, fetchedMedia, projects }) => ({
  user,
  createMedia,
  fetchedMedia,
  projects
});

const mapDispatchToProps = dispatch => {
  const actions = {
    createMediaAction,
    clear,
    fetchMedia,
    fetchSingleMedia,
    editMedia,
    fetchProjects
  };
  return { actions: bindActionCreators(actions, dispatch) };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateMedia);
