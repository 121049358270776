import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import '../../sass/components/shared/ErrorPage.scss';

const defaultBodyText =
  'Something’s gone wrong. Our team has been notified and we’re ' +
  'working to fix the issue. Please tell us more about what happened.';

export default class ErrorPage extends React.Component {
  static propTypes = {
    title: PropTypes.string,
    bodyText: PropTypes.string,
    className: PropTypes.string,
    buttonText: PropTypes.string,
    hasButton: PropTypes.bool,

    errorPop: PropTypes.func,
    Raven: PropTypes.func
  };

  static defaultProps = {
    title: 'We’re sorry.',
    bodyText: defaultBodyText,
    buttonText: 'Fill Out A Report',
    className: '',
    hasButton: true,
    errorPop: null,
    Raven: null
  };

  constructor(props) {
    super(props);
    this.errorPop = this.errorPop.bind(this);
  }

  errorPop() {
    const { errorPop, Raven, location } = this.props;
    const { query } = location || {};
    const { eid } = query || {};

    if (errorPop) {
      this.props.errorPop();
    } else {
      const raven = Raven || global.Raven;
      const eventId = eid || raven.lastEventId();
      raven && eventId && raven.showReportDialog({ eventId });
    }
  }

  render() {
    const { title, bodyText, buttonText, className, hasButton, children } = this.props;

    return (
      <div className={classNames('error-page', className)}>
        <div className="error-notice">
          <h1 className="error-title">{title}</h1>
          {children || <p>{bodyText}</p>}
          {hasButton && (
            <button className="standard-button primary-button" onClick={this.errorPop}>
              {buttonText}
            </button>
          )}
        </div>
      </div>
    );
  }
}
