import React from 'react';
import { Link } from 'react-router';

const { Typeahead } = require('react-typeahead');

export default class OrderWizardVendorInfo extends React.Component {
  componentWillReceiveProps(nextProps) {
    if (this.props.typeaheadVal !== nextProps.typeaheadVal) {
      this.typeahead &&
        this.typeahead.setEntryText(nextProps.typeaheadVal || 'Number not yet assigned');
    }
  }

  render() {
    const {
      COTextInput,
      vendors,
      handleVendorSelect,
      typeaheadVal,
      errors = [],
      edit,
      comments,
      user
    } = this.props;

    const { authOverrides } = user.data;
    const canAddNewVendors = authOverrides && authOverrides.vendors.create;

    const typeaheadClasses = {
      input: `text-input-standard ${errors.vendor && 'input-error'}`,
      results: 'typeahead-results',
      listItem: 'typeahead-list-item',
      listAnchor: 'typeahead-list-anchor',
      hover: 'typeahead-item-hover',
      typeahead: 'standard-typeahead',
      resultsTruncated: 'typeahead-results'
    };

    return (
      <div>
        <div className="top-bar">
          <Typeahead
            closeDropdownOnBlur
            id="vendorRef"
            options={vendors.filter(x => x && x.numberStr)}
            maxVisible={6}
            filterOption="numberStr"
            displayOption="numberedName"
            customClasses={typeaheadClasses}
            onOptionSelected={handleVendorSelect}
            value={typeaheadVal}
            placeholder="Type Vendor number"
            ref={el => (this.typeahead = el)}
          />
          {canAddNewVendors && (
            <Link
              to={{
                pathname: '/vendors/create',
                state: {
                  modal: true,
                  returnTo: '/orders/create',
                  extraClass: 'add-edit-modal add-vendor-modal'
                }
              }}
              className="standard-button outline-button"
            >
              Add New
            </Link>
          )}
        </div>
        <p className="form-section-title">Vendor Information</p>
        <div className="input-fields">
          <div className="modal-form-block">
            <COTextInput
              readOnly
              labelText="Name"
              name="vendor"
              edit={edit}
              commentExist={comments.findIndex(c => c.position === 'vendor') > -1}
            />
          </div>
          <div className="modal-form-block">
            <COTextInput
              readOnly
              labelText="Representative Name"
              name="representative.name"
              edit={edit}
              commentExist={comments.findIndex(c => c.position === 'representative.name') > -1}
            />
            <COTextInput readOnly labelText="Title" name="representative.title" />
          </div>
          <div className="modal-form-block">
            <COTextInput readOnly type="tel" labelText="Phone" name="representative.phone" />
            <COTextInput readOnly type="email" labelText="Email" name="representative.email" />
          </div>
        </div>
      </div>
    );
  }
}
