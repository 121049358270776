import axios from 'axios';
import * as types from '../../constants/report/reportView';
import transAxoisErr from '../../etc/axios/transformAxiosError';

export const fetchReportRequest = () => ({
  type: types.FETCH_REPORT_REQUEST,
  data: {}
});

export const fetchReportSuccess = ({ data }) => ({
  type: types.FETCH_REPORT_SUCCESS,
  payload: data,
  receivedAt: Date.now()
});

export const fetchReportFailure = error => ({
  type: types.FETCH_REPORT_FAILURE,
  error,
  receivedAt: Date.now()
});

export const fetchReport = ({ id }) => (dispatch) => {
  dispatch(fetchReportRequest());
  return axios
    .get(`/report/${id}`)
    .then(res => dispatch(fetchReportSuccess(res.data)))
    .catch(error => dispatch(fetchReportFailure(transAxoisErr(error))));
};

export const makePdfRequest = () => ({type: types.MAKE_PDF_REQUEST});

export const makePdfSuccess = ({ data }) => ({
  type: types.MAKE_PDF_SUCCESS,
  payload: data,
  receivedAt: Date.now()
});

export const makePdfFailure = error => ({
  type: types.MAKE_PDF_FAILURE,
  error,
  receivedAt: Date.now()
});

export const makePdf = id => (dispatch) => {
  dispatch(makePdfRequest());
  return axios
    .get(`/report/${id}/make-pdf`)
    .then(res => dispatch(makePdfSuccess(res.data)))
    .catch(error => dispatch(makePdfFailure(transAxoisErr(error))));
};

export const resendEmailsRequest = () => ({type: types.RESEND_EMAILS_REQUEST});

export const resendEmailsSuccess = ({ data }) => ({
  type: types.RESEND_EMAILS_SUCCESS,
  payload: data,
  receivedAt: Date.now()
});

export const resendEmailsFailure = error => ({
  type: types.RESEND_EMAILS_FAILURE,
  error,
  receivedAt: Date.now()
});

export const resendEmails = id => (dispatch) => {
  dispatch(resendEmailsRequest());
  return axios
    .get(`/report/${id}/resend-emails`)
    .then(res => dispatch(resendEmailsSuccess(res.data)))
    .catch(error => dispatch(resendEmailsFailure(transAxoisErr(error))));
};
