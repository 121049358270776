/* eslint-disable jsx-a11y/anchor-has-content,no-underscore-dangle */
/* eslint-disable react/jsx-indent */
/* eslint "jsx-a11y/no-static-element-interactions": 0 */

import React from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Typeahead } from 'react-typeahead';
import { Link, browserHistory } from 'react-router';
import { bindActionCreators } from 'redux';

import pick from 'lodash/pick';
import pickBy from 'lodash/pickBy';

import IoCloseCircled from 'react-icons/lib/io/close-circled';
import IoAlertCircled from 'react-icons/lib/io/alert-circled';

import { fetchMedia } from '../../actions/media';
import { deleteMedia } from '../../actions/deleteMedia';
import { fetchProjects } from '../../actions/projects';
import Loader from '../../components/shared/Loader';
import Alert from '../../components/shared/Alert';
import Dropdown from '../../components/shared/Dropdown';
import Modal from '../../components/shared/Modal';
import Pagination from '../../components/shared/Pagination';

import '../../sass/containers/Reports/Index.scss';
import '../../sass/containers/Media.scss';

import sevensonLogoText from '../../images/sevenson-logo-text.png';

// eslint-disable-next-line global-require
const URL = global.URL || require('url').URL;

const searchFields = ['projectRef', 'page'];

const getExpiry = () => Date.now() - 1000;

const getProject = (projects, query) => projects.find(project => project._id === query.projectRef);

class Media extends React.Component {
  static need = [fetchMedia, fetchProjects.bind(null, { limit: -1 })];

  constructor(props) {
    super(props);

    this.state = {};

    this.updateSearch = this.updateSearch.bind(this);
    this.changeSort = this.changeSort.bind(this);
    this.searchSubmit = this.searchSubmit.bind(this);
    this.projectFilterSubmit = this.projectFilterSubmit.bind(this);
    this.clearProjectFilter = this.clearProjectFilter.bind(this);
    this.deleteConfirm = this.deleteConfirm.bind(this);
    this.cancelDeleteModal = this.cancelDeleteModal.bind(this);
  }

  componentWillMount() {
    const { media, location } = this.props;
    const { isFetching, lastUpdated } = media;

    if ((!isFetching && lastUpdated < getExpiry()) || this.props.projectId) {
      this.props.actions.fetchMedia((location && location.query) || {});
    }
  }

  componentWillReceiveProps(nextProps) {
    const { actions } = this.props;
    const { location, media } = nextProps;
    const { isFetching, lastUpdated } = media;
    const { query } = location;

    if (!isFetching && !lastUpdated) {
      this.props.actions.fetchMedia(query);
    } else if (this.props.location && this.props.location.query) {
      const oldQuery = pick(this.props.location.query, searchFields);
      const newQuery = pick(query, searchFields);

      const changed = Object.entries(newQuery)
        .map(([key, val]) => oldQuery[key] !== val)
        .filter(Boolean).length;

      const keysRemoved = Object.keys(oldQuery).length > Object.keys(newQuery).length;

      if (!changed && !keysRemoved) return;
      actions.fetchMedia(newQuery);
    }
  }

  updateSearch(props) {
    const { location } = this.props;

    if (!Object.keys(props).includes('page')) {
      props.page = undefined;
    }

    const oldQuery = pick(location.query, searchFields);
    const newQuery = pick(props, searchFields);

    const query = pickBy(Object.assign(oldQuery, newQuery), Boolean);
    browserHistory.push({ ...location, query });
  }

  changeSort(_field) {
    return () => {
      let field = _field;
      if (this.props.location.query.sort === field) {
        field = `-${field}`;
      }
      this.updateSearch({ sort: field });
    };
  }

  searchSubmit(e) {
    e.preventDefault();
    this.updateSearch({ textSearch: this.searchInput.value });
  }

  projectFilterSubmit({ _id }) {
    this.projectDropdown.closeDropdown();
    this.updateSearch({ projectRef: _id });
  }

  clearProjectFilter() {
    this.typeahead._onEscape();
    this.typeahead.setEntryText('');
    this.updateSearch({ projectRef: undefined });
  }

  deleteConfirm = id => () => {
    this.setState({ deleteMediaModal: id });
  };

  cancelDeleteModal = () => {
    this.setState({ deleteMediaModal: undefined });
  };

  deleteMediaSubmit = id => () => {
    this.props.actions.deleteMedia(id);
    this.setState({ deleteMediaModal: undefined });
  };

  /* TODO: add alerts for delete errors like if associated with a report */

  render() {
    const { media, location } = this.props;
    const { query, pathname, search } = location;
    const { authLevel, authOverrides } = this.props.user.data;
    const canAddNewMedia = authLevel >= 1 || (authOverrides && authOverrides.media.create);

    const returnTo = pathname + search;

    let mediaAuth;
    if (authOverrides) {
      mediaAuth = authOverrides.media;
    }

    const allowedToEditMedia = authLevel >= 1 || (authOverrides && mediaAuth.edit);
    const allowedToDeleteMedia = authLevel >= 2 || (authOverrides && mediaAuth.delete);

    const editButtonClasses = classNames({
      'media-button': true,
      'edit-button': true,
      disabled: !allowedToEditMedia
    });

    const deleteButtonClasses = classNames({
      'media-button': true,
      'delete-button': true,
      disabled: !allowedToDeleteMedia
    });

    const projects = this.props.projects.data;
    const projectSpecific = !!query.projectRef;
    const thisProject = (projectSpecific && getProject(projects, query)) || {};

    let alert;
    if (media.error && media.error.message) {
      alert = (
        <Alert delay={5000} type="danger">
          <i>
            <IoAlertCircled />
          </i>
          &nbsp;
          {media.error.message}
        </Alert>
      );
    }

    const mediumEls = media.data.map(medium => (
      <div key={medium._id} className="media-block">
        <div className="image-sizer">
          <Link
            to={{
              pathname: `/media/${medium._id}`,
              state: {
                returnTo,
                modal: true,
                extraClass: 'media-modal'
              }
            }}
            className="media-img"
            style={{ backgroundImage: `url(${medium.resizedUrl})` }}
          />
        </div>
        <div className="media-caption">
          <Link
            to={{
              pathname: `/media/${medium._id}`,
              state: {
                returnTo,
                modal: true,
                extraClass: 'media-modal'
              }
            }}
          >
            <p className="media-title">{medium.title}</p>
          </Link>
          {medium.projectRef && (
            <p>
              <span className="media-subtitle">Project: </span>
              {medium.projectRef.name}
            </p>
          )}
          <p>
            <span className="media-subtitle">Added by: </span>
            {medium.author.name.first} {medium.author.name.last}
          </p>
          <p>{moment(medium.createdAt).format('MMMM Do[,] YYYY')}</p>
        </div>
        <div className="media-button-row">
          <Link
            className={editButtonClasses}
            to={{
              pathname: `/media/${medium._id}/edit`,
              state: {
                returnTo,
                modal: true
              }
            }}
          >
            Edit
          </Link>
          {allowedToDeleteMedia && (
            <button
              disabled={!allowedToDeleteMedia}
              className={deleteButtonClasses}
              onClick={this.deleteConfirm(medium._id)}
            >
              Trash
            </button>
          )}
        </div>
        <a href={medium.url} className="download-media" download={medium.title}>
          Download
        </a>
      </div>
    ));

    let pageCnt;
    let thisPage;

    const { next, prev } = media;

    if (next || prev) {
      try {
        const pagingSearch = new URL(next || prev).searchParams;

        pageCnt = parseInt(pagingSearch.get('pages'), 10) || 1;
        const queryPage = parseInt(pagingSearch.get('page'), 10);

        thisPage = parseInt(query.page, 10) || (next ? queryPage : queryPage + 2);
      } catch (e) {
        console.error(e);
      }
    }

    let noResults;
    if (media.data.length === 0) {
      noResults = 'There are no results to display.';
    }

    const typeaheadClasses = {
      input: 'table-filter-input',
      results: 'table-filter-results',
      listItem: 'table-filter-list-item',
      listAnchor: 'table-filter-list-anchor',
      hover: 'table-filter-hover',
      typeahead: 'table-filter-typeahead',
      resultsTruncated: 'table-filter-results'
    };

    return (
      <div className="media-page-container">
        <Loader isFetching={media.isFetching} />
        {alert}
        {this.state.deleteMediaModal && (
          <Modal
            isOpen
            extraClass="delete-confirm-modal"
            ref={modal => {
              this.deleteModal = modal;
            }}
            toCancel={() => {
              this.setState({ deleteMediaModal: undefined });
            }}
          >
            <div className="modal-text-top">
              <p className="modal-header">Are you sure?</p>
              <p className="modal-subheader">Delete this media?</p>
            </div>
            <div className="modal-body">
              <div className="modal-buttons">
                <button
                  className="modal-button modal-delete"
                  onClick={this.deleteMediaSubmit(this.state.deleteMediaModal)}
                >
                  confirm
                </button>
                <button className="modal-button modal-cancel" onClick={this.cancelDeleteModal}>
                  cancel
                </button>
              </div>
            </div>
          </Modal>
        )}
        <div className="pre-table-header">
          <div className="report-top-section">
            <div className="top-section-left">
              <p className="page-title">
                Media
                {projectSpecific && <span>&nbsp;for {thisProject.name}</span>}
              </p>
            </div>
            <div className="top-section-right">
              <div className="search-filter-things">
                {query.projectRef && (
                  <div className="filter-notification-text media-filter-notes">
                    <span>Filtering by</span>
                    <span className="filter-notification-type">Project</span>
                    <div className="filter-clear-icon" onClick={this.clearProjectFilter}>
                      <IoCloseCircled />
                    </div>
                  </div>
                )}

                <Dropdown
                  className="filter-dropdown media-projects-dropdown"
                  outsideText={
                    <button className="standard-button secondary-button">Filter by Project</button>
                  }
                  ref={dropdown => {
                    this.projectDropdown = dropdown;
                  }}
                >
                  <Typeahead
                    options={projects}
                    maxVisible={6}
                    filterOption="name"
                    displayOption="name"
                    customClasses={typeaheadClasses}
                    onOptionSelected={this.projectFilterSubmit}
                    ref={input => {
                      this.typeahead = input;
                    }}
                    placeholder="Filter by Project Name"
                  />
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
        <div className="add-and-sorts">
          {canAddNewMedia && (
            <Link
              to={{
                pathname: '/media/create',
                state: {
                  returnTo,
                  modal: true
                }
              }}
              className="main-add-button standard-button primary-button"
            >
              Add New
            </Link>
          )}
        </div>
        <div className="media-grid">{mediumEls}</div>
        {noResults && (
          <div className="no-results">
            <p>{noResults}</p>
          </div>
        )}
        <Pagination
          pages={pageCnt}
          activePage={thisPage}
          prev={!!media.prev}
          next={!!media.next}
          updateSearch={this.updateSearch}
        />
        <img src={sevensonLogoText} alt="sevenson logo" className="table-bottom-sevenson-logo" />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  media: state.media,
  projects: state.projects,
  user: state.user
});

const mapDispatchToProps = dispatch => {
  const actions = { fetchMedia, fetchProjects, deleteMedia };
  return { actions: bindActionCreators(actions, dispatch) };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Media);
