import React from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import moment from 'moment';

import Loader from '../../components/shared/Loader';
import Dropdown from '../../components/shared/Dropdown';
import combineName from '../../etc/combineName';
import { fetchOrder } from '../../actions/order/orders';
import { getAuthVals } from '../../etc/checkAuth';

const classNames = require('classnames');

require('../../sass/containers/Orders/OrderContainer.scss');

const viewButtonClasses = classNames({
  'standard-button': true,
  'view-button': true,
  'primary-button': true
});

const statusValues = {
  open: 'Open',
  draft: 'Draft',
  complete: 'Complete',
  needEdit: 'Needs Edits',
  needSignature: 'Needs Signature',
  pendingFinalApproval: 'Pending Final Approval'
};

class OrderContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentWillMount() {
    this.props.actions.fetchOrder(this.props.params);
  }

  render() {
    const { order, user } = this.props;

    const data = order.data || {};

    let reqns = [];
    let originalPO = {};

    if (order.data && order.data.reqns) {
      reqns = order.data.reqns && cloneDeep(order.data.reqns);
      originalPO = reqns.shift() || {};
    }

    const authv = getAuthVals(user.data, order.data);

    const hasCOs = reqns.length > 0;

    return (
      <div className="main-list-page order-container-page">
        <Loader isFetching={order && order.isFetching} />
        <div className="pre-table-header">
          <div className="report-top-section">
            <div className="page-title">
              Order #{data.number}
              <p className="sub-title">
                Project
                {data.projectRef && (data.projectRef.number || data.projectRef.name)
                  ? ` ${data.projectRef.number} ${data.projectRef.name}`
                  : ' Unknown'}
              </p>
            </div>
            <Link
              className="standard-button secondary-button justify-right"
              to={{
                pathname: `/orders/${data._id}/edit`,
                state: {
                  modal: true,
                  returnTo: this.props.location.pathname,
                  extraClass: 'add-report-modal'
                }
              }}
            >
              Edit
            </Link>
          </div>
        </div>
        <div className="list-cards-container">
          {authv.coAllowed ? (
            <Link
              className="standard-button primary-button add-new"
              to={`/orders/${data._id}/reqns/create`}
            >
              Create Change Order
            </Link>
          ) : null}
          <div className="all-reqns">
            <div className="list-card double reqn-cards order-cards original-po">
              <div className="card-status-bar">
                <span>
                  <span className={`status-marker ${originalPO.status}`} />
                  {statusValues[originalPO.status]}
                </span>
              </div>
              <div className="card-title">
                <h2 className="card-title-main">Original PO: #{originalPO.number}</h2>
              </div>
              <div className="card-body">
                <div className="double-top">
                  <div className="top">
                    <div className="group project">
                      <p className="body-field">Project #</p>
                      <p>{data.projectRef && data.projectRef.number}</p>
                    </div>
                    <div className="group vendor">
                      <p className="body-field">Vendor</p>
                      <p>{data.vendorRef && data.vendorRef.name}</p>
                    </div>
                    <div className="group">
                      <p className="body-field">Author</p>
                      <p>{order.data.author && combineName(order.data.author.name)}</p>
                    </div>
                  </div>
                  <div className="top">
                    <div className="group created">
                      <p className="body-field">Date</p>
                      <p>{moment.utc(originalPO.date).format('MM/DD/YYYY')}</p>
                    </div>
                    <div className="group">
                      <p className="body-field">Created At</p>
                      <p>{moment(data.createdAt).calendar()}</p>
                    </div>
                    <div className="group">
                      <p className="body-field">Last Updated</p>
                      <p>{moment(data.updatedAt).calendar()}</p>
                    </div>
                  </div>
                </div>
                <div className="button-row">
                  <Link
                    className={viewButtonClasses}
                    to={`/orders/${data._id}/reqns/${originalPO._id}`}
                  >
                    View
                  </Link>
                  {authv.editAllowed && (
                    <Link
                      className="standard-button secondary-button"
                      to={`/orders/${data._id}/reqns/${originalPO._id}/edit`}
                    >
                      Edit
                    </Link>
                  )}
                  <Dropdown
                    className="extra-options-dropdown filter-dropdown"
                    outsideText={<button className="standard-button">PDFs</button>}
                  >
                    <ul className="project-dropdown">
                      {originalPO.pdfUrls && originalPO.pdfUrls.internal && (
                        <li>
                          <a
                            href={originalPO.pdfUrls.internal}
                            target="_blank"
                            className="view-media-button"
                          >
                            View Requisition PDF
                          </a>
                        </li>
                      )}
                      {originalPO.pdfUrls && originalPO.pdfUrls.external && (
                        <li>
                          <a
                            href={originalPO.pdfUrls.external}
                            target="_blank"
                            className="view-media-button"
                          >
                            PO PDF
                          </a>
                        </li>
                      )}
                    </ul>
                  </Dropdown>
                </div>
              </div>
            </div>
            {hasCOs && (
              <div>
                <h3>Change Orders</h3>
                <div className="list-cards-container change-orders">
                  {reqns.reverse().map((rq, idx) => {
                    const coAuthV = getAuthVals(user.data, { ...order.data, status: rq.status });

                    return (
                      <div className="list-card reqn-cards order-cards" key={rq._id || idx}>
                        <div className="card-status-bar">
                          <span>
                            <span className={`status-marker ${rq.status}`} />
                            {statusValues[rq.status]}
                          </span>
                        </div>
                        <div className="card-title">
                          <h2 className="card-title-main">
                            CO:{' '}
                            {rq.isInternal
                              ? `Internal ${moment(rq.createdAt).format('M-D-YY')}`
                              : `#${rq.number}`}
                          </h2>
                        </div>
                        <div className="card-body">
                          <div className="top">
                            <div className="group created">
                              <p className="body-field">Date</p>
                              <p>{moment(rq.date).format('MM/DD/YYYY')}</p>
                            </div>
                            <div className="group">
                              <p className="body-field">Created At</p>
                              <p>{moment(rq.createdAt).calendar()}</p>
                            </div>
                            <div className="group">
                              <p className="body-field">Last Updated</p>
                              <p>{moment(rq.updatedAt).calendar()}</p>
                            </div>
                          </div>
                          <div className="button-row">
                            <Link
                              className={viewButtonClasses}
                              to={`/orders/${data._id}/reqns/${rq._id}`}
                            >
                              View
                            </Link>
                            {coAuthV.editAllowed && (
                              <Link
                                className="standard-button secondary-button"
                                to={`/orders/${data._id}/reqns/${rq._id}/edit`}
                              >
                                Edit
                              </Link>
                            )}
                            <Dropdown
                              className="extra-options-dropdown filter-dropdown"
                              outsideText={<button className="standard-button">PDFs</button>}
                            >
                              <ul className="project-dropdown">
                                {rq.pdfUrls && rq.pdfUrls.internal && (
                                  <li>
                                    <a
                                      href={rq.pdfUrls.internal}
                                      target="_blank"
                                      className="view-media-button"
                                    >
                                      View Requisition PDF
                                    </a>
                                  </li>
                                )}
                                {rq.pdfUrls && rq.pdfUrls.external && (
                                  <li>
                                    <a
                                      href={rq.pdfUrls.external}
                                      target="_blank"
                                      className="view-media-button"
                                    >
                                      CO PDF
                                    </a>
                                  </li>
                                )}
                              </ul>
                            </Dropdown>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ order, user }) => ({
  order,
  user
});

const mapDispatchToProps = dispatch => {
  const actions = { fetchOrder };
  return { actions: bindActionCreators(actions, dispatch) };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderContainer);
