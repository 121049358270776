/* eslint jsx-a11y/no-static-element-interactions: 0 */
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link, browserHistory } from 'react-router';
import IoAlertCircled from 'react-icons/lib/io/alert-circled';

import get from 'lodash/get';
import set from 'lodash/set';

import TextInput from '../../components/shared/forms/TextInput';
import Alert from '../../components/shared/Alert';
import Tabs from '../../components/shared/Tabs';

import { editVendor } from '../../actions/vendor/editVendor';
import { fetchVendor } from '../../actions/vendor/fetchVendor';
import { createVendorSubmit, createVendorReset } from '../../actions/vendor/createVendor';

import combineName from '../../etc/combineName';
import stateOptions from '../../etc/states.json';

require('../../sass/containers/Reports/CreateReport.scss');
require('../../sass/containers/CreateProject.scss');
require('../../sass/containers/CreateVendor.scss');

class CreateVendor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      vendor: {
        address: {
          street: '',
          city: '',
          state: '',
          zip: ''
        },
        remitAddress: {
          street: '',
          city: '',
          state: '',
          zip: ''
        },
        representative: {
          name: '',
          phone: '',
          title: '',
          email: ''
        },
        name: '',
        number: null
      }
    };

    this.getValueFromId = this.getValueFromId.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSameAddress = this.handleSameAddress.bind(this);
  }

  componentWillMount() {
    if (this.props.route.path === 'vendors/:id/edit') {
      this.setState({ editForm: true });
      this.props.actions.fetchVendor(this.props.params);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.vendor.data !== nextProps.vendor.data) {
      const vendor = nextProps.vendor.data;
      if (vendor && vendor.representative) {
        vendor.representative.name = combineName(vendor.representative.name);
      }
      this.setState({ vendor });
    } else if (nextProps.createVendor.submitted) {
      const locationState = this.props.location.state;
      browserHistory.push(locationState ? locationState.returnTo : '/vendors');
    }
  }

  componentWillUnmount() {
    this.props.actions.createVendorReset();
  }

  getValueFromId(id) {
    return get(this.state.vendor, id);
  }

  handleSubmit(e) {
    e.preventDefault();
    if (this.state.editForm) {
      this.props.actions.editVendor(this.state.vendor, this.props.params.id);
    } else {
      this.props.actions.createVendorSubmit(this.state.vendor);
    }
  }

  handleInputChange(key) {
    return event => {
      this.setState({ vendor: set({ ...this.state.vendor }, key, event.target.value) });
    };
  }

  handleSameAddress() {
    const { checked } = this.checkbox;
    const nextState = { ...this.state };

    if (checked) {
      nextState.vendor.remitAddress = nextState.vendor.address;
    }

    nextState.sameAddress = checked;

    this.setState(nextState);
  }

  render() {
    const { vendor, sameAddress } = this.state;
    const { createVendor, user } = this.props;
    const defaultInputProps = {
      valueGetter: this.getValueFromId,
      onChange: this.handleInputChange
    };

    let returnTo = false;
    const locationObject = this.props.location.state;
    if (locationObject && locationObject.returnTo) {
      // eslint-disable-next-line prefer-destructuring
      returnTo = locationObject.returnTo;
    }

    const { authOverrides } = user.data;

    const canNumber = authOverrides && authOverrides.vendors && authOverrides.vendors.number;

    let alert;
    if (createVendor.error && createVendor.error.message) {
      alert = (
        <Alert delay={5000} type="danger">
          <i>
            <IoAlertCircled />
          </i>
          &nbsp;
          {createVendor.error.message}
        </Alert>
      );
    }

    return (
      <div className="create-edit-modal vendors">
        {alert}
        <p className="modal-header">{vendor.name || 'New Vendor'}</p>
        <Tabs ref={el => (this.tabs = el)}>
          <div idx={0} label="Vendor Info">
            <form className="modal-form" id="create-vendor-form">
              <p className="form-section-title">Vendor Information</p>
              <div className="input-fields">
                <div className="modal-form-block">
                  <TextInput labelText="Vendor Name" inputId="name" {...defaultInputProps} />
                  <TextInput
                    labelText="Vendor Number"
                    inputId="number"
                    inputType="number"
                    disabled={!canNumber}
                    {...defaultInputProps}
                  />
                </div>
                <div className="modal-form-block">
                  <TextInput labelText="Street" inputId="address.street" {...defaultInputProps} />
                  <TextInput labelText="City" inputId="address.city" {...defaultInputProps} />
                </div>
                <div className="modal-form-block">
                  <TextInput labelText="Zip Code" inputId="address.zip" {...defaultInputProps} />
                  <div className="text-input-block select-block">
                    <p className="text-input-standard-label">State</p>
                    <select
                      required
                      id="address.state"
                      form="create-vendor-form"
                      className="standard-select"
                      value={this.getValueFromId('address.state')}
                      onChange={this.handleInputChange('address.state')}
                    >
                      {stateOptions.map(state => (
                        <option value={state} key={state}>
                          {state}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <p
                className="next-tab"
                onClick={() => {
                  this.tabs.setTab(this.tabs.state.tabIdx + 1);
                }}
              >
                Next
              </p>
              <div className="form-submit-buttons">
                <input
                  className="form-button primary-button"
                  type="submit"
                  onClick={this.handleSubmit}
                  value="Save"
                />
                {returnTo && (
                  <Link to={returnTo} className="div-link-button form-button cancel-button">
                    Cancel
                  </Link>
                )}
              </div>
            </form>
          </div>
          <div idx={1} label="Remit Payment">
            <form className="modal-form" id="create-vendor-form">
              <p className="form-section-title">Remit Payment Address</p>
              <div className="checkbox-group">
                <label htmlFor="option-checkbox">Same As Vendor Address?</label>
                <input
                  type="checkbox"
                  id="option-checkbox"
                  ref={el => (this.checkbox = el)}
                  onChange={this.handleSameAddress}
                />
              </div>
              <div className="modal-form-block">
                <TextInput
                  labelText="Remit Pay Street"
                  inputId="remitAddress.street"
                  disabled={sameAddress}
                  {...defaultInputProps}
                />
                <TextInput
                  labelText="Remit Pay City"
                  inputId="remitAddress.city"
                  disabled={sameAddress}
                  {...defaultInputProps}
                />
              </div>
              <div className="modal-form-block">
                <TextInput
                  labelText="Remit Pay Zip Code"
                  inputId="remitAddress.zip"
                  disabled={sameAddress}
                  {...defaultInputProps}
                />
                <div className="text-input-block select-block">
                  <p className="text-input-standard-label">Remit Pay State</p>
                  <select
                    required
                    id="remitAddress.state"
                    form="create-vendor-form"
                    className="standard-select"
                    value={this.getValueFromId('remitAddress.state')}
                    onChange={this.handleInputChange('remitAddress.state')}
                    disabled={sameAddress}
                  >
                    {stateOptions.map(state => (
                      <option value={state} key={state}>
                        {state}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="prev-next-block">
                <p
                  className="prev-tab"
                  onClick={() => {
                    this.tabs.setTab(this.tabs.state.tabIdx - 1);
                  }}
                >
                  Previous
                </p>
                <p
                  className="next-tab"
                  onClick={() => {
                    this.tabs.setTab(this.tabs.state.tabIdx + 1);
                  }}
                >
                  Next
                </p>
              </div>
              <div className="form-submit-buttons">
                <input
                  className="form-button primary-button"
                  type="submit"
                  onClick={this.handleSubmit}
                  value="Save"
                />
                {returnTo && (
                  <Link to={returnTo} className="div-link-button form-button cancel-button">
                    Cancel
                  </Link>
                )}
              </div>
            </form>
          </div>
          <div idx={2} label="Representative">
            <form className="modal-form" id="create-vendor-form">
              <p className="form-section-title">Representative Information</p>
              <div className="modal-form-block">
                <TextInput
                  labelText="Representative Name"
                  inputId="representative.name"
                  {...defaultInputProps}
                />
                <TextInput
                  labelText="Representative Title"
                  inputId="representative.title"
                  {...defaultInputProps}
                />
              </div>
              <div className="modal-form-block">
                <TextInput
                  required
                  labelText="Representative Email"
                  inputId="representative.email"
                  inputType="email"
                  {...defaultInputProps}
                />
                <TextInput
                  labelText="Representative Phone"
                  inputId="representative.phone"
                  inputType="phone"
                  {...defaultInputProps}
                />
              </div>
              <p
                className="prev-tab"
                onClick={() => {
                  this.tabs.setTab(this.tabs.state.tabIdx - 1);
                }}
              >
                Previous
              </p>
              <div className="form-submit-buttons">
                <input
                  className="form-button primary-button"
                  type="submit"
                  onClick={this.handleSubmit}
                  value="Save"
                />
                {returnTo && (
                  <Link to={returnTo} className="div-link-button form-button cancel-button">
                    Cancel
                  </Link>
                )}
              </div>
            </form>
          </div>
        </Tabs>
      </div>
    );
  }
}

const mapStateToProps = ({ createVendor, user, vendor }) => ({ createVendor, user, vendor });

const mapDispatchToProps = dispatch => {
  const actions = {
    createVendorSubmit,
    createVendorReset,
    fetchVendor,
    editVendor
  };
  return { actions: bindActionCreators(actions, dispatch) };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateVendor);
