import React from 'react';
import PropTypes from 'prop-types';

require('../../sass/components/shared/progressBar.scss');
const classNames = require('classnames');

export default class ProgressBar extends React.Component {
  static defaultProps = {
    customClass: '',
    hidePercentage: false,
    percentage: 0
  };

  static propTypes = {
    customClass: PropTypes.string,
    hidePercentage: PropTypes.bool,
    percentage: PropTypes.number
  };

  render() {
    const { percentage, hidePercentage, customClass } = this.props;

    const progressBarClasses = classNames({
      customClass,
      'upload-complete': percentage === 100,
      'progress-bar-container': true
    });

    return (
      <div className="progress-bar-component">
        <div className={progressBarClasses} style={{ width: `calc(${percentage}% - 20px)` }} />
        {!hidePercentage &&
          percentage > 0 && <p className="upload-percent-counter">{percentage}</p>}
      </div>
    );
  }
}
