import SET_COLLAPSE_NAV from '../constants/collapseNav';

const initialState = false;

export default (state = initialState, { type, value }) => {
  switch (type) {
    case SET_COLLAPSE_NAV:
      return typeof value === 'undefined' ? !state : value;
    default:
      return state;
  }
};
