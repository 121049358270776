/* eslint-disable react/jsx-wrap-multilines,react/jsx-indent,no-nested-ternary,no-underscore-dangle */
/* eslint "jsx-a11y/no-static-element-interactions": 0 */

import React from 'react';
import classNames from 'classnames';
import querystring from 'querystring';
import { connect } from 'react-redux';
import { Typeahead } from 'react-typeahead';
import { bindActionCreators } from 'redux';
import { Link, browserHistory } from 'react-router';

import pick from 'lodash/pick';
import pickBy from 'lodash/pickBy';

import IoCheckmarkCircled from 'react-icons/lib/io/checkmark-circled';
import IoCloseCircled from 'react-icons/lib/io/close-circled';
import IoAlertCircled from 'react-icons/lib/io/alert-circled';
import IoIosPlay from 'react-icons/lib/io/ios-play';

import Loader from '../../components/shared/Loader';
import FilterDrawer from '../../components/shared/FilterDrawer';
import DateFilter from '../../components/shared/DateFilter';
import Dropdown from '../../components/shared/Dropdown';
import Modal from '../../components/shared/Modal';
import Alert from '../../components/shared/Alert';
import Pagination from '../../components/shared/Pagination';

import { fetchOrders } from '../../actions/order/orders';
import { createOrderReset } from '../../actions/order/createOrder';
import { editBulkOrder } from '../../actions/order/bulkEditOrder';
import { deleteOrder } from '../../actions/order/deleteOrder';
import { fetchProjects } from '../../actions/projects';
import { fetchVendors } from '../../actions/vendor/vendors';
import { recordFilterDrawer } from '../../actions/setFilterDrawer';

import '../../sass/containers/Orders/Orders.scss';
import '../../sass/containers/Users.scss';
import '../../sass/containers/Reports/Index.scss';

import sevensonLogoText from '../../images/sevenson-logo-text.png';
import trash from '../../images/buttonIcons/trash.svg';
import pdf from '../../images/buttonIcons/pdf.svg';

const getExpiry = () => Date.now() - 1000;

const typeaheadClasses = {
  input: 'typeahead-input',
  results: 'typeahead-results',
  listItem: 'typeahead-list-item',
  listAnchor: 'typeahead-list-anchor',
  hover: 'typeahead-item-hover',
  typeahead: 'typeahead-typeahead',
  resultsTruncated: 'typeahead-results'
};

const statusCheckboxes = [
  { label: 'Open', value: 'open' },
  { label: 'Draft', value: 'draft' },
  { label: 'Complete', value: 'complete' },
  { label: 'Needs Edits', value: 'needEdit' },
  { label: 'Needs Signature', value: 'needSignature' },
  { label: 'Pending Final Approval', value: 'pendingFinalApproval' }
];

const statusValues = {
  open: 'Open',
  draft: 'Draft',
  complete: 'Complete',
  needEdit: 'Needs Edits',
  needSignature: 'Needs Signature',
  pendingFinalApproval: 'Pending Final Approval'
};

const makePdfLis = rqs => {
  return rqs.map(rq => {
    return (
      <li key={rq._id}>
        {rq.pdfUrls && rq.pdfUrls.external && (
          <a href={rq.pdfUrls.external} target="_blank" className="view-media-button">
            View {rq.isChangeOrder ? `CO #${rq.number}` : 'PO'} PDF
          </a>
        )}
      </li>
    );
  });
};

class Orders extends React.Component {
  static need = [
    fetchOrders,
    fetchProjects.bind(null, { limit: -1 }),
    fetchVendors.bind(null, { limit: -1 })
  ];

  static searchFields = [
    'sort',
    'textSearch',
    'projectRef',
    'vendorRef',
    'dateAbove',
    'dateBelow',
    'page',
    'status',
    'email',
    'user'
  ];

  constructor(props) {
    super(props);

    this.state = { bulkOrder: [] };

    this.changeSort = this.changeSort.bind(this);

    this.updateSearch = this.updateSearch.bind(this);
    this.searchSubmit = this.searchSubmit.bind(this);
    this.clearSearch = this.clearSearch.bind(this);

    this.actionItemFilter = this.actionItemFilter.bind(this);
    this.projectFilterSubmit = this.projectFilterSubmit.bind(this);
    this.vendorFilterSubmit = this.vendorFilterSubmit.bind(this);
    this.statusCheckboxSubmit = this.statusCheckboxSubmit.bind(this);
    this.clearProjectFilter = this.clearProjectFilter.bind(this);
    this.clearVendorFilter = this.clearVendorFilter.bind(this);
    this.clearDateFilter = this.clearDateFilter.bind(this);
    this.clearAllFilters = this.clearAllFilters.bind(this);
    this.filterByDateSubmit = this.filterByDateSubmit.bind(this);

    this.handleBulkSubmit = this.handleBulkSubmit.bind(this);
    this.handleBulkStatusCheckbox = this.handleBulkStatusCheckbox.bind(this);

    this.deleteConfirm = this.deleteConfirm.bind(this);
    this.cancelDeleteModal = this.cancelDeleteModal.bind(this);
    this.deleteOrderSubmit = this.deleteOrderSubmit.bind(this);
  }

  componentWillMount() {
    const {
      actions,
      location: { query },
      orders: { isFetching, lastUpdated },
      user: { data: user }
    } = this.props;

    if (lastUpdated < getExpiry() && !isFetching && query.user) {
      actions.fetchOrders(query || {});
      actions.fetchProjects({ limit: -1 });
    }

    if (!query.user) {
      // set initial load with open items checked
      this.updateSearch({ user: user._id });
    }
  }

  componentDidMount() {
    this.props.actions.recordFilterDrawer('orders');
  }

  componentWillReceiveProps(nextProps) {
    const { query } = nextProps.location;
    const { isFetching, lastUpdated } = nextProps.orders;
    const { actions } = this.props;

    if (!isFetching && !lastUpdated) {
      actions.fetchOrders(query);
    } else if (nextProps.createOrder.submitted) {
      actions.createOrderReset();
      actions.fetchOrders(query);
      this.setState({ bulkOrder: [] });
    } else if (this.props.location && this.props.location.query) {
      const oldQuery = pick(this.props.location.query, Orders.searchFields);
      const newQuery = pick(query, Orders.searchFields);

      const changed = Object.entries(newQuery)
        .map(([key, val]) => oldQuery[key] !== val)
        .filter(Boolean).length;

      const keysRemoved = Object.keys(oldQuery).length > Object.keys(newQuery).length;

      if (!changed && !keysRemoved) return;
      actions.fetchOrders(newQuery);
    }
  }

  updateSearch(props) {
    const { location } = this.props;

    if (!Object.keys(props).includes('page')) {
      props.page = undefined;
    }

    const oldQuery = location.query;
    const query = pickBy(Object.assign(oldQuery, props), Boolean);
    browserHistory && browserHistory.push({ ...location, query });
  }

  changeSort(_field) {
    return () => {
      let field = _field;
      if (this.props.location.query.sort === field) {
        field = field.startsWith('-') ? field.slice(1) : `-${field}`;
      }
      this.updateSearch({ sort: field });
    };
  }

  searchSubmit(e) {
    e.preventDefault();
    this.updateSearch({ textSearch: this.searchInput.value });
  }

  actionItemFilter() {
    const {
      location: { query },
      user: { data: user }
    } = this.props;

    const userId = query.user ? null : user._id;

    this.updateSearch({ user: userId });
  }

  projectFilterSubmit({ _id, name }) {
    this.updateSearch({ projectRef: _id, projName: name });
  }

  vendorFilterSubmit({ _id, name }) {
    this.updateSearch({ vendorRef: _id, vendName: name });
  }

  statusCheckboxSubmit(_value) {
    const { query } = this.props.location;
    let value;

    if (!query.status) {
      value = _value;
    } else {
      const statusArray = query.status.split(',');
      const valueIdx = statusArray.indexOf(_value);
      if (valueIdx > -1) {
        statusArray.splice(valueIdx, 1);
      } else {
        statusArray.push(_value);
      }
      value = statusArray.join(',');
    }
    this.updateSearch({ status: value });
  }

  filterByDateSubmit(dateAbove, dateBelow, value) {
    this.updateSearch({ dateAbove, dateBelow, dateFilter: value });
  }

  clearSearch() {
    this.updateSearch({ textSearch: undefined });
  }

  clearProjectFilter() {
    this.typeaheadP._onEscape();
    this.typeaheadP.setEntryText('');
    this.updateSearch({ projectRef: undefined, projName: undefined });
  }

  clearVendorFilter() {
    this.typeaheadV._onEscape();
    this.typeaheadV.setEntryText('');
    this.updateSearch({ vendorRef: undefined, vendName: undefined });
  }

  clearDateFilter() {
    this.orderDateFilter.resetDatepicker();
    this.updateSearch({ dateAbove: undefined, dateBelow: undefined, dateFilter: undefined });
  }

  clearAllFilters() {
    this.orderDateFilter.resetDatepicker();
    this.typeaheadP._onEscape();
    this.typeaheadP.setEntryText('');
    this.typeaheadV._onEscape();
    this.typeaheadV.setEntryText('');
    this.updateSearch({
      dateAbove: undefined,
      dateBelow: undefined,
      dateFilter: undefined,
      projectRef: undefined,
      projName: undefined,
      vendorRef: undefined,
      vendName: undefined,
      status: undefined
    });
  }

  deleteOrderSubmit = id => () => {
    this.props.actions.deleteOrder(id);
    this.setState({ deleteModal: undefined });
  };

  deleteConfirm = id => () => {
    this.setState({ deleteModal: id });
  };

  cancelDeleteModal = () => {
    this.setState({ deleteModal: undefined });
  };

  handleBulkSubmit() {
    this.props.actions.editBulkOrder({ ids: this.state.bulkOrder, status: 'complete' });
  }

  handleBulkStatusCheckbox(id) {
    const bulkOrderArray = this.state.bulkOrder;
    const valueIdx = bulkOrderArray.indexOf(id);
    if (valueIdx > -1) {
      bulkOrderArray.splice(valueIdx, 1);
    } else {
      bulkOrderArray.push(id);
    }
    this.setState({ bulkOrder: bulkOrderArray });
  }

  render() {
    const { orders, actions, setFilterDrawer } = this.props;

    const { query, pathname, search } = this.props.location;
    const { authOverrides } = this.props.user.data;
    const canAddNewOrders = authOverrides && authOverrides.orders.create;
    const canSign = authOverrides && authOverrides.orders.sign;
    const canDelete = authOverrides && authOverrides.orders.delete;

    const returnTo = pathname + search;

    let noResults;
    if (!orders.data || (orders.data && orders.data.length === 0)) {
      noResults = 'There are no results to display.';
    }

    const projects = this.props.projects.data;
    const vendors = this.props.vendors && this.props.vendors.data;
    projects.filter(Boolean).forEach(p => {
      p.numberName = `${p.number} – ${p.name}`;
    });

    const projectFilterOn = query.projectRef;
    const dateFilterOn = query.dateAbove || query.dateBelow;
    const vendorFilterOn = query.vendorRef;
    const searchFilterOn = query.textSearch;
    const areThereFiltersOn = dateFilterOn || projectFilterOn || searchFilterOn;

    const pagingQuery = querystring.parse(orders.next || orders.prev);

    const pageCnt = parseInt(pagingQuery.pages, 10) || 1;

    let thisPage = parseInt(query.page, 10);
    if (!thisPage) thisPage = orders.next ? parseInt(pagingQuery.page, 10) : pagingQuery.page + 2;

    let alert, createSuccess;

    const lastSubmitted = this.props.createOrder.lastSubmitted;
    if (lastSubmitted && lastSubmitted > Date.now() - 1e3) {
      createSuccess = (
        <Alert delay={5000} type="success">
          <i>
            <IoCheckmarkCircled />
          </i>
          &nbsp; Success!
        </Alert>
      );
    }

    if (orders.error && orders.error.message) {
      alert = (
        <Alert delay={5000} type="danger">
          <i>
            <IoAlertCircled />
          </i>
          &nbsp;
          {orders.error.message}
        </Alert>
      );
    }

    const filterOn = setFilterDrawer && setFilterDrawer.drawer === 'orders';

    return (
      <div className={classNames('main-list-page grid orders-page', { 'filter-open': filterOn })}>
        <FilterDrawer noCloseOnBlur open={filterOn} closeDrawer={actions.recordFilterDrawer}>
          <div className="filter-section">
            <button className="clear-filters-button standard-button" onClick={this.clearAllFilters}>
              Clear All Filters
            </button>
          </div>
          <div className="filter-section column">
            <p className="filter-name">Status</p>
            {statusCheckboxes.map(option => {
              return (
                <label htmlFor={option.value} key={option.value}>
                  <input
                    id={option.value}
                    type="checkbox"
                    onChange={() => this.statusCheckboxSubmit(option.value)}
                    checked={query.status && query.status.split(',').indexOf(option.value) > -1}
                  />
                  {option.label}
                </label>
              );
            })}
            {projectFilterOn && (
              <div className="filter-notification-type" onClick={this.clearProjectFilter}>
                {query.projName}
                <span className="filter-clear-icon">
                  <IoCloseCircled />
                </span>
              </div>
            )}
          </div>
          <div className="filter-section">
            <p className="filter-name">Project Name/Number</p>
            <Typeahead
              closeDropdownOnBlur
              options={projects}
              maxVisible={6}
              filterOption="numberName"
              displayOption="numberName"
              customClasses={typeaheadClasses}
              onOptionSelected={this.projectFilterSubmit}
              ref={input => {
                this.typeaheadP = input;
              }}
              placeholder="Filter by Project"
            />
            {projectFilterOn && (
              <div className="filter-notification-type" onClick={this.clearProjectFilter}>
                {query.projName}
                <span className="filter-clear-icon">
                  <IoCloseCircled />
                </span>
              </div>
            )}
          </div>
          <div className="filter-section">
            <p className="filter-name">Date</p>
            <DateFilter
              filterFunc={this.filterByDateSubmit}
              ref={datefilter => {
                this.orderDateFilter = datefilter;
              }}
            />
            {dateFilterOn && (
              <div className="filter-notification-type" onClick={this.clearDateFilter}>
                {query.dateFilter}
                <span className="filter-clear-icon">
                  <IoCloseCircled />
                </span>
              </div>
            )}
          </div>
          <div className="filter-section">
            <p className="filter-name">Vendor</p>
            <Typeahead
              closeDropdownOnBlur
              options={vendors}
              maxVisible={6}
              filterOption="name"
              displayOption="name"
              customClasses={typeaheadClasses}
              onOptionSelected={this.vendorFilterSubmit}
              ref={i => {
                this.typeaheadV = i;
              }}
              placeholder="Filter by Vendor"
            />
            {vendorFilterOn && (
              <div className="filter-notification-type" onClick={this.clearVendorFilter}>
                {query.vendName}
                <span className="filter-clear-icon">
                  <IoCloseCircled />
                </span>
              </div>
            )}
          </div>
        </FilterDrawer>
        <div className="non-drawer-content">
          {alert}
          {createSuccess}
          <Loader isFetching={orders && orders.isFetching} />
          {this.state.deleteModal && (
            <Modal
              isOpen
              extraClass="delete-confirm-modal"
              ref={modal => {
                this.deleteModal = modal;
              }}
              toCancel={() => {
                this.setState({ deleteModal: undefined });
              }}
            >
              <div className="modal-text-top">
                <p className="modal-header">Are you sure?</p>
                <p className="modal-subheader">
                  Delete this order? This order and all of its data will no longer be accessible to
                  users.
                </p>
              </div>
              <div className="modal-body">
                <div className="modal-buttons">
                  <button
                    className="modal-button modal-delete"
                    onClick={this.deleteOrderSubmit(this.state.deleteModal)}
                  >
                    confirm
                  </button>
                  <button className="modal-button modal-cancel" onClick={this.cancelDeleteModal}>
                    cancel
                  </button>
                </div>
              </div>
            </Modal>
          )}
          <div className="pre-table-header">
            <div className="report-top-section">
              <div className="top-section-left">
                <p className="page-title">Requisitions</p>
              </div>
              <div className="top-section-right">
                <div className="search-filter-things">
                  <form className="search-and-button">
                    <input
                      id="report-search"
                      className="search-bar"
                      type="text"
                      ref={el => {
                        this.searchInput = el;
                      }}
                      placeholder="Search for requisitions..."
                    />
                    <input type="submit" value="Search" onClick={this.searchSubmit} />
                  </form>
                  {areThereFiltersOn && (
                    <div className="filter-notification-text">
                      <span>Current Filters:</span>
                      {projectFilterOn && (
                        <div className="filter-notification-type" onClick={this.clearProjectFilter}>
                          Project
                          <span className="filter-clear-icon">
                            <IoCloseCircled />
                          </span>
                        </div>
                      )}
                      {dateFilterOn && (
                        <div className="filter-notification-type" onClick={this.clearDateFilter}>
                          Date
                          <span className="filter-clear-icon">
                            <IoCloseCircled />
                          </span>
                        </div>
                      )}
                      {searchFilterOn && (
                        <div className="filter-notification-type" onClick={this.clearSearch}>
                          Search for: “{query.textSearch}”
                          <span className="filter-clear-icon">
                            <IoCloseCircled />
                          </span>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="add-and-sorts">
            {canAddNewOrders && (
              <Link
                to={{
                  pathname: '/orders/create',
                  state: {
                    returnTo,
                    extraClass: 'orders-creation-container'
                  }
                }}
                className="standard-button main-add-button primary-button"
              >
                Add New
              </Link>
            )}
            <ul className="sorts">
              <li className="action-item-group">
                <label htmlFor="action-item-checkbox">
                  <input
                    id="action-item-checkbox"
                    type="checkbox"
                    checked={!!query.user}
                    onChange={this.actionItemFilter}
                  />
                  My Open Items
                </label>
              </li>
              <li onClick={this.changeSort('-createdAt')}>
                Date
                <div className="sort-arrows">
                  <IoIosPlay className={`sort-down ${query.sort === '-createdAt'}`} />
                  <IoIosPlay className={`sort-up ${query.sort === 'createdAt'}`} />
                </div>
              </li>
              <li onClick={this.changeSort('-updatedAt')}>
                Last Updated
                <div className="sort-arrows">
                  <IoIosPlay className={`sort-down ${query.sort === '-updatedAt'}`} />
                  <IoIosPlay className={`sort-up ${query.sort === 'updatedAt'}`} />
                </div>
              </li>
              <li onClick={this.changeSort('number')}>
                Order #
                <div className="sort-arrows">
                  <IoIosPlay className={`sort-down ${query.sort === '-number'}`} />
                  <IoIosPlay className={`sort-up ${query.sort === 'number'}`} />
                </div>
              </li>
              <li onClick={this.changeSort('projectNumber')}>
                Project #
                <div className="sort-arrows">
                  <IoIosPlay className={`sort-down ${query.sort === '-projectNumber'}`} />
                  <IoIosPlay className={`sort-up ${query.sort === 'projectNumber'}`} />
                </div>
              </li>
              <li onClick={this.changeSort('vendorName')}>
                Vendor
                <div className="sort-arrows">
                  <IoIosPlay className={`sort-down ${query.sort === '-vendorName'}`} />
                  <IoIosPlay className={`sort-up ${query.sort === 'vendorName'}`} />
                </div>
              </li>
            </ul>
            {this.state.bulkOrder.length ? (
              <button
                className="standard-button primary-button justify-right"
                onClick={this.handleBulkSubmit}
              >
                Mark as Complete
              </button>
            ) : null}
          </div>
          <div className="list-cards-container">
            {// TODO might be good to extract below to a component
            orders.data.map(order => {
              const viewButtonClasses = classNames({
                'standard-button': true,
                'view-button': true,
                'primary-button': true
              });

              const rqs = order.reqns;

              return (
                <div className="list-card reports-table" key={order._id}>
                  <div className="card-status-bar">
                    {order.status === 'needSignature' && canSign && (
                      <span>
                        <input
                          type="checkbox"
                          onChange={() => this.handleBulkStatusCheckbox(order._id)}
                        />
                      </span>
                    )}
                    <span>
                      <span className={`status-marker ${order.status}`} />
                      {statusValues[order.status]}
                    </span>
                  </div>
                  <div className="card-body">
                    <div className="group order-num">
                      <p className="body-field">Order</p>
                      <p className="emph"> #{order.number}</p>
                    </div>
                    <div className="group project">
                      <p className="body-field">Project</p>
                      <p className="emph"> {order.projectRef && order.projectRef.name}</p>
                    </div>

                    <div className="group project-num">
                      <p className="body-field">Project #</p>
                      <p className="emph">
                        {(order.projectRef && order.projectRef.number) || 'Unassigned'}
                      </p>
                    </div>
                    <div className="group vendor">
                      <p className="body-field">Vendor</p>
                      <p>{(order.vendorRef && order.vendorRef.name) || 'Unassigned'}</p>
                    </div>

                    <div className="group actions">
                      <p className="body-field">Actions</p>
                      <div className="button-contain">
                        <Link
                          title="View"
                          className={viewButtonClasses}
                          to={`/orders/${order._id}`}
                        >
                          View
                        </Link>
                        <Dropdown
                          className="extra-options-dropdown filter-dropdown"
                          outsideText={
                            <button title="PDFs" className="standard-button">
                              {' '}
                              <img src={pdf} alt="pdf logo on doc" />
                            </button>
                          }
                        >
                          <ul className="project-dropdown">
                            {rqs && rqs.length && makePdfLis(rqs)}
                          </ul>
                        </Dropdown>
                        {canDelete && (
                          <button
                            title="Delete"
                            className="standard-button delete-button"
                            onClick={this.deleteConfirm(order._id)}
                          >
                            <img src={trash} alt="trash can" />
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          {noResults && (
            <div className="no-results">
              <span>{noResults}</span>
            </div>
          )}
          <Pagination
            pages={pageCnt}
            activePage={thisPage}
            prev={!!orders.prev}
            next={!!orders.next}
            updateSearch={this.updateSearch}
          />
          <img src={sevensonLogoText} alt="sevenson logo" className="table-bottom-sevenson-logo" />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  projects: state.projects,
  orders: state.orders,
  createOrder: state.createOrder,
  vendors: state.vendors,
  user: state.user,
  setFilterDrawer: state.setFilterDrawer
});

const mapDispatchToProps = dispatch => {
  const actions = {
    fetchOrders,
    editBulkOrder,
    createOrderReset,
    deleteOrder,
    fetchProjects,
    fetchVendors,
    recordFilterDrawer
  };
  return { actions: bindActionCreators(actions, dispatch) };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Orders);
