import axios from 'axios';
import * as types from '../constants/users';
import transAxoisErr from '../etc/axios/transformAxiosError';

export const deleteUserRequest = () => ({type: types.DELETE_USER_REQUEST});

export const deleteUserSuccess = ({ data }) => ({
  data,
  receivedAt: Date.now(),
  type: types.DELETE_USER_SUCCESS
});

export const deleteUserFailure = error => ({
  error,
  receivedAt: Date.now(),
  type: types.DELETE_USER_FAILURE
});

export const deleteUser = id => (dispatch) => {
  dispatch(deleteUserRequest());
  return axios
    .delete(`/user/${id}`)
    .then(res => dispatch(deleteUserSuccess(res.data)))
    .catch(error => dispatch(deleteUserFailure(transAxoisErr(error))));
};
