import React from 'react';
import PropTypes from 'prop-types';

export default class CaptionedMedia extends React.Component {
  static propTypes = { mediaArray: PropTypes.arrayOf(PropTypes.object) };

  static defaultProps = { mediaArray: [{}] };

  render() {
    const mediaEls = this.props.mediaArray.map(media => {
      const url = media.mediaRef?.resizedUrl || media.mediaRef?.url;

      return (
        <div className="rv-photo-block" key={media._id}>
          <div
            className="rv-photo"
            style={url ? { backgroundImage: `url(${url})` } : {}}
          />
          <p className="rv-photo-caption">{media.description}</p>
        </div>
      );
    });

    return <div className="rv-photos clearfix">{mediaEls}</div>;
  }
}
