import axios from 'axios';
import * as types from '../../constants/order/bulkEditOrder';
import transAxoisErr from '../../etc/axios/transformAxiosError';

export const editBulkOrderRequest = () => ({type: types.EDIT_BULK_ORDER_REQUEST});

export const editBulkOrderSuccess = ({ data }) => ({
  data,
  receivedAt: Date.now(),
  type: types.EDIT_BULK_ORDER_SUCCESS,
});

export const editBulkOrderFailure = error => ({
  error,
  receivedAt: Date.now(),
  type: types.EDIT_BULK_ORDER_FAILURE
});

export const editBulkOrder = (data) => (dispatch) => {
  dispatch(editBulkOrderRequest());
  return axios
    .put('order/bulk', data)
    .then(res => dispatch(editBulkOrderSuccess(res.data)))
    .catch(error => dispatch(editBulkOrderFailure(transAxoisErr(error))));
};
