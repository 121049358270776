export default [
  'Vendor shall furnish all requirements to perform and complete the services set forth on the face of this agreement (hereafter “Services”) in a safe and efficient manner. Vendor shall perform and provide all Services in a professional, timely, skillful and competent manner, consistent with all generally recognized and applicable codes and standards of Vendor’s business, trade or profession. TIME OF PERFORMANCE IS OF THE ESSENCE. Vendor agrees that the Services shall be commenced promptly, prosecuted with strict diligence, and fully completed within the time limits herein fixed for completion or, if no time limits are herein fixed, as directed by Contractor.',
  'Vendor shall be responsible for all means and methods, techniques, sequences and procedures, and for coordinating all portions of Vendor’s Services. Vendor shall be responsible for the accuracy of designs, plans, drawings, layouts, and other work prepared or performed by Vendor or its Vendor. Vendor’s employees, Vendors, methods, facilities and equipment used in performing the Services shall be at all times under Vendor’s ultimate supervision, direction and control. Vendor’s relationship to Contractor shall be that of an independent contractor, and nothing in this agreement shall be construed to constitute Vendor, its Vendors or any of their employees as an employee or agent of Contractor.',
  'Vendor shall perform its Services in a careful, professional and workmanlike manner and take all necessary precautions in performance of the Services to prevent injuries to persons or damage to property. Vendor shall be solely responsible for the health and safety of its employees and all other persons who may be exposed to harm incidental to Vendor’s Services. At all times Vendor shall provide and maintain a safe place to work; shall ensure persons performing the Services are properly trained, qualified and licensed; shall be satisfied that all equipment, tools, facilities, ladders, scaffolds and like items, regardless of ownership, are fit and safe for use; shall provide all necessary personal safety equipment and be responsible to insure that same is used at all times when required; and shall comply with all laws, rules, regulations, instructions, standards and requirements concerning safety. In fulfilling its obligations hereunder, Vendor shall not rely on Contractor as having taken or to take any particular action or precaution.',
  'Vendor assumes entire responsibility for any and all damage or injury of any kind or nature whatever, including death, illness and disease, to persons, including Vendor’s employees, and to property and including loss of use or services, caused by, resulting from, or occurring in connection with the Services or any act or omission of Vendor. Vendor shall defend and, to the fullest extent permitted by law, indemnify and save harmless, Contractor, the owner of the site (“Site”) at which the Services are to be provided (and/or party with whom Contractor holds a contract for the project at the Site), the project’s Engineer, and their respective officers, agents and employees, from and against any and all claims, losses, damages, costs, liability, and expenses, including legal fees and expenses, directly or indirectly caused by, resulting from, or occurring in connection with Vendor’s Services or any Vendor act or omission. Vendor’s indemnity obligation shall not be restricted by any limitation on the amount or type of damages, compensation or benefits payable by or for Vendor under workers compensation, disability, or other employee benefit acts.',
  'Vendor shall maintain such insurance as Contractor shall designate simultaneously with this agreement or, in the absence of such designation, the following minimum coverages, all with carriers satisfactory to Contractor: Workers Compensation Insurance as required by the jurisdiction wherein the work is performed; Commercial General Liability Insurance including the following: Contractual Liability Insurance, Completed Operations and Product Liability, Broad Form Property Damage, Premises/Operations, and Explosion, Collapse and Underground Property Damage; Automobile Liability Insurance including coverage for non-owned and hired vehicles; and, if Vendor’s Services include professional engineering, surveying, laboratory or other professional services, Professional Liability Insurance (“errors and omissions”). Such insurance shall provide limits of not less than $1,000,000 combined bodily injury and property damage and a $1,000,000 single project aggregate. Vendor shall furnish a certificate in form and substance as Contractor shall require certifying that such insurance is in force, that the liability policies (except Professional Liability Insurance) name as additional insureds, and all policies waive subrogation against, Contractor, the Site’s owner (and/or party with whom Contractor holds a contract for the project at the Site), the project’s Engineer and their respective officers, agents and employees, and all other persons or entities which Contractor is required to so name for the Site and that such insurance may not be cancelled or allowed to expire except upon 30 days’ prior written notice to Contractor. Coverage provided additional insureds shall be primary coverage with respect to any insurance or self-insurance maintained by an additional insured which shall be excess of, and non-contributory to, Vendor’s insurance.',
  'Each month Vendor shall submit to Contractor a written requisition for payment for Services furnished or performed the previous month. Payment shall be made in the manner, at the time, and to the extent specified herein, but in no event shall payment exceed the value of Services performed through the end of the previous month less all previous payments, less all charges for material and services furnished by Contractor to Vendor, less all amounts reserved for deficiencies in the Services or for third-party claims related to the Services for which Contractor may be liable, and less any other sums Contractor shall be entitled to retain under this agreement or by law. Contractor shall not be liable to Vendor for interest except any monies past due more than 45 days due to no fault or neglect of Vendor will thereafter bear interest at the prime rate. Vendor shall not assign, transfer, convey, or sublet this agreement or any money due hereunder, without Contractor’s prior written consent.',
  'Contractor shall have the right at any time upon written notice to Vendor to terminate and cancel this agreement, without fault of Vendor, for any cause or for its convenience, and to require Vendor to cease work as Contractor directs. In such event, Contractor shall pay Vendor for such of Vendor’s Services actually performed at the agreement’s price for such service; Vendor shall not be entitled to or receive anticipated profits on services unperformed. Termination by Contractor shall not relieve Vendor of any responsibility or liability for any default or breach of this agreement.',
  'Vendor agrees to comply with all laws, rules, regulations, standards and ordinances of the federal, state and local authorities applicable to Vendor’s Services. For government projects, Vendor agrees to comply with all required flow down provisions applicable to Vendor’s Services. Vendor shall be familiar with and shall adhere to Contractor’s “Vendor/Vendor Code of Ethics” found at www.sevenson.com/policies.',
  'This agreement shall be governed and construed under the laws of the state, territory or province in which the project is located without regard to rules concerning conflicts of law. Contractor and Vendor agree that neither shall be liable to the other for any special, indirect, punitive or exemplary damages and hereby mutually release each other from any such liability. Vendor agrees that venue in any action by Vendor against Contractor and/or Contractor’s surety shall be Erie County, New York.',
  'This agreement is the sole and entire agreement between the parties and supersedes all prior communications, proposals, offers and agreements, whether written or oral. Any reference to Vendor’s quotation, bid or proposal does not imply acceptance of any term or condition contained therein. In the event of conflict between this agreement and any term in a Vendor-supplied form, this agreement shall govern. Vendor agrees that Contractor has made no representations or promises of any kind with respect to Vendor’s Services or this agreement except as may be expressly set forth herein. No waiver or change by Contractor of any term hereof shall be effective unless in writing subscribed by an officer of Contractor. '
];
