import SET_FILE_VIEWER_FILE from '../constants/fileViewer';

const initialState = { file: {} };

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_FILE_VIEWER_FILE:
      return { ...state, file: action.file || {} };
    default:
      return state;
  }
};
