import axios from 'axios';
import * as types from '../../constants/vendor/createVendor';
import transAxoisErr from '../../etc/axios/transformAxiosError';

export const editVendorRequest = () => ({type: types.EDIT_VENDOR_REQUEST});

export const editVendorSuccess = ({ data }) => ({
  data,
  receivedAt: Date.now(),
  type: types.EDIT_VENDOR_SUCCESS,
});

export const editVendorFailure = error => ({
  error,
  receivedAt: Date.now(),
  type: types.EDIT_VENDOR_FAILURE
});

export const editVendor = (data, id) => (dispatch) => {
  dispatch(editVendorRequest());
  return axios
    .put(`/vendor/${id}`, data)
    .then(res => dispatch(editVendorSuccess(res.data)))
    .catch(error => dispatch(editVendorFailure(transAxoisErr(error))));
};
