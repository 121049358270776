import React from 'react';
import Repeater from '../shared/forms/Repeater';

export default class OrderWizardAddEmails extends React.Component {
  render() {
    const { order } = this.props;

    const defaultRepeaterProps = { data: order };

    return (
      <Repeater
        {...{ ...this.props, ...defaultRepeaterProps }}
        noTitle
        single
        buttonRight
        circleRemove
        path="emailRecipients"
        namePath="order.emailRecipients"
        className="create-order-repeater"
        tableID="email-repeater"
        fields={[
          {
            title: 'Email Address',
            inputType: 'email',
            required: true,
            setOnChange: true
          }
        ]}
      />
    );
  }
}
