import React from 'react';
import { Link } from 'react-router';

import ErrorPage from './ErrorPage';

export default () => (
  <ErrorPage title="404" hasButton={false} className="do-not-follow">
    <p>The page you were looking for isn’t here.</p>
    <p>
      Please contact an administrator or return to the <Link to="/">home page</Link>.
    </p>
  </ErrorPage>
);
