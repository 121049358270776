/* eslint jsx-a11y/no-static-element-interactions: 0 */
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link, browserHistory } from 'react-router';
import { Typeahead } from 'react-typeahead';

import IoAlertCircled from 'react-icons/lib/io/alert-circled';

import get from 'lodash/get';
import set from 'lodash/set';

import TextInput from '../components/shared/forms/TextInput';
import Alert from '../components/shared/Alert';
import Tabs from '../components/shared/Tabs';
import Repeater from '../components/shared/forms/Repeater';

import { createProject as createProjectSubmit, createProjectReset } from '../actions/createProject';
import { editProject } from '../actions/editProject';
import { fetchProject } from '../actions/fetchProject';
import { fetchUsers } from '../actions/users';

import '../sass/containers/Reports/CreateReport.scss';
import '../sass/containers/CreateProject.scss';

import stateOptions from '../etc/states.json';
import combineName from '../etc/combineName';

const typeaheadClasses = {
  input: 'text-input-standard',
  results: 'typeahead-results',
  listItem: 'typeahead-list-item',
  listAnchor: 'typeahead-list-anchor',
  hover: 'typeahead-item-hover',
  typeahead: 'standard-typeahead',
  resultsTruncated: 'typeahead-results'
};

const reduceKeys = keys => keys.reduce((obj, key) => set(obj, key, ''), {});

let alert;

class CreateProject extends React.Component {
  constructor(props) {
    super(props);
    const { location } = this.props;
    const { state = {} } = location;
    const { defaultName } = state;

    this.state = {
      project: {
        address: {
          street: '',
          city: '',
          state: '',
          zip: ''
        },
        tax: '',
        name: defaultName || '',
        number: '',
        mainContact: {
          name: {
            first: '',
            last: ''
          },
          email: '',
          phone: '',
          title: ''
        },
        subContract: {
          owner: '',
          engineer: '',
          date: '',
          number: ''
        },
        accessors: [{ name: { first: '', last: '' }, userRef: '' }],
        approvers: [{ name: { first: '', last: '' }, userRef: '' }]
      },
      accessorError: false,
      approverError: false
    };

    this.getValueFromId = this.getValueFromId.bind(this);
    this.getPath = this.getPath.bind(this);
    this.replacePath = this.replacePath.bind(this);
    this.addRow = this.addRow.bind(this);
    this.delRow = this.delRow.bind(this);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.typeaheadSelect = this.typeaheadSelect.bind(this);
    this.renderPrevNext = this.renderPrevNext.bind(this);
    this.renderFormSubmit = this.renderFormSubmit.bind(this);
  }

  componentWillMount() {
    const { route, users } = this.props;

    if (route.path === 'projects/:id/edit') {
      this.setState({ editForm: true });
      this.props.actions.fetchProject(this.props.params);
    }

    if (!users.data.length || users.lastUpdated < Date.now() - 5e3) {
      this.props.actions.fetchUsers({ limit: -1 });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.project.data !== nextProps.project.data) {
      const fetchedProject = nextProps.project.data;
      this.setState({ project: fetchedProject });
    } else if (nextProps.createProject.submitted) {
      browserHistory.push(this.props.location.state.returnTo);
    }
  }

  componentWillUnmount() {
    if (this.props.createProject.submitted) {
      this.props.actions.createProjectReset();
    }
  }

  getValueFromId(id) {
    return get(this.state.project, id);
  }

  getPath(path) {
    return get(this.state.project, path);
  }

  replacePath(path, content) {
    this.setState({ project: set({ ...this.state.project }, path, content) });
  }

  addRow(path, keys) {
    return cb => {
      const newItem = typeof keys === 'string' ? keys : reduceKeys(keys);
      const project = { ...this.state.project };
      project[path].push(newItem);
      this.setState({ project }, cb);
    };
  }

  delRow(path, idx) {
    return () => {
      const project = { ...this.state.project };
      const parent = get(project, path);
      parent.splice(idx, 1);

      const nextState = { project };

      this.setState(nextState);
    };
  }

  handleSubmit(e) {
    e && e.preventDefault();

    const { accessors, approvers } = this.state.project;

    if (!accessors.length || !accessors[0].userRef) {
      this.setState({ accessorError: true });
      return;
    }

    if (!approvers.length || !approvers[0].userRef) {
      this.setState({ approverError: true });
      return;
    }

    if (this.state.editForm) {
      this.props.actions.editProject(this.state.project, this.props.params.id);
    } else {
      this.props.actions.createProjectSubmit(this.state.project);
    }
  }

  handleInputChange(key) {
    return event => {
      this.setState({ project: set({ ...this.state.project }, key, event.target.value) });
    };
  }

  typeaheadSelect(path = '') {
    return (
      { name: { first = '', last = '' }, phone = '', title = '', email = '', _id = '' },
      altPath
    ) => {
      let content;
      if (path === 'mainContact') {
        content = { email, phone, title, name: { first, last } };
      } else {
        content = { name: { first, last }, userRef: _id };
      }

      this.setState({ project: set({ ...this.state.project }, path || altPath, content) });
    };
  }

  renderPrevNext() {
    return (
      <div className="prev-next-block">
        <p
          className="prev-tab"
          onClick={() => {
            this.tabs.setTab(this.tabs.state.tabIdx - 1);
          }}
        >
          Previous
        </p>
        <p
          className="next-tab"
          onClick={() => {
            this.tabs.setTab(this.tabs.state.tabIdx + 1);
          }}
        >
          Next
        </p>
      </div>
    );
  }

  renderFormSubmit() {
    let returnTo = false;
    const locationObject = this.props.location.state;
    if (locationObject && locationObject.returnTo) {
      // eslint-disable-next-line prefer-destructuring
      returnTo = locationObject.returnTo;
    }

    return (
      <div className="form-submit-buttons">
        <input
          className="form-button primary-button"
          form="create-project-form"
          type="submit"
          value="Save"
        />
        {returnTo && (
          <Link to={returnTo} className="div-link-button form-button cancel-button">
            Cancel
          </Link>
        )}
      </div>
    );
  }

  render() {
    const { project } = this.state;
    const {
      createProject,
      users: { data: users }
    } = this.props;

    const defaultInputProps = {
      valueGetter: this.getValueFromId,
      onChange: this.handleInputChange
    };

    let returnTo = false;
    const locationObject = this.props.location.state;
    if (locationObject && locationObject.returnTo) {
      // eslint-disable-next-line prefer-destructuring
      returnTo = locationObject.returnTo;
    }

    if (createProject.error && createProject.error.message) {
      alert = (
        <Alert delay={5000} type="danger">
          <i>
            <IoAlertCircled />
          </i>
          &nbsp;
          {createProject.error.message}
        </Alert>
      );
    }

    const defaultRepeaterProps = {
      data: project,
      addRow: this.addRow,
      delRow: this.delRow,
      onChange: this.handleInputChange,
      valueGetter: this.getValueFromId,
      getPath: this.getPath,
      replacePath: this.replacePath
    };

    return (
      <div className="create-edit-modal">
        {alert}
        <p className="modal-header">{project.name || 'New Project'}</p>
        <Tabs ref={el => (this.tabs = el)}>
          <div idx={0} label="Project Info">
            <form className="modal-form" id="create-project-form" onSubmit={this.handleSubmit}>
              <p className="form-section-title">Project Information</p>
              <div className="input-fields">
                <div className="modal-form-block">
                  <TextInput
                    required
                    multiLine
                    labelText="Project Name*"
                    inputId="name"
                    {...defaultInputProps}
                  />
                  <TextInput
                    required
                    labelText="Job Number*"
                    inputId="number"
                    {...defaultInputProps}
                  />
                </div>
                <div className="modal-form-block">
                  <TextInput labelText="Street" inputId="address.street" {...defaultInputProps} />
                  <TextInput labelText="City" inputId="address.city" {...defaultInputProps} />
                </div>
                <div className="modal-form-block">
                  <TextInput
                    required
                    labelText="Zip Code*"
                    inputId="address.zip"
                    {...defaultInputProps}
                  />
                  <div className="text-input-block select-block">
                    <p className="text-input-standard-label">State*</p>
                    <select
                      required
                      id="address.state"
                      form="create-project-form"
                      className="standard-select"
                      value={this.getValueFromId('address.state')}
                      onChange={this.handleInputChange('address.state')}
                    >
                      {stateOptions.map(state => (
                        <option value={state} key={state}>
                          {state}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="modal-form-block tax">
                  <TextInput
                    labelText="Sales Tax"
                    inputId="tax"
                    inputType="number"
                    {...defaultInputProps}
                  />
                  <span>&nbsp;%</span>
                </div>
              </div>
              <p
                className="next-tab"
                onClick={() => {
                  this.tabs.setTab(this.tabs.state.tabIdx + 1);
                }}
              >
                Next
              </p>
              {this.renderFormSubmit()}
            </form>
          </div>
          <div idx={1} label="Main Contact">
            <form className="modal-form" id="create-project-form" onSubmit={this.handleSubmit}>
              <p className="form-section-title">Main Contact Information</p>
              <p className="form-section-info">
                Select a user from the database or manually enter information in the fields below.
              </p>
              <div className="modal-form-block main-contact-typeahead">
                <Typeahead
                  closeDropdownOnBlur
                  options={users}
                  maxVisible={6}
                  filterOption="searchName"
                  displayOption={({ name }) => combineName(name)}
                  customClasses={typeaheadClasses}
                  onOptionSelected={this.typeaheadSelect('mainContact')}
                  ref={input => (this.typeahead = input)}
                  placeholder="Start typing a name to autofill contact information."
                />
              </div>
              <div className="modal-form-block">
                <TextInput
                  labelText="First Name"
                  inputId="mainContact.name.first"
                  {...defaultInputProps}
                />
                <TextInput
                  labelText="Last Name"
                  inputId="mainContact.name.last"
                  {...defaultInputProps}
                />
              </div>
              <div className="modal-form-block">
                <TextInput labelText="Title" inputId="mainContact.title" {...defaultInputProps} />
              </div>
              <div className="modal-form-block">
                <TextInput labelText="Phone" inputId="mainContact.phone" {...defaultInputProps} />
                <TextInput labelText="Email" inputId="mainContact.email" {...defaultInputProps} />
              </div>
              {this.renderPrevNext()}
              {this.renderFormSubmit()}
            </form>
          </div>
          <div
            idx={2}
            label="Accessors"
            className={this.state.accessorError && 'error'}
            onClick={() => {
              this.setState({ accessorError: false });
            }}
          >
            <form className="modal-form" id="create-project-form" onSubmit={this.handleSubmit}>
              <p className="form-section-title">Accessors</p>
              <p className="form-section-info">
                Please list users associated with this Project. Start typing and select the
                appropriate user from the dropdown. Users listed here who <i>also</i> have been
                assigned ability* to Create/Edit Orders will then be able to do so for this Project.
              </p>
              <p className="form-section-info">
                *Users and individual user permissions may be added & edited (by authorized
                personnel) from the Users page.
              </p>
              <Repeater
                {...{ ...this.props, ...defaultRepeaterProps }}
                noTitle
                single
                buttonRight
                circleRemove
                name="accessors"
                path="accessors"
                className="items-repeats"
                tableID="accessor-repeater"
                fields={[
                  {
                    id: 'accessors',
                    title: 'Accessors*',
                    inputType: 'typeahead',
                    typeaheadKey: 'accessors',
                    typeaheadData: users,
                    typeaheadFilter: 'searchName',
                    typeaheadDisplay: ({ name }) => combineName(name),
                    typeaheadOnSelect: this.typeaheadSelect(),
                    typeaheadValuePath: 'name',
                    typeaheadValueFn: name => combineName(name),
                    required: true
                  }
                ]}
              />
              {this.renderPrevNext()}
              {this.renderFormSubmit()}
            </form>
          </div>
          <div
            idx={3}
            label="Approvers"
            className={this.state.approverError && 'error'}
            onClick={() => {
              this.setState({ approverError: false });
            }}
          >
            <form className="modal-form" id="create-project-form" onSubmit={this.handleSubmit}>
              <p className="form-section-title">Approvers</p>
              <p className="form-section-info">
                Please list the users who will need to approve orders for this Project. The approval
                of <i>all</i> of these users will be required for each Order.
              </p>
              <p className="form-section-info">
                *Please make sure these users have been assigned authority to approve Orders.
                Individual permissions may be edited by authorized users from the Users page.
              </p>
              <Repeater
                {...{ ...this.props, ...defaultRepeaterProps }}
                noTitle
                single
                buttonRight
                circleRemove
                name="approvers"
                path="approvers"
                className="items-repeats"
                tableID="approver-repeater"
                fields={[
                  {
                    id: 'approvers',
                    title: 'Approvers',
                    inputType: 'typeahead',
                    typeaheadKey: 'approvers',
                    typeaheadData: users,
                    typeaheadFilter: 'searchName',
                    typeaheadDisplay: ({ name }) => combineName(name),
                    typeaheadOnSelect: this.typeaheadSelect(),
                    typeaheadValuePath: 'name',
                    typeaheadValueFn: name => combineName(name),
                    required: true
                  }
                ]}
              />
              {this.renderPrevNext()}
              {this.renderFormSubmit()}
            </form>
          </div>
          <div idx={4} label="Subcontract">
            <form className="modal-form" id="create-project-form" onSubmit={this.handleSubmit}>
              <p className="form-section-title">Subcontractor Information (optional)</p>
              <p className="form-section-info">
                If the project has a subcontractor, enter the information below. This information
                will be used when creating Purchase Orders for this Project.
              </p>
              <div className="modal-form-block">
                <TextInput labelText="Owner" inputId="subContract.owner" {...defaultInputProps} />
                <TextInput
                  labelText="Engineer"
                  inputId="subContract.engineer"
                  {...defaultInputProps}
                />
              </div>
              <div className="modal-form-block">
                <TextInput
                  labelText="Principal Contract Date"
                  inputId="subContract.date"
                  inputType="date"
                  {...defaultInputProps}
                />
                <TextInput
                  labelText="Principal Contract Number"
                  inputId="subContract.number"
                  inputType="number"
                  {...defaultInputProps}
                />
              </div>
              {this.renderPrevNext()}
              {this.renderFormSubmit()}
            </form>
          </div>
          <div idx={5} label="Misc Settings">
            <form className="modal-form" id="create-project-form" onSubmit={this.handleSubmit}>
              <p className="form-section-title">Project Offset (optional)</p>
              <p className="form-section-info">
                If you have imported your project from elsewhere and need your Daily Report total
                job hours to begin counting at a number other than zero, enter the amount for
                adjustment below.
              </p>
              <TextInput
                labelText="Project Hours Offset"
                inputId="hoursMod"
                type="number"
                {...defaultInputProps}
              />
              <TextInput
                labelText="Custom PDF Header Text"
                inputId="reportPdfHeader"
                {...defaultInputProps}
              />
              <p
                className="prev-tab"
                onClick={() => {
                  this.tabs.setTab(this.tabs.state.tabIdx - 1);
                }}
              >
                Previous
              </p>
              {this.renderFormSubmit()}
            </form>
          </div>
        </Tabs>
      </div>
    );
  }
}

const mapStateToProps = ({ createProject, user, project, users }) => ({
  createProject,
  user,
  project,
  users
});

const mapDispatchToProps = dispatch => {
  const actions = {
    createProjectSubmit,
    editProject,
    fetchProject,
    createProjectReset,
    fetchUsers
  };
  return { actions: bindActionCreators(actions, dispatch) };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateProject);
