import * as actions from '../../constants/order/createOrder';
import * as moreActions from '../../constants/order/bulkEditOrder';

const initialState = {
  data: {},
  isFetching: false,
  lastUpdated: null,
  error: {},
  submitted: false,
  lastSubmitted: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.CREATE_ORDER_REQUEST:
    case actions.EDIT_ORDER_REQUEST:
    case moreActions.EDIT_BULK_ORDER_REQUEST:
      return { ...state, isFetching: !action.isInterval, error: {} };
    case actions.CREATE_ORDER_FAILURE:
    case actions.EDIT_ORDER_FAILURE:
    case moreActions.EDIT_BULK_ORDER_FAILURE:
      return { ...state, isFetching: false, lastUpdated: action.receivedAt, error: action.error };
    case actions.CREATE_ORDER_SUCCESS:
    case actions.EDIT_ORDER_SUCCESS:
    case moreActions.EDIT_BULK_ORDER_SUCCESS:
      return {
        ...state,
        data: action.data,
        isFetching: false,
        lastUpdated: action.receivedAt,
        error: {},
        submitted: action.receivedAt
      };
    case actions.CREATE_ORDER_RESET:
      return { initialState, lastSubmitted: state.submitted };
    default:
      return state;
  }
};
