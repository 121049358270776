import axios from 'axios';
import * as types from '../../constants/order/createOrder';
import transAxoisErr from '../../etc/axios/transformAxiosError';

export const createOrderRequest = () => ({ type: types.CREATE_ORDER_REQUEST });

export const createOrderReset = () => ({ type: types.CREATE_ORDER_RESET });

export const createOrderSuccess = ({ data }) => ({
  data,
  receivedAt: Date.now(),
  type: types.CREATE_ORDER_SUCCESS
});

export const createOrderFailure = error => ({
  error,
  receivedAt: Date.now(),
  type: types.CREATE_ORDER_FAILURE
});

export const createOrderSubmit = data => dispatch => {
  dispatch(createOrderRequest());

  if (data.reqn.salesTax === '') delete data.reqn.salesTax;
  if (data.reqn.totalOffset === '') delete data.reqn.totalOffset;

  return axios
    .post('/order', data)
    .then(res => dispatch(createOrderSuccess(res.data)))
    .catch(error => dispatch(createOrderFailure(transAxoisErr(error))));
};
