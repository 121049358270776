import React from 'react';
import { browserHistory } from 'react-router';

import sevensonLogo from '../images/sevenson-logo-text-white.png';

import '../sass/containers/Login.scss';

export default class Reset extends React.Component {
  constructor(props) {
    super(props);

    this.token = props.location.query?.token;
    this.sendPasswordReset = this.sendPasswordReset.bind(this);
  }

  getInputWatcher(sibling, reverse) {
    return function watcher(e) {
      if (e.target.value !== sibling.value) return;

      const vTarget = reverse ? sibling : e.target;
      vTarget.setCustomValidity('');
      vTarget.reportValidity();

      e.target.removeEventListener('input', watcher);
      reverse && vTarget.removeEventListener('input', watcher);
    };
  }

  async sendPasswordReset(e) {
    e.preventDefault();

    const data = new FormData(e.target);
    const password = data.get('password');

    const i0 = e.target[0];
    const i1 = e.target[1];

    if (password !== data.get('passwordConfirm')) {
      i1.setCustomValidity('Password do not match!');
      i1.reportValidity();
      i1.addEventListener('input', this.getInputWatcher(i0));
      i0.addEventListener('input', this.getInputWatcher(i1, true));
    }

    const res = await fetch(
      `/api/public/user/set-new-password?token=${this.token}`,
      {
        method: 'post',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify({ password })
      }
    );

    if (!res.ok) {
      let msg = await res.text();
      alert(msg);
    } else {
      alert('Password reset successfully! Redirecting you to login…');
      browserHistory.replace('/login');
    }
  }

  render() {
    return (
      <div className="login-page login-background-image">
        <div className="login-gradient">
          <img
            src={sevensonLogo}
            alt="sevenson-logo"
            className="sevenson-logo"
          />
          <div className="login-container">
            <div className="login-text-top">
              <h1 className="login-header">Reset your Password</h1>
              <h2 className="login-subheader">
                Please set and confirm your new password. After submission you
                will be redirected to log in with your new password.
              </h2>
            </div>
            <form className="login-form" onSubmit={this.sendPasswordReset}>
              <div className="login-form-field">
                <p className="input-title">New Password</p>
                <input
                  required
                  id="password"
                  ref="password"
                  name="password"
                  type="password"
                  minLength={8}
                  autoComplete="new-password"
                />
              </div>
              <div className="login-form-field">
                <p className="input-title">Confirm Password</p>
                <input
                  required
                  id="passwordConfirm"
                  ref="passwordConfirm"
                  name="passwordConfirm"
                  type="password"
                  minLength={8}
                  autoComplete="new-password"
                  className="last-field"
                />
              </div>
              <div className="login-form-button">
                <input type="submit" value="Submit" className="login-button" />
                <div className="forgot-password-link">
                  <a href="/login">Back to Login</a>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
