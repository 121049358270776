import * as actions from '../../constants/vendor/createVendor';

const initialState = {
  data: {},
  isFetching: false,
  lastUpdated: null,
  error: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.CREATE_VENDOR_REQUEST:
    case actions.EDIT_VENDOR_REQUEST:
      return { ...state, isFetching: true, error: {} };
    case actions.CREATE_VENDOR_FAILURE:
    case actions.EDIT_VENDOR_FAILURE:
      return {
        ...state,
        isFetching: false,
        lastUpdated: action.receivedAt,
        error: action.error
      };
    case actions.CREATE_VENDOR_SUCCESS:
    case actions.EDIT_VENDOR_SUCCESS:
      return {
        ...state,
        data: action.data,
        isFetching: false,
        lastUpdated: action.receivedAt,
        error: {},
        submitted: true
      };
    case actions.CREATE_VENDOR_RESET:
      return {
        ...state,
        data: {},
        isFetching: false,
        lastUpdated: null,
        error: {},
        submitted: false
      };
    default:
      return state;
  }
};
