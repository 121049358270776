import React from 'react';

import IosCloseEmpty from 'react-icons/lib/io/ios-close-empty';

import TextInput from '../../components/shared/forms/TextInput';

require('../../sass/components/orders/CommentBox.scss');

export default class CommentBox extends React.Component {
  render() {
    const { position, title, style, COTextInput, close, saveIdx, readOnly, message } = this.props;

    return (
      <div className="comment-box" style={style} onBlur={() => close(saveIdx)}>
        <div className="comment-header">
          Comment for {title} <IosCloseEmpty className="close" onClick={() => close(saveIdx)} />
        </div>
        <div className="comment-body">
          {COTextInput ? (
            <COTextInput multiLine position={position} name={`reqn.comments.${saveIdx}.message`} />
          ) : (
            <TextInput multiLine readOnly={readOnly} value={message} onChange={() => null} />
          )}
        </div>
      </div>
    );
  }
}
