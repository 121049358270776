/* eslint-disable global-require */
/* eslint jsx-a11y/no-static-element-interactions: 0 */
/* eslint no-mixed-operators: 0 */
/* eslint no-nested-ternary: 0 */
/* eslint react/forbid-prop-types: 0 */
import React, { Children, cloneElement } from 'react';
import moment from 'moment';
import propTypes from 'prop-types';
import S3Upload from 'react-s3-uploader/s3upload';
import produce from 'immer';
import { set } from 'dot-prop-immutable';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import { bindActionCreators } from 'redux';
import { getClassNameForFilename } from 'font-awesome-filetypes';

import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import debounce from 'lodash/debounce';

import IoAlertCircled from 'react-icons/lib/io/alert-circled';
import TiTrash from 'react-icons/lib/ti/trash';

import Alert from '../../components/shared/Alert';
import Wizard from '../../components/shared/Wizard';

import OrderWizardBasicInfo from '../../components/orders/OrderWizardBasicInfo';
import OrderWizardProjectInfo from '../../components/orders/OrderWizardProjectInfo';
import OrderWizardVendorInfo from '../../components/orders/OrderWizardVendorInfo';
import OrderWizardPaymentTerms from '../../components/orders/OrderWizardPaymentTerms';
import OrderWizardOtherDetails from '../../components/orders/OrderWizardOtherDetails';
import OrderWizardItems from '../../components/orders/OrderWizardItems';
import OrderWizardNotes from '../../components/orders/OrderWizardNotes';
import OrderWizardUploadFiles from '../../components/orders/OrderWizardUploadFiles';
import OrderWizardAddEmails from '../../components/orders/OrderWizardAddEmails';

import UploadFileModal from '../../components/orders/UploadFileModal';
import TextInput from '../../components/shared/forms/TextInput';
import CommentBox from '../../components/orders/CommentBox';
import Loader from '../../components/shared/Loader';

import setFileViewerFile from '../../actions/fileViewer';
import { editOrder } from '../../actions/order/editOrder';
import { fetchOrder } from '../../actions/order/orders';
import { fetchUsers } from '../../actions/users';
import { fetchVendors } from '../../actions/vendor/vendors';
import { fetchProjects } from '../../actions/projects';
import { createOrderSubmit, createOrderReset } from '../../actions/order/createOrder';
import { fetchReqnDraft, postReqnDraft } from '../../actions/order/reqnDraft';

import * as dfltTerms from '../../constants/order/terms';

import combineName from '../../etc/combineName';
import { getAuthVals } from '../../etc/checkAuth';

require('../../sass/containers/Orders/CreateOrder.scss');
const classNames = require('classnames');

const toFinancial = x => Number.parseFloat(x).toFixed(2);

const icons = {
  'fa-file-image-o': require('react-icons/lib/fa/file-image-o'),
  'fa-file-pdf-o': require('react-icons/lib/fa/file-pdf-o'),
  'fa-file-word-o': require('react-icons/lib/fa/file-word-o'),
  'fa-file-powerpoint-o': require('react-icons/lib/fa/file-powerpoint-o'),
  'fa-file-excel-o': require('react-icons/lib/fa/file-excel-o'),
  'fa-file-audio-o': require('react-icons/lib/fa/file-audio-o'),
  'fa-file-video-o': require('react-icons/lib/fa/file-movie-o'),
  'fa-file-zip-o': require('react-icons/lib/fa/file-archive-o'),
  'fa-file-code-o': require('react-icons/lib/fa/file-code-o'),
  'fa-file-text-o': require('react-icons/lib/fa/file-text-o'),
  'fa-file-o': require('react-icons/lib/fa/file-o')
};

const dfltHeader =
  'Vendor shall furnish all requirements unless explicitly excluded' +
  ' below to furnish, perform and complete the following all in strict compliance with' +
  ' the Principal Contract, including addendum no. ___, for the above-referenced project.';

const getNumberedName = (number, name) => {
  return number ? `${number} — ${name}` : name;
};

const getInitObj = {
  item() {
    return {
      costCode: '',
      description: '',
      unit: '',
      unitPrice: '',
      quantity: '',
      hasTax: true,
      total: ''
    };
  },
  terms() {
    return {
      selectedType: 'netDays',
      retentionRate: '',
      discountRate: '',
      discountDays: '',
      netDays: '45',
      other: ''
    };
  },
  comment(user) {
    return {
      position: '',
      message: '',
      author: {
        email: user.email,
        name: { first: user.name.first, last: user.name.last },
        userRef: user._id
      },
      replies: []
    };
  },
  reqn(user) {
    return {
      number: '',
      isInternal: false,
      status: '',
      isPending: false,
      header: dfltHeader,
      date: moment().format('YYYY-MM-DD'),
      items: [getInitObj.item()],
      subtotal: '0.00',
      salesTax: '',
      taxTotal: '0.00',
      totalOffset: '',
      total: '0.00',
      notes: '',
      company: 'ses',
      division: '',
      costCode: '',
      via: '',
      viaOther: '',
      fob: '',
      fobOther: '',
      schedule: '',
      scheduleOther: '',
      addendumNumber: '',
      paymentTerms: getInitObj.terms(),
      comments: [getInitObj.comment(user)],
      approvers: [],
      tcs: dfltTerms.standard
    };
  }
};

const errorDefs = {
  project: 'Project Required.',
  vendor: 'Vendor Required.',
  otherDetails: 'Schedule, FOB, and Via fields are all required.',
  items: 'Need at least one item.',
  codeCodeCharacters: 'Cost code must be letters or numbers only',
  itemsQuantity: 'A quantity is required on every item.',
  projectAccess: 'Only users listed as approvers & accessors for this project may create orders.',
  files: 'At least one file (Vendor Quote) must be uploaded.'
};

const reduceKeys = keys => keys.reduce((obj, key) => set(obj, key, ''), {});

const COTextInput = ({ onChange, valueGetter, openComment }) => props => {
  const { showError } = props;
  const inputClasses = classNames('text-input-standard', { 'input-error': showError });
  return (
    <TextInput
      setOnChange
      className={inputClasses}
      {...{ onChange, valueGetter, openComment, ...props }}
    />
  );
};

const CORadioInput = ({ getValue }) => ({ name, value, children }) => {
  const id = `${name}.${value}`;
  return (
    <div className="radio-label-group">
      <input
        type="radio"
        onChange={() => {}}
        checked={getValue(name) === value}
        {...{ id, name, value }}
      />
      <label htmlFor={id}>{children}</label>
    </div>
  );
};

const CORadioGroup = ({ onChange, valueGetter, ...props }) => {
  const { showError } = props;
  const selectClasses = classNames('radio-select', { 'input-error': showError });
  return (
    <div className={selectClasses}>
      <div className="radio-option" {...{ onChange }}>
        {Children.map(props.children, child => cloneElement(child, { name: props.name }))}
      </div>
      {props.other && (
        <TextInput
          labelText={props.label}
          setOnChange
          className="text-input-standard"
          disabled={!props.editable || valueGetter(props.name) !== props.other}
          multiLine={props.otherMultiLine}
          name={props.otherName}
          {...{ onChange, valueGetter, ...props.otherProps }}
        />
      )}
    </div>
  );
};

CORadioGroup.propTypes = {
  name: propTypes.string,
  other: propTypes.oneOfType([propTypes.string, propTypes.bool]),
  otherMultiLine: propTypes.bool,
  otherName: propTypes.string,
  otherProps: propTypes.object
};

CORadioGroup.defaultProps = {
  name: '',
  other: 'other',
  otherName: '',
  otherProps: {},
  otherMultiLine: true
};

const CORadioGroupHO = ({ onChange, valueGetter }) => props => (
  <CORadioGroup {...{ onChange, valueGetter, ...props }} />
);

const handlePriceCalculations = (input, nextState) => {
  const inputs = ['quantity', 'costCode', 'unitPrice', 'salesTax', 'hasTax', 'totalOffset'];
  if (input && inputs.find(x => x === input)) return nextState;

  nextState.reqn = produce(nextState.reqn, draft => {
    draft.items.forEach(item => {
      const qty = toFinancial(item.quantity.replace(/,/g, '')) || 1;
      const total = qty * item.unitPrice;

      item.total = toFinancial(
        typeof total === 'number' && !Number.isNaN(total) ? qty * item.unitPrice : 0
      );
    });
  });

  // eslint-disable-next-line no-multi-assign
  const { subtotal, taxTotal, codeTotals } = nextState.reqn.items.reduce(
    // eslint-disable-next-line no-shadow
    ({ subtotal, taxTotal, codeTotals }, item) => {
      if (!item.quantity || !item.unitPrice) return { subtotal, taxTotal, codeTotals };

      const intTotal = parseFloat(item.total);
      const itemTax = !item.hasTax ? 0 : intTotal * nextState.reqn.salesTax * 1e-2;
      const intTotalWithTax = intTotal + itemTax;

      // eslint-disable-next-line no-param-reassign
      subtotal += intTotal;
      // eslint-disable-next-line no-param-reassign
      taxTotal += itemTax;

      const { costCode } = item;
      if (costCode) {
        codeTotals[costCode] = codeTotals[costCode] || 0;
        codeTotals[costCode] += intTotalWithTax;
      }

      return { subtotal, taxTotal, codeTotals };
    },
    { subtotal: 0, taxTotal: 0, codeTotals: {} }
  );

  const total = subtotal + taxTotal + nextState.reqn.totalOffset * 1;

  return produce(nextState, state => {
    state.reqn.total = toFinancial(total);
    state.reqn.taxTotal = toFinancial(taxTotal);
    state.reqn.subtotal = toFinancial(subtotal);
    state.reqn.codeTotals = codeTotals;
  });
};

function checkCategoryChanges(name, value, _state) {
  return produce(_state, state => {
    if (/reqn\.tcs/.test(name)) state.reqn.tcsCustom = true;
    if (name !== 'order.category') return state;

    const { header } = state.reqn;

    if (!state.reqn.tcsCustom) {
      if (value === 'other') {
        state = set(state, 'reqn.tcs', dfltTerms.standard);
      } else {
        state = set(state, 'reqn.tcs', dfltTerms[value]);
      }
    }

    if (!header || header === dfltHeader) {
      if (/rental|other/.test(value)) {
        state = set(state, 'reqn.header', '');
      } else {
        state = set(state, 'reqn.header', dfltHeader);
      }
    }
  });
}

function parseProjectAndVendorInfo(nextState = {}, project = {}, vendor = {}) {
  if (!project) project = {};
  if (!vendor) vendor = {};

  const mc = project.mainContact || {};
  const mcName = mc.name || {};

  nextState.mainContact = mc;
  nextState.mainContact.name = combineName(mcName) || 'No main contact provided';

  nextState.projectObj = project;

  nextState.project = project.name;
  nextState.order.projectRef = project._id;

  nextState.accessors = project.accessors;
  nextState.approvers = project.approvers;

  const rep = vendor.representative || {};
  const rName = rep.name || {};

  nextState.representative = rep;
  nextState.representative.name = combineName(rName) || 'No information provided';

  nextState.vendor =
    vendor.numberStr || vendor.name ? getNumberedName(vendor.numberStr, vendor.name) : '';
  nextState.vendorName = vendor.name;
  nextState.order.vendorRef = vendor._id;
}

class CreateOrder extends React.Component {
  static need = [fetchProjects.bind(null, { limit: -1 }), fetchVendors.bind(null, { limit: -1 })];

  constructor(props) {
    super(props);

    this.state = {
      order: {
        category: 'standard',
        isTD: false,
        number: '',
        lastUpdater: {
          name: {
            first: '',
            last: ''
          }
        },
        files: [],
        emailRecipients: [props.user && props.user.data && props.user.data.email],
        vendorRef: null,
        projectRef: null,
        isPublished: false,
        hasEmailed: false,
        totalProgress: null
      },
      files: {},
      filesArray: [],
      error: {},
      totalProgress: null,
      reqn: getInitObj.reqn(props.user && props.user.data),
      mainContact: {},
      representative: {},
      useOld: false
    };

    this.cancelCreate = this.cancelCreate.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleProjectSelect = this.handleProjectSelect.bind(this);
    this.handleVendorSelect = this.handleVendorSelect.bind(this);
    this.trackWizard = this.trackWizard.bind(this);
    this.trackWizardDrawer = this.trackWizardDrawer.bind(this);
    this.createDrawerContent = this.createDrawerContent.bind(this);
    this.openFileViewer = this.openFileViewer.bind(this);
    this.deleteAttachment = this.deleteAttachment.bind(this);

    this.saveFilesToOrder = this.saveFilesToOrder.bind(this);
    this.onUploadProgress = this.onUploadProgress.bind(this);
    this.onUploadFinish = this.onUploadFinish.bind(this);
    this.onUploadError = this.onUploadError.bind(this);
    this.handleDrop = this.handleDrop.bind(this);

    this.openUploadModal = this.openUploadModal.bind(this);
    this.closeUploadModal = this.closeUploadModal.bind(this);

    this.makeErrors = this.makeErrors.bind(this);

    this.openCommentBox = this.openCommentBox.bind(this);
    this.closeCommentBox = this.closeCommentBox.bind(this);

    this.handleDropTc = this.handleDropTc.bind(this);
    this.unsetTcsPdf = this.unsetTcsPdf.bind(this);

    this.toggleUseOldReqn = this.toggleUseOldReqn.bind(this);
    this.getCloseoutAlertFn = this.getCloseoutAlertFn.bind(this);

    this.draftSave = debounce(this.draftSave.bind(this), 1e3, { maxWait: 10e3 });

    this.inputCtrls = {
      getValue: this.getValue.bind(this),
      handleInputChange: this.handleInputChange.bind(this),
      openComment: this.openCommentBox.bind(this)
    };

    this.inputCtrls.onChange = this.inputCtrls.handleInputChange;
    this.inputCtrls.valueGetter = this.inputCtrls.getValue;

    this.repeaterCtrls = {
      addRow: this.addRow.bind(this),
      delRow: this.delRow.bind(this),
      getPath: this.inputCtrls.getValue,
      replacePath: this.replacePath.bind(this)
    };

    this.inputs = {
      COTextInput: COTextInput(this.inputCtrls),
      CORadioGroup: CORadioGroupHO(this.inputCtrls),
      CORadioInput: CORadioInput(this.inputCtrls)
    };
  }

  componentWillMount() {
    if (this.props.route.path === 'orders/:id/reqns/:reqnId/edit') {
      this.checkAuthState(true);

      this.setState({ editForm: true });
      this.props.actions.fetchOrder({ id: this.props.params.id });
    } else if (this.props.route.path === 'orders/:id/reqns/create') {
      this.checkAuthState(false, true);

      this.setState({ changeOrder: true });
      this.props.actions.fetchOrder({ id: this.props.params.id });
    } else {
      this.checkAuthState();
    }

    if (!this.props.users || !this.props.users.data || !this.props.user.data.length) {
      this.props.actions.fetchUsers();
    }

    if (!this.props.projects || !this.props.projects.data || !this.props.projects.data.length) {
      this.props.actions.fetchProjects({ limit: -1 });
    }

    if (!this.props.vendors || !this.props.vendors.data || !this.props.vendors.data.length) {
      this.props.actions.fetchVendors({ limit: -1 });
    }

    this.props.actions.fetchReqnDraft(this.props.params.reqnId);
  }

  componentWillReceiveProps(nextProps) {
    const { editForm, changeOrder } = this.state;
    const { order, createVendor, createProject } = this.props;

    if (!order.data !== nextProps.order.data) {
      this.checkAuthState(editForm, changeOrder);
    }

    const nextVendors = nextProps.createVendor;
    const nextProjects = nextProps.createProject;

    if (this.props.project.data !== nextProps.project.data) {
      const fetchedProject = nextProps.project.data;
      this.setState({ project: fetchedProject });
    } else if (nextProps.createOrder.submitted) {
      if (
        window.confirm('Order saved successfully, would you like to go back to the orders list?')
      ) {
        browserHistory.push('/orders');
      }
    }

    if (
      !createProject.isFetching &&
      !nextProjects.isFetching &&
      createProject.submitted &&
      nextProjects.submitted
    ) {
      // TODO add action for typeahead fetch
      this.handleProjectSelect(nextProjects.data);
      this.props.actions.fetchProjects({ limit: -1 });
    }

    if (
      !createVendor.isFetching &&
      !nextVendors.isFetching &&
      createVendor.submitted &&
      nextVendors.submitted
    ) {
      this.handleVendorSelect(nextVendors.data);
      this.props.actions.fetchVendors({ limit: -1 });
    }

    if (!this.state.editForm && !this.state.changeOrder) return;

    if (
      nextProps.order.data &&
      this.props.order.data !== nextProps.order.data &&
      !nextProps.createOrder.submitted
    ) {
      const nextState = { ...this.state };
      const fetchedOrder = nextProps.order.data;

      nextState.order = fetchedOrder;

      parseProjectAndVendorInfo(nextState, fetchedOrder.projectRef, fetchedOrder.vendorRef);

      const { reqns } = fetchedOrder;
      const { reqnId } = nextProps.params;
      const { data: userData } = this.props.user;

      // set reqn info
      if (changeOrder) {
        nextState.reqn = cloneDeep(fetchedOrder.reqns.slice(-1)[0]);

        if (!nextState.reqn.isChangeOrder) {
          // that means it's the first change order
          nextState.reqn.isChangeOrder = true;
          nextState.reqn.number = 0;
        }

        nextState.reqn.originalPONumber = fetchedOrder.number;
        nextState.reqn.status = '';
        nextState.reqn.number = parseInt(nextState.reqn.number) + 1;

        if (!this.state.useOld) {
          //clear items unless useOld button clicked
          nextState.reqn.items = [getInitObj.item()];
        }

        delete nextState.reqn.id;
        delete nextState.reqn._id;
        delete nextState.reqn.author;
        delete nextState.reqn.createdAt;
        delete nextState.reqn.updatedAt;
      } else if (reqns && reqns.length && reqnId) {
        nextState.reqn = cloneDeep(reqns.find(reqn => reqn._id === reqnId));
      } else {
        nextState.reqn = getInitObj.reqn(userData);
      }
      const { reqn } = nextState;

      if (!nextState.reqn.tcs.length) {
        const { category } = nextState.order;
        nextState.reqn.tcs = category === 'other' ? dfltTerms.standard : dfltTerms[category];
      }

      const { comments } = reqn;

      reqn.date = moment.utc(nextState.reqn.date).format('YYYY-MM-DD');
      reqn.comments = comments && comments.length ? reqn.comments : [getInitObj.comment(userData)];

      this.setState(nextState);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { order, reqn } = this.state;

    if (this.state.reqn !== prevState.reqn || this.state.order !== prevState.order) {
      this.draftSave();
    } else if (this.props.reqnDraftGet.submitted !== prevProps.reqnDraftGet.submitted) {
      this.draftSave(true);
    }

    this.leavePrompt(prevState.order !== order || prevState.reqn !== reqn);
  }

  componentWillUnmount() {
    this.leavePrompt(false);

    if (this.props.createOrder.submitted) {
      this.props.actions.createOrderReset();
    }
  }

  onUploadError(error, { name }) {
    console.error(error);
    this.setState({ error: { ...error, fileName: name } });
  }

  onUploadFinish(res, { name }, cb) {
    const match = res.signedUrl.match(/(.*)\?/);
    const url = match && match[1];

    if (url) {
      const nextState = { ...this.state };

      nextState.files[name].url = url;
      nextState.filesArray.push({
        url,
        name: url
          .split('_')
          .slice(1)
          .join('')
      });
      this.setState(nextState, cb);
    } else {
      const err = 'Something has happened in onUploadFinish that should not have.';
      cb(err);
    }
  }

  onUploadProgress(progress, status, { name }) {
    const files = { ...this.state.files };

    files[name] = files[name] || {};
    files[name].status = status;
    files[name].progress = progress;

    const filesArray = Object && files && Object.values ? Object.values(files) : [];
    const totalProgress = filesArray.reduce((acc, f) => acc + f.progress, 0) / filesArray.length;

    this.setState({ files, totalProgress });
  }

  getValue(key) {
    return get(this.state, key) || '';
  }

  handleDrop(files) {
    files.forEach(file => {
      const upload = new S3Upload({ signingUrl: '/api/s3/attachments/sign' });
      S3Upload.prototype.onFinishS3Put = this.onUploadFinish;
      S3Upload.prototype.onProgress = this.onUploadProgress;
      S3Upload.prototype.onError = this.onUploadError;
      upload.handleFileSelect([file]);
    });
  }

  handleDropTc(files) {
    files.forEach(file => {
      const upload = new S3Upload({ signingUrl: '/api/s3/attachments/sign' });

      S3Upload.prototype.onFinishS3Put = (res, obj) =>
        this.onUploadFinish(res, obj, () => {
          const nextState = { ...this.state };
          nextState.reqn.tcsPdf = this.state.filesArray.slice(-1)[0].url;
          nextState.filesArray = [];
          nextState.files = {};
          this.setState(nextState);
        });

      S3Upload.prototype.onProgress = this.onUploadProgress;
      S3Upload.prototype.onError = this.onUploadError;

      upload.handleFileSelect([file]);
    });
  }

  unsetTcsPdf() {
    const nextState = { ...this.state };
    nextState.reqn.tcsPdf = null;
    this.setState(nextState);
  }

  checkAuthState(isEdit, isCO) {
    const { order, reqn } = this.state;

    const { authOverrides, authLevel } = this.props.user.data || {};
    const orderAOs = (authOverrides && authOverrides.orders) || {};
    const superUser = authLevel === 100;

    const canEdit = authOverrides && orderAOs.edit;
    const canCreate = authOverrides && orderAOs.create;
    const canApprove = authOverrides && orderAOs.approve;

    const unknown = !order.data || !order.data._id;

    const hasAccess =
      (canEdit && reqn.status === 'needEdit') ||
      (canCreate && (reqn.status === 'draft' || !reqn.status || isCO)) ||
      (canApprove && reqn.status === 'open');

    if (
      (isEdit && (hasAccess || (unknown && (canEdit || canApprove)))) ||
      (hasAccess || (unknown && canCreate)) ||
      superUser
    ) {
      return;
    }

    if (global.window) {
      browserHistory.replace('/401');
    }
  }

  leavePrompt(isUnsaved = false, leaveMsg = 'Are you sure you want to leave?') {
    const { router, route } = this.props;
    if (!this.skipLeavePrompt) {
      // eslint-disable-next-line no-alert
      router.setRouteLeaveHook(route, isUnsaved && (() => window && window.confirm(leaveMsg)));
      window.onbeforeunload = isUnsaved && (() => leaveMsg);
    }
  }

  handleInputChange({ target }) {
    if (!target) return;

    const { name, type, checked, value: _value } = target;
    const value = type === 'checkbox' ? checked : _value;

    let { state } = this;

    state = set(state, name, value);
    state = handlePriceCalculations(name, state);
    state = checkCategoryChanges(name, value, state);
    state = this.toggleUseOldReqn(name, value, state);

    this.setState(state);
  }

  handleProjectSelect({ _id, mainContact = {}, nameNumber, name, tax, approvers, accessors }) {
    this.setState(
      produce(this.state, nextState => {
        nextState.mainContact = mainContact;
        nextState.mainContact.name =
          combineName(mainContact.name) || mainContact.name || 'No main contact provided.';
        nextState.order.projectRef = _id;
        nextState.projectName = nameNumber;
        nextState.project = name;
        nextState.reqn.salesTax = tax || '0.00';
        nextState.approvers = approvers;
        nextState.accessors = accessors;
      })
    );
  }

  handleVendorSelect({ _id, representative = {}, numberedName, name, number }) {
    this.setState(
      produce(this.state, nextState => {
        nextState.representative = representative;
        nextState.representative.name =
          combineName(representative.name) || 'No information provided';
        nextState.order.vendorRef = _id;
        nextState.vendor = numberedName || getNumberedName(number, name);
      })
    );
  }

  makeErrors(finalSubmit) {
    // TODO this is an inane way to do things
    const { reqn, order, showErrors, accessors, approvers } = this.state;
    const { items, isChangeOrder } = reqn;
    const { user } = this.props;
    const currentUser = user.data._id;
    const superUser = user.data.authLevel === 100;

    if (!showErrors && !finalSubmit) return false;
    // these are the error messages
    const errors = [];

    // these fields correspond to the fields the input will check for errors
    const errorFields = {};

    // specify which pages in the wizard have errors by index
    const errorPages = [];

    if (!order.projectRef && !isChangeOrder) {
      errors.push(errorDefs.project);
      errorFields.project = true;
      errorPages.push(1);
    } else if (
      order.projectRef &&
      accessors &&
      approvers &&
      !(
        superUser ||
        approvers.find(approver => approver.userRef === currentUser) ||
        accessors.find(accessor => accessor.userRef === currentUser)
      )
    ) {
      errors.push(errorDefs.projectAccess);
      errorFields.projectAccess = true;
      errorPages.push(isChangeOrder ? 0 : 1);
    }

    if (!order.vendorRef && !isChangeOrder) {
      errors.push(errorDefs.vendor);
      errorFields.vendor = true;
      errorPages.push(2);
    }

    if (!reqn.schedule || !reqn.fob || !reqn.via) {
      errors.push(errorDefs.otherDetails);
      errorFields.otherDetails = true;
      errorPages.push(4);
    }

    const itemsIdx = isChangeOrder ? 3 : 5;

    if (!items[0].description) {
      errors.push(errorDefs.items);
      errorFields.items = true;
      if (!errorPages.includes(itemsIdx)) {
        errorPages.push(itemsIdx);
      }
    }

    if (items.length) {
      let missingQuantity = false;
      items.forEach(item => {
        if (isNaN(parseInt(item.quantity))) {
          missingQuantity = true;
        }
      });
      if (missingQuantity) {
        errors.push(errorDefs.itemsQuantity);
        errorFields.itemsQuantity = true;
        if (!errorPages.includes(itemsIdx)) {
          errorPages.push(itemsIdx);
        }
      }
    }

    if (reqn.costCode) {
      if (/[^a-zA-Z0-9-]/.test(reqn.costCode.trim())) {
        errors.push(errorDefs.codeCodeCharacters);
        errorFields.costCodeCharacters = true;
        if (!errorPages.includes(itemsIdx)) {
          errorPages.push(itemsIdx);
        }
      }
    }

    if (!order.files || !order.files.length) {
      errors.push(errorDefs.files);
      if (!errorPages.includes(7)) {
        errorPages.push(7);
      }
      if (!this.state.wizardDrawer) {
        this.wizard.toggleDrawer();
        this.trackWizardDrawer(true);
      }
    }

    this.setState({ errors, errorPages, errorFields });
    return !!errors.length;
  }

  cancelCreate() {
    const { order } = this.state;
    if (window.confirm('Are you sure you want to leave?')) {
      if (order._id) {
        browserHistory.push(`/orders/${order._id}`);
      } else {
        browserHistory.push('/orders');
      }
    }
  }

  handleSubmit(draft, extraDraft) {
    // eslint-disable-next-line no-unused-expressions

    if (!draft && this.makeErrors(true)) {
      this.setState({ showErrors: true });
      return;
    }

    const { changeOrder, editForm } = this.state;
    const draftCheckbox = !(this.wizard && this.wizard.trackCheckbox());

    const reqn = produce(this.state.reqn, reqn => {
      if (!changeOrder && !editForm) {
        reqn.originalPONumber = reqn.number;
      }

      switch (reqn.status) {
        case 'open':
          /**
           *  TODO: might need a check here to skip 'pendingFinalApproval'
           *  if there are no project approvers.
           */
          reqn.status = draftCheckbox ? 'pendingFinalApproval' : 'needEdit';
          break;
        case 'needEdit':
          reqn.status = 'open';
          break;
        case 'pendingFinalApproval':
          if (!draftCheckbox) {
            reqn.status = 'needEdit';
            reqn.approvers.forEach(a => (a.approved = false));
          } else {
            let allApproved = true;
            reqn.approvers.forEach(a => {
              a.userRef === this.props.user.data._id && (a.approved = true);
              !a.approved && (allApproved = false);
            });
            allApproved && (reqn.status = 'needSignature');
          }
          break;
        default:
          reqn.status = draftCheckbox || draft ? 'draft' : 'open';
      }

      if (reqn.isInternal) {
        reqn.number -= 1;
      }
    });

    const submitObject = produce(this.state.order, submitObject => {
      submitObject.reqn = reqn;
    });

    if (extraDraft) {
      this.props.actions.postReqnDraft(reqn.id, {
        ...reqn,
        order: this.state.order,
        projectRef: this.state.order.projectRef,
        vendorRef: this.state.order.vendorRef
      });
    } else if (editForm || changeOrder) {
      this.props.actions.editOrder(submitObject, this.state.order._id);
    } else {
      this.props.actions.createOrderSubmit(submitObject);
    }
  }

  trackWizard(step) {
    this.setState({ wizardPage: step });
  }

  trackWizardDrawer(state) {
    this.setState({ wizardDrawer: state });
  }

  toggleUseOldReqn(name, value, state) {
    if (name !== 'useOld') return state;
    if (value) {
      const clonedOriginalReq = cloneDeep(this.state.order.reqns.slice(-1)[0]);
      state.reqn.items = clonedOriginalReq.items;
    } else {
      state.reqn.items = [getInitObj.item()];
    }
    return state;
  }

  addRow(path, keys) {
    return cb => {
      if (path === 'emailRecipients') {
        const emailRecipients = [...this.state.order.emailRecipients];
        emailRecipients.push('');
        this.setState({ order: set({ ...this.state.order }, path, emailRecipients) }, cb);
      } else {
        const newItem = typeof keys === 'string' ? keys : reduceKeys(keys);
        if (path !== 'tcs') newItem.hasTax = true;
        this.setState(
          {
            reqn: produce(this.state.reqn, newReqn => {
              newReqn[path].push(newItem);
            })
          },
          cb
        );
      }
    };
  }

  delRow(path, idx) {
    return () => {
      // eslint-disable-next-line no-alert
      if (!window.confirm('Are you sure you want to delete this row?')) return;
      if (path === 'emailRecipients') {
        const emailRecipients = [...this.state.order.emailRecipients];
        emailRecipients.splice(idx, 1);
        this.setState({ order: set({ ...this.state.order }, path, emailRecipients) });
      } else {
        const reqn = produce(this.state.reqn, reqn => {
          const parent = get(reqn, path);
          parent.splice(idx, 1);
        });
        this.setState({ reqn: handlePriceCalculations(false, { reqn }).reqn });
      }
    };
  }

  replacePath(path, content) {
    if (path === 'emailRecipients') {
      this.setState({ order: set({ ...this.state.order }, 'emailRecipients', content) });
    } else {
      this.setState({ reqn: set({ ...this.state.reqn }, path, content) });
    }
  }

  saveFilesToOrder() {
    this.setState(
      produce(this.state, nextState => {
        nextState.order.files = nextState.order.files.concat(this.state.filesArray);
        nextState.filesArray = [];
        nextState.files = {};
        nextState.uploadModal = false;
      })
    );
  }

  openUploadModal() {
    this.setState({ uploadModal: true });
  }

  closeUploadModal() {
    this.setState({ uploadModal: false, filesArray: [] });
  }

  deleteAttachment(idx) {
    return () => {
      if (window.confirm('Are you sure you want to delete this attachment?')) {
        const { order } = this.state;
        this.setState({
          order: { ...order, files: order.files.filter((f, fIdx) => fIdx !== idx) }
        });
      }
    };
  }

  openFileViewer(file) {
    return () => {
      this.trackWizardDrawer(false);
      this.wizard.toggleDrawer();
      this.props.actions.setFileViewerFile(file);
    };
  }

  createDrawerContent() {
    const files = produce(this.state.order.files, files => {
      files = files
        .map(x => {
          x.created = moment(x.createdAt);
          return x;
        })
        .sort((a, b) => b.created - a.created);
    });

    return (
      <div>
        <button className="standard-button secondary-button" onClick={this.openUploadModal}>
          Upload
        </button>
        {files.map((file, idx) => (
          <div key={file.url} className="list-item-container">
            <button onClick={this.openFileViewer(file)} tabIndex="-1" className="files-list-item">
              {icons[getClassNameForFilename(file.url)]({
                size: 50,
                color: '#4a90e2',
                className: 'file-icon'
              })}
              <div className="file-info-container">
                <div className="file-name">{file.name}</div>
                <div className="file-date">{file.created.fromNow()}</div>
                <div className="file-date">Click to View Inline</div>
              </div>
            </button>
            <button
              type="button"
              className="delete-button circle-delete attachment-delete"
              onClick={this.deleteAttachment(idx)}
            >
              <TiTrash size={22} />
            </button>
          </div>
        ))}
      </div>
    );
  }

  createCheckboxAndLabels(order, reqn) {
    const { editForm, changeOrder, projectObj } = this.state;
    const authv = getAuthVals(this.props.user.data, { ...order, projectRef: projectObj });

    // checkboxFinish is the text of the button when checkbox is checked
    let checkboxFinish = '';
    // checkbox is the text next to the checkbox. probably don't need both this and disabledCB
    let checkbox = null;
    let allPageTitle = 'Opening New Order/Purchase Order';
    let extraSubtitle = null;
    // 'disabled' disables the save button
    let disabled = true;
    // 'disabledCB' hides the checkbox
    let disabledCB = true;

    if (!reqn.status || reqn.status === 'draft') {
      checkboxFinish = 'Submit for Review';
      disabled = !authv.canAddNewOrders;
      disabledCB = !authv.canAddNewOrders;
    } else if (reqn.status === 'open' || reqn.status === 'pendingFinalApproval') {
      checkboxFinish = 'Save & Approve';
      disabled = !authv.isHQ && !authv.isApprover;
    } else if (reqn.status === 'needEdit') {
      disabled = !authv.canEdit;
    }

    if (changeOrder || !reqn.status || reqn.status === 'draft') {
      checkbox = 'Submit';
      disabled = !authv.canAddNewOrders;
    } else if (reqn.status === 'open') {
      checkbox = 'Approve';
      disabledCB = !authv.isHQ && !authv.isSuper;
      disabled = !authv.canEdit;
    } else if (reqn.status === 'pendingFinalApproval') {
      checkbox = 'Approve';
      disabledCB = !authv.isApprover;
      disabled = !authv.canEdit;
    }

    if (changeOrder) {
      allPageTitle = `Creating Change Order for PO #${reqn.originalPONumber}`;
    } else if (reqn.isChangeOrder) {
      allPageTitle = reqn.originalPONumber
        ? `Editing Change Order ${
            reqn.isInternal
              ? `Internal ${moment.utc(reqn.createdAt).format('M-D-YY')}`
              : `#${reqn.number}`
          } for PO #${reqn.originalPONumber}`
        : `Editing Change Order ${
            reqn.isInternal
              ? `Internal ${moment.utc(reqn.createdAt).format('M-D-YY')}`
              : `#${reqn.number}.`
          }`;
    } else if (editForm) {
      allPageTitle = `Editing Purchase Order #${reqn.number}`;
    }

    if (changeOrder) {
      extraSubtitle = `Project: ${order.projectNumber}, Vendor: ${order.vendorName}`;
    }

    return {
      checkboxFinish,
      checkbox,
      allPageTitle,
      disabled,
      disabledCB,
      extraSubtitle
    };
  }

  openCommentBox({ labelText, position }, e) {
    const nextState = cloneDeep(this.state);
    const { comments } = nextState.reqn;
    const { data: userData } = this.props.user;
    let saveIdx;
    const left = e.clientX - 200 > 0 ? e.clientX - 200 : e.clientX;

    if (!comments[0] || !comments[0].position) {
      saveIdx = 0;
      comments[0] = getInitObj.comment(userData);
    } else {
      saveIdx = comments.findIndex(comment => comment.position === position);

      if (saveIdx < 0) {
        comments.push(getInitObj.comment(this.props.user.data));
        saveIdx = comments.length - 1;
      }
    }
    comments[saveIdx].position = position;
    nextState.addComment = { labelText, position, saveIdx, top: e.clientY, left };
    this.setState(nextState);
  }

  closeCommentBox(saveIdx) {
    const nextState = cloneDeep(this.state);
    const { comments } = nextState.reqn;

    if (!comments[saveIdx].message) {
      comments.splice(saveIdx, 1);
    }

    nextState.addComment = false;
    this.setState(nextState);
  }

  draftSave(loadOnly) {
    const reqnDraft = this.props.reqnDraftGet;

    if (reqnDraft.submitted > reqnDraft.lastSubmitted && !this.state.draftLoaded) {
      if (!reqnDraft.data || !reqnDraft.data.reqn) {
        return this.setState({ draftLoaded: true });
      }

      const draft = reqnDraft.data.reqn;
      const draftOrder = reqnDraft.data.order;
      const date = moment.utc(reqnDraft.data?.updatedAt).fromNow();
      const edit = !!draft.id;

      const confirmed =
        this.state.draftLoaded ||
        window.confirm(
          (edit
            ? 'It appears you have a draft for this order,'
            : 'It appears you have a draft for an unsaved order,') +
            ` last updated ${date}, would you like to load it?` +
            '\n\nCaution: not loading the draft will delete it.'
        );

      if (confirmed) {
        const nextState = produce(this.state, nextState => {
          nextState.draftLoaded = true;
          parseProjectAndVendorInfo(nextState, draft.projectRef, draft.vendorRef);
          nextState.reqn = produce(draft, x => {
            x.date = moment.utc(x.date).format('YYYY-MM-DD');
          });
          if (draftOrder) {
            nextState.order = produce(nextState.order, x => {
              x.isTD = draftOrder.isTD;
              x.category = draftOrder.category;
              x.notToExceed = draftOrder.notToExceed;
              x.files = draftOrder.files;
              x.emailRecipients = draftOrder.emailRecipients;
            });
          }
        });

        this.setState(nextState);
      } else {
        this.setState({ draftLoaded: true });
      }
    } else if (
      !loadOnly &&
      this.state.draftLoaded &&
      !reqnDraft.isFetching &&
      !this.props.createOrder.isFetching &&
      !this.props.createOrder.submitted &&
      /^orders\/(:id\/reqns|create)/.test(this.props.route.path)
    ) {
      this.handleSubmit(true, true);
    }
  }

  getCloseoutAlertFn(alertDelay, returnTo) {
    return msg => {
      msg =
        'An error has occurred, possibly because you have arrived here by mistake: \n\n"' +
        msg +
        '"\n\nIf you continue, your changes may not be saved or may create problems in the' +
        ' order process.\n\nIf you think you have received this message by mistake,' +
        ' please contact an administrator or the development team.' +
        '\n\nPlease confirm to be redirected to the orders list.';

      return window.setTimeout(() => {
        if (window.confirm(msg)) {
          return browserHistory.push(returnTo || '/orders');
        }
      }, alertDelay);
    };
  }

  render() {
    const {
      reqn,
      order,
      totalProgress,
      filesArray,
      changeOrder,
      editForm,
      addComment,
      errorFields,
      approvers,
      accessors,
      project
    } = this.state;
    const { user, location } = this.props;
    const { comments } = reqn;
    const { createOrder } = this.props;
    const projects = this.props.projects.data;
    const vendors = this.props.vendors.data;

    const currentUser = user.data._id;

    const projectLoaded = !!project;
    const isApprover = approvers && approvers.find(approver => approver.userRef === currentUser);
    const isAccessor = accessors && accessors.find(accessor => accessor.userRef === currentUser);

    vendors.forEach(v => (v.numberedName = getNumberedName(v.numberStr, v.name)));

    const { authOverrides, authLevel } = user.data;
    const canNumber = authLevel === 100 || (authOverrides && authOverrides.orders.number);

    projects.forEach(p => {
      p.numberName = `${p.number} – ${p.name}`;
    });

    let returnTo = false;
    const locationObject = this.props.location.state;
    if (locationObject && locationObject.returnTo) {
      // eslint-disable-next-line prefer-destructuring
      returnTo = locationObject.returnTo;
    }

    const alertDelay = 5e3;

    let alert;
    if (createOrder.error && createOrder.error.message) {
      alert = (
        <Alert delay={alertDelay} type="danger">
          <i>
            <IoAlertCircled />
          </i>
          &nbsp;
          {createOrder.error.message}
        </Alert>
      );
    }

    if (this.props.route.path === 'orders/:id/reqns/:reqnId/edit' && global.window) {
      const { author, status } = reqn;
      const superUser = authLevel === 100;

      const getAlert = this.getCloseoutAlertFn(alertDelay, returnTo);

      if (status === 'complete' || status === 'needSignature') {
        this.skipLeavePrompt = true;

        const msg =
          'This requisition is already approved.' +
          ' Please open a change order if changes are desired.';

        alert = (
          <Alert delay={alertDelay} type="danger">
            <i>
              <IoAlertCircled />
            </i>
            &nbsp; {msg}
          </Alert>
        );

        this.redir = getAlert(msg);
      } else {
        this.redir && window.clearTimeout(this.redir);
      }

      if (superUser) {
        alert = '';
      } else if (projectLoaded && !isApprover && !isAccessor) {
        this.skipLeavePrompt = true;

        const msg = `Only users marked as approvers or accessors for Job "${project}" may edit orders for Job "${project}."`;

        alert = (
          <Alert delay={alertDelay} type="danger">
            <i>
              <IoAlertCircled />
            </i>
            &nbsp; {msg}
          </Alert>
        );

        this.redir = getAlert(msg);
      } else if (projectLoaded && approvers && status === 'open' && !isApprover) {
        this.skipLeavePrompt = true;

        const msg = `Only users listed as Approvers for Project ${project} may edit an open order.`;

        alert = (
          <Alert delay={alertDelay} type="danger">
            <i>
              <IoAlertCircled />
            </i>
            {msg}
          </Alert>
        );

        this.redir = getAlert(msg);
      } else if (
        projectLoaded &&
        approvers &&
        !isApprover &&
        author &&
        user.data._id !== author.userRef &&
        (status === 'needEdit' || status === 'draft')
      ) {
        this.skipLeavePrompt = true;

        const msg = 'Requisitions may only be edited by their original author.';

        alert = (
          <Alert delay={alertDelay} type="danger">
            <i>
              <IoAlertCircled />
            </i>
            &nbsp; {msg}
          </Alert>
        );

        this.redir = getAlert(msg);
      } else {
        this.redir && window.clearTimeout(this.redir);
      }
    }

    let finishLabel;

    switch (reqn.status) {
      case 'open':
      case 'pendingFinalApproval':
        finishLabel = 'Request Edits';
        break;
      default:
        finishLabel = 'Save as Draft';
    }

    return (
      <div className="wizard-page">
        <Loader isFetching={createOrder.isFetching} />
        {alert}
        {this.state.uploadModal && (
          <UploadFileModal
            {...this.inputs}
            toCancel={this.closeUploadModal}
            totalProgress={totalProgress}
            filesArray={filesArray}
            save={this.saveFilesToOrder}
            handleDrop={this.handleDrop}
          />
        )}
        {addComment && (
          <CommentBox
            {...this.inputs}
            title={addComment.labelText}
            style={{ top: addComment.top + 40, left: addComment.left }}
            close={this.closeCommentBox}
            saveIdx={addComment.saveIdx}
            position={addComment.position}
          />
        )}
        <Wizard
          reqn={reqn}
          order={order}
          drawer={{
            button: this.state.wizardDrawer ? 'Hide Files' : 'Show Files',
            content: this.createDrawerContent(),
            className: 'upload-drawer'
          }}
          trackWizard={this.trackWizard}
          trackWizardDrawer={this.trackWizardDrawer}
          onNext={this.makeErrors}
          handleFinish={this.handleSubmit}
          finishLabel={finishLabel}
          {...this.createCheckboxAndLabels(order, reqn)}
          ref={el => (this.wizard = el)}
          cancel={this.cancelCreate}
          errors={this.state.showErrors && this.state.errors}
          errorPages={this.state.showErrors && this.state.errorPages}
          location={location}
          isChangeOrder={reqn.isChangeOrder}
          draftButton={!reqn.status || reqn.status === 'draft'}
        >
          <div key="basic" idx={0} label="Basic Info">
            <OrderWizardBasicInfo
              {...{ comments, canNumber, ...this.inputs }}
              isChangeOrder={changeOrder || reqn.isChangeOrder}
              errors={errorFields}
              edit={editForm}
              internal={reqn.isInternal}
              isTD={order.isTD}
            />
          </div>
          {!changeOrder && !reqn.isChangeOrder && (
            <div key="project" idx={1} label="Project Info">
              <OrderWizardProjectInfo
                handleProjectSelect={this.handleProjectSelect}
                typeaheadVal={this.state.project}
                errors={errorFields}
                {...{ projects, comments, user, ...this.inputs }}
                edit={editForm}
              />
            </div>
          )}
          {!changeOrder && !reqn.isChangeOrder && (
            <div key="vendor" idx={2} label="Vendor Info">
              <OrderWizardVendorInfo
                handleVendorSelect={this.handleVendorSelect}
                typeaheadVal={this.state.vendor}
                errors={errorFields}
                {...{ vendors, comments, user, ...this.inputs }}
                edit={editForm}
              />
            </div>
          )}
          <div key="payment" idx={3} label="Payment Terms">
            <OrderWizardPaymentTerms
              {...{ reqn, ...this.inputs, ...this.inputCtrls }}
              errors={errorFields}
              edit={editForm}
              openComment={this.openCommentBox}
              comments={comments}
            />
          </div>
          <div key="other" idx={4} label="Other Details">
            <OrderWizardOtherDetails
              {...{
                reqn,
                ...this.inputs,
                ...this.inputCtrls,
                ...this.repeaterCtrls
              }}
              edit={editForm}
              errors={errorFields}
              comments={comments}
              openComment={this.openCommentBox}
              unsetTcsPdf={this.unsetTcsPdf}
              uploaderProps={{
                ...this.inputs,
                filesArray,
                totalProgress,
                save: this.saveFilesToOrder,
                toCancel: this.closeUploadModal,
                handleDrop: this.handleDropTc
              }}
            />
          </div>
          <div key="items" idx={5} label="Add Items">
            <OrderWizardItems
              errors={errorFields}
              {...{
                reqn,
                order,
                ...this.inputs,
                ...this.inputCtrls,
                ...this.repeaterCtrls
              }}
              edit={editForm}
              openComment={this.openCommentBox}
              comments={comments}
              isChangeOrder={changeOrder || reqn.isChangeOrder}
              useOld={this.state.useOld}
            />
          </div>
          <div key="notes" idx={6} label="Notes">
            <OrderWizardNotes
              {...this.inputs}
              errors={errorFields}
              edit={editForm}
              comments={comments}
            />
          </div>
          <div key="files" idx={7} label="Files">
            <OrderWizardUploadFiles>{this.createDrawerContent()}</OrderWizardUploadFiles>
          </div>
          <div key="emails" idx={8} label="Add Emails">
            <OrderWizardAddEmails {...{ order, ...this.inputCtrls, ...this.repeaterCtrls }} />
          </div>
        </Wizard>
      </div>
    );
  }
}

const mapStateToProps = ({
  createOrder,
  order,
  user,
  project,
  users,
  projects,
  vendors,
  createProject,
  createVendor,
  reqnDraftGet
}) => ({
  createOrder,
  order,
  user,
  project,
  users,
  projects,
  vendors,
  createProject,
  createVendor,
  reqnDraftGet
});

const mapDispatchToProps = dispatch => {
  const actions = {
    createOrderSubmit,
    fetchOrder,
    editOrder,
    fetchProjects,
    createOrderReset,
    fetchUsers,
    fetchVendors,
    setFileViewerFile,
    fetchReqnDraft,
    postReqnDraft
  };
  return { actions: bindActionCreators(actions, dispatch) };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateOrder);
