import { combineReducers } from 'redux';
import { routerReducer as routing } from 'react-router-redux';

import user from './user';
import users from './users';
import fetchUser from './fetchUser';
import passwordReset from './passwordReset';
import setFilterDrawer from './setFilterDrawer';
import collapseNav from './collapseNav';
import fileViewer from './fileViewer';

import media from './media';
import createMedia from './createMedia';
import fetchedMedia from './fetchedMedia';

import report from './report/report';
import reports from './report/reports';
import createReport from './report/createReport';
import activity from './report/activity';

import createProject from './createProject';
import createUser from './createUser';
import projects from './projects';
import project from './project';

import vendors from './vendor/vendors';
import vendor from './vendor/vendor';
import createVendor from './vendor/createVendor';

import orders from './order/orders';
import order from './order/order';
import createOrder from './order/createOrder';
import reqn from './order/reqn';
import reqnDiff from './order/reqnDiff';
import { reqnDraftGet, reqnDraftPost } from './order/reqnDraft';

import weather from './weather';

import settings from './settings';

// Combine reducers with routeReducer which keeps track of
// router state
const rootReducer = combineReducers({
  user,
  users,
  createUser,
  fetchUser,
  passwordReset,
  setFilterDrawer,
  collapseNav,
  fileViewer,

  fetchedMedia,
  media,
  createMedia,

  report,
  reports,
  createReport,
  activity,

  project,
  projects,
  createProject,

  vendors,
  vendor,
  createVendor,

  orders,
  order,
  createOrder,
  reqn,
  reqnDiff,
  reqnDraftGet,
  reqnDraftPost,

  weather,
  settings,

  routing
});

export default rootReducer;
