import React from 'react';
import { connect } from 'react-redux';

class Viewer extends React.Component {
  constructor(props) {
    super(props);

    this.mediaId = this.props.params.id;
    this.image = this.props.media.data.find(medium => medium._id === this.mediaId) || {};
  }

  render() {
    return (
      <div className="modal-image-container">
        <img
          src={this.image.url}
          alt={this.image.title}
          className="media-modal-image modal-box-shadow"
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({ media: state.media });

export default connect(mapStateToProps)(Viewer);
