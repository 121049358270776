import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link, browserHistory } from 'react-router';

import pick from 'lodash/pick';
import pickBy from 'lodash/pickBy';

import IoCloseCircled from 'react-icons/lib/io/close-circled';
import IoAlertCircled from 'react-icons/lib/io/alert-circled';
import IoEmailOutline from 'react-icons/lib/io/ios-email-outline';
import IoIosPlay from 'react-icons/lib/io/ios-play';

import { fetchUsers } from '../actions/users';
import { deleteUser } from '../actions/deleteUser';

import Loader from '../components/shared/Loader';
import Modal from '../components/shared/Modal';
import Alert from '../components/shared/Alert';
import Pagination from '../components/shared/Pagination';

import '../sass/containers/Reports/Index.scss';
import '../sass/containers/Users.scss';

import sevensonLogoText from '../images/sevenson-logo-text.png';
import trash from '../images/buttonIcons/trash.svg';

// eslint-disable-next-line global-require
const URL = global.URL || require('url').URL;

const getExpiry = () => Date.now() - 1000;

const searchFields = ['sort', 'page', 'searchName'];

class Users extends React.Component {
  static need = [fetchUsers];

  constructor(props) {
    super(props);

    this.state = {};

    this.updateSearch = this.updateSearch.bind(this);
    this.changeSort = this.changeSort.bind(this);
    this.deleteConfirm = this.deleteConfirm.bind(this);
    this.cancelDeleteModal = this.cancelDeleteModal.bind(this);
    this.deleteUserSubmit = this.deleteUserSubmit.bind(this);
    this.searchSubmit = this.searchSubmit.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
  }

  componentWillMount() {
    const { isFetching, lastUpdated } = this.props.users;
    if (!isFetching && lastUpdated < getExpiry()) {
      this.props.actions.fetchUsers();
    }
  }

  componentWillReceiveProps(nextProps) {
    const { actions } = this.props;
    const { location, users } = nextProps;
    const { isFetching, lastUpdated } = users;
    const { query } = location;

    if (!isFetching && !lastUpdated) {
      actions.fetchUsers(query);
    } else if (this.props.location && this.props.location.query) {
      const oldQuery = pick(this.props.location.query, searchFields);
      const newQuery = pick(query, searchFields);

      const changed = Object.entries(newQuery)
        .map(([key, val]) => oldQuery[key] !== val)
        .filter(Boolean).length;

      const keysRemoved = Object.keys(oldQuery).length > Object.keys(newQuery).length;

      if (!changed && !keysRemoved) return;
      actions.fetchUsers(newQuery);
    }
  }

  updateSearch(props) {
    const { location } = this.props;

    if (!Object.keys(props).includes('page')) {
      props.page = undefined;
    }

    const oldQuery = pick(location.query, searchFields);
    const newQuery = pick(props, searchFields);

    const query = pickBy(Object.assign(oldQuery, newQuery), Boolean);
    browserHistory.push({ ...location, query });
  }

  changeSort(_field) {
    return () => {
      let field = _field;
      if (this.props.location.query.sort === field) {
        field = `-${field}`;
      }
      this.updateSearch({ sort: field });
    };
  }

  searchSubmit(e) {
    e.preventDefault();

    const { value } = this.searchInput;
    const searchName = !value || /^\s+$/.test(value) ? undefined : value.trim();

    this.updateSearch({ searchName });
  }

  clearSearch() {
    this.updateSearch({ searchName: undefined });
  }

  deleteUserSubmit = id => () => {
    this.props.actions.deleteUser(id);
    this.setState({ deleteUserModal: undefined });
  };

  deleteConfirm = id => () => {
    this.setState({ deleteUserModal: id });
  };

  cancelDeleteModal = () => {
    this.setState({ deleteUserModal: undefined });
  };

  render() {
    const { users, location } = this.props;
    const { authLevel, authOverrides } = this.props.user.data;
    const canAddNewUsers = authLevel >= 2 || (authOverrides && authOverrides.users.create);

    let noResults;
    if (users.data.length === 0) {
      noResults = 'There are no results to display.';
    }

    let pageCnt;
    let thisPage;

    const { next, prev } = users;
    const { query } = location || {};

    if (next || prev) {
      try {
        const pagingSearch = new URL(next || prev).searchParams;

        pageCnt = parseInt(pagingSearch.get('pages'), 10) || 1;
        const queryPage = parseInt(pagingSearch.get('page'), 10);

        thisPage = parseInt(query.page, 10) || (next ? queryPage : queryPage + 2);
      } catch (e) {
        console.error(e);
      }
    }

    const searchFilterOn = !!query.searchName;

    let alert;
    if (users.error && users.error.message) {
      alert = (
        <Alert delay={5000} type="danger">
          <i>
            <IoAlertCircled />
          </i>
          &nbsp;
          {users.error.message}
        </Alert>
      );
    }

    let userAuth;
    if (authOverrides) {
      userAuth = authOverrides.users;
    }

    const allowedToEditUsers = authLevel >= 2 || (authOverrides && userAuth.edit);
    const allowedToDeleteUsers = authLevel >= 2 || (authOverrides && userAuth.delete);

    const editButtonClasses = classNames({
      'standard-button': true,
      'primary-button': true,
      disabled: !allowedToEditUsers
    });

    const deleteButtonClasses = classNames({
      'delete-button': true,
      'standard-button': true,
      disabled: !allowedToDeleteUsers
    });

    return (
      <div className="main-list-page users-container">
        {alert}
        <Loader isFetching={users.isFetching} />
        {this.state.deleteUserModal && (
          <Modal
            isOpen
            extraClass="delete-confirm-modal"
            ref={modal => {
              this.deleteModal = modal;
            }}
            toCancel={() => {
              this.setState({ deleteUserModal: undefined });
            }}
          >
            <div className="modal-text-top">
              <p className="modal-header">Are you sure?</p>
              <p className="modal-subheader">
                Delete this user? They will no longer be able to view or edit reports.
              </p>
            </div>
            <div className="modal-body">
              <div className="modal-buttons">
                <button
                  className="modal-button modal-delete"
                  onClick={this.deleteUserSubmit(this.state.deleteUserModal)}
                >
                  confirm
                </button>
                <button className="modal-button modal-cancel" onClick={this.cancelDeleteModal}>
                  cancel
                </button>
              </div>
            </div>
          </Modal>
        )}
        <div className="pre-table-header">
          <div className="report-top-section">
            <div className="top-section-left">
              <p className="page-title">Users</p>
            </div>
            <div className="top-section-right">
              <div className="search-filter-things">
                <form className="search-and-button">
                  <input
                    id="user-search"
                    className="search-bar"
                    type="text"
                    ref={el => (this.searchInput = el)}
                    placeholder="Search by name…"
                  />
                  <input type="submit" value="Search" onClick={this.searchSubmit} />
                </form>
                {searchFilterOn && (
                  <div className="filter-notification-text">
                    <span>Current Filters:</span>
                    <div className="filter-notification-type" onClick={this.clearSearch}>
                      Name: {this.state.searchName}
                      <span className="filter-clear-icon">
                        <IoCloseCircled />
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="add-and-sorts">
          {canAddNewUsers && (
            <Link
              to={{
                pathname: '/users/create',
                state: {
                  modal: true,
                  returnTo: this.props.location.pathname,
                  extraClass: 'add-edit-modal add-user-modal'
                }
              }}
              className="standard-button main-add-button primary-button"
            >
              Add New
            </Link>
          )}
          <ul className="sorts">
            <li onClick={this.changeSort('name')}>
              Name
              <IoIosPlay className={`sort-down ${query.sort === 'name'}`} />
              <IoIosPlay className={`sort-up ${query.sort === '-name'}`} />
            </li>
            <li onClick={this.changeSort('email')}>
              Email
              <IoIosPlay className={`sort-down ${query.sort === 'email'}`} />
              <IoIosPlay className={`sort-up ${query.sort === '-email'}`} />
            </li>
          </ul>
        </div>
        <div className="list-cards-container">
          {users.data.map(user => {
            return (
              <div className="list-card reports-table" key={user._id}>
                <div className="card-body">
                  <div className="group name">
                    <p className="body-field">Name</p>
                    <p className="emph">{[user.name.first, user.name.last].join(' ')}</p>
                  </div>
                  <div className="group email">
                    <p className="body-field">Email</p>
                    <p>
                      {' '}
                      {user.email ? (
                        <a href={`mailto:${user.email}`}>
                          {user.email}
                          <IoEmailOutline className="email-icon" />
                        </a>
                      ) : (
                        <p>None Provided</p>
                      )}
                    </p>
                  </div>
                  <div className="group phone">
                    <p className="body-field">Phone</p>
                    <p>{user.phone || 'None Provided'}</p>
                  </div>
                  <div className="group actions">
                    <p className="body-field">Actions</p>
                    <div className="button-contain">
                      <Link
                        title="Edit"
                        className={editButtonClasses}
                        to={{
                          pathname: `/users/${user._id}/edit`,
                          state: {
                            modal: true,
                            returnTo: this.props.location.pathname
                          }
                        }}
                      >
                        Edit
                      </Link>
                      {allowedToDeleteUsers && (
                        <button
                          title="Delete"
                          disabled={!allowedToDeleteUsers}
                          className={deleteButtonClasses}
                          onClick={this.deleteConfirm(user._id)}
                        >
                          <img src={trash} alt="trash can" />
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
          {noResults && (
            <div className="no-results">
              <span>{noResults}</span>
            </div>
          )}
        </div>
        <Pagination
          pages={pageCnt}
          activePage={thisPage}
          prev={!!users.prev}
          next={!!users.next}
          updateSearch={this.updateSearch}
        />
        <img src={sevensonLogoText} alt="sevenson logo" className="table-bottom-sevenson-logo" />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  users: state.users,
  createUser: state.createUserAction,
  user: state.user
});

const mapDispatchToProps = dispatch => {
  const actions = { fetchUsers, deleteUser };
  return { actions: bindActionCreators(actions, dispatch) };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Users);
