export const FETCH_VENDORS_REQUEST = 'FETCH_VENDORS_REQUEST';
export const FETCH_VENDORS_SUCCESS = 'FETCH_VENDORS_SUCCESS';
export const FETCH_VENDORS_FAILURE = 'FETCH_VENDORS_FAILURE';

export const FETCH_VENDOR_REQUEST = 'FETCH_VENDOR_REQUEST';
export const FETCH_VENDOR_SUCCESS = 'FETCH_VENDOR_SUCCESS';
export const FETCH_VENDOR_FAILURE = 'FETCH_VENDOR_FAILURE';

export const DELETE_VENDOR_REQUEST = 'DELETE_VENDOR_REQUEST';
export const DELETE_VENDOR_SUCCESS = 'DELETE_VENDOR_SUCCESS';
export const DELETE_VENDOR_FAILURE = 'DELETE_VENDOR_FAILURE';
