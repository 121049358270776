import axios from 'axios';
import * as types from '../constants/resetPassword';
import transAxoisErr from '../etc/axios/transformAxiosError';

export const resetPasswordRequest = () => ({type: types.RESET_PASSWORD_REQUEST});

export const resetPasswordSuccess = ({ data }) => ({
  data,
  receivedAt: Date.now(),
  type: types.RESET_PASSWORD_SUCCESS
});

export const resetPasswordFailure = error => ({
  error,
  receivedAt: Date.now(),
  type: types.RESET_PASSWORD_FAILURE
});

export const resetPassword = data => dispatch => {
  dispatch(resetPasswordRequest());
  return axios
    .post('/public/user/reset-password', { email: data.toLowerCase() })
    .then(res => dispatch(resetPasswordSuccess(res.data)))
    .catch(error => dispatch(resetPasswordFailure(transAxoisErr(error))));
};
