import axios from 'axios';
import * as types from '../constants/createProject';
import transAxoisErr from '../etc/axios/transformAxiosError';

export const editProjectRequest = () => ({type: types.EDIT_PROJECT_REQUEST});

export const editProjectSuccess = ({ data }) => ({
  data,
  receivedAt: Date.now(),
  type: types.EDIT_PROJECT_SUCCESS,
});

export const editProjectFailure = error => ({
  error,
  receivedAt: Date.now(),
  type: types.EDIT_PROJECT_FAILURE
});

export const editProject = (data, id) => (dispatch) => {
  dispatch(editProjectRequest());
  return axios
    .put(`/project/${id}`, data)
    .then(res => dispatch(editProjectSuccess(res.data)))
    .catch(error => dispatch(editProjectFailure(transAxoisErr(error))));
};
