/* eslint jsx-a11y/no-static-element-interactions: 0 */

import React from 'react';
import moment from 'moment';
import { Link, browserHistory } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import IoAlertCircled from 'react-icons/lib/io/alert-circled';

import get from 'lodash/get';
import set from 'lodash/set';

import TextInput from '../components/shared/forms/TextInput';
import Alert from '../components/shared/Alert';
import Tabs from '../components/shared/Tabs';
import { createUserAction, createUserReset } from '../actions/createUser';
import { editUser } from '../actions/editUser';
import { fetchUser as fetchUserAction } from '../actions/fetchUser';
import { sdp } from '../actions/users';

require('../sass/containers/Reports/CreateReport.scss');
require('../sass/containers/Users/CreateUser.scss');

const authOptions = [null, 'Basic (DPR)', 'Engineer (DPR)', 'Admin (DPR)'];
const reportCheckboxes = [
  ['View Reports', 'view'],
  ['View Unpublished Reports', 'viewUnpublished'],
  ['Create New Reports (Save as Draft)', 'create'],
  ['Edit Reports (Save as Draft)', 'edit'],
  ['Clone Reports (Save as Draft)', 'clone'],
  ['Publish Reports', 'publish'],
  ['Delete Reports', 'delete']
];
const userCheckboxes = [
  ['View Users', 'view'],
  ['Edit Users', 'edit'],
  ['Create Users', 'create'],
  ['Delete Users', 'delete']
];
const projectCheckboxes = [
  ['View Projects', 'view'],
  ['Create Projects', 'create'],
  ['Edit Projects', 'edit'],
  ['Delete Projects', 'delete']
];
const mediaCheckboxes = [
  ['View Media', 'view'],
  ['Create Media', 'create'],
  ['Edit Media', 'edit'],
  ['Delete Media', 'delete']
];
const ordersCheckboxes = [
  ['View Orders', 'view'],
  ['Edit Orders', 'edit'],
  ['Create Orders', 'create'],
  ['Delete Orders', 'delete'],
  ['Approve', 'approve'],
  ['Assign Reqn #s', 'number'],
  ['Mark as Complete', 'sign']
];
const vendorsCheckboxes = [
  ['View Vendors', 'view'],
  ['Edit Vendors', 'edit'],
  ['Create Vendors', 'create'],
  ['Delete Vendors', 'delete'],
  ['Assign Vendor #s', 'number']
];
const adminCheckboxes = [['System Admin Priveledges', 'editSystem']];

const getInitialAuthState = () => ({
  reports: {
    view: false,
    edit: false,
    clone: false,
    create: false,
    delete: false,
    publish: false,
    viewUnpublished: false
  },
  projects: {
    view: false,
    edit: false,
    create: false,
    delete: false
  },
  media: {
    view: false,
    edit: false,
    create: false,
    delete: false
  },
  users: {
    view: false,
    edit: false,
    create: false,
    delete: false
  },
  orders: {
    view: false,
    edit: false,
    create: false,
    delete: false,
    approve: false,
    number: false,
    sign: false
  },
  vendors: {
    view: false,
    edit: false,
    create: false,
    delete: false,
    number: false
  },
  admin: { editSystem: false }
});

const getBasicDPRAuthState = () => ({
  reports: {
    view: true,
    edit: false,
    clone: false,
    create: false,
    delete: false,
    publish: false,
    viewUnpublished: false
  },
  projects: {
    view: false,
    edit: false,
    create: false,
    delete: false
  },
  media: {
    view: true,
    edit: false,
    create: false,
    delete: false
  },
  users: {
    view: false,
    edit: false,
    create: false,
    delete: false
  },
  orders: {
    view: false,
    edit: false,
    create: false,
    delete: false,
    approve: false,
    number: false,
    sign: false
  },
  vendors: {
    view: false,
    edit: false,
    create: false,
    delete: false,
    number: false
  },
  admin: { editSystem: false }
});

const getEngineerAuthState = () => ({
  reports: {
    view: true,
    edit: true,
    clone: true,
    create: true,
    delete: false,
    publish: true,
    viewUnpublished: true
  },
  projects: {
    view: false,
    edit: false,
    create: false,
    delete: false
  },
  media: {
    view: true,
    edit: true,
    create: true,
    delete: false
  },
  users: {
    view: false,
    edit: false,
    create: false,
    delete: false
  },
  orders: {
    view: false,
    edit: false,
    create: false,
    delete: false,
    approve: false,
    number: false,
    sign: false
  },
  vendors: {
    view: false,
    edit: false,
    create: false,
    delete: false,
    number: false
  },
  admin: { editSystem: false }
});

const getAdminAuthState = () => ({
  reports: {
    view: true,
    edit: true,
    clone: true,
    create: true,
    delete: true,
    publish: true,
    viewUnpublished: true
  },
  projects: {
    view: true,
    edit: true,
    create: true,
    delete: true
  },
  media: {
    view: true,
    edit: true,
    create: true,
    delete: true
  },
  users: {
    view: true,
    edit: true,
    create: true,
    delete: true
  },
  orders: {
    view: false,
    edit: false,
    create: false,
    delete: false,
    approve: false,
    number: false,
    sign: false
  },
  vendors: {
    view: false,
    edit: false,
    create: false,
    delete: false,
    number: false
  },
  admin: { editSystem: false }
});

const getSuperAuthState = () => ({
  reports: {
    view: true,
    edit: true,
    clone: true,
    create: true,
    delete: true,
    publish: true,
    viewUnpublished: true
  },
  projects: {
    view: true,
    edit: true,
    create: true,
    delete: true
  },
  media: {
    view: true,
    edit: true,
    create: true,
    delete: true
  },
  users: {
    view: true,
    edit: true,
    create: true,
    delete: true
  },
  orders: {
    view: true,
    edit: true,
    create: true,
    delete: true,
    approve: true,
    number: true,
    sign: true
  },
  vendors: {
    view: true,
    edit: true,
    create: true,
    delete: true,
    number: true
  },
  admin: { editSystem: true }
});

class CreateUser extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {
        name: {
          first: '',
          last: ''
        },
        email: '',
        phone: '',
        username: '',
        createdAt: moment().format('YYYY-MM-DD'),
        image: '',
        authLevel: '',
        authOverrides: getInitialAuthState()
      }
    };

    this.getValueFromId = this.getValueFromId.bind(this);
    this.handleSDPassword = this.handleSDPassword.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  componentWillMount() {
    if (this.props.route.path === 'users/:id/edit') {
      this.setState({ editForm: true });
      this.props.actions.fetchUserAction(this.props.params);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.fetchUser.data !== nextProps.fetchUser.data) {
      const fetchedUser = nextProps.fetchUser.data;
      this.setState({ user: fetchedUser });
    } else if (nextProps.createUser.submitted) {
      browserHistory.push(this.props.location.state.returnTo);
    }
  }

  componentWillUnmount() {
    if (this.props.createUser.submitted) {
      this.props.actions.createUserReset();
    }
  }

  getValueFromId(id) {
    return get(this.state.user, id);
  }

  handleSDPassword(e) {
    e.preventDefault();
    if (window.confirm('Are you sure you want to set this user’s password to the default?')) {
      this.props.actions.sdp(this.state.user._id);
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    const { authOverrides } = this.state.user;

    if (!authOverrides.reports.view && !authOverrides.orders.view) {
      this.setState({ submitError: 'Either View Reports or View Orders must be checked.' });
      window.setTimeout(() => this.setState({ submitError: false }), 5100);
      return;
    }

    if (this.state.editForm) {
      this.props.actions.editUser(this.state.user, this.props.params.id);
    } else {
      this.props.actions.createUserAction(this.state.user);
    }
  }

  handleInputChange(key, value = 'value', base) {
    return event => {
      const val = event.target[value];
      const nextState = { user: set({ ...this.state.user }, key, event._id || val) };

      if (key === 'authLevel') {
        if (parseInt(val, 10) === -1) {
          nextState.user.authOverrides = getInitialAuthState();
        } else if (parseInt(val, 10) === 0) {
          nextState.user.authOverrides = getBasicDPRAuthState();
        } else if (parseInt(val, 10) === 1) {
          nextState.user.authOverrides = getEngineerAuthState();
        } else if (parseInt(val, 10) === 2) {
          nextState.user.authOverrides = getAdminAuthState();
        } else if (parseInt(val, 10) === 100) {
          nextState.user.authOverrides = getSuperAuthState();
        }
      }

      const permission = key.split('.').pop();
      const baseObj = nextState.user.authOverrides[base];

      if (base && permission !== 'view' && value === 'checked') {
        baseObj.view = true;
      } else if (base && permission === 'view' && !val) {
        Object.keys(baseObj).forEach(k => (baseObj[k] = false));
      }

      this.setState(nextState);
    };
  }

  render() {
    const { createUser, user: currentUser } = this.props;
    const { user, submitError } = this.state;
    const defaultInputProps = {
      valueGetter: this.getValueFromId,
      onChange: this.handleInputChange
    };
    const selectedAuthLevel = parseInt(this.state.user.authLevel, 10);

    let returnTo = false;
    if (this.props.location.state && this.props.location.state.returnTo) {
      // eslint-disable-next-line prefer-destructuring
      returnTo = this.props.location.state.returnTo;
    }

    let alert;
    if (createUser.error && createUser.error.message) {
      alert = (
        <Alert delay={5000} type="danger">
          <i>
            <IoAlertCircled />
          </i>
          &nbsp;
          {createUser.error.message}
        </Alert>
      );
    }
    if (submitError) {
      alert = (
        <Alert delay={5000} type="danger">
          <i>
            <IoAlertCircled />
          </i>
          &nbsp;
          {submitError}
        </Alert>
      );
    }

    return (
      <div className="create-edit-modal">
        {alert}
        <p className="modal-header">
          {user.name.first || user.name.last
            ? [user.name.first, user.name.last].join(' ')
            : 'New User'}
        </p>
        <Tabs
          ref={el => {
            this.tabs = el;
          }}
        >
          <div idx={0} label="Contact Info">
            <form className="modal-form" id="create-user-form">
              <p className="form-section-title">Contact Information</p>
              <div className="input-fields">
                <div className="modal-form-block">
                  <TextInput labelText="First Name" inputId="name.first" {...defaultInputProps} />
                  <TextInput labelText="Last Name" inputId="name.last" {...defaultInputProps} />
                </div>
                <div className="modal-form-block">
                  <TextInput required labelText="Email" inputId="email" {...defaultInputProps} />
                  <TextInput
                    labelText="Phone"
                    inputId="phone"
                    inputType="tel"
                    {...defaultInputProps}
                  />
                </div>
                <div className="modal-form-block">
                  <TextInput labelText="Username" inputId="username" {...defaultInputProps} />
                  {this.state.editForm && (
                    <button
                      style={{ float: 'right' }}
                      className="form-button primary-button"
                      onClick={this.handleSDPassword}
                    >
                      Set Default Password
                    </button>
                  )}
                </div>
              </div>
              <p
                className="next-tab"
                onClick={() => {
                  this.tabs.setTab(this.tabs.state.tabIdx + 1);
                }}
              >
                Next
              </p>
              <div className="form-submit-buttons">
                <input
                  className="form-button primary-button"
                  type="submit"
                  onClick={this.handleSubmit}
                  value="Save"
                />
                {returnTo && (
                  <Link to={returnTo} className="div-link-button form-button cancel-button">
                    Cancel
                  </Link>
                )}
              </div>
            </form>
          </div>
          <div idx={1} label="Permissions">
            <form className="modal-form" id="create-user-form">
              <p className="form-section-title">Access Level</p>
              <select
                id="authLevel"
                form="create-user-form"
                className="standard-select"
                value={this.getValueFromId('authLevel')}
                onChange={this.handleInputChange('authLevel')}
              >
                {authOptions.map((authType, idx) => (
                  <option value={idx - 1} key={authType}>
                    {authType}
                  </option>
                ))}
                {currentUser.data.authLevel === 100 && (
                  <option value={100} key="super">
                    Super (all)
                  </option>
                )}
              </select>
              <p className="form-section-title">System Permissions</p>
              <p className="form-section-info">Add or remove specific permissions for this user.</p>
              <div className="checkbox-series">
                <div className="form-checkbox-column">
                  <p className="form-checkbox-category">Users</p>
                  {/* TODO: these maps should be made into a function that is reused */}
                  {userCheckboxes.map(option => (
                    <label htmlFor={`user.${option[1]}`} key={option[1]}>
                      <input
                        id={`user.${option[1]}`}
                        type="checkbox"
                        disabled={
                          (selectedAuthLevel === 1 && getEngineerAuthState().users[option[1]]) ||
                          (selectedAuthLevel === 2 && getAdminAuthState().users[option[1]]) ||
                          (selectedAuthLevel === 100 && getSuperAuthState().users[option[1]])
                        }
                        checked={this.getValueFromId(`authOverrides.users.${option[1]}`)}
                        onChange={this.handleInputChange(
                          `authOverrides.users.${option[1]}`,
                          'checked',
                          'users'
                        )}
                      />
                      {option[0]}
                    </label>
                  ))}
                </div>
                <div className="form-checkbox-column">
                  <p className="form-checkbox-category">Projects</p>
                  {projectCheckboxes.map(option => (
                    <label htmlFor={`project.${option[1]}`} key={option[1]}>
                      <input
                        id={`project.${option[1]}`}
                        type="checkbox"
                        disabled={
                          (selectedAuthLevel === 1 && getEngineerAuthState().projects[option[1]]) ||
                          (selectedAuthLevel === 2 && getAdminAuthState().projects[option[1]]) ||
                          (selectedAuthLevel === 100 && getSuperAuthState().projects[option[1]])
                        }
                        checked={this.getValueFromId(`authOverrides.projects.${option[1]}`)}
                        onChange={this.handleInputChange(
                          `authOverrides.projects.${option[1]}`,
                          'checked',
                          'projects'
                        )}
                      />
                      {option[0]}
                    </label>
                  ))}
                </div>
                <div className="form-checkbox-column">
                  <p className="form-checkbox-category">Admin</p>
                  {adminCheckboxes.map(option => {
                    return (
                      <label htmlFor={`admin.${option[1]}`} key={option[1]}>
                        <input
                          id={`admin.${option[1]}`}
                          type="checkbox"
                          disabled={
                            selectedAuthLevel === 100 && getSuperAuthState().admin[option[1]]
                          }
                          checked={this.getValueFromId(`authOverrides.admin.${option[1]}`)}
                          onChange={this.handleInputChange(
                            `authOverrides.admin.${option[1]}`,
                            'checked'
                          )}
                        />
                        {option[0]}
                      </label>
                    );
                  })}
                </div>
              </div>
              <p className="form-section-title">DPR Permissions</p>
              <div className="checkbox-series">
                <div className="form-checkbox-column">
                  <p className="form-checkbox-category">Reports</p>
                  {reportCheckboxes.map(option => (
                    <label htmlFor={`report.${option[1]}`} key={option[1]}>
                      <input
                        id={`report.${option[1]}`}
                        type="checkbox"
                        disabled={
                          (selectedAuthLevel === 0 && getBasicDPRAuthState().reports[option[1]]) ||
                          (selectedAuthLevel === 1 && getEngineerAuthState().reports[option[1]]) ||
                          (selectedAuthLevel === 2 && getAdminAuthState().reports[option[1]]) ||
                          (selectedAuthLevel === 100 && getSuperAuthState().reports[option[1]])
                        }
                        checked={this.getValueFromId(`authOverrides.reports.${option[1]}`)}
                        onChange={this.handleInputChange(
                          `authOverrides.reports.${option[1]}`,
                          'checked',
                          'reports'
                        )}
                      />
                      {option[0]}
                    </label>
                  ))}
                </div>
                <div className="form-checkbox-column">
                  <p className="form-checkbox-category">Media</p>
                  {mediaCheckboxes.map(option => (
                    <label htmlFor={`media.${option[1]}`} key={option[1]}>
                      <input
                        id={`media.${option[1]}`}
                        type="checkbox"
                        disabled={
                          (selectedAuthLevel === 0 && getBasicDPRAuthState().media[option[1]]) ||
                          (selectedAuthLevel === 1 && getEngineerAuthState().media[option[1]]) ||
                          (selectedAuthLevel === 2 && getAdminAuthState().media[option[1]]) ||
                          (selectedAuthLevel === 100 && getSuperAuthState().media[option[1]])
                        }
                        checked={this.getValueFromId(`authOverrides.media.${option[1]}`)}
                        onChange={this.handleInputChange(
                          `authOverrides.media.${option[1]}`,
                          'checked',
                          'media'
                        )}
                      />
                      {option[0]}
                    </label>
                  ))}
                </div>
              </div>
              <p className="form-section-title">Order Permissions</p>
              <div className="checkbox-series">
                <div className="form-checkbox-column">
                  <p className="form-checkbox-category">Orders</p>
                  {ordersCheckboxes.map(option => (
                    <label htmlFor={`orders.${option[1]}`} key={option[1]}>
                      <input
                        id={`orders.${option[1]}`}
                        type="checkbox"
                        disabled={
                          selectedAuthLevel === 100 && getSuperAuthState().orders[option[1]]
                        }
                        checked={this.getValueFromId(`authOverrides.orders.${option[1]}`)}
                        onChange={this.handleInputChange(
                          `authOverrides.orders.${option[1]}`,
                          'checked',
                          'orders'
                        )}
                      />
                      {option[0]}
                    </label>
                  ))}
                </div>
                <div className="form-checkbox-column">
                  <p className="form-checkbox-category">Vendors</p>
                  {vendorsCheckboxes.map(option => (
                    <label htmlFor={`vendors.${option[1]}`} key={option[1]}>
                      <input
                        id={`vendors.${option[1]}`}
                        type="checkbox"
                        disabled={
                          selectedAuthLevel === 100 && getSuperAuthState().vendors[option[1]]
                        }
                        checked={this.getValueFromId(`authOverrides.vendors.${option[1]}`)}
                        onChange={this.handleInputChange(
                          `authOverrides.vendors.${option[1]}`,
                          'checked',
                          'vendors'
                        )}
                      />
                      {option[0]}
                    </label>
                  ))}
                </div>
              </div>
              <p
                className="prev-tab"
                onClick={() => {
                  this.tabs.setTab(this.tabs.state.tabIdx - 1);
                }}
              >
                Previous
              </p>
              <div className="form-submit-buttons">
                <input
                  className="form-button primary-button"
                  type="submit"
                  onClick={this.handleSubmit}
                  value="Save"
                />
                {returnTo && (
                  <Link to={returnTo} className="div-link-button form-button cancel-button">
                    Cancel
                  </Link>
                )}
              </div>
            </form>
          </div>
        </Tabs>
      </div>
    );
  }
}

const mapStateToProps = ({ createUser, fetchUser, user }) => ({ createUser, user, fetchUser });

const mapDispatchToProps = dispatch => {
  const actions = {
    createUserAction,
    editUser,
    fetchUserAction,
    createUserReset,
    sdp
  };
  return { actions: bindActionCreators(actions, dispatch) };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateUser);
