import axios from 'axios';
import * as types from '../constants/media';
import transAxoisErr from '../etc/axios/transformAxiosError';

export const fetchMediaRequest = () => ({ type: types.FETCH_MEDIA_REQUEST });

export const fetchMediaSuccess = ({ data, next, prev }) => ({
  data,
  next,
  prev,
  receivedAt: Date.now(),
  type: types.FETCH_MEDIA_SUCCESS
});

export const fetchMediaFailure = error => ({
  error,
  receivedAt: Date.now(),
  type: types.FETCH_MEDIA_FAILURE
});

export const fetchMedia = ({ projectRef, page } = {}) => dispatch => {
  dispatch(fetchMediaRequest());
  return axios
    .get('/media/all', { params: { projectRef, page } })
    .then(res => dispatch(fetchMediaSuccess(res.data)))
    .catch(error => dispatch(fetchMediaFailure(transAxoisErr(error))));
};
