import axios from 'axios';
import * as types from '../../constants/vendor/createVendor';
import transAxoisErr from '../../etc/axios/transformAxiosError';

export const createVendorRequest = () => ({type: types.CREATE_VENDOR_REQUEST});

export const createVendorReset = () => ({type: types.CREATE_VENDOR_RESET});

export const createVendorSuccess = ({ data }) => ({
  data,
  receivedAt: Date.now(),
  type: types.CREATE_VENDOR_SUCCESS,
});

export const createVendorFailure = error => ({
  error,
  receivedAt: Date.now(),
  type: types.CREATE_VENDOR_FAILURE
});

export const createVendorSubmit = data => (dispatch) => {
  dispatch(createVendorRequest());
  return axios
    .post('/vendor', data)
    .then(res => dispatch(createVendorSuccess(res.data)))
    .catch(error => dispatch(createVendorFailure(transAxoisErr(error))));
};
