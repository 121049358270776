import React from 'react';
import { pathToRegexp } from 'path-to-regexp';
import { Route, IndexRedirect } from 'react-router';
import { routerActions } from 'react-router-redux';
import { connectedReduxRedirect } from 'redux-auth-wrapper/history3/redirect';

import get from 'lodash/get';
import memoize from 'lodash/memoize';

import App from './containers/App';
import Login from './containers/Login';
import Reset from './containers/Reset';
import UnAuth from './containers/UnAuth';
import Dash from './containers/Dash';
import Users from './containers/Users';
import CreateUser from './containers/CreateUser';
import Media from './containers/Media/Media';
import CreateMedia from './containers/Media/CreateMedia';
import Viewer from './containers/Media/Viewer';
import Report from './containers/Report/Report';
import Reports from './containers/Report/Reports';
import Orders from './containers/Order/Orders';
import CreateOrder from './containers/Order/CreateOrder';
import EditOrder from './containers/Order/EditOrder';
import OrderContainer from './containers/Order/OrderContainer';
import RequisitionView from './containers/Order/RequisitionView';
import CreateReport from './containers/Report/CreateReport';
import ActivityLog from './containers/ActivityLog';
import Projects from './containers/Projects';
import CreateProject from './containers/CreateProject';
import Vendors from './containers/Vendor/Vendors';
import CreateVendor from './containers/Vendor/CreateVendor';
import Account from './containers/Account';
import Settings from './containers/Settings';
import ErrorPage from './components/shared/ErrorPage';
import MissingPage from './components/shared/MissingPage';

const routeRules = {
  '/reports': {
    level: 0,
    override: 'reports.view'
  },
  '/reports/create': {
    level: 1,
    override: 'reports.create'
  },
  '/reports/:id/edit': {
    level: 1,
    override: 'reports.edit'
  },
  '/reports/:id/clone': {
    level: 1,
    override: 'reports.clone'
  },

  '/media': {
    level: 0,
    override: 'media.view'
  },
  '/media/create': {
    level: 1,
    override: 'media.create'
  },
  '/media/:id/edit': {
    level: 1,
    override: 'media.edit'
  },

  '/projects': {
    level: 2,
    override: 'projects.view'
  },
  '/projects/:id/edit': {
    level: 2,
    override: 'projects.edit'
  },

  '/users': {
    level: 2,
    override: 'users.view'
  },
  '/users/create': {
    level: 2,
    override: 'users.create'
  },
  '/users/:id/edit': {
    level: 2,
    override: 'users.edit'
  },

  '/orders': { level: 100, override: 'orders.view' },
  '/orders/:id': { level: 100, override: 'orders.view' },
  '/orders/:id/edit': { level: 100, override: 'orders.edit' },
  '/orders/:id/reqns/:reqnId': { level: 100, override: 'orders.view' },

  '/vendors': { level: 100, override: 'vendors.view' },
  '/vendors/create': { level: 100, override: 'vendors.create' },
  '/vendors/:id/edit': { level: 100, override: 'vendors.edit' }
};

const lookupRouteRule = memoize(path => {
  const rule = Object.keys(routeRules).find(route =>
    pathToRegexp(route).test(path)
  );
  return rule && routeRules[rule];
});

const isAuthorized = (user, authRule) =>
  user.authLevel >= authRule.level ||
  get(user.authOverrides, authRule.override);

const UserIsAuthenticated = connectedReduxRedirect({
  authenticatedSelector: (state, ownProps) => {
    const user = state.user.data;
    const path = ownProps.location.pathname;
    if (!user) {
      return false;
    }
    const authRule = lookupRouteRule(path);
    return !authRule || isAuthorized(user, authRule);
  },
  redirectPath: state => (state.user.data ? '/401' : '/login'),
  allowRedirectBack: (location, redirectPath) => redirectPath === '/login',
  wrapperDisplayName: 'UserIsAuthenticated',
  redirectAction: routerActions.replace
});

/*
 * @param {Redux Store}
 * We require store as an argument here because we wish to get
 * state from the store after it has been authenticated.
 */
export default store => {
  const userMeta = store.getState().user;
  const user = userMeta.data;

  const indexRedir =
    user && user.authOverrides.orders.view ? 'orders' : 'reports';

  return (
    <Route path="/" component={App}>
      <Route path="404" component={MissingPage} />
      <Route path="500" component={ErrorPage} />
      <Route path="login" component={Login} />
      <Route path="reset" component={Reset} />
      <Route component={UserIsAuthenticated(Dash)}>
        <Route path="401" component={UnAuth} />

        <IndexRedirect to={indexRedir} />

        <Route path="reports" component={Reports} />
        <Route path="reports/create" component={CreateReport} />
        <Route path="reports/all/activity" component={ActivityLog} />
        <Route path="reports/:id" component={Report} />
        <Route path="reports/:id/edit" component={CreateReport} />
        <Route path="reports/:id/clone" component={CreateReport} />
        <Route path="reports/:report/activity" component={ActivityLog} />

        <Route path="orders" component={Orders} />
        <Route path="orders/create" component={CreateOrder} />
        <Route path="orders/:id" component={OrderContainer} />
        <Route path="orders/:id/edit" component={EditOrder} />
        <Route path="orders/:id/reqns/create" component={CreateOrder} />
        <Route path="orders/:id/reqns/:reqnId" component={RequisitionView} />
        <Route path="orders/:id/reqns/:reqnId/edit" component={CreateOrder} />

        <Route path="media" component={Media} />
        <Route path="media/create" component={CreateMedia} />
        <Route path="media/:id" component={Viewer} />
        <Route path="media/:id/edit" component={CreateMedia} />
        <Route path="media/project/:projectId" component={Media} />

        <Route path="projects" component={Projects} />
        <Route path="projects/create" component={CreateProject} />
        <Route path="projects/:id/edit" component={CreateProject} />

        <Route path="users" component={Users} />
        <Route path="users/create" component={CreateUser} />
        <Route path="users/:id/edit" component={CreateUser} />
        <Route path="account" component={Account} />

        <Route path="vendors" component={Vendors} />
        <Route path="vendors/create" component={CreateVendor} />
        <Route path="vendors/:id/edit" component={CreateVendor} />

        <Route path="settings" component={Settings} />
      </Route>
    </Route>
  );
};
