import axios from 'axios';
import * as types from '../constants/createMedia';
import transAxoisErr from '../etc/axios/transformAxiosError';

export const editMediaRequest = () => ({type: types.EDIT_MEDIA_REQUEST});

export const editMediaSuccess = ({ data }) => ({
  data,
  receivedAt: Date.now(),
  type: types.EDIT_MEDIA_SUCCESS,
});

export const editMediaFailure = error => ({
  error,
  receivedAt: Date.now(),
  type: types.EDIT_MEDIA_FAILURE
});

export const editMedia = (data, id) => (dispatch) => {
  dispatch(editMediaRequest());
  return axios
    .put(`/media/${id}`, data)
    .then(res => dispatch(editMediaSuccess(res.data)))
    .catch(error => dispatch(editMediaFailure(transAxoisErr(error))));
};
